import React, { useState } from "react";
import { successNotification,errorNotification } from "../../../../utils/notifications"
import API from "../../../../utils/api";
import { trackPromise } from "react-promise-tracker";

const OnHold = ({closeModal, data, updatedData}) => {

  const [formValue, setFormValue] = useState({
    onholdReason: "",
    Description: "",
  });

  const refresh = () => {
    updatedData();
  }

  const finalObj = {
    status:"On Hold By Finance",
    BOMID:data.BOMID,
    POID:data.POID,
    reason:formValue.onholdReason,
    ...(formValue.onholdReason === "Other" && {
      Description:formValue.Description
    })
  }

  const submitHandler = async() => {
    const resp = await trackPromise(API.put(`finance/api/v1/newrequest/actionstatus`,finalObj))  
         
  const { success,message} = resp;

  if (success) {

    closeModal(false)
      successNotification(message, { autoClose: 3000 });
      refresh()
  } else {
      errorNotification(message, { autoclose: 2000 });
  }
   
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Hold BOM Request</h3> 
        </div>
        <div className="body">
          <label>
            <select
              name="onholdReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option disabled selected hidden>
                On Hold Reason*
              </option>
              <option value="Vendor not approved yet">
              Vendor not approved yet
              </option>
              <option value="Supplies delayed by vendors">
              Supplies delayed by vendors
              </option>
              <option value="Other">Other</option>
            </select>
          </label>
              {formValue.onholdReason === "Other" ? (
                  <>
                  <label className="lgInput">
            Description
            <input
              type="text"
              style= {{ width: "508px" }}
              name="Description"
              value={formValue.Description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="On Hold Reason*"
              autoComplete="off"
            />
          </label>
                  </>

              ):null }
          
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnHold;
