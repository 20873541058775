import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import moment from "moment";
import "../../work-order/workorder.scss";
import { KeyboardBackspace } from "@material-ui/icons";

const ReceivedQtySP = ({ orderID, close, newArray, data, rowdata, updatedData, action }) => {
  console.log(newArray)
  const filteredData = data?.filter(item => item !== null && item !== undefined);

  let filterData = filteredData?.flat();
  console.log(rowdata)
  console.log(rowdata?.createdDate)
  // console.log(tempArr)

  const tempArr = []
  filterData?.partsSno?.map((item) => {
    if (!item) {
      return
    } else {
      tempArr.push({ ...item })
    }
  })

  const refresh = () => {
    updatedData();
  };

  const addedHandler = async (item) => {
    let body = {
      "orderID": orderID,
      "componentID": rowdata?.componentID,
      "partSno": item?.partSno
    }
    const resp = await trackPromise(API.post(`shop-floor-module/api/v1/shopFloor/addToWIPInventory`, body));
    console.log(resp);
    refresh();
  };
  useEffect(() => {
  }, [rowdata]);

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "655px", height: "471px", padding: "15px" }}
        >
          <div className="header">
            <h3>Added Qty.</h3>
          </div>

          <div className="body" style={{ height: "315px" }}>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Component Name: {rowdata?.componentName}</span>
                <span>Category: {rowdata?.category}</span>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Component SKU Code: {rowdata?.skuCode}</span>
                <span>Sub Category: {rowdata?.subCategory}</span>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span> Receipt Date: {moment(rowdata?.hsnSacAddedOn).format("DD-MMM-YYYY/HH:mm")} </span>
                <span>Received Qty: {(action == "woInProgress" ? filterData?.length : newArray?.length)}</span>
              </div>
            </div>
<br />
            <div style={{ width: "100%", overflow: "auto", height: "265px" }}>
              <table className="simple_tbl" width="100%">
                <thead>
                  <tr style={{border:"none"}}>
                    <th>S.No.</th>
                    <th>Part Serial No.</th>
                    {/* <th>Status</th>
                    <th style={{width:"193px"}}>Action</th> */}
                  </tr>
                </thead>

                {(action == "woInProgress" ? filterData : newArray[0])?.map((item, index,arr) => {
                  console.log("check--- ",item.partSno, "  arr length== ",arr.length, "check item== ",item)
                  return (
                    <tr style={{border:"none"}}>
                      <td style={{fontSize:"13px"}}>{index + 1}</td>
                      <td style={{fontSize:"13px"}}>{item?.partSno}</td>
                      {/* {item?.status == "Passed" ?
                        <td style={{fontSize:"13px"}}>
                          <button className="reactive-btn"><span className="reactive-btn-font">New</span></button>
                        </td>
                        : item?.status == "Added To WIP" ?
                          <td style={{fontSize:"13px"}}>
                            <button className="active-btn"><span className="active-btn-font">Added</span></button>
                          </td> : ""}
                      <td style={{width:"193px", fontSize:"13px"}}>
                        {item?.status == "Passed" ?
                          <button
                            className="action-btn"
                            onClick={() => addedHandler(item)}
                            style={{ color: "blue", border: "1px solid blue", borderRadius: "3px" }}>
                            Add to WIP Inventory
                          </button> : ""}
                      </td> */}
                    </tr>
                  )
                })}

              </table>
            </div>
          </div>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}><KeyboardBackspace />Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceivedQtySP;