import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { KeyboardBackspace } from "@material-ui/icons";
import "../../work-order/workorder.scss";
import ViewSpecs from "../../wo-shopfloor/wo-sf-actions/wo-sf-viewspecs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle, faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "../BomCosting.scss";
import { errorNotification, successNotification } from "../../../utils/notifications";
import auth from "../../../utils/auth";

const ViewEditComponents = ({ close, rowdata }) => {
    const [specsShow, setSpecsShow] = useState(false);
    const [componentObj, setComponentObj] = useState({});
    const [onEditClick, setEditClick] = useState(false);
    const [editAction, setEditAction] = useState();
    const [showEdited, setEdited] = useState(false);
    const [blankForm, setBlankForm] = useState([]);
    const [editedVendorData, setEditedVendorData] = useState([]);
    const [selectedVendorData, setSelectedVendorData] = useState({});
    const [vendorIDData, setVendorID] = useState({});
    const [olderData, setOlderData] = useState();

    const fetchReport = async () => {
        const resp = await trackPromise(API.get(`procurement-module/api/v1/boms/viewComponents?BOMID=${rowdata.BOMID}`));
        console.log(resp);
        setComponentObj(resp);
        setBlankForm(resp.components);
    };
    useEffect(() => {
        fetchReport();
    }, []);




    const actionHandler = async (item, index) => {
        setVendorID(item);
        const resp = await trackPromise(API.get(`procurement-module/api/v1/boms/getVendorsByComponentID?BOMID=${rowdata.BOMID}&componentID=${item?.componentID}`));
        setEditedVendorData(resp);
        setEditAction(index);
        setEditClick(true);
    };

    // const refresh = () => {
    //     updatedData();
    // }
    const submitEdit = async (item, index) => {
        let finalBody = {
            "BOMID": rowdata?.BOMID,
            "componentID": vendorIDData?.componentID,
            "oldVendorID": item?.vendor?.vendorID,
            "vendorID": olderData
        };
        console.log(finalBody);
        const resp = await trackPromise(API.put(`procurement-module/api/v1/boms/editComponents`, finalBody));
        resp.success == false ? errorNotification(resp.message, { autoClose: 3000 }) : successNotification(resp.message, { autoClose: 3000 });
        fetchReport();
        setEditClick(false);
        setEdited(index)
    };

    const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        const field = [...blankForm];
        field[index][name] = value;

        if (name == 'vendorName') {
            let selectedVendorData = editedVendorData?.filter((data) => data?.vendorID == value);
            let unitPriceData = selectedVendorData[0]?.deliveryDetails?.filter((data) => data?.deliveryLocation == componentObj?.bomLocation);
            console.log(unitPriceData[0]);
            setOlderData(value);
            setSelectedVendorData(unitPriceData[0]);
        }
        setBlankForm(field);
    };

    const submitHandler = () => {
        console.log(blankForm)
    };


    console.log(blankForm)

    return (
        <>
            <div className="container1">
                <div
                    className="modalContainer"
                    style={{ width: "90%", height: "465px", padding: "15px" }}
                >
                    <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>Required Components</h3>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "16px",
                            fontWeight: "500",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Total Components: {componentObj.totalComponents}</span>
                            <span>{`Total Cost (${auth.getUserInfo().currencySymbol})`} : {componentObj.totalComponentsCost}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Delivery Location: {componentObj.bomLocation}</span>
                        </div>
                    </div>
                    <div className="body" style={{ height: "300px" }}>
                        <div style={{ width: "100%", overflow: "auto", height: "285px" }}>
                            <table className="viewcomp_tbl" style={{ width: "100%" }} >
                                <thead>
                                    <tr>
                                        <th style={{ width: "4%" }}>S.No.</th>
                                        <th style={{ width: "13%" }}>Component Name</th>
                                        <th style={{ width: "8%" }}>SKU Code</th>
                                        <th style={{ width: "14%" }}>Category</th>
                                        <th style={{ width: "10%" }}>Sub Category</th>
                                        <th style={{ width: "8%" }}>Specification</th>
                                        <th style={{ width: "7%" }}>Req Qty.</th>
                                        <th style={{ width: "13%" }}>Vendor Name</th>
                                        <th style={{ width: "10%" }}>{`Unit Price (${auth.getUserInfo().currencySymbol})`}</th>
                                        <th style={{ width: "8%" }}>{`Sub Total (${auth.getUserInfo().currencySymbol})`}</th>
                                        <th style={{ width: "5%" }}>Action</th>
                                    </tr>
                                </thead>

                                {blankForm?.map((item, index) => {
                                    return (
                                        <tr>
                                            <td style={{ width: "4%" }} className="leftcell">{index + 1}</td>
                                            <td style={{ width: "13%" }}>{item?.componentName}</td>
                                            <td style={{ width: "8%" }}>{item?.skuCode}</td>
                                            <td style={{ width: "14%" }}>{item?.category}</td>
                                            <td style={{ width: "10%" }}>{item?.subCategory}</td>
                                            <td style={{ width: "8%" }}>
                                                <button
                                                    onClick={() => setSpecsShow(true)}
                                                    className="action-btn"
                                                    style={{ color: "blue" }}>
                                                    View Specs
                                                </button>
                                                {specsShow && 
                                                <ViewSpecs rowData={item} close={setSpecsShow} />}
                                            </td>
                                            <td style={{ width: "6%" }}>{item?.componentQuantity}</td>
                                            {editAction === index && onEditClick ?
                                                <td style={{ width: "19%" }}>
                                                    <select
                                                        style={{ width: "100%", height: "30px" }}
                                                        name="vendorName"
                                                        defaultValue={""}
                                                        onChange={(event) => handleInputChange(event, index)}
                                                    >
                                                        <option selected hidden>Select Vendor</option>
                                                        {
                                                            editedVendorData?.map((item) => (
                                                                <option value={item?.vendorID}>{item?.vendorName}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </td>
                                                : <td style={{ width: "19%" }} >{item?.vendor?.vendorName}</td>}

                                            {editAction === index && onEditClick ?
                                                <td style={{ width: "10%" }}>
                                                    <input
                                                        className="unit-price-input"
                                                        type="text"
                                                        name="unitPrice"
                                                        defaultValue={selectedVendorData?.unitPrice}
                                                        placeholder="Unit Price"
                                                        onChange={event => handleInputChange(event, index)}
                                                    />
                                                </td>
                                                :
                                                <td style={{ width: "10%" }}>₹{item?.location?.unitPrice}</td>}

                                            {editAction === index && onEditClick ?
                                                <td style={{ width: "9%" }}>
                                                    <input
                                                        className="unit-price-input"
                                                        type="text"
                                                        name="subTotal"
                                                        value={selectedVendorData ? (item?.componentQuantity * selectedVendorData.unitPrice) : (item?.componentQuantity * item?.location.unitPrice)}
                                                        placeholder="Sub Total"
                                                    />
                                                </td>
                                                :
                                                <td style={{ width: "9%" }}>₹{(item?.componentQuantity * item?.location?.unitPrice)}</td>}

                                            {editAction === index && onEditClick ?
                                                <td style={{ width: "5%" }} className="rightcell">
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <span onClick={() => setEditClick(false)} style={{ color: "red", cursor: "pointer", fontSize: "20px" }}><FontAwesomeIcon icon={faTimesCircle} /></span>
                                                        <span onClick={() => submitEdit(item, index)} style={{ color: "green", cursor: "pointer", fontSize: "20px" }}><FontAwesomeIcon icon={faCheckCircle} /></span>
                                                    </div>
                                                </td> :
                                                <td style={{ width: "5%" }} className="rightcell">
                                                    <button
                                                        onClick={() => actionHandler(item, index)}
                                                        style={{ color: "#0A18A3", backgroundColor: "white", border: "1px solid #0A18A3", borderRadius: "3px", fontSize: "15px", width: "60px" }}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                                    </button>
                                                </td>
                                            }
                                        </tr>
                                    )
                                })}
                            </table>
                        </div>
                    </div>

                    <br />

                    {onEditClick ?
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <button className="btn-footer" onClick={() => close(false)}>
                            <KeyboardBackspace style={{ fontSize: "25px" }} /> Back</button>
                            <div>
                            <button className="btn-footer" onClick={() => close(false)}>
                                <FontAwesomeIcon icon={faTimesCircle} /> Cancel</button>
                                <button onClick={submitHandler} className="btn-footer" ><FontAwesomeIcon icon={faCheckCircle} />Update</button>
                            </div>
                        </div>
                     :
                        <div className="footer">
                        <button className="btn-footer" onClick={() => close(false)}>
                            <KeyboardBackspace style={{ fontSize: "25px" }} /> Back</button>
                        </div>
                         } 
                </div>
            </div>
        </>
    );
};

export default ViewEditComponents;