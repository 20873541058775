import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
    KeyboardBackspace,
    CheckCircle,
} from "@material-ui/icons";
import ViewSpecs from "./ViewSpecs";
import moment from "moment";
import { errorNotification, successNotification } from "../../../utils/notifications";
import auth from "../../../utils/auth";

const GenerateGRN = ({ closeModal, selectedPO, nextPage, refresh }) => {
    const [viewSpecs, setViewSpecs] = useState();

    const generateGRN = async () => {
        let finalBody = { ...selectedPO, userID: auth.getUserInfo().userID }
        console.log(finalBody)
        const resp = await trackPromise(API.post(`inventory-module/api/v1/grn`, finalBody));
        console.log(resp);
        if (resp.success) {
            successNotification(resp.message, { autoClose: 10000 })
        } else {
            errorNotification(resp.message, { autoClose: 10000 })
        }
        closeModal(false)
        nextPage(true)
        refresh()
    }
    return (
        <div className="container1">
            <div
                className="modalContainer"
                style={{ width: "980px", height: "425px", padding: '15px' }}
            >
                <div className="header">
                    <h3>Goods Received Note</h3>
                </div>

                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll' }}
                >

                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            margin: "10px 0"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "500" }}>
                            <span>PO No. : {selectedPO.POID}</span>
                            <span>PO Date: {moment(selectedPO.PODate).format("DD-MMM-YYYY/HH:mm")}</span>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Requested By: {selectedPO.requestedBy}</span>
                            <span>Delivery Location : {selectedPO.bomLocation}</span>
                        </div>

                    </section>

                    <section
                        style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            margin: "10px 0"
                        }}
                    >
                        <span style={{ fontWeight: "500", }}>Vendor Name: {selectedPO.vendorName}</span>
                        <span>{ }</span>
                        {/* <span>+91 3289735487</span> */}

                    </section>


                    <section style={{ width: '100%', }}>
                        <div id='POVendorHeading'>
                            <div style={{ width: '13%' }}>Component Name</div>
                            <div style={{ width: '10%' }}>SKU Code</div>
                            <div style={{ width: '13%' }}>Vendor Part Name</div>
                            <div style={{ width: '13%' }}>Vendor Part Code</div>
                            <div style={{ width: '10%' }}>Category</div>
                            <div style={{ width: '10%' }}>Sub Category</div>
                            <div style={{ width: '13%' }}>Specification</div>
                            <div style={{ width: '9%' }}>Ordered Qty.</div>
                            <div style={{ width: '10%' }}>Received Qty.</div>
                        </div>
                        <div>
                            {/* <h2>{JSON.stringify(grnData)}</h2> */}
                            {selectedPO.components.map((item, index) => {
                                return (
                                    <div id='POVendorContent' style={{ borderBottom: '1px solid #999999', boxShadow: 'none', padding: '0px' }} key={index}>
                                        <div style={{ width: '13%' }}>{item.componentName}</div>
                                        <div style={{ width: '10%' }}>{item.skuCode}</div>
                                        <div style={{ width: '13%' }}>{item.vendorPartName}</div>
                                        <div style={{ width: '13%' }}>{item.vendorPartCode}</div>
                                        <div style={{ width: '10%' }}>{item.category}</div>
                                        <div style={{ width: '10%' }}>{item.subCategory}</div>
                                        <div style={{ width: '13%' }}>
                                            <span onClick={() => setViewSpecs(index)} style={{ color: "#0a18a3", fontWeight: "500", cursor: 'pointer' }}> View Specs.</span>
                                            {viewSpecs === index && <ViewSpecs specs={item} closeModal={setViewSpecs} />}
                                        </div>
                                        <div style={{ width: '9%' }}>{item.requiredQuantity}</div>
                                        <div style={{ width: '10%' }}>{item.receivedQuantity}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </section>

                </div>

                <div className="footer">
                    <button style={{ width: '104px', height: '42px', background: '#fff' }} onClick={() => closeModal(false)}><KeyboardBackspace style={{ fontSize: "25px" }} />Back</button>
                    <button onClick={() => generateGRN()} style={{ marginRight: '0', width: '120px', height: '42px' }} id="submit-button"><CheckCircle style={{ fontSize: "17px" }} />Proceed</button>
                </div>
            </div>
        </div>
    );
};

export default GenerateGRN;