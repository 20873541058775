import { findIndex } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import ViewSpecs from "./action/ViewSpecs";
import AddPartSno from "./AddPartSno";
import { errorNotification } from "../../utils/notifications";

const AddReceivedQty = ({ receivedQty, selectedPO }) => {
  console.log(selectedPO);
  const [viewSpecs, setViewSpecs] = useState();
  const [openAddPartSno, setAddPartSno] = useState();
  const [finalReceivedQty, setReceivedQty] = useState([]);

  const receivedQtyChange = (e, id, index, requiredQuantity) => {
    console.log(requiredQuantity);
    console.log(e.target.value);
    if (parseInt(e.target.value) < 0) {
      // Notify the user that negative values are not allowed
      errorNotification("Negative values are not allowed", { autoclose: 2000 });
      return;
    }
    if (parseInt(requiredQuantity) < parseInt(e.target.value)) {
      errorNotification("Received quantity cannot be greater than required quantity", { autoclose: 2000 })
      return
    }
    selectedPO.components.forEach((item) => {
      if (item.componentID == id) {
        item.receivedQuantity = e.target.value;
      }
    });
    receivedQty(selectedPO.components);
    const field = [...finalReceivedQty];
    field[index][e.target.name] = e.target.value;
    setReceivedQty(field);
  };

  useEffect(() => {
    const array = [];
    for (let i = 0; i < selectedPO.components.length; i++) {
      const obj = {
        value: "",
      };
      array.push(obj);
    }
    setReceivedQty(array);
  }, []);

  const compSno = (data, index) => {
    selectedPO.components[index].componentSno = data;
    selectedPO.components[index].receivedQuantity = data.length.toString();
  };

  return (
    <div>
      <br />
      <small>Add Received Qty.</small>
      <hr />

      <section style={{ width: "100%" }}>
        <div id="POVendorHeading">
          <div style={{ width: "13%" }}>Component Name</div>
          <div style={{ width: "9%" }}>SKU Code</div>
          <div style={{ width: "13%" }}>Vendor Part Name</div>
          <div style={{ width: "13%" }}>Vendor Part Code</div>
          <div style={{ width: "8%" }}>Category</div>
          <div style={{ width: "10%" }}>Sub Category</div>
          <div style={{ width: "9%" }}>Specification</div>
          <div style={{ width: "10%" }}>Ordered Qty.</div>
          <div style={{ width: "10%" }}>Received Qty.</div>
          <div style={{ width: "10%" }}>Add Units</div>
        </div>
        <div>
          {selectedPO?.components?.map((item, index) => (
            <div
              id="POVendorContent"
              style={{
                borderBottom: "1px solid #999999",
                boxShadow: "none",
                padding: "0px",
              }}
              key={index}
            >
              <div style={{ width: "13%" }}>{item.componentName}</div>
              <div style={{ width: "9%" }}>{item.skuCode}</div>
              <div style={{ width: "13%" }}>{item.vendorPartName}</div>
              <div style={{ width: "13%" }}>{item.vendorPartCode}</div>
              <div style={{ width: "8%" }}>{item.category}</div>
              <div style={{ width: "10%" }}>{item.subCategory}</div>
              <div style={{ width: "9%" }}>
                <span
                  onClick={() => setViewSpecs(index)}
                  style={{
                    color: "#0a18a3",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  View Specs.
                </span>
                {viewSpecs === index && (
                  <ViewSpecs specs={item} closeModal={setViewSpecs} />
                )}
              </div>
              <div style={{ width: "10%" }}>{item.requiredQuantity}</div>
              <div style={{ width: "10%" }}>
                <input
                  name="value"
                  // type="number"
                  onKeyPress={(e) => {
                  const isNumeric = /^[0-9]*$/.test(e.key);
                  if (!isNumeric) {
                    e.preventDefault();
                  }
                }}
                  placeholder="Enter Qty"
                  style={{
                    width: "80px",
                    margin: "0 -7px 0 0",
                    textAlign: "center",
                    height: "20px",
                    background: "#ebebeb",
                    border: "none",
                  }}
                  onChange={(e) =>
                    receivedQtyChange(e, item.componentID, index, item.requiredQuantity)
                  }
                />
              </div>
              <div style={{ width: "10%" }}>
                <span
                  onClick={() => setAddPartSno(index)}
                  style={{
                    color: "#0a18a3",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  Add Part S.No
                </span>
                {openAddPartSno === index && (
                  <AddPartSno
                    index={index}
                    componentID={item.componentID}
                    selectedPO={selectedPO}
                    comp={item}
                    qty={
                      finalReceivedQty[index].value == ""
                        ? item.receivedQuantity
                        : finalReceivedQty[index].value
                    }
                    closeModal={setAddPartSno}
                    compSno={compSno}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default AddReceivedQty;
