import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFileDownload,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import auth from "../../../utils/auth";
import moment from "moment";
import "../../../pages/vendors/Vendors.scss";
import AppConstants from "../../../utils/app-constants";
import SearchBox from "../../../components/search/SearchBox";
import handleExport from "../../../utils/exportExcel";
import { BsDownload } from "react-icons/bs";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BusinessEntitiesAction from "./BusinessEntitiesAction";
import DealerMappedStates from "./DealerMappedStates";
import ViewSalesManager from "./ViewSalesManager";

const ManagingBusinessEntities = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [filterData, setFilterData] = useState();
  const [showFilter, setShowFilter] = useState(false);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `operations-module/api/v1/businessEntities?businessLevel=${formValue.businessLevel}&businessType=${formValue.businessType}&businessName=${formValue.businessName}&businessEmailID=${formValue.businessEmailID}&status=${formValue.status}&lastUpdated=${formValue.lastUpdated}`
      )
    );
    console.log(resp);
    let filterData = resp.filter((elem) => {
      return (
        elem.status == "Approve" ||
        elem.status == "Reactivated" ||
        elem.status == "Deactivated"
      );
    });
    setUsersData(filterData);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  console.log();

  const statusFormatter = (cell, row, rowIndex) => {
    return cell === AppConstants.APPROVE ? (
      <button className="active-btn" style={{ width: "100%" }}>
        <span className="active-btn-font">Verified & Approved</span>
      </button>
    ) : cell === AppConstants.DEACTIVATED ? (
      <button className="deactive-btn" style={{ width: "100%" }}>
        {" "}
        <span className="deactive-btn-font">Deactivated</span>
      </button>
    ) : cell === AppConstants.REACTIVATED ? (
      <button className="reactive-btn" style={{ width: "100%" }}>
        {" "}
        <span className="reactive-btn-font">Reactivated</span>
      </button>
    ) : cell === AppConstants.DELETED ? (
      <button className="delete-btn" style={{ width: "100%" }}>
        {" "}
        <span className="delete-btn-font">Deleted</span>
      </button>
    ) : cell === AppConstants.REJECTED ? (
      <button className="delete-btn" style={{ width: "100%" }}>
        {" "}
        <span className="delete-btn-font">Rejected</span>
      </button>
    ) : cell === AppConstants.ACTIVE ? (
      <button className="pendingByProc-btn" style={{ width: "100%" }}>
        <span className="pendingByProc-btn-font">Pending</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const actionHandler = (rowIndex) => {
    console.log("god");
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log(row.entityID);
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && isOpen ? (
          <BusinessEntitiesAction
            updatedData={fetchReport}
            rowData={row}
            setIsOpen={setIsOpen}
            entityID={row.entityID}
            rowIndex={rowIndex}
          />
        ) : null}
      </div>
    );
  };

  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    businessLevel: "",
    businessType: "",
    businessName: "",
    businessEmailID: "",
    salesManager: "",
    status: "",
    lastUpdated: "",
  });

  const bLevel = [...new Set(UsersData.map((item) => item.businessLevel))];
  const bType = [...new Set(UsersData.map((item) => item.businessType))];
  const bName = [...new Set(UsersData.map((item) => item.businessName))];
  const bEmailID = [...new Set(UsersData.map((item) => item.businessEmailID))];
  const bsalesManager = [...new Set(UsersData.map((item) => item.salesManager))];
  const bstatus = [...new Set(UsersData.map((item) => item.status))];

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async (e) => {
    setFormValue({
      businessLevel: "",
      businessType: "",
      businessName: "",
      businessEmailID: "",
      salesManager: "",
      status: "",
      lastUpdated: "",
    });
    const resp = await trackPromise(
      API.get(
        `http://localhost:3014/operations-module/api/v1/businessEntities?`
      )
    );

    let clearData = resp.filter((elem) => {
      return elem.status !== "Reject" && elem.status !== "Pending";
    });

    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setUsersData(clearData);
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  const [statesShow, setStateShow] = useState();
  const [stateOpen, setStateOpen] = useState(true);

  const stateHandler = (rowIndex) => {
    setStateOpen(true);
    setStateShow(rowIndex);
  };

  const statesFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log(row);
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => stateHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          View Mappings
        </button>
        {statesShow === rowIndex && stateOpen ? (
          <ViewSalesManager
            blankForm={row}
            close={setStateOpen}
          />
        ) : null}
      </div>
    );
  };

  const columns = [
    {
      text: "S.No",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "BusinessType",
      dataField: "businessType",
      headerAttrs: { width: "170px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Business Name",
      dataField: "businessName",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Contact Person",
      dataField: "contactPersonName",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Business Email ID",
      dataField: "businessEmailID",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Business Address",
      dataField: "address",
      headerAttrs: { width: "300px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Business Contact No.",
      dataField: "businessContactNo",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row) => {
        if (!cell) {
          return "-"
        } else {
          return `${row.std} - ${row.businessContactNo}`
        }
      }
    },
    {
      text: "Mobile No.",
      dataField: "contactPersonNo",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row) => {
        if (!cell) {
          return "-"
        } else {
          return `${row.isd} - ${row.contactPersonNo}`
        }
      }
    },
    {
      text: "PAN",
      dataField: "panNo",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "GSTIN",
      dataField: "gstin",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Dealer Certificate No",
      dataField: "dealerCertificateNo",
      headerAttrs: { width: "170px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Mappings",
      dataField: "cities",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { statesShow, stateOpen },
      formatter: statesFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: statusFormatter,
    },
    {
      text: "Approved By",
      dataField: "actionBy",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Approval Date & time",
      dataField: "actionDate",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "Modification Reason",
      dataField: "reason",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      classes: "cellsOverflow",
    },
    {
      text: "Last Update",
      dataField: "lastUpdated",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "60px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Business Entities</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "manageUsers")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form
                    onSubmit={submitHandler}
                    className="UHinputfields VMform"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "135px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Business Level
                        </label>
                        <select
                          name="businessLevel"
                          value={formValue.businessLevel}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Business Level
                          </option>
                          {bLevel.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Business Type
                        </label>
                        <select
                          name="businessType"
                          value={formValue.businessType}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Business Type
                          </option>
                          {bType.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Business Name
                        </label>
                        <select
                          name="businessName"
                          value={formValue.businessName}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Business Name
                          </option>
                          {bName.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Business Email ID
                        </label>
                        <select
                          name="businessEmailID"
                          value={formValue.businessEmailID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Business Email ID
                          </option>
                          {bEmailID.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Sales Manager
                        </label>
                        <select
                          name="salesManager"
                          value={formValue.salesManager}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Sales Manager
                          </option>
                          {bsalesManager.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          {bstatus.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Onboarding Date
                        </label>
                        <input
                          type="date"
                          onFocus={(e) => (e.currentTarget.type = "date")}
                          onBlur={(e) => (e.currentTarget.type = "text")}
                          name="createdDate"
                          value={formValue.createdDate}
                          onChange={handleChange}
                          placeholder="Date Range"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          justifyContent: "end",
                          width: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <button
                          id="apply"
                          style={{ marginTop: "22px", marginLeft: "10px" }}
                          onClick={fetchReport}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ marginTop: "22px", width: "auto" }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ManagingBusinessEntities;
