import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import SearchBox from "../../components/search/SearchBox";
import Actions from "./Actions";
import moment from "moment";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ServiceJobs = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [open, setOpen] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [show, setShow] = useState(false);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `/serviceCenter-module/api/v1/job/serviceJobs?jobID=${formValue.jobID}&jobDate=${formValue.jobDate}&jobType=${formValue.jobType}&vehicleRegistrationNo=${formValue.vehicleRegistrationNo}&deliveryDate=${formValue.deliveryDate}&status=${formValue.status}`
      )
    );
    console.log(resp);
    setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);


  const uniquejobID  = [...new Set(UsersData?.map((item)=>item.jobID))]
  const uniquejobType  = [...new Set(UsersData?.map((item)=>item.jobType))]
  const uniquevehicleRegistrationNo  = [...new Set(UsersData?.map((item)=>item.vehicleRegistrationNo))]
  const uniquestatus  = [...new Set(UsersData?.map((item)=>item.status))]



  const statusFormatter = (cell) => {
    return cell == "New Job Card" ? (
      <button className="reactive-btn" style={{width:"100%"}}>
        <span className="reactive-btn-font">New Job</span>
      </button>
    ) : cell == "Job In-Progress" ? (
      <button className="deactive-btn" style={{width:"100%"}}>
        {" "}
        <span className="deactive-btn-font">In-Progress</span>
      </button>
    ) : cell == "Job On-Halt" ? (
      <button className="delete-btn" style={{width:"100%"}}>
        {" "}
        <span className="delete-btn-font">On-Halt</span>
      </button>
    ) : cell === "Job Closed" ? (
      <button className="active-btn"style={{width:"100%"}}>
        {" "}
        <span className="active-btn-font">Completed</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && open ? (
          <Actions rowData={row} status={true} rowIndex={rowIndex} />
        ) : null}
      </div>
    );
  };

  const [formValue, setFormValue] = useState({
    jobID: "",
    jobDate: "",
    jobType: "",
    jobDate: "",
    vehicleRegistrationNo: "",
    deliveryDate: "",
    status: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = (e) => {
    setFormValue({
      jobID: "",
      jobDate: "",
      jobType: "",
      jobDate: "",
      vehicleRegistrationNo: "",
      deliveryDate: "",
      status: "",
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY")}`;
  }

  const handleClick = (e) => {
    setShow(!show);
  };

  const columns = [
    {
      text: "S.No.",
      // dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Job ID",
      dataField: "jobID",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      // classes: "alignment"
    },
    {
      text: "Job Type",
      dataField: "jobType",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes: "cellsOverflow",
    },
    {
      text: "Job Date",
      dataField: "jobDate",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "Vehicle Reg. No.",
      dataField: "vehicleRegNo",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Customer Name",
      dataField: "customerName",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return row.customerDetails.customerName;
      },
    },
    {
      text: "Customer Email",
      dataField: "customerEmail",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return row.customerDetails.customerEmail;
      },
    },
    {
      text: "Customer Phone",
      dataField: "customerPhone",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return row.customerDetails.MobileNo;
      },
    },
    {
      text: "Delivery Date",
      dataField: "deliveryDate",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return moment(row.serviceDetails.deliveryDateAndTime).format(
          "DD-MMM-YYYY"
        );
      },
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "140px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      // dataField: "title",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Service Jobs</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <div className="UHinputfields VMform">
                    <select
                      name="jobID"
                      value={formValue.jobID}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected>
                        Job ID
                      </option>
                      {
                        uniquejobID.map((item)=>(
                          <option>{item}</option>
                        ))
                      }
                    </select>
                    <select
                      name="jobType"
                      value={formValue.jobType}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected>
                        Job Type
                      </option>
                      {
                        uniquejobType.map((item)=>(
                          <option>{item}</option>
                        ))
                      }
                    </select>
                    <input
                      type="date"
                      name="jobDate"
                      value={formValue.jobDate}
                      onChange={handleChange}
                      placeholder="Job Date"
                    />
                    <select
                      name="vehicleRegistrationNo"
                      value={formValue.vehicleRegistrationNo}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected>
                        Vehicle Registration No
                      </option>
                      {
                        uniquevehicleRegistrationNo.map((item)=>(
                          <option>{item}</option>
                        ))
                      }
                    </select>
                    <input
                      type="date"
                      name="deliveryDate"
                      value={formValue.deliveryDate}
                      onChange={handleChange}
                      placeholder="Delivery Date"
                    />
                    <select
                      name="status"
                      value={formValue.status}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected>
                        Status
                      </option>
                      {
                        uniquestatus.map((item)=>(
                          <option>{item}</option>
                        ))
                      }
                    </select>
                    <button id="apply" onClick={fetchReport}>
                      Apply
                    </button>
                    <button onClick={clearFilter} id="clear">
                      Clear Filter
                    </button>
                  </div>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ServiceJobs;
