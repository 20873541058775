import React from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import { errorNotification, successNotification } from "../../../utils/notifications";
import {
  CancelOutlined,
  CheckCircle,
} from "@material-ui/icons";

const SendToVendor_MO = ({ closeModal, editData }) => {
  console.log(editData);

  const submitHandler = async () => {
    let vendorData = {
      POID: editData.POID,
      vendorID: editData.vendorID
    };

    const resp = await trackPromise(API.post(`procurement-module/api/v1/orders/sendToVendor`, vendorData));
    console.log(resp);
    closeModal(false);
    successNotification(resp.message, { autoClose: 3000 });
    refresh();
  };

  return (
    <div className='container1'>
      <div className="modalContainer" style={
        { width: '400px', height: '200px' }
      }>

        <div className="body" style={{ justifyContent: 'center', fontSize: '16px', padding: "0px" }}>
          Are you sure to send this <br /> order to vendor?

        </div>
        <div className="footer" style={{ justifyContent: "center" }}>
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              marginRight: "10px",
              background: 'white',
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              color: "white",
            }}
            onClick={submitHandler}
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Confirm
          </button>

        </div>
      </div>
    </div>
  )
}

export default SendToVendor_MO 