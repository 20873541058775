import { KeyboardBackspace } from '@material-ui/icons';
import React from 'react'

const ShowMappedCities = ({ citiesData, rowData, closeModal, area }) => {
console.log(citiesData,rowData)
    return (
        <div className="container1">
            <div className="modalContainer" style={{ width: "501px", height: "450px" }}>
                <div className="header">
                    <h3>Mapped Cities</h3>
                </div>
                <label style={{ textAlign: "left" }}>Area Name :<b style={{ color: "#0A18A3" }}>{rowData.area}</b></label>
                <label style={{ textAlign: "left" }}>State Name :<b style={{ color: "#0A18A3" }}>{citiesData?.stateName}</b></label>
                <div className="body">
                    <table style={{ width: "100%" }}>
                        <tr>
                            <th style={{ width: "16%" }}>S.No</th>
                            <th>Cities</th>
                        </tr>
                        {
                            rowData.cities.filter((item1) => {
                                return item1.stateId == citiesData.stateId
                            }).map((el, index) => {
                                return (
                                    <tr>
                                        <td>{index + 1}.</td>
                                        <td>{el.City}</td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                </div>
                <div className="footer">
                    <button onClick={() => closeModal(false)}><KeyboardBackspace /> Back</button>
                </div>
            </div>
        </div>
    );
}

export default ShowMappedCities