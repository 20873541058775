import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ViewComponentsCost from "./action/ViewComponentsCost";
import ViewLaborCost from "./action/ViewLaborCost";
import ViewAssemblyCost from "./action/ViewAssemblyCost";
import "../../components/modal/modal.scss";
import SearchBox from "../../components/search/SearchBox";
import { errorNotification } from "../../utils/notifications";
import ViewEdit from "./action/viewEdit";
import ViewDetailsBomCost from "./action/viewDetailsBomCost";
import handleExport from "../../utils/exportExcel";
import auth from "../../utils/auth";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ManageBomCosting = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState();
  const [indexTracker, setIndexTracker] = useState();
  const [isComponentsOpen, setIsComponentsOpen] = useState(false);
  const [isLaborOpen, setIsLaborOpen] = useState(false);
  const [isAssemblyOpen, setIsAssemblyOpen] = useState(false);
  const [BomCostingData, setBomCostingData] = useState([]);
  const [formValue, setFormValue] = useState({
    location: "",
    bomId: "",
    bomType: "",
    vehicleModel: "",
    vehicleVariant: "",
    status: "",
  });
  const [openAction, setOpenAction] = useState(false);
  const [row, setRow] = useState();

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `procurement-module/api/v1/boms?BOMID=${formValue.bomId}&bomLocation=${formValue.location}&bomTypes=${formValue.bomType}&vehicleModel=${formValue.vehicleModel}&vehicleVariant=${formValue.vehicleVariant}&status=${formValue.status}`
      )
    );
    console.log(resp);
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setBomCostingData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const statusFormatter = (cell) => {
    return cell === "Approved By Management" ? (
      <button className="active-btn">
        <span className="active-btn-font">Approved</span>
      </button>
    ) : cell === "Pending at Management" || "Pending At Management" ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">Pending</span>
      </button>
    ) : cell === "Rejected By Management" ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Rejected</span>
      </button>
    ) : (
      cell
    );
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const drawingFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "12px",
            color: "blue",
            cursor: "pointer",
          }}
        >
          View Drawing
        </p>
      </div>
    );
  };

  const componentsCostHandler = (rowIndex) => {
    setIsComponentsOpen(true);
    setIndexTracker(rowIndex);
  };

  const componentsCostFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "12px",
            color: "blue",
            cursor: "pointer",
          }}
          onClick={() => componentsCostHandler(rowIndex)}
        >
          View Components Cost
        </p>
        {indexTracker === rowIndex && isComponentsOpen ? (
          <ViewComponentsCost row={row} closeModal={setIsComponentsOpen} />
        ) : null}
      </div>
    );
  };

  const laborCostHandler = (rowIndex) => {
    setIsLaborOpen(true);
    setIndexTracker(rowIndex);
  };

  const laborCostFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "12px",
            color: "blue",
            cursor: "pointer",
          }}
          onClick={() => laborCostHandler(rowIndex)}
        >
          View Labor Cost
        </p>
        {indexTracker === rowIndex && isLaborOpen ? (
          <ViewLaborCost row={row} closeModal={setIsLaborOpen} />
        ) : null}
      </div>
    );
  };

  const assemblyCostHandler = (rowIndex) => {
    setIsAssemblyOpen(true);
    setIndexTracker(rowIndex);
  };

  const assemblyCostFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "12px",
            color: "blue",
            cursor: "pointer",
          }}
          onClick={() => assemblyCostHandler(rowIndex)}
        >
          View Assembly Cost
        </p>
        {indexTracker === rowIndex && isAssemblyOpen ? (
          <ViewAssemblyCost row={row} closeModal={setIsAssemblyOpen} />
        ) : null}
      </div>
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (row, rowIndex) => {
    setOpenAction(true);
    setRow(row);
    setOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          onClick={() => actionHandler(row, rowIndex)}
          style={{
            color: "#0A18A3",
            backgroundColor: "white",
            border: "1px solid #0A18A3",
            borderRadius: "3px",
            width: "100%",
          }}
        >
          {row.status == "Approved By Management" ||
            row.status == "Rejected By Management" ||
            row.status == "Pending At Management"
            ? "View & Edit Details"
            : "View Details"}
        </button>
      </div>
    );
  };

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      location: "",
      bomId: "",
      bomType: "",
      vehicleModel: "",
      vehicleVariant: "",
      status: "",
    });

    const resp = await trackPromise(API.get(`procurement-module/api/v1/boms`));
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setBomCostingData(resp);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          BomCostingData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "BOM ID",
      dataField: "BOMID",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "BOM Name",
      dataField: "bomName",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Location",
      dataField: "bomLocation",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "BOM Type",
      dataField: "bomTypes",
      headerAttrs: { width: "130px" },
      classes: "cellsOverflow",
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "BOM Version",
      dataField: "bomVersion",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "BOM Level",
      dataField: "bomLevel",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vehicle Model",
      dataField: "vehicleModel",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vehicle Variant",
      dataField: "vehicleVariant",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    // {
    //   text: "Vehicle Drawing",
    //   dataField: "vehicleDrawing",
    //   headerAttrs: { width: "180px" },
    //   headerStyle: {fontWeight:'500',borderTop:'1px solid rgba(0,0,0,0.2)'},
    //   formatExtraData: {},
    //   formatter: drawingFormatter,
    // },
    {
      text: "Created By",
      dataField: "createdBy",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Creation Date & Time",
      dataField: "bomApprovalReqDate",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "Components Cost",
      dataField: "bomComponents",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { isComponentsOpen, indexTracker },
      formatter: componentsCostFormatter,
    },
    {
      text: "Labor Cost",
      dataField: "bomLabour",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { isLaborOpen, indexTracker },
      formatter: laborCostFormatter,
    },
    {
      text: "Assembly Cost",
      dataField: "bomAssembly",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { isAssemblyOpen, indexTracker },
      formatter: assemblyCostFormatter,
    },
    {
      text: `Total BOM Cost (${auth.getUserInfo().currencySymbol})`,
      dataField: "totalBomCost",
      headerAttrs: { width: "170px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    {
      text: "Approval Date & Time",
      dataField: "approvalDate",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Action",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open, openAction },
      formatter: actionFormatter,
    },
  ];

  let uniqueBomLocationArray = [
    ...new Map(
      BomCostingData.map((item) => [item["bomLocation"], item])
    ).values(),
  ];
  let uniqueBomTypeArray = [
    ...new Map(BomCostingData.map((item) => [item["bomTypes"], item])).values(),
  ];
  let uniqueVehicleModelArray = [
    ...new Map(
      BomCostingData.map((item) => [item["vehicleModel"], item])
    ).values(),
  ];
  let uniqueVehicleVariantArray = [
    ...new Map(
      BomCostingData.map((item) => [item["vehicleVariant"], item])
    ).values(),
  ];
  let uniqueStatusArray = [
    ...new Map(BomCostingData.map((item) => [item["status"], item])).values(),
  ];

  if (openAction === false) {
    return (
      <>
        <div>
          <div id="overlay" onClick={(e) => off(e)}></div>
          <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
            <span>Manage BOM Costing</span>
          </div>

          <ToolkitProvider
            keyField="id"
            data={BomCostingData}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div>
                  <div style={{ float: "right", marginTop: "-42px" }}>
                    <div
                      style={{
                        display: "flex",
                        marginRight: "-15px",
                        height: "26px",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ width: "220px" }}>
                        <SearchBar
                          {...props.searchProps}
                          placeholder="Search..."
                          style={{
                            height: "25px",
                            // border: "1px solid #C6C6C6",
                            border: "none",
                            background: "white",
                            borderRadius: "3px",
                            marginRight: "-50px",
                          }}
                        />
                      </div>
                      <div onClick={clearFilter} className="filter-refresh">
                        <FontAwesomeIcon icon={faRedoAlt} />
                        &nbsp; Refresh
                      </div>
                      <div className="filter-refresh" onClick={handleClick}>
                        {" "}
                        <FontAwesomeIcon icon={faFilter} />
                        &nbsp; Filters
                      </div>
                      <div
                        className="filter-refresh"
                        onClick={() =>
                          handleExport(BomCostingData, "Manage BOM Costing")
                        }
                      >
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp; Export
                      </div>
                      {inDashboard ? (
                        <div
                          className="filter-refresh"
                          onClick={() => inDashboard(false)}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  {show && (
                    <form
                      onSubmit={submitHandler}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        height: "135px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                      }}
                      className="UHinputfields VMform"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          Location
                        </label>
                        <select
                          name="location"
                          value={formValue.location}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Location
                          </option>
                          {uniqueBomLocationArray.map((item, id) => (
                            <option key={id}>{item.bomLocation}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          BOM ID
                        </label>
                        <select
                          name="bomId"
                          value={formValue.bomId}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            BOM ID
                          </option>
                          {BomCostingData.map((item, id) => (
                            <option key={id}>{item.BOMID}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          BOM Type
                        </label>
                        <select
                          name="bomType"
                          value={formValue.bomType}
                          style={{ marginTop: "-5px", width: "100%" }}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            BOM Type
                          </option>
                          {uniqueBomTypeArray.map((item, id) => (
                            <option key={id}>{item.bomTypes}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Model
                        </label>
                        <select
                          name="vehicleModel"
                          value={formValue.vehicleModel}
                          style={{ marginTop: "-5px", width: "100%" }}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Vehicle Model
                          </option>
                          {uniqueVehicleModelArray.map((item, id) => (
                            <option key={id}>{item.vehicleModel}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Variant
                        </label>
                        <select
                          name="vehicleVariant"
                          style={{ marginTop: "-5px", width: "100%" }}
                          value={formValue.vehicleVariant}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Vehicle Variant
                          </option>
                          {uniqueVehicleVariantArray.map((item, id) => (
                            <option key={id}>{item.vehicleVariant}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          style={{ marginTop: "-5px", width: "100%" }}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Status
                          </option>
                          {uniqueStatusArray.map((item, id) => (
                            <option key={id}>{item.status}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          onClick={fetchReport}
                          style={{ width: "100px", marginTop: "24px" }}
                          id="apply"
                        >
                          Apply
                        </button>
                        <button
                          style={{ width: "100px", marginTop: "24px" }}
                          onClick={clearFilter}
                          id="clear"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </form>
                  )}
                  <br></br>
                </div>

                <BootstrapTable
                  wrapperClasses="table-responsive"
                  // rowClasses="text-nowrap"
                  rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                  pagination={paginationFactory({
                    hidePageListOnlyOnePage: true,
                    showTotal: true,
                    hideSizePerPage: true,
                    paginationSize: 2,
                    sizePerPage: "10",
                  })}
                  noDataIndication="No Data Found"
                  {...props.baseProps}
                />
              </>
            )}
          </ToolkitProvider>
        </div>
      </>
    );
  } else if (
    (openAction === true && row.status == "Approved By Management") ||
    row.status == "Rejected By Management" ||
    row.status == "Pending At Procurement"
  ) {
    return <ViewEdit rowData={row} close={setOpenAction} />;
  } else if (openAction === true && row.status == "Pending At Management") {
    return (
      <ViewDetailsBomCost
        updatedData={fetchReport}
        rowData={row}
        close={setOpenAction}
      />
    );
  }
};

export default ManageBomCosting;
