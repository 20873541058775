import React, { useEffect, useState, useRef } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Multiselect from "multiselect-react-dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import "../bom.scss";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
// import CancelPopup from "./bom-actions/cancelPopup";

function BomAssembly({ assemblyData, idx, bomAssData  }) {
  console.log(bomAssData);
  const [allAssembly, setAllAssembly] = useState([]);
  const [final, setFinal] = useState([]);
  const [form, setForm] = useState({
    assemblyType: "",
    assemblyName: "",
    assemblyServices: [
      {
        serviceName: "",
        serviceID: "",
        serviceCost: "",
        timeDuration: "",
        time: "",
        unit: "",
      },
    ],
    // totalDuration: "",
  });
  const [row, setRow] = useState({});
  const [temp, setTemp] = useState(bomAssData);
  const [expanded, setExpanded] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const multiselectRef = useRef(null);

  const addFormFields = () => {
    if (
      !form.assemblyType ||
      !form.assemblyName ||
      form.assemblyServices.length == 0
    ) {
      errorNotification("Please fill all the fields");
      return;
    }
    const currentform = Object.assign({}, form);
    setFinal((obj) => [...obj, currentform]);
    const tempX = { ...currentform, id: idArray[0] };
    setTemp((obj) => [...obj, tempX]);


    document.getElementById("type").value = "";
    document.getElementById("name").value = "";
    document.getElementById("services").value = "";
    multiselectRef.current.resetSelectedValues();
    setForm({
      assemblyType: "",
      assemblyName: "",
      assemblyServices: [
        {
          serviceName: "",
        serviceID: "",
        serviceCost: "",
        timeDuration: "",
        time: "",
        unit: "",
        },
      ],
      totalDuration: "",
    });
  };

  console.log(form);
  const handleAccordionChange = (accordion) => (event, isExpanded) => {
    setExpanded(isExpanded ? accordion : false);
  };

  const getAllAssembly = async () => {
    const resp = await trackPromise(
      API.get(`production-module/api/v1/assembly`)
    );

    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setAllAssembly(resp);
  };
  useEffect(() => {
    getAllAssembly();
  }, []);

  const uniqueAssemblyType = [
    ...new Set(allAssembly?.map((item) => item.assemblyType)),
  ];

  let subAssemblyArray = allAssembly
    .filter((item) => item.assemblyType === "Sub Assembly")
    .map((item) => item);

  let mainAssemblyArray = allAssembly
    .filter((item) => item.assemblyType === "Main Assembly")
    .map((item) => item);

  let servicesArray = allAssembly
    .filter((item) => item.assemblyName === form.assemblyName)
    .map((item) => item.assemblyServices);

  const idArray = allAssembly
    .filter((item) => item.assemblyName == form.assemblyName)
    .map((item) => item.id);

  const Id = [...final, { id: idArray[0] === undefined ? "" : idArray[0] }];

  const tempX = final.map((item, index) => {
    return (final[index] = { ...item, id: idArray[0] });
  });

  const changeTime = (e, row, rowIndex, index) => {
    // const arr = [...temp];
    temp[rowIndex].assemblyServices[index]["timeDuration"] = e.target.value;
    // setTemp(arr);
  };

  const handleList = (selectdata) => {
    const updatedServices = selectdata.map((item) => ({
      serviceName: item.serviceName,
      serviceID: item.serviceID,
      serviceCost: item.serviceCost,
      time: item.time,
      timeDuration: "",
      unit: "",
    }));
    setForm({ ...form, assemblyServices: updatedServices });
  };
  const handleDuration = (e, rowIndex, index, item) => {
    if (item.time == e.target.value) {
      temp[rowIndex].assemblyServices[index]["unit"] = e.target.value;
    } else {
      temp[rowIndex].assemblyServices[index]["unit"] = "";
      errorNotification("Costing Unit Doesn't Match")
    }
  };

  const removeServiceName = (rowIndex, index) => {
    const updatedTemp = [...temp];
    if (updatedTemp[rowIndex].assemblyServices.length === 1) {
      updatedTemp.splice(rowIndex, 1);
    } else {
      updatedTemp[rowIndex].assemblyServices.splice(index, 1);
    }

    setTemp(updatedTemp);
  };

  const timeFormatter = (cell, row, rowIndex) => {
    console.log(row)
    return (
      <div>
        {row.assemblyServices.map((item, index) => (
          <ul
            key={index}
            style={{
              width: "auto",
              height: "auto",
              outline: "none",
              padding: "0",
              margin: "0",
            }}
          >
            <input
              type="text"
              name="timeDuration"
              id={`time-${index}`}
              placeholder="Time Req."
              autoComplete="off"
              defaultValue={item.timeDuration || item.duration}
              onChange={(e) => changeTime(e, row, rowIndex, index)}
              style={{
                width: "25%",
                height: "12px",
                padding: "0px",
                fontSize: "small",
                outline: "none",
                border: "none",
                borderBottom: "1px solid black",
                padding: "2%",
                borderRadius: "0",
                fontWeight:"500",
                background:"transparent"
              }}
            />
            <select
              required={true}
              name="unit"
              className="input-config bomasseminputConfg"
              id={`unit-${index}`}
              defaultValue={item.unit}
              onChange={(e) => handleDuration(e, rowIndex, index)}
              style={{
                width: "25%",
                height: "16px",
                outline: "none",
                padding: "0",
                fontSize: "small",
                background: "white",
                borderBottom: "1px solid black",
                borderBottomRadius: "0 !important",
              }}
            >
              <option value="" disabled selected>
                Unit
              </option>
              <option>second</option>
              <option>minute</option>
              <option>hour</option>
              <option>day</option>
              <option>week</option>
              <option>month</option>
            </select>
            <button
              className="action-btn"
              onClick={() => removeServiceName(rowIndex, index)}
              style={{ color: "red" }}
            >
              <FontAwesomeIcon
                style={{ height: "13px", width: "15px" }}
                icon={faTimesCircle}
              />
            </button>
          </ul>
        ))}
      </div>
    );
  };

  const serviceFormatter = (cell, row, rowIndex) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ol type="1" style={{ textAlign: "left", marginTop: "8px" }}>
          {row.assemblyServices.map((item) => (
            <li style={{ marginTop: "4px" }}>{item.serviceName}</li>
          ))}
        </ol>
      </div>
    );
  };

  const removeHandler = (row, rowIndex) => {
    const field = [...temp];
    field.splice(rowIndex, 1);
    setTemp(field);
  };

  const removeFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={(e) => removeHandler(row, rowIndex)}
          style={{ color: "red" }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    );
  };

  const columns = [
    {
      text: "Assembly Name",
      dataField: "assemblyName",
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid #CDCDCD",
        borderRight: "2px solid white",
      },
      headerAttrs: { width: "60px" },
    },
    {
      text: "Required Services",
      dataField: "assemblyServices",
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid #CDCDCD",
        borderRight: "2px solid white",
      },
      headerAttrs: { width: "60px" },
      formatter: serviceFormatter,
      // formatExtraData:form,
    },
    {
      text: "Required Time",
      dataField: "assemblyServices",
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid #CDCDCD",
        borderRight: "2px solid white",
      },
      headerAttrs: { width: "60px" },
      // formatExtraData: ,
      formatter: timeFormatter,
    },
    {
      text: "Action",
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid #CDCDCD",
        borderRight: "2px solid white",
      },
      formatExtraData: { final },
      // dataField: "removerow",
      headerAttrs: { width: "15px" },
      formatter: removeFormatter,
    },
  ];

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  console.log(temp);
  const handleSubmit = () => {
    assemblyData(temp, idx);
    setExpanded(false);
    successNotification("Added Successfully!");
    document.getElementById("type").value = "";
    document.getElementById("name").value = "";
    document.getElementById("services").value = "";
  };

  const cancel = (e) => {
    setForm({
      assemblyType: "",
      assemblyName: "",
      assemblyServices: [],
      timeDuration: "",
      totalDuration: "",
    });
    document.getElementById("type").value = "";
    document.getElementById("name").value = "";
    document.getElementById("services").value = "";
    setAllAssembly([]);
    setTemp([]);
    // setExpanded(false);
    // setOpenCancel(false);
  };

  return (
    <>
      {/* {openCancel && (
        <CancelPopup closeModal={setOpenCancel} submitData={cancel} />
      )} */}
      <Accordion
        style={{ width: "96.1%", marginLeft: "11px" }}
        expanded={expanded}
        onChange={handleAccordionChange("accordion")}
      >
        <div style={{ backgroundColor: "#ebebeb" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Assembly</Typography>
          </AccordionSummary>
        </div>
        <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "11px",
                }}
              >
                Assembly Type<span style={{ color: "red" }}>*</span>
              </label>
              <select
                required={true}
                name="assemblyType"
                className="input-config"
                id="type"
                onChange={handleChange}
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              >
                <option value="" disabled selected>
                  Assembly Type
                </option>
                {/* {
              uniqueAssemblyType.map((item)=>(
                <option>{item}</option>
              ))
            } */}
                <option>Main Assembly</option>
                <option>Sub Assembly</option>
              </select>
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "12px",
                }}
              >
                Assembly Name<span style={{ color: "red" }}>*</span>
              </label>
              <select
                required={true}
                disabled={form.assemblyType ? false : true}
                name="assemblyName"
                className="input-config"
                id="name"
                onChange={handleChange}
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              >
                <option value="" disabled selected>
                  Assembly Name
                </option>
                {form.assemblyType === "Sub Assembly"
                  ? subAssemblyArray.map((item, id) => (
                      <>
                        <option key={id}>{item.assemblyName}</option>
                      </>
                    ))
                  : mainAssemblyArray.map((item, id) => (
                      <>
                        <option key={id}>{item.assemblyName}</option>
                      </>
                    ))}
              </select>
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "6px",
                }}
              >
                Assembly Services<span style={{ color: "red" }}>*</span>
              </label>

              <Multiselect
              ref={multiselectRef}
                disable={form.assemblyName ? false : true}
                name="assemblyServices"
                // defaultValue={form?.assemblyServices?.serviceName}
                placeholder="Assembly Service"
                options={servicesArray[0]}
                displayValue="serviceName"
                onSelect={handleList}
                onRemove={handleList}
                showCheckbox={true}
                className="checkbox"
                //className="input-config"
                id="services"
                style={{
                  multiselectContainer: {
                    marginTop: "10px",
                    marginLeft: "7px",
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                    backgroundColor: "#ebebeb",
                    borderRadius: "4px",
                  },
                  searchBox: {
                    // To change search box element look
                    display: "flex",
                    fontSize: "15px",
                    height: "50px",
                    overflowY: "auto",
                  },
                  inputField: {
                    color: "black",
                    width: "100%",
                  },
                }}
              />
            </div>
            <div
              style={{
                margin: "0px 5px 0px 5px",
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "11px",
                }}
              >
                Unit<span style={{ color: "red" }}>*</span>
              </label>
              <select
                disabled={form.assemblyName ? false : true}
                required={true}
                name="timeDuration"
                className="input-config"
                id="timeDuration"
                value={form.timeDuration}
                onChange={handleDuration}
                style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              >
                <option value="" disabled selected>
                  Unit
                </option>
                <option>hour</option>
                <option>day</option>
                <option>week</option>
                <option>month</option>
              </select> */}
              <button
                type="button"
                className="btn btn-md"
                onClick={addFormFields}
                style={{
                  marginTop: "22px",
                  width: "25%",
                  background: "#015d01",
                  color: "white",
                  height: "51%",
                  marginLeft: "22px",
                }}
              >
                <div style={{ color: "white" }}>Add</div>
              </button>
            </div>
          </div>
          <br />
          <div>
            <BootstrapTable
              wrapperClasses="table-responsive"
              rowClasses="text-nowrap"
              // classes='bomtable'
              bordered={false}
              keyField="id"
              data={temp}
              columns={columns}
              rowStyle={{ border: "1px solid #CDCDCD", borderRadius: "4px" }}
            />
          </div>
          <div className="bomfooter">
            {/* <button id="bomcancel" onClick={() => setOpenCancel(true)}>  */}
            <button id="bomcancel" onClick={cancel}>
              <FontAwesomeIcon icon={faTimesCircle} /> Cancel
            </button>
            <button id="bomsubmit" onClick={handleSubmit}>
              <FontAwesomeIcon icon={faCheckCircle} /> Submit
            </button>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default BomAssembly;
