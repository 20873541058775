import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import SearchBox from "../../../components/search/SearchBox";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppConstants from "../../../utils/app-constants";
import VehicleCompletedDetails from "./VehicleCompletedDetails";
import CompletedDetails from "./CompletedDetails";
import RejectedDetails from "./RejectedDetails";
import VehicleRejectedDetails from "./VehicleRejectedDetails";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import VehicleReturnsCompletedDetails from "./VehicleReturnsCompletedDetails";
import handleExport from "../../../utils/exportExcel";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const VehicleManageRequest = () => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [openRPI, setOpenRPI] = useState();
  const [openGIN, setOpenGIN] = useState();
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [filterData, setFilterData] = useState();

  const fetchData = async () => {
    const res = await trackPromise(
      API.get(
        `qc-module/api/v1/vehicle/newrequest?requestCategory=${formValue.requestCategory}&requestID=${formValue.requestID}&requestedBy=${formValue.requestedBy}&requestDate=${formValue.requestDate}&requestType=${formValue.requestType}&GINno=${formValue.GINNo}&status=${formValue.Status}`
      )
    );
    console.log(res);
    const finalData = res.filter((item, i) => {
      return (
        item.status === AppConstants.COMPLETED ||
        item.status === AppConstants.REJECTED
      );
    });
    console.log(finalData);
    let filteredData = finalData;

    const compareDates = (a, b) => {
      const dateA = new Date(a.requestDate);
      const dateB = new Date(b.requestDate);
      return dateB - dateA; // Sort in descending order (latest first)
    };

    // Sort the array based on the latest requestedDate
    filteredData.sort(compareDates);
    console.log(filteredData);

    setData(filteredData);
    setTempData(filteredData);
  };

  useEffect(() => {
    fetchData();
  }, []);



  const uniquerequestID = [...new Set(data.map((item) => item.requestID))];
  const uniquerequestCategory = [...new Set(data.map((item) => item.requestCategory))];
  const uniquerequestedBy = [...new Set(data.map((item) => item.requestedBy))];
  const uniquerequestType = [...new Set(data.map((item) => item.requestType))];
  const uniqueGINNo = [...new Set(data.map((item) => item.GRNNo))];
  const uniqueWONo = [...new Set(data.map((item) => item.GINNo))];
  const uniquestatus = [...new Set(data.map((item) => item.status))];





  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY / HH:mm")}`;
  }

  const WOFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.requestCategory === "Finished Goods") {
      return (
        <div>
          <p>{row.orderID}</p>
        </div>
      );
    } else {
      return (
        <div>
          <p>-</p>
        </div>
      );
    }
  };

  const GINFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.requestCategory === "Vehicle Returns") {
      return (
        <div>
          <p>{row.ginNo}</p>
        </div>
      );
    } else {
      return (
        <div>
          <p>-</p>
        </div>
      );
    }
  };

  const statusFormatter = (cell) => {
    return cell === AppConstants.COMPLETED ? (
      <button className="active-btn">
        <span className="active-btn-font">Completed</span>
      </button>
    ) : cell === AppConstants.REJECTED ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Rejected</span>
      </button>
    ) : (
      cell
    );
  };

  const filterGRNData = ["213245", "324451266", "635536363", "536477"];
  const filterGINData = ["12345", "2345432", "786477362", "7635442"];

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (
      row.status === AppConstants.COMPLETED &&
      row.requestCategory == "Finished Goods"
    )
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
            }}
            onClick={() => setActionShow(rowIndex)}
          >
            View Details
          </button>
          {actionShow === rowIndex && (
            <CompletedDetails rowData={row} closeModal={setActionShow} />
          )}
        </div>
      );
    else if (
      row.status === AppConstants.COMPLETED &&
      row.requestCategory == "Vehicle Returns"
    )
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
            }}
            onClick={() => setActionShow(rowIndex)}
          >
            View Details
          </button>
          {actionShow === rowIndex && (
            <VehicleReturnsCompletedDetails
              rowData={row}
              closeModal={setActionShow}
            />
          )}
        </div>
      );
    else if (row.status === AppConstants.REJECTED) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
            }}
            onClick={() => setActionShow(rowIndex)}
          >
            View Details
          </button>
          {actionShow === rowIndex && (
            <RejectedDetails rowData={row} closeModal={setActionShow} />
          )}
        </div>
      );
    }
  };

  const [formValue, setFormValue] = useState({
    requestID: "",
    requestedBy: "",
    requestDate: "",
    requestCategory: "",
    requestType: "",
    GRNNo: "",
    GINNo: "",
    Status: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      requestID: "",
      requestBy: "",
      requestDate: "",
      requestCategory: "",
      requestType: "",
      GRNno: "",
      GINNo: "",
      Status: "",
    });

    const res = await trackPromise(
      API.get(
        `qc-module/api/v1/vehicle/newrequest?requestID=&requestedBy=&requestDate=&requestType=&GINno=&orderID=&status=&page=1&limit=10`
      )
    );
    const finalData = res.filter((item, i) => {
      return (
        item.status === AppConstants.COMPLETED ||
        item.status === AppConstants.REJECTED
      );
    });
    console.log(finalData);
    setData(finalData);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };
  const applyFilters = () => {
    fetchData();
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          data.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requestID",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request Category",
      dataField: "requestCategory",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request Type",
      dataField: "requestType",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Requested By",
      dataField: "requestedBy",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Requested Date",
      dataField: "requestDate",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "WO No.",
      dataField: "WoNo",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { openRPI },
      formatter: WOFormatter,
    },
    {
      text: "GIN No.",
      dataField: "returnedQty",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: GINFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Requests</span>
        </div>

        <ToolkitProvider keyField="id" data={data} columns={columns} search>
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(data, "Manage Requests")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form
                    onSubmit={submitHandler}
                    className="VMform"
                    style={{
                      height: "130px",
                      justifyContent: "flex-start",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                    }}
                  >
                    <div className="UHinputfields" style={{ padding: "0px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "19%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Category
                        </label>
                        <select
                          name="requestCategory"
                          value={formValue.requestCategory}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Request Category
                          </option>
                          {
                            uniquerequestCategory.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "19%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request ID
                        </label>
                        <select
                          name="requestID"
                          value={formValue.requestID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Request ID
                          </option>
                          {
                            uniquerequestID.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "19%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Requested By
                        </label>
                        <select
                          name="requestedBy"
                          value={formValue.requestedBy}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Requested By
                          </option>
                          {
                            uniquerequestedBy.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "19%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Date
                        </label>
                        <input
                          type="date"
                          name="requestDate"
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "19%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Type
                        </label>
                        <select
                          name="requestType"
                          value={formValue.requestType}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Request Type
                          </option>
                          {formValue.requestCategory === "Finished Goods" && (
                            <option> Verification </option>
                          )}
                          {formValue.requestCategory === "Vehicle Returns" && (
                            <option> Re-Verification </option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="UHinputfields" style={{ padding: "0px" }}>
                      {formValue.requestCategory === "Finished Goods" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "19%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            WO NO.
                          </label>
                          <select
                            name="GRNNo"
                            value={formValue.GRNNo}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected hidden>
                              WO No.
                            </option>
                            {
                              uniqueWONo.map((item) => (
                                <option>{item}</option>
                              ))
                            }
                          </select>
                        </div>
                      )}

                      {formValue.requestCategory === "Spare Returns" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "19%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            GIN No.
                          </label>
                          <select
                            name="GINNo"
                            value={formValue.GINNo}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected hidden>
                              GIN No.
                            </option>
                            {
                              uniqueGINNo.map((item) => (
                                <option>{item}</option>
                              ))
                            }
                          </select>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "19%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.Status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Status
                          </option>
                          {
                            uniquestatus.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>

                      <button
                        type="submit"
                        id="apply"
                        style={{ marginTop: "20px" }}
                        onClick={() => applyFilters()}
                      >
                        Apply
                      </button>
                      <button
                        style={{ width: "100px", marginTop: "20px" }}
                        onClick={clearFilter}
                        id="clear"
                      >
                        Clear Filter
                      </button>
                    </div>
                  </form>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default VehicleManageRequest;
