//export default EditJobCard

import React, { useState, useEffect, useRef } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import {
  successNotification,
  errorNotification,
} from "../../../utils/notifications";
import { IoMdRefresh } from "react-icons/io";
import auth from "../../../utils/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTimesCircle,
  faTrashAlt,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import { CancelOutlined, CheckCircle } from "@material-ui/icons";
import ViewCustomerDetails from "../Components/ViewCustomerDetails";
import CreateJobCardView from "../Components/CreateJobCardView";
import moment from "moment";
import ViewJobCard from "../Components/ViewJobCard";
import ViewJobCardHistory from "../Components/ViewJobCardHistory";
import ViewTermsDetails from "../../OperationsWarrantyServices/Component/ViewTermsDetails";
import ServiceHistoryDetails from "../Components/serviceHistoryDetails";

const EditJobCard = ({ updatedData, rowData, closeModal , setIsOpen}) => {
  // console.log("hii", rowData);

  // const rowData = {
  //   _id: "66bc92334bdcf918529920db",
  //   jobType: "",
  //   jobID: "17236342275838",
  //   jobDate: "2024-08-17T16:51:00.000Z",
  //   vehicleSkuCode: "TGRD01",
  //   vehicleRegNo: "DL-SDKD-8378",
  //   serviceCenterUserID: "172147190955331",
  //   serviceCenterLocation: "G2/18, Sec 10 , , Rohini, Delhi, 110085",
  //   serviceCenterName: "Khanna Chaps Vehicles",
  //   serviceCenterGstin: null,
  //   serviceCenterPhone: "8700000043",
  //   dealerID: "172147135242884",
  //   tenantID: "d48c2153-0032-4027-b007-f8f89366e7c4",
  //   vehicleModel: "TGFR",
  //   inspectionsChecks: {
  //     serviceNumber: 1,
  //     serviceCategoryId: 50672,
  //     serviceCategory: "Interim Service",
  //     mandatoryInspectionChecks: [
  //       {
  //         serviceDetailsId: 50672,
  //         inspectionCheck: [
  //           {
  //             serviceDetailsId: 50672,
  //             inspectionCheck: "service",
  //             cost: 2000,
  //           },
  //           {
  //             serviceDetailsId: 50672,
  //             inspectionCheck: "Air Filter Check",
  //             cost: 20,
  //           },
  //           {
  //             serviceDetailsId: 50672,
  //             inspectionCheck: "Spark Plug Check",
  //             cost: 20,
  //           },
  //           {
  //             serviceDetailsId: 50672,
  //             inspectionCheck: "Head Light Check",
  //             cost: 100,
  //           },
  //         ],
  //       },
  //     ],
  //     optionalInspectionChecks: [
  //       {
  //         serviceDetailsId: 50672,
  //         inspectionCheck: [
  //           {
  //             serviceDetailsId: 96216,
  //             inspectionCheck: "Back Light Check",
  //             cost: 100,
  //           },
  //         ],
  //       },
  //     ],
  //     spares: [
  //       {
  //         skuCode: "MPT001",
  //         componentID: "71A68E2F79",
  //         componentName: "Handle Grips",
  //         category: "Moulded Plastic Partss",
  //         subCategory: "Plastic Materials",
  //         requiredQty: "",
  //         spareType: "Mandatory",
  //         sparePartMrp: 1925,
  //       },
  //     ],
  //   },
  //   customerComplaints: [
  //     {
  //       customerComplaint: "Example",
  //       cost: "345",
  //       spare: "Handle Grips",
  //       spareCost: 1925,
  //     },
  //   ],
  //   vehicleCategory: "Hybrid",
  //   vehicleSegment: "4-Wheeler",
  //   vehicleVariant: "DTS-i",
  //   vehicleType: "Sports",
  //   vehicleColor: "White",
  //   engineNo: "897",
  //   chassisNo: "897",
  //   dateOfPurchase: "2024-07-22T09:08:06.407Z",
  //   warrantyDuration: "2",
  //   serviceDetails: {
  //     serviceCategory: "Interim Service",
  //     serviceNo: "",
  //     serviceType: "1",
  //     additionalChecks: [],
  //     additionalSpares: [],
  //     technicianName: "Rahul",
  //     jobDate: "2024-08-17T16:51:00.000Z",
  //     deliveryDateAndTime: "2024-08-23T16:46:00.000Z",
  //     cgst: "",
  //     sgst: "",
  //     serviceCharge: "",
  //   },
  //   customerDetails: {
  //     customerName: "manisha chodhary",
  //     customerAddress: "",
  //     customerEmail: "manisha@mailinator.com",
  //     customerPhone: "980000000",
  //   },
  //   status: "New Job Card",
  //   actions: [
  //     {
  //       status: "New Job Card",
  //       actionDate: "2024-08-14T11:17:07.583Z",
  //     },
  //   ],
  //   serviceAdvisorName: "Lokesh Kumar",
  // };

  console.log("rowData", rowData);

  const [blankForm, setBlankForm] = useState({
    jobType: "",
    vehicleRegNo: "",
    serviceCategory: "",
    serviceNo: "",
    serviceType: "",
    mandatoryInspectionChecks: [],
    additionalChecks: "",
    mandatoryReplacement: [],
    additionalSpares: "",
    technicianName: "",
    deliveryDateAndTime: "",
    jobDate: "",
    serviceCharges: "",
    cgst: "",
    sgst: "",
  });

  const [view, setView] = useState(false);
  const [open, setOpen] = useState(false);

  const cData = rowData?.customerComplaints?.map((item) => item);
  const [blankData, setBlankData] = useState(cData);

  const [viewCharges, setViewCharges] = useState();
  const [viewCJC, setViewCJC] = useState();
  const [serviceDetailsData, setServiceDetailsData] = useState(
    rowData.serviceDetails
  );

  const handleInputChange = (event) => {
    setServiceDetailsData({
      ...serviceDetailsData,
      [event.target.name]: event.target.value,
    });
  };

  const refresh = () => {
    updatedData();
  };

  const inspectionCheckField = () => {
    serviceDetailsData.mandatoryInspectionCheck?.filter((item) => {
      if (item.check == true) {
        return item.inspectionCheck;
      } else {
        return "-";
      }
    });
  };

  const mandReplacementsField = () => {
    serviceDetailsData.mandatoryReplacements?.filter((item) => {
      if (item.verify == true) {
        return item.replacements;
      } else {
        return "-";
      }
    });
  };

  var expanded = false;
  function showCheckboxes() {
    var checkboxes = document.getElementById("checkboxes");
    if (!expanded) {
      checkboxes.style.display = "block";
      expanded = true;
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }
  }

  const [formValue, setFormValue] = useState(
    serviceDetailsData.additionalChecks
  );

  const onCheckboxChange = (e, item, index) => {
    let tempInfo = [...formValue];

    if (e.target.checked == true) {
      console.log("pushed");
      if (tempInfo.some((info) => info.additionalID == item.additionalID)) {
        serviceDetailsData.additionalChecks[index].verify = true;
      } else {
        tempInfo.push(item);
        serviceDetailsData.additionalChecks[index].verify = true;
      }
    } else {
      console.log("poped");
      if (tempInfo.some((info) => info.additionalID == item.additionalID)) {
        serviceDetailsData.additionalChecks[index].verify = false;
      } else {
        tempInfo = tempInfo?.filter(
          (info) => info.additionalID != item.additionalID
        );
        serviceDetailsData.additionalChecks[index].verify = false;
      }
    }

    setFormValue(tempInfo);
    console.log(tempInfo);
  };

  var spareExpanded = false;
  function showSparesCheckboxes() {
    var spareCheckboxes = document.getElementById("spareCheckboxes");
    if (!spareExpanded) {
      spareCheckboxes.style.display = "block";
      spareExpanded = true;
    } else {
      spareCheckboxes.style.display = "none";
      spareExpanded = false;
    }
  }

  const [spareFormValue, setSpareFormValue] = useState(
    serviceDetailsData.additionalSpares
  );

  const onSparesCheckboxChange = (e, item, index) => {
    let tempInfo = [...spareFormValue];

    if (e.target.checked == true) {
      console.log("pushed");
      if (tempInfo.some((info) => info.spareID == item.spareID)) {
        serviceDetailsData.additionalSpares[index].check = true;
      } else {
        tempInfo.push(item);
        serviceDetailsData.additionalSpares[index].check = true;
      }
    } else {
      console.log("poped");
      if (tempInfo.some((info) => info.spareID == item.spareID)) {
        serviceDetailsData.additionalSpares[index].check = false;
      } else {
        tempInfo = tempInfo?.filter((info) => info.spareID != item.spareID);
        serviceDetailsData.additionalSpares[index].check = false;
      }
    }

    setSpareFormValue(tempInfo);
    console.log(tempInfo);
  };

  console.log("blankData-->", blankData);

  // const handleInputChanger = async (event, index) => {
  //   const { name, value } = event.target;
  //   const field = [...blankData];
  //   field[index][name] = value;
  //   setBlankData(field);
  // };

  const handleInputChanger = async (event, index) => {
    const { name, value } = event.target;
    const field = [...blankData];
    field[index][name] = value;
    if (name === "spare") {
      const spareCost =
        spareData
          ?.flatMap((item) => item.spareDetails)
          ?.find((spare) => spare?.sparePartname == value)?.sparePartMrp || "";
      field[index]["spareCost"] = spareCost;
    }
    setBlankData(field);
  };

  const addFormFields = () => {
    setBlankData([
      ...blankData,
      {
        customerComplain: "",
        cost: "",
        spare: "",
      },
    ]);
  };

  const removeFields = (index) => {
    const field = [...blankData];
    field.splice(index, 1);
    setBlankData(field);
  };

  const servType = [
    { text: "First", value: 1 },
    { text: "Second", value: 2 },
    { text: "Third", value: 3 },
    { text: "Fourth", value: 4 },
    { text: "Fifth", value: 5 },
    { text: "Sixth", value: 6 },
    { text: "Seventh", value: 7 },
    { text: "Eight", value: 8 },
    { text: "Ninth", value: 9 },
    { text: "Tenth", value: 10 },
  ];

  function numberToOrdinalText(num) {
    const ordinals = [
      "First",
      "Second",
      "Third",
      "Fourth",
      "Fifth",
      "Sixth",
      "Seventh",
      "Eighth",
      "Ninth",
      "Tenth",
    ];

    if (num >= 1 && num <= 10) {
      return ordinals[num - 1];
    } else {
      return "Number out of range";
    }
  }

  const spareValueType =
    rowData?.inspectionsChecks?.spares?.map((el) => el?.spareType) || [];
  console.log("spareValueType-->", spareValueType);
  console.log("uoooo-->", spareValueType == "Mandatory");

  const [checkSpare, setCheckSpare] = useState();

  const [typeData, setTypeData] = useState({
    vehicleSkuCode: rowData?.vehicleSkuCode || "",
    serviceCategory: serviceDetailsData?.serviceCategory || "",
    serviceType: serviceDetailsData?.serviceType || "",
  });

  const handleTypeChange = (event) => {
    const { name, value } = event.target;
    console.log("name, value", name, value);
    setTypeData({ ...typeData, [name]: value });
    setChanged(true);
  };

  console.log("typeData", typeData);
  console.log("checkSpare", checkSpare);

  // Function to change job details
  const changeJobDetails = async () => {
    try {
      // Making API request
      const resp = await trackPromise(
        API.get(`serviceAdvisor-module/api/v1/serviceJobs/getJobChecks`, {
          params: typeData,
        })
      );
      setCheckSpare(resp);
      const { success, message } = resp;
      if (success) {
        closeModal(false);
        setIsOpen(false)
        successNotification(message, { autoClose: 3000 });
        refresh();
      } else {
        errorNotification(message, { autoClose: 2000 });
      }
    } catch (error) {
      // errorNotification('An error occurred while fetching job details.', { autoClose: 2000 });
      console.error("Error fetching job details:", error);
    }
  };

  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (changed) {
      changeJobDetails();
    }
  }, [typeData]);

  console.log("serviceDetailsData", serviceDetailsData);

  const [spareData, setSpareData] = useState();

  const fetchSpareData = async () => {
    const resp3 = await trackPromise(
      API.get(`serviceAdvisor-module/api/v1/serviceJobs/getAllSpares`)
    );
    setSpareData(resp3);
  };

  useEffect(() => {
    fetchSpareData();
  }, []);

  console.log(spareData);

  const [showData, setShowData] = useState();
  const fetchData = async () => {
    try {
      // Making API request
      const resp = await trackPromise(
        API.get(
          `serviceAdvisor-module/api/v1/serviceJobs/getJobCardDetails?jobID=${rowData?.jobID}`
        )
      );
      setShowData(resp);
      const { success, message } = resp;
      if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        refresh();
      } else {
        errorNotification(message, { autoClose: 2000 });
      }
    } catch (error) {
      errorNotification("An error occurred while fetching job details.", {
        autoClose: 2000,
      });
      console.error("Error fetching job details:", error);
    }
  };

  useEffect(() => {
    if (rowData?.jobID) {
      // Check if rowData and jobID are available
      fetchData();
    }
  }, [rowData]); // Include rowData in dependency array if it affects the fetch

  console.log("showData", showData);

  const submitHandler = async () => {
    console.log(serviceDetailsData);
    const additionalSpares = spareFormValue?.filter((item) => "check" in item);
    const additionalChecks = formValue?.filter((item) => "verify" in item);
    const finalAdditionalData = [...additionalChecks, ...additionalSpares];

    let finalBody = {
      jobID: rowData.jobID,
      serviceCategory: typeData.serviceCategory,
      serviceType: typeData?.serviceType,
      serviceNo: serviceDetailsData.serviceNo,
      additionalData: finalAdditionalData,

      inspectionsChecks: checkSpare?.data,
      customerComplaints: blankData,
      technicianName: serviceDetailsData?.technicianName,
      jobDate: serviceDetailsData?.jobDate,
      deliveryDateAndTime: serviceDetailsData?.deliveryDateAndTime,
    };

    console.log("finalBody--->", finalBody);

    const resp = await trackPromise(
      API.post(`serviceAdvisor-module/api/v1/serviceJobs/editJob`, finalBody)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  // FOR SCROLLBAR


  // const ref1 = useRef(null);
  // const ref2 = useRef(null);
  // const ref3 = useRef(null);
  // const ref4 = useRef(null);

  // const [showScrollbar1, setShowScrollbar1] = useState(false);
  // const [showScrollbar2, setShowScrollbar2] = useState(false);
  // const [showScrollbar3, setShowScrollbar3] = useState(false);
  // const [showScrollbar4, setShowScrollbar4] = useState(false);

  // useEffect(() => {
  //   // Check if each container needs a scrollbar
  //   if (ref1.current) {
  //     setShowScrollbar1(ref1.current.scrollHeight > ref1.current.clientHeight);
  //   }
  //   if (ref2.current) {
  //     setShowScrollbar2(ref2.current.scrollHeight > ref2.current.clientHeight);
  //   }
  //   if (ref3.current) {
  //     setShowScrollbar3(ref3.current.scrollHeight > ref3.current.clientHeight);
  //   }
  //   if (ref4.current) {
  //     setShowScrollbar4(ref4.current.scrollHeight > ref4.current.clientHeight);
  //   }
  // }, [checkSpare, rowData]); // Recheck when data changes



  const closeHandler = ()=>{
    setIsOpen(false)
    closeModal(false)
  }



  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1100px", height: "90vh", overflow: "scroll" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Edit Job Card</h3>
        </div>

        <>
          <div>
            <div id="add-user-from" className="setting-form">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px , 0px",
                }}
              >
                <div>Vehicle Reg. No. {rowData?.vehicleRegNo}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#0a18a3",
                  }}
                >
                  <span>Job Card Id : {rowData?.jobID}</span>
                  <span>
                    Job Card Date :{" "}
                    {moment(rowData?.jobDate).format("DD-MM-YYYY")}
                  </span>
                </div>
              </div>

              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <div
                  style={{
                    borderRadius: "5px",
                    padding: "3px",
                    boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.2)",
                    width: "24%",
                    marginTop: "12px",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      display: "block",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Vehicle Details
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "3px",
                      alignItems: "start",
                    }}
                  >
                    <span style={{ fontWeight: "100" }}>
                      Model : {rowData?.vehicleModel}
                    </span>
                    <span style={{ fontWeight: "100" }}>
                      Variant : {rowData?.vehicleVariant}
                    </span>
                    <span style={{ fontWeight: "100" }}>
                      Type : {rowData?.vehicleType}
                    </span>
                    <span style={{ fontWeight: "100" }}>
                      color : {rowData?.vehicleColor}
                    </span>
                    <span style={{ fontWeight: "100" }}>
                      Engine No : {rowData?.engineNo}
                    </span>
                    <span style={{ fontWeight: "100" }}>
                      Purchase Date :{" "}
                      {rowData?.invoiceDate? moment(rowData?.invoiceDate).format("DD-MM-YYYY"):""}
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "5px",
                    padding: "3px",
                    boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.2)",
                    width: "24%",
                    marginTop: "12px",
                    marginLeft: "7px",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      display: "block",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Customer Details
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "3px",
                      alignItems: "start",
                    }}
                  >
                    <span style={{ fontWeight: "100" }}>
                      Name : {rowData?.customerDetails?.customerName}
                    </span>
                    <span style={{ fontWeight: "100" , textAlign:"left"}}>
                      Address :{rowData?.customerDetails?.customerAddress}{" "}
                    </span>
                    <span style={{ fontWeight: "100" }}>
                      Ph : {rowData?.customerDetails?.customerPhone}
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "5px",
                    padding: "3px",
                    boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.2)",
                    width: "24%",
                    marginTop: "12px",
                    marginLeft: "7px",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      display: "block",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Service History
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "3px",
                      alignItems: "start",
                    }}
                  >
                    <span style={{ fontWeight: "100" }}>
                      Last Service Date :{" "}
                      {
                        showData?.serviceHistory?.serviceDetails?.jobDate ? (
                          moment(
                        showData?.serviceHistory?.serviceDetails?.jobDate
                      ).format("DD-MM-YYYY")
                        ) : ""
                      }
                      
                    </span>
                    <span style={{ fontWeight: "100" }}>
                      Service Type :{" "}
                      {showData?.serviceHistory?.serviceDetails?.serviceNo}
                    </span>
                    <span style={{ fontWeight: "100" }}>
                      Service Category :{" "}
                      {
                        showData?.serviceHistory?.serviceDetails
                          ?.serviceCategory
                      }
                    </span>
                    <span
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setView(true)}
                    >
                      ServiceHistory
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    borderRadius: "5px",
                    padding: "3px",
                    boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.2)",
                    width: "24%",
                    marginTop: "12px",
                    marginLeft: "7px",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "1px solid black",
                      display: "block",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Warranty Details
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "3px",
                      alignItems: "start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        display: "flex",
                        alignItems: "start",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        Standard Warranty :{" "}
                      </span>
                      <span
                        style={{
                          fontWeight: "100",
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        Distance Covered :{" "}
                        {
                          showData?.warrantyDetails?.standardWarranty
                            ?.warrantyDistance
                        }
                        {" "}
                        {
                          showData?.warrantyDetails?.standardWarranty
                            ?.warrantyDistanceUnit
                        }
                      </span>
                      <span
                        style={{
                          fontWeight: "100",
                          display: "flex",
                          textAlign:"start"
                        }}
                      >
                        Warranty Duration :
                        {
                          showData?.warrantyDetails?.standardWarranty
                            ?.warrantyDuration
                        }
                        {" "}
                        {
                          showData?.warrantyDetails?.standardWarranty
                            ?.warrantyDurationUnit
                        }{" "}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        display: "flex",
                        alignItems: "start",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        Extended Warranty :{" "}
                      </span>
                      <span
                        style={{
                          fontWeight: "100",
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        Distance Covered :{" "}
                        {
                          showData?.warrantyDetails?.extendedWarranty
                            ?.addOnWarrantyDistance
                        }
                        {" "}
                        {
                          showData?.warrantyDetails?.extendedWarranty
                            ?.addOnWarrantyDistanceUnit
                        }
                      </span>
                      <span
                        style={{
                          fontWeight: "100",
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        Warranty Duration :{" "}
                        {
                          showData?.warrantyDetails?.extendedWarranty
                            ?.addOnWarrantyDuration
                        }
                        {" "}
                        {
                          showData?.warrantyDetails?.extendedWarranty
                            ?.addOnWarrantyDurationUnit
                        }
                      </span>
                      <span
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "start",
                        }}
                        onClick={() => setOpen(true)}
                      >
                        Warranty Term
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h6
                  style={{
                    marginTop: "16px",
                    marginLeft: "12px",
                    display: "flex",
                  }}
                >
                  Job Details :
                </h6>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "12px",
                  alignItems: "start",
                }}
              >
                <label style={{ flexDirection: "column", width: "24%" }}>
                  <label
                    style={{
                      float: "left",
                      marginLeft: "2px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Service Type<span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    style={{
                      marginTop: "-8px",
                      width: "90%",
                      marginLeft: "-18px",
                    }}
                    required={true}
                    name="serviceType"
                    value={
                      typeData?.serviceType ||
                      serviceDetailsData?.serviceType ||
                      ""
                    }
                    className="input-config"
                    onChange={(event) => handleTypeChange(event)}
                  >
                    <option value="" hidden>
                      Service Type
                    </option>
                    {servType.map((item) => (
                      <option key={item.text} value={item.text}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                </label>

                <label style={{ flexDirection: "column", width: "24%" }}>
                  <label
                    style={{
                      float: "left",
                      marginLeft: "12px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Service Category<span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    style={{
                      marginTop: "-8px",
                      width: "90%",
                      marginLeft: "2px",
                    }}
                    required={true}
                    name="serviceCategory"
                    value={
                      typeData.serviceCategory ||
                      serviceDetailsData?.serviceCategory | ""
                    }
                    className="input-config"
                    onChange={(event) => {
                      handleTypeChange(event);
                    }}
                  >
                    <option value="" selected hidden>
                      Service Category
                    </option>
                    <option value="Interim Service">Interim Service</option>
                    <option value="Full Service">Full Service</option>
                  </select>
                </label>
              </div>
            </div>
          </div>

          <div>
            <div>
              <h6 style={{ marginTop: "5px", color: "blue", display: "flex" }}>
                Checks and Spares
              </h6>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                color: "white",
              }}
            >
              <div
                style={{
                  borderRadius: "5px",
                  padding: "3px",
                  boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.2)",
                  width: "24%",
                  marginTop: "12px",
                  backgroundColor: "#0a18a3",
                }}
              >
                <span
                  style={{
                    borderBottom: "1px solid black",
                    display: "block",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Mandatory Inspection Check
                </span>
                <div
                  // ref={ref1}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "3px",
                    boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.2)",
                    borderRadius: "1px",
                    margin: "2px",
                    backgroundColor: "#8c8cd9",
                    height: "70px",
                    //overflowY: showScrollbar1 ? "scroll" : "hidden",
                    overflowY:"auto",
                    scrollbarWidth: "thin", // For Firefox
                    scrollbarColor: "#4a4a9a #e0e0e0", // For Firefox
                    alignItems: "start",
                    paddingLeft: "2px",
                  }}
                >
                  {checkSpare?.data?.mandatoryInspectionChecks[0]?.inspectionCheck?.map(
                    (item) => <span>{item?.inspectionCheck}</span>
                  ) ||
                    rowData?.inspectionsChecks?.mandatoryInspectionChecks[0]?.inspectionCheck?.map(
                      (item) => <span> {item?.inspectionCheck}</span>
                    )}
                </div>
              </div>

              <div
                style={{
                  borderRadius: "5px",
                  padding: "3px",
                  boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.2)",
                  width: "24%",
                  marginTop: "12px",
                  backgroundColor: "#0a18a3",
                  marginLeft: "7px",
                }}
              >
                <span
                  style={{
                    borderBottom: "1px solid black",
                    display: "block",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Mandatory Spares
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "3px",
                    boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.2)",
                    borderRadius: "1px",
                    margin: "2px",
                    backgroundColor: "#8c8cd9",
                    height: "70px",
                    overflowY:"auto",
                    scrollbarWidth: "thin", // For Firefox
                    scrollbarColor: "#4a4a9a #e0e0e0", // For Firefox
                    alignItems: "start",
                    paddingLeft: "2px",
                  }}
                >
                {
  rowData?.inspectionsChecks?.spares
    ?.filter(item => item?.spareType === "Mandatory")
    .map(item => (
      <span key={item?.componentName}>{item?.componentName}</span>
    ))
}

                </div>
              </div>

              <div
                style={{
                  borderRadius: "5px",
                  padding: "3px",
                  boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.2)",
                  width: "24%",
                  marginTop: "12px",
                  backgroundColor: "#0a18a3",
                  marginLeft: "7px",
                }}
              >
                <span
                  style={{
                    borderBottom: "1px solid black",
                    display: "block",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Optional Inspection Check
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "3px",
                    boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.2)",
                    borderRadius: "1px",
                    margin: "2px",
                    backgroundColor: "#8c8cd9",
                    height: "70px",
                    overflowY:"auto",
                    scrollbarWidth: "thin", // For Firefox
                    scrollbarColor: "#4a4a9a #e0e0e0", // For Firefox
                    alignItems: "start",
                    paddingLeft: "2px",
                  }}
                >
                  {checkSpare?.data?.optionalInspectionChecks[0]?.inspectionCheck?.map(
                    (item) => <span> {item?.inspectionCheck}</span>
                  ) ||
                    rowData?.inspectionsChecks?.optionalInspectionChecks[0]?.inspectionCheck?.map(
                      (item) => <span> {item?.inspectionCheck}</span>
                    )}
                </div>
              </div>

              <div
                style={{
                  borderRadius: "5px",
                  padding: "3px",
                  boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.2)",
                  width: "24%",
                  marginTop: "12px",
                  backgroundColor: "#0a18a3",
                  marginLeft: "7px",
                }}
              >
                <span
                  style={{
                    borderBottom: "1px solid black",
                    display: "block",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Optional Spares
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "3px",
                    boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.2)",
                    borderRadius: "1px",
                    margin: "2px",
                    backgroundColor: "#8c8cd9",
                    height: "70px",
                    overflowY:"auto",
                    scrollbarWidth: "thin", // For Firefox
                    scrollbarColor: "#4a4a9a #e0e0e0", // For Firefox
                    alignItems: "start",
                    paddingLeft: "2px",
                  }}
                >
                {
  rowData?.inspectionsChecks?.spares
    ?.filter(item => item?.spareType === "Optional")
    .map(item => (
      <span key={item?.componentName}>{item?.componentName}</span>
    ))
}

                </div>
              </div>
            </div>

            <div style={{ marginTop: "15px" }}>
              {Array.isArray(blankData) &&
                blankData.map((item, index) => (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginLeft: "-14px",
                    }}
                    key={index}
                  >
                    <div style={{ margin: "0px 5px 0px 5px", width: "50%" }}>
                      <label
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          width: "auto",
                          marginLeft: "-205px",
                        }}
                      >
                        Customer Complaints/Remarks
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <br />
                      <input
                        className="input-config"
                        id="customerComplaint"
                        type="text"
                        name="customerComplaint"
                        value={item?.customerComplaint}
                        placeholder="Enter Customer Complaint"
                        onChange={(event) => handleInputChanger(event, index)}
                        style={{ width: "100%", marginTop: "-7px" }}
                      />
                    </div>

                    <div style={{ margin: "0px 5px 0px 5px", width: "22%" }}>
                      <label
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          width: "auto",
                          marginLeft: "-115px",
                        }}
                      >
                        {`Cost (${auth.getUserInfo().currencySymbol})`}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <br />
                      <input
                        className="input-config"
                        type="text"
                        id="cost"
                        name="cost"
                        value={item.cost}
                        placeholder="Enter Cost"
                        onChange={(event) => handleInputChanger(event, index)}
                        style={{ width: "100%", marginTop: "-7px" }}
                      />
                    </div>

                    <div style={{ margin: "0px 5px 0px 5px", width: "22%" }}>
                      <label
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          width: "auto",
                          marginLeft: "-115px",
                        }}
                      >
                        {`Spares`}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <br />
                      <select
                        className="input-config"
                        id="spare"
                        name="spare"
                        value={item.spare}
                        onChange={(event) => handleInputChanger(event, index)}
                        style={{ width: "100%", marginTop: "-7px" }}
                      >
                        <option value="" disabled>
                          Select a spare
                        </option>
                        {spareData?.map((item, index) => {
                          return item?.spareDetails?.map((value, subIndex) => {
                            return (
                              <option
                                value={value?.sparePartname}
                                key={value?.sparePartname}
                              >
                                {value?.sparePartname}
                              </option>
                            );
                          });
                        })}
                      </select>
                    </div>

                    <div style={{ margin: "0px 5px 0px 5px", width: "22%" }}>
                      <label
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          width: "auto",
                          marginLeft: "-90px",
                        }}
                      >
                        {`Spare cost (${auth.getUserInfo().currencySymbol})`}
                      </label>
                      <br />
                      <input
                        className="input-config"
                        type="text"
                        id="spareCost"
                        name="spareCost"
                        value={item.spareCost}
                        disabled
                        placeholder="Spare Cost"
                        onChange={(event) => handleInputChanger(event, index)}
                        style={{ width: "100%", marginTop: "-7px" }}
                      />
                    </div>

                    {index == 0 ? (
                      <button
                        type="button"
                        className="btn btn-md"
                        onClick={() => addFormFields()}
                        style={{ margin: "25px" }}
                      >
                        <div style={{ color: "green" }}>
                          <FontAwesomeIcon icon={faPlus} />
                        </div>
                      </button>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-md"
                          onClick={() => removeFields(index)}
                          style={{ margin: "2px" }}
                        >
                          <div style={{ color: "red" }}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </div>
                        </button>
                        <button
                          type="button"
                          className="btn btn-md"
                          onClick={() => addFormFields()}
                          style={{ margin: "2px" }}
                        >
                          <div style={{ color: "green" }}>
                            <FontAwesomeIcon icon={faPlus} />
                          </div>
                        </button>
                      </>
                    )}
                  </div>
                ))}
            </div>

            <div style={{ marginTop: "15px" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "24%",
                  }}
                >
                  <label
                    style={{
                      float: "left",
                      marginLeft: "-120px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Technician/Mechanic<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    name="technicianName"
                    id="type"
                    className="input-config"
                    placeholder="Technician Name"
                    defaultValue={serviceDetailsData.technicianName}
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    style={{
                      marginTop: "-8px",
                      width: "87%",
                      marginLeft: "-2px",
                    }}
                  />
                </label>

                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "2px",
                    width: "24%",
                    marginRight: "2%",
                  }}
                >
                  <label
                    style={{
                      float: "left",
                      marginLeft: "-170px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Job Date<span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                    className="input-config"
                    id="services"
                    type="datetime-local"
                    name="jobDate"
                    value={moment(serviceDetailsData?.jobDate).format(
                      "YYYY-MM-DDTHH:mm"
                    )}
                    placeholder="Job Date"
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    style={{
                      marginTop: "-8px",
                      width: "87%",
                      marginLeft: "12px",
                    }}
                  />
                </label>
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "2px",
                    width: "24%",
                  }}
                >
                  <label
                    style={{
                      float: "left",
                      marginLeft: "-178px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Delivery Date<span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                    className="input-config"
                    id="services"
                    type="datetime-local"
                    name="deliveryDateAndTime"
                    defaultValue={moment(
                      serviceDetailsData?.deliveryDateAndTime
                    ).format("YYYY-MM-DDTHH:mm")}
                    placeholder="Delivery Date & Time"
                    autoComplete="off"
                    style={{
                      marginTop: "-8px",
                      width: "90%",
                      marginLeft: "-5px",
                    }}
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                  />
                </label>
              </div>
              <br />
            </div>

            <div
              className="save-cancel"
              style={{ margin: "22px 0px 0px 20px" }}
            >
              <div className="cust-reset  cancel-button">
                <button
                  type="button"
                  className="btn btn-icon-label"
                  onClick={closeHandler}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#0A18A3",
                      fontWeight: "500",
                    }}
                  >
                    <CancelOutlined style={{ fontSize: "17px" }} />
                    &nbsp;Cancel
                  </div>
                </button>
              </div>
              <div
                className=" cancel-button"
                style={{ backgroundColor: "#0A18A3", marginRight: "0px" }}
              >
                <button className="btn btn-icon-label" onClick={submitHandler}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "white",
                      fontWeight: "500",
                    }}
                  >
                    <CheckCircle style={{ fontSize: "17px" }} />
                    &nbsp;Update Job Card
                  </div>
                </button>
              </div>
            </div>
          </div>
        </>
      </div>

      {view && (
        <ServiceHistoryDetails
          closeModal={setView}
         // serviceHistory={serviceData}
          UsersData={rowData}
        />
       
      )}

      {open && (
        <ViewTermsDetails
          data={"Vehicle Warranty Details"}
          closeModal={setOpen}
        />
      )}
    </div>
  );
};

export default EditJobCard;
