import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import "../../work-order/workorder.scss";

const ViewQuantity = ({ close, rowData }) => {
  console.log(rowData);
  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "40%", height: "60%" }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                Production Quantity : {parseInt(rowData.productionQuantity)}
              </span>
              <span>Vehicle Model: {rowData.vehicleModel}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Segment: {rowData.vehicleSegment}</span>
              <span>Vehicle Variant: {rowData.vehicleVariant}</span>
            </div>
          </div>

          <div style={{ width: "100%", display: "flex" }}>
            <div
              style={{
                width: "100%",
                overflow: "auto",
                // height: "256px",
                marginTop: "20px",
                textAlign: "left",
              }}
            >
              <span>{rowData.vehicleCategory}</span>
              <table className="simple_tbl" width="100%">
                <thead>
                  <tr>
                    <th style={{ width: "50%", position: "sticky", top: "0" }}>
                      Vehicle Color
                    </th>
                    <th style={{ width: "50%", position: "sticky", top: "0" }}>
                      Production Qty
                    </th>
                  </tr>
                </thead>

                <tr>
                  <td>{rowData.vehicleColor}</td>
                  <td>{rowData.productionQuantity}</td>
                </tr>
              </table>
            </div>
          </div>
          <br />
          <div className="footer">
            <button onClick={() => close(false)}><KeyboardBackspace/> Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewQuantity;
