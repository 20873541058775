import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFilter, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../components/modal/modal.scss";
import SearchBox from "../../components/search/SearchBox";
import moment from "moment";
import AppConstants from "../../utils/app-constants";
import FinishedGoodsViewAct from "./action/finishedGoodsViewAct";
import FinishedGoodsUpdates from "./action/finishedGoodsUpdates";
import handleExport from "../../utils/exportExcel";
import ToolkitProvider, { CSVExport , Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const FinishedGoods = () => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [show, setShow] = useState(false);
  const [openWO, setOpenWO] = useState();
  const [wipData, setWipData] = useState([]);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/inventoryGoodsVerification/getFinishedGoodsDetails?requestID=${formValue.requestId}&requestedDate=${formValue.requestDate}&orderID=${formValue.wono}&status=${formValue.status}&requestCategory=${formValue.requestCategory}&requestType=${formValue.requestType}&requestBy=${formValue.requestBy}`
      )
    );
    console.log(resp);
    setWipData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);


  const uniqueRequestID = [...new Set(wipData.map((item) => item.requestID))];
  const uniqueRequestedBy = [...new Set(wipData.map((item) => item.requestedBy))];
  const uniqueOrderID = [...new Set(wipData.map((item) => item.orderID))];
  const uniqueStatus = [...new Set(wipData.map((item) => item.status))];




  const [formValue, setFormValue] = useState({
    requestId: "",
    status: "",
    wono: "",
    requestCategory: "",
    requestType: "",
    requestDate: "",
    requestBy: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async(e) => {
    setFormValue({
      requestId: "",
      status: "",
      wono: "",
      requestCategory: "",
      requestType: "",
      requestDate: "",
      requestBy: "",
    });

    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/inventoryGoodsVerification/getFinishedGoodsDetails`
      )
    );
    setWipData(resp);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY / HH:mm")}`;
  }

  const statusFormatter = (cell) => {
    return cell === "Rejected" ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Rejected</span>
      </button>
    ) : cell === "New Request" ? (
      <button className="pendingByProc-btn">
        {" "}
        <span className="pendingByProc-btn-font">Pending at QC</span>
      </button>
    ) : cell === AppConstants.ONHOLD ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">On Hold</span>
      </button>
    ) : cell === "In-Progress" ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">In Progress</span>
      </button>
    ) : cell === "Completed" ? (
      <button className="active-btn">
        {" "}
        <span className="active-btn-font">Completed</span>
      </button>
    ) : cell === "Passed" ? (
      <button className="active-btn">
        {" "}
        <span className="active-btn-font">Passed</span>
      </button>
    ) : (
      cell
    );
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          style={{
            width: "100px",
            fontSize: "12px",
            color: "#0a18a3",
            cursor: "pointer",
            padding: "3px 9px",
            border: "1px solid #0a18a3",
            borderRadius: "4px",
            background: "white",
          }}
          onClick={() => setActionShow(rowIndex)}
        >
          {row.status === "New Request"
            ? "View & Act"
            : row.status === "On Hold" || row.status === "Failed"
            ? "View Details"
            : "View Updates"}
        </button>
        {actionShow === rowIndex &&
          (row.status === "New Request" ||
            row.status === "On Hold" ||
            row.status === "Failed") && (
            <FinishedGoodsViewAct rowData={row} closeModal={setActionShow} />
          )}
        {actionShow === rowIndex &&
          (row.status === "In-Progress" || row.status === "Completed") && (
            <FinishedGoodsUpdates rowData={row} SNO={wipData.SNO} closeModal={setActionShow} />
          )}
        {/* {(actionShow === rowIndex) && row.status === "In Progress" || row.status === "Completed" && <EditDetails_WIP rowData={row} closeModal={setActionShow} />}  */}
      </div>
    );
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return wipData.findIndex(function (value, idx, arr) { return value == row }) + 1;
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requestID",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { openWO },
      // formatter: woFormatter,
    },
    {
      text: "Request Category",
      dataField: "requestCategory",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request Type",
      dataField: "requestType",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request By",
      dataField: "requestedBy",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "WO ID",
      dataField: "orderID",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow },
      formatter: actionFormatter,
    },
  ];

  return (
         
          <>
          <div >
                <div id="overlay" onClick={(e) => off(e)}></div>
                 <div className="heading bussiness" style={{marginBottom:"-5px"}}>
                   <span>Finished Goods Verification</span>
                 </div>
         
                  <ToolkitProvider keyField="id" data={wipData} columns={columns} search >
                  
                   {(props) => (
                     <>
                     <div>
                       <div style={{ float: "right", marginTop: "-42px"}}>
                         <div style={{ display: "flex", marginRight: "-15px", height: "26px", marginTop:"10px", }}>
                           <div style={{ width: "220px" }}>
                             
                              <SearchBar
                               {...props.searchProps}
                               placeholder='Search...'
                               style={{
                                 height: "25px",
                                // border: "1px solid #C6C6C6",
                                 border:"none",
                                 background: "white",
                                 borderRadius: "3px",
                                 marginRight: "-50px",
                               }}
                             />
                             
                           </div>
                           <div onClick={clearFilter} className="filter-refresh">
                             <FontAwesomeIcon icon={faRedoAlt} />
                             &nbsp; Refresh
                           </div>
                           <div className="filter-refresh" onClick={handleClick}>
                             {" "}
                             <FontAwesomeIcon icon={faFilter} />
                             &nbsp; Filters
                           </div>
                           <div
                             className="filter-refresh"
                             onClick={() => handleExport(wipData, "manageUsers")}
                           >
                             <FontAwesomeIcon icon={faDownload} />
                             &nbsp; Export
                           </div>
                         </div>
                        </div>
                       </div>
                        
              <div>
              

      {show && (
        <form
          onSubmit={submitHandler}
          style={{
            display: "flex",
            flexWrap: "wrap",
            height: "135px",
            backgroundColor: "#F7F7F7",
            padding: "5px",
          }}
          className="UHinputfields VMform"
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "24%" }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              {" "}
              Request ID
            </label>
            <select
              style={{ marginTop: "-5px", width: "100%" }}
              name="requestId"
              value={formValue.requestId}
              onChange={handleChange}
            >
              <option value="" disabled selected hidden>
                Request ID
              </option>
              {uniqueRequestID.map((item) => (
                <option>{item}</option>
              ))}
            </select>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "24%" }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              {" "}
              Request Category
            </label>

            <input
              type="text"
              style={{ marginTop: "-5px", width: "100%" }}
              name="requestCategory"
              value={formValue.requestCategory}
              onChange={handleChange}
              placeholder="Request Category"
              autoComplete="off"
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "24%" }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              {" "}
              Request Type
            </label>
            <input
              type="text"
              style={{ marginTop: "-5px", width: "100%" }}
              name="requestType"
              value={formValue.requestType}
              onChange={handleChange}
              placeholder="Request Type"
              autoComplete="off"
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "24%" }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              {" "}
              Requested By
            </label>
            <select
              style={{ marginTop: "-5px", width: "100%" }}
              name="requestBy"
              value={formValue.requestBy}
              onChange={handleChange}
            >
              <option value="" disabled selected hidden>
                Requested By
              </option>
              {uniqueRequestedBy.map((item) => (
                <option>{item}</option>
              ))}
            </select>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "24%" }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              {" "}
              WO Id.
            </label>
            <select
              style={{ marginTop: "-5px", width: "100%" }}
              name="wono"
              value={formValue.wono}
              onChange={handleChange}
            >
              <option value="" disabled selected hidden>
                WO Id.
              </option>
              {uniqueOrderID.map((item) => (
                <option>{item}</option>
              ))}
            </select>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "24%" }}
          >
            <label style={{ fontSize: "13px", fontWeight: "500" }}>
              {" "}
              Status
            </label>

            <select
              style={{ marginTop: "-5px", width: "100%" }}
              name="status"
              value={formValue.status}
              onChange={handleChange}
            >
              <option value="" disabled selected hidden>
                Status
              </option>
              {uniqueStatus.map((item) => (
                <option>{item}</option>
              ))}
            </select>
          </div>
          {/* <ReactDatePicker
            name="requestDate"
            className="requestdate"
            selected={formValue.requestDate}
            onChange={(date) => setFormValue({ ...formValue, 'requestDate': date })}
            isClearable
            placeholderText="Request Date"
            dateFormat="DD-MM-YYYY/HH:mm"
            autoComplete="off"
          /> */}
          <div
            style={{
              width: "46%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <button
              onClick={fetchReport}
              style={{ width: "100px", marginTop: "24px" }}
              id="apply"
            >
              Apply
            </button>
            <button
              style={{ width: "100px", marginTop: "24px" }}
              onClick={clearFilter}
              id="clear"
            >
              Clear Filter
            </button>
          </div>
        </form>
      )}
      <br></br>

      </div>

      
<BootstrapTable
  wrapperClasses="table-responsive"
  // rowClasses="text-nowrap"
  rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
  pagination={paginationFactory({
    hidePageListOnlyOnePage: true,
    showTotal: true,
    hideSizePerPage: true,
    paginationSize: 2,
    sizePerPage: "10",
  })}
  noDataIndication="No Data Found"
  {...props.baseProps}
/>
</>
)}
</ToolkitProvider>
</div>
</>
  );
};

export default FinishedGoods;
