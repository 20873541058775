import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import SearchBox from "../../../components/search/SearchBox";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import handleExport from "../../../utils/exportExcel";
import AppConstants from "../../../utils/app-constants";
import Action from "./Action";
import MappedStates from "./MappedStates";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ManageSalesUserTable = ({ userType, usersData, updatedData, inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [statesShow, setStateShow] = useState();
  const [stateOpen, setStateOpen] = useState(true);

  // console.log(usersData);

  const uniqueCountry = [...new Set(usersData?.map((item) => item.countryName))];
  const uniquezone = [...new Set(usersData?.map((item) => item.zone))];
  const uniquearea = [...new Set(usersData?.map((item) => item.area))];
  const uniqueStatus = [...new Set(usersData?.map((item) => item.zsmStatus))];
  const uniqueullName = [...new Set(usersData?.map((item) => item.zsmFullName))];

  console.log(usersData)

  const zoneFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log(row);
    if (!row.zones) {
      return row.zone
    } else {
      return (
        <>
          {row.zones?.map((item, index) => (
            <span key={index}>
              {item.zone}
              {index !== row.zones.length - 1 && ","}
            </span>
          ))}
        </>
      );
    }
  };

  const areaFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log(row);
    if (!row.areas) {
      return row.area
    } else {
      return (
        <>
          {row.areas?.map((item, index) => (
            <span key={index}>
              {item.area}
              {index !== row.areas.length - 1 && ","}
            </span>
          ))}
        </>
      );
    }
    // return <>{row.area ? <span>{row.area}</span> : "-"}</>;
  };

  const stateHandler = (rowIndex) => {
    setStateOpen(true);
    setStateShow(rowIndex);
  };

  const statesFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log(row);
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => stateHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          View
        </button>
        {statesShow === rowIndex && stateOpen ? (
          <MappedStates
            rowData={row.areas}
            states={row.states}
            rowdata={row}
            zone={row.zone}
            areas={row.areas}
            userType={userType}
            close={setStateOpen}
          />
        ) : null}
      </div>
    );
  };

  const nameFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log(row);
    if (userType == "National Sales") {
      return <span>{row.nsmFullName}</span>;
    } else if (userType == "Zonal Sales") {
      return <span>{row.zsmFullName}</span>;
    } else {
      return <span>{row.asmFullName}</span>;
    }
  };

  const userTypeFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <span>{userType}</span>;
  };

  const emailFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (userType == "National Sales") {
      return <span>{row.nsmEmail}</span>;
    } else if (userType == "Zonal Sales") {
      return <span>{row.zsmEmail}</span>;
    } else {
      return <span>{row.asmEmail}</span>;
    }
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (userType == "National Sales") {
      return row.nsmStatus === AppConstants.DEACTIVATE ? (
        <button className="deactive-btn" style={{ width: "100%" }}>
          {" "}
          <span className="deactive-btn-font">Deactivated</span>
        </button>
      ) : row.nsmStatus === AppConstants.REACTIVATE ? (
        <button className="reactive-btn" style={{ width: "100%" }}>
          {" "}
          <span className="reactive-btn-font">Reactivated</span>
        </button>
      ) : row.nsmStatus === AppConstants.DELETE ? (
        <button className="delete-btn" style={{ width: "100%" }}>
          {" "}
          <span className="delete-btn-font">Deleted</span>
        </button>
      ) : (
        <button className="active-btn" style={{ width: "50%" }}>
          <span className="active-btn-font">Active</span>
        </button>
      );
    }
    if (userType == "Zonal Sales") {
      return row.zsmStatus === AppConstants.DEACTIVATE ? (
        <button className="deactive-btn" style={{ width: "100%" }}>
          {" "}
          <span className="deactive-btn-font">Deactivated</span>
        </button>
      ) : row.zsmStatus === AppConstants.REACTIVATE ? (
        <button className="reactive-btn" style={{ width: "100%" }}>
          {" "}
          <span className="reactive-btn-font">Reactivated</span>
        </button>
      ) : row.zsmStatus === AppConstants.DELETE ? (
        <button className="delete-btn" style={{ width: "100%" }}>
          {" "}
          <span className="delete-btn-font">Deleted</span>
        </button>
      ) : (
        <button className="active-btn" style={{ width: "50%" }}>
          <span className="active-btn-font">Active</span>
        </button>
      );
    }
    if (userType == "Area Sales") {
      return row.asmStatus === AppConstants.DEACTIVATE ? (
        <button className="deactive-btn" style={{ width: "100%" }}>
          {" "}
          <span className="deactive-btn-font">Deactivated</span>
        </button>
      ) : row.asmStatus === AppConstants.REACTIVATE ? (
        <button className="reactive-btn" style={{ width: "100%" }}>
          {" "}
          <span className="reactive-btn-font">Reactivated</span>
        </button>
      ) : row.asmStatus === AppConstants.DELETE ? (
        <button className="delete-btn" style={{ width: "100%" }}>
          {" "}
          <span className="delete-btn-font">Deleted</span>
        </button>
      ) : (
        <button className="active-btn" style={{ width: "50%" }}>
          <span className="active-btn-font">Active</span>
        </button>
      );
    }
  };

  // idea is here

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    let status;
    userType == "National Sales"
      ? (status = row.nsmStatus)
      : userType == "Zonal Sales"
        ? (status = row.zsmStatus)
        : userType == "Area Sales"
          ? (status = row.asmStatus)
          : (status = "Active");
    return (
      <div>
        {status == "Delete" ? (
          ""
        ) : (
          <button
            className="action-btn"
            onClick={() => actionHandler(rowIndex)}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </button>
        )}
        {actionShow === rowIndex && isOpen ? (
          <Action
            rowData={row}
            setIsOpen={setIsOpen}
            userType={userType}
            usersData={usersData}
            updatedData={updatedData}
            rowIndex={rowIndex}
          />
        ) : null}
      </div>
    );
  };

  function dateFormatter(cell, row, rowIndex, formatExtraData) {
    let date;
    userType == "National Sales"
      ? (date = row?.nsmDate)
      : userType == "Zonal Sales"
        ? (date = row?.zsmDate)
        : userType == "Area Sales"
          ? (date = row?.asmUserAddedDate)
          : "-";
    return `${moment(date).format("DD-MMM-YYYY / HH:mm")}`;
  }
  function reasonFormatter(cell, row, rowIndex, formatExtraData) {
    let reason;
    userType == "National Sales"
      ? (reason = row?.nsmStatusReasson)
      : userType == "Zonal Sales"
        ? (reason = row?.zsmStatusReasson)
        : userType == "Area Sales"
          ? reason = row?.asmStatusReasson
          : "-";
    return <span>{reason}</span>;
  }

  const [formValue, setFormValue] = useState({
    userType: "",
    country: "",
    zones: "",
    area: "",
    mappedStates: "",
    fName: "",
    lName: "",
    email: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async (e) => {
    setFormValue({
      userType: "",
      country: "",
      zones: "",
      area: "",
      mappedStates: "",
      fName: "",
      lName: "",
      email: "",
    });

    const resp = await trackPromise(
      API.get(`inventory-module/api/v1/vehicleReturns`)
    );
    setUsersData(
      resp.data.filter((item) => item.status == "Request Completed")
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },

      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          usersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "User Type",
      dataField: "userType",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: userTypeFormatter,
    },
    {
      text: "Country",
      dataField: "countryName",
      classes: "cellsOverflow",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    userType == "National Sales"
      ? {
        headerStyle: { display: "none" },
        style: { display: "none" },
      }
      : {
        text: "Zone",
        dataField: "zone",
        headerAttrs: { width: "200px" },
        classes: "cellsOverflow",
        headerStyle: {
          fontWeight: "500",
          borderTop: "1px solid rgba(0,0,0,0.2)",
        },
        formatter: zoneFormatter,
      },
    userType == "National Sales" || userType == "Zonal Sales"
      ? {
        headerStyle: { display: "none" },
        style: { display: "none" },
      }
      : {
        text: "Area",
        dataField: "area",
        classes: "cellsOverflow",
        headerAttrs: { width: "200px" },
        headerStyle: {
          fontWeight: "500",
          borderTop: "1px solid rgba(0,0,0,0.2)",
        },
        formatter: areaFormatter,
      },
    userType == "National Sales"
      ? {
        headerStyle: { display: "none" },
        style: { display: "none" },
      }
      : {
        text: "View Mappings",
        dataField: "states",
        classes: "cellsOverflow",
        headerAttrs: { width: "150px" },
        headerStyle: {
          fontWeight: "500",
          borderTop: "1px solid rgba(0,0,0,0.2)",
        },
        formatExtraData: { statesShow, stateOpen },
        formatter: statesFormatter,
      },
    {
      text: "Name",
      // dataField: "name",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: nameFormatter,
    },
    {
      text: "Email ID",
      // dataField: "email",
      classes: "cellsOverflow",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: emailFormatter,
    },
    {
      text: "Status",
      // dataField: "status",
      classes: "cellsOverflow",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    {
      text: "Last Updated",
      // dataField: "lastUpdate",
      classes: "cellsOverflow",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "Reason",
      // dataField: "reason",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: reasonFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "80px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Sales Users</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={usersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(usersData, "manageUsers")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <>
                    <form
                      onSubmit={submitHandler}
                      className="UHinputfields VMform"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "135px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "18%",
                            marginRight: "2%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Country <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            name="country"
                            // value={formValue.subscriptionPlanID}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Country
                            </option>
                            {uniqueCountry?.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "18%",
                            marginRight: "2%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Zone <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            name="zone"
                            // value={formValue.subscriptionPlanName}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Zone
                            </option>
                            {uniquezone.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "18%",
                            marginRight: "2%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Area
                          </label>
                          <select
                            name="area"
                            // value={formValue.businessEntityEmailID}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Area
                            </option>
                            {uniquearea.map((item) => (
                              <option>{item}</option>
                            ))}

                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "18%",
                            marginRight: "2%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Status
                          </label>
                          <select
                            name="status"
                            // value={formValue.businessEntityName}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Status
                            </option>
                            {uniqueStatus.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "18%",
                            marginRight: "2%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Name
                          </label>
                          <select
                            name="Name"
                            // value={formValue.businessEntityName}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Name
                            </option>
                            {uniqueullName.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "18%",
                            marginRight: "2%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Email ID
                          </label>
                          <input
                            type="text"
                            id="date"
                            name="email"
                            // defaultValue={formValue.subscriptionDate}
                            onChange={handleChange}
                            placeholder="Email ID"
                            autoComplete="off"
                            style={{ marginTop: "-5px", width: "100%" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "18%",
                            marginRight: "2%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            From
                          </label>
                          <input
                            type="date"
                            placeholder="From"
                            style={{ marginTop: "-5px", width: "100%" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "18%",
                            marginRight: "2%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            To
                          </label>
                          <input
                            type="date"
                            placeholder="To"
                            style={{ marginTop: "-5px", width: "100%" }}
                          />
                        </div>
                        <div
                          style={{
                            justifyContent: "end",
                            width: "auto",
                            marginLeft: "auto",
                          }}
                        >
                          <button
                            id="apply"
                            style={{ marginTop: "22px", marginLeft: "10px" }}
                          >
                            Apply
                          </button>
                          <button
                            onClick={clearFilter}
                            id="clear"
                            style={{ marginTop: "22px", width: "auto" }}
                          >
                            Clear Filter
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                )}

                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ManageSalesUserTable;
