import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import { successNotification, errorNotification } from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BusinessEntitiesReactivate = ({ updatedData, editData, closeModal,entityID }) => {
  const [formValue, setFormValue] = useState({
    reactivatedReason: "",
    description: "",
    status:"",
  });

  
console.log("entityID============>",editData.entityID)


  const editDetails = {
    entityID:entityID,
    businessEmailID: editData.businessEmailID,
    status: "Reactivated",
    reactivatedReason: formValue.reactivatedReason,
    reasonComment: formValue.description,
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async (formValue) => {
    console.log(formValue);
    const resp = await trackPromise(
      API.put(`operations-module/api/v1/businessEntities/${editDetails.entityID}`, editDetails)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const submitOperationsHandler = async () => {
    console.log("formValue========>",formValue);
    const resp = await trackPromise(
      API.put(`operations-module/api/v1/businessEntities/${editDetails.entityID}`, editDetails)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };



  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Reactivate User</h3>
        </div>

        <div className="body">
          <label>
            <select
              name="reactivatedReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option disabled selected hidden>
                Reactivation Reason
              </option>
              <option value="Dispute resolved">Dispute resolved</option>
              <option value="Terms Accepted">
              Terms Accepted
              </option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.reactivatedReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Reactivation Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={auth.isOperations() ? submitOperationsHandler : ""}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessEntitiesReactivate;
