import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import { successNotification, errorNotification } from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import SpareDetails from "./SpareDetails";
import ConfirmSumit from "../Components/ConfirmSumit";
import ServiceInvoice from "../../Invoice/ServiceInvoice";

const JobClosed = ({ updatedData, rowData, closeModal , setIsOpen}) => {

  const [openConfirm, setConfirm] = useState(false)
  const [openSpare, setOpenSpare] = useState();
  const [spareData, setSpareData] = useState();
  const [formValue, setFormValue] = useState({
    reason: "",
    description: "",
  });


  const spareDetailsFormatter = (rowData) => {
    setOpenSpare(true);
    setSpareData(rowData);
  };


  const closeHandler = ()=>{
    setIsOpen(false)
    closeModal(false)
  }





  return (
    <div className="container1">
      {openSpare ? (
        <SpareDetails rowData={spareData} closeModal={setOpenSpare} />
      ) : (
        ""
      )}
      <div className="modalContainer" style={{ flexDirection: "column" , height:"380px"}}>
        <div className="header">
          <h3>Close Job Card</h3>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 8,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h6 style={{textAlign:"start"}}>Job Card ID : {rowData.jobID}</h6>
            <h6  style={{textAlign:"start"}}>Vehicle Reg. No. {rowData.vehicleRegNo}</h6>
            <h6 onClick={() => spareDetailsFormatter(rowData)}
              style={{ color: "#0A18A3",marginLeft:"-110px",marginTop:"2px" }}>View Spare Details</h6>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h6  style={{textAlign:"end"}}>Job Date : {moment(rowData.jobDate).format("DD-MMM-YYYY/HH:mm")}</h6>
            <h6  style={{textAlign:"end"}}>
              Delivery Date : {moment(rowData.jobDate).format("DD-MMM-YYYY/HH:mm")}
            </h6>

          </div>
        </div>


        <div className="body" style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <label style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "83px" }}>Closure Type<span style={{ color: "red" }}>*</span></label>
            <select
              name="closure"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: '100%', marginTop: '-5px' }}
            >
              <option disabled selected hidden>
                Select
              </option>
              <option value="closure">Closure</option>
              <option value="fullClosure">Full Closure</option>
            </select>
          </label>

          <label style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "150px", marginLeft:"-28px" }}>Select Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="reason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: '100%', marginTop: '-5px' }}
            >
              <option disabled selected hidden>
                Select
              </option>
              <option value="serviceCompleted">Service is Completed</option>
              <option value="spareNotAvailable">All Spare is not available</option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.reason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Put On Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={() => setConfirm(true)}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
      {/* {
        openConfirm ? <ConfirmSumit rowData={rowData} formValue={formValue} updatedData={updatedData} closeModal={setConfirm}/> :""
      } */}
      {
        openConfirm ? <ServiceInvoice closeModal={setConfirm} rowData={rowData} formValue={formValue} updatedData={updatedData} /> : ""
      }
    </div>
  );
};

export default JobClosed;
