import React, { useState } from "react";
import "../../../components/modal/modal.scss";
import AppConstants from "../../../utils/app-constants";
import OnHold from "./OnHold";
import Reject from "./Reject";
import GeneratePO from "./GeneratePO";
import SpareGeneratePO from "./SpareGeneratePO";

const RequisitionActions = ({ extraData, rowData, updatedData, OpenOne, actionShow ,rowIndex}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      approve: false,
      onHoldApprove: false,
      onHold: false,
      reject: false,
      release: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {!extraData && activeModal.approve && showModal && (
        <GeneratePO
          rowData={rowData}
          closeModal={setShowModal}
          updatedData={updatedData}
          OpenOne={OpenOne}
          actionShow={actionShow}
        />
      )}

      {extraData == "spare" && activeModal.approve && showModal && (
        <SpareGeneratePO
          rowData={rowData}
          closeModal={setShowModal}
          updatedData={updatedData}
          OpenOne={OpenOne}
          actionShow={actionShow}
        />
      )}

      {activeModal.onHold && showModal && (
        <OnHold
          extraData={extraData}
          rowData={rowData}
          closeModal={setShowModal}
          updatedData={updatedData}
        />
      )}

      {activeModal.reject && showModal && (
        <Reject
          extraData={extraData}
          rowData={rowData}
          closeModal={setShowModal}
          updatedData={updatedData}
        />
      )}

      <div style={{ position: "relative", marginTop: rowIndex <= 4 ? "0px" : "-145px", }}>
        <div className="action vendoraction" style={{ marginLeft: "-105px" }}>
          {rowData.status == AppConstants.NEWREQUEST || "New Request" ? (
            <>
              <div>
                <span id="approve" onClick={(e) => clickHandler(e)}>
                  Generate PO
                </span>
              </div>
              <div>
                <span id="onHold" onClick={(e) => clickHandler(e)}>
                  On Hold
                </span>
              </div>
              <div>
                <span id="reject" onClick={(e) => clickHandler(e)}>
                  Reject
                </span>
              </div>
            </>
          ) : (
            <>
              <div>
                <span id="approve" onClick={(e) => clickHandler(e)}>
                  Release & Generate PO
                </span>
              </div>
              <div>
                <span id="reject" onClick={(e) => clickHandler(e)}>
                  Reject
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequisitionActions;
