import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import SearchBox from "../../../components/search/SearchBox";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppConstants from "../../../utils/app-constants";
import VehicleViewAct from "./VehicleViewAct";
import VehicleReleaseUpdate from "./VehicleReleaseUpdate";
import VehicleViewUpdate from "./VehicleViewUpdate";
import VehicleReturnReleaseUpdate from "./VehicleReturnReleaseUpdate";
import VehicleReturnViewAct from "./VehicleReturnViewAct";
import VehicleReturnViewUpdate from "./VehicleReturnViewUpdate";
import VehicleManageRequest from "./VehicleManageRequest";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import handleExport from "../../../utils/exportExcel";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const VehicleNewRequest = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState();
  const [actionShow, setActionShow] = useState();
  const [tempData, setTempData] = useState([]);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState();

  const [formValue, setFormValue] = useState({
    requestID: "",
    requestedBy: "",
    requestDate: "",
    requestCategory: "",
    requestType: "",
    WONo: "",
    GINNo: "",
    status: "",
  });

  const fetchData = async () => {
    const res = await trackPromise(
      API.get(
        `qc-module/api/v1/vehicle/newrequest?requestCategory=${formValue.requestCategory}&requestID=${formValue.requestID}&requestedBy=${formValue.requestedBy}&requestedDate=${formValue.requestDate}&requestType=${formValue.requestType}&WONo=${formValue.WONo}&GIN=${formValue.GINNo}&status=${formValue.status}`
      )
    );

    const finalData = res.filter((item, i) => {
      return (
        item.status === AppConstants.NEWREQUEST ||
        item.status === AppConstants.ONHOLD ||
        item.status === AppConstants.IN__PROGRESS ||
        item.status === "New Request"
      );
    });
    console.log(finalData);
    let filteredData = finalData;

    const compareDates = (a, b) => {
      const dateA = new Date(a.requestDate);
      const dateB = new Date(b.requestDate);
      return dateB - dateA; // Sort in descending order (latest first)
    };

    // Sort the array based on the latest requestedDate
    filteredData.sort(compareDates);
    console.log(filteredData);

    setData(filteredData);
    setTempData(filteredData);
  };
  console.log(tempData);
  useEffect(() => {
    fetchData();
  }, []);




  const uniquerequestID = [...new Set(data.map((item) => item.requestID))];
  const uniquerequestCategory = [...new Set(data.map((item) => item.requestCategory))];
  const uniquerequestedBy = [...new Set(data.map((item) => item.requestedBy))];
  const uniqueGINNo = [...new Set(data.map((item) => item.GRNNo))];
  const uniqueWONo = [...new Set(data.map((item) => item.GINNo))];
  const uniquestatus = [...new Set(data.map((item) => item.status))];




  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY / HH:mm")}`;
  }

  const WOFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.requestCategory === "Finished Goods") {
      return (
        <div>
          <p>{row.orderID}</p>
        </div>
      );
    } else {
      return <div> - </div>;
    }
  };

  const GINFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.requestCategory === "Vehicle Returns") {
      return (
        <div>
          <p>{row.ginNo}</p>
        </div>
      );
    } else {
      return <div> - </div>;
    }
  };

  const statusFormatter = (cell) => {
    return cell === AppConstants.NEWREQUEST || cell == "New Request" ? (
      <button className="active-btn">
        <span className="active-btn-font">New Request</span>
      </button>
    ) : cell === AppConstants.ONHOLD ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">On Hold</span>
      </button>
    ) : cell === AppConstants.IN__PROGRESS ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">In-Progress</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    console.log("on");
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    console.log("off");
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (
      (row.status === AppConstants.NEWREQUEST || row.status === "New Request") &&
      row.requestCategory === "Finished Goods"
    ) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              width: "115px",
              height: "25px",
              padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
            }}
            onClick={() => actionHandler(rowIndex)}
          >
            View & Act
          </button>
          {actionShow == rowIndex && open ? (
            <VehicleViewAct
              updatedData={fetchData}
              rowData={row}
              closeModal={setOpen}
            />
          ) : null}
        </div>
      );
    } else if (
      row.status === AppConstants.ONHOLD &&
      row.requestCategory === "Finished Goods"
    ) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              padding: "3px 9px",
              height: "26px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
            }}
            onClick={() => actionHandler(rowIndex)}
          >
            Release & Update
          </button>
          {actionShow == rowIndex && open ? (
            <VehicleReleaseUpdate
              updatedData={fetchData}
              rowData={row}
              closeModal={setOpen}
            />
          ) : null}
        </div>
      );
    } else if (
      row.status === AppConstants.IN__PROGRESS &&
      row.requestCategory === "Finished Goods"
    ) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              padding: "3px 9px",
              border: "1px solid #0a18a3",
              width: "115px",
              height: "25px",
              borderRadius: "4px",
              background: "white",
            }}
            onClick={() => actionHandler(rowIndex)}
          >
            View & Update
          </button>
          {actionShow == rowIndex && open ? (
            <VehicleViewUpdate
              updatedData={fetchData}
              rowData={row}
              closeModal={setOpen}
            />
          ) : null}
        </div>
      );
    } else if (
      (row.status === AppConstants.NEWREQUEST || row.status === "New Request") &&
      row.requestCategory === "Vehicle Returns"
    ) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              width: "115px",
              height: "25px",
              padding: "3px 9px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
            }}
            onClick={() => actionHandler(rowIndex)}
          >
            View & Act
          </button>
          {actionShow == rowIndex && open ? (
            <VehicleReturnViewAct
              updatedData={fetchData}
              rowData={row}
              closeModal={setOpen}
            />
          ) : null}
        </div>
      );
    } else if (
      row.status === AppConstants.ONHOLD &&
      row.requestCategory === "Vehicle Returns"
    ) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              padding: "3px 9px",
              height: "26px",
              border: "1px solid #0a18a3",
              borderRadius: "4px",
              background: "white",
            }}
            onClick={() => actionHandler(rowIndex)}
          >
            Release & Update
          </button>
          {actionShow == rowIndex && open ? (
            <VehicleReturnReleaseUpdate
              updatedData={fetchData}
              rowData={row}
              closeModal={setOpen}
            />
          ) : null}
        </div>
      );
    } else if (
      row.status === AppConstants.IN__PROGRESS &&
      row.requestCategory === "Vehicle Returns"
    ) {
      return (
        <div>
          <button
            style={{
              fontSize: "12px",
              color: "#0a18a3",
              cursor: "pointer",
              padding: "3px 9px",
              border: "1px solid #0a18a3",
              width: "115px",
              height: "25px",
              borderRadius: "4px",
              background: "white",
            }}
            onClick={() => actionHandler(rowIndex)}
          >
            View & Update
          </button>
          {actionShow == rowIndex && open ? (
            <VehicleReturnViewUpdate
              updatedData={fetchData}
              rowData={row}
              closeModal={setOpen}
            />
          ) : null}
        </div>
      );
    }
  };

  const handleChange = async (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
    var res;
    if (e.target.name == "requestCategory") {
      res = await trackPromise(
        API.get(
          `qc-module/api/v1/vehicle/newrequest?requestCategory=${e.target.value}&requestID=&requestedBy=&requestedDate=&requestType=&GRNID=&GIN=&status=`
        )
      );
    } else {
      res = await trackPromise(
        API.get(
          `qc-module/api/v1/vehicle/newrequest?requestCategory=&requestID=&requestedBy=&requestedDate=&requestType=&GRNID=&GIN=&status=`
        )
      );
    }
    const finalData = res.filter((item, i) => {
      return (
        item.status == AppConstants.NEW_REQUEST ||
        item.status == AppConstants.NEW_REQUEST_NEW ||
        item.status == AppConstants.ON_HOLD ||
        item.status == AppConstants.IN_PROGRESS
      );
    });
    setTempData(finalData);
  };

  const clearFilter = async (e) => {
    setFormValue({
      requestID: "",
      requestedBy: "",
      requestDate: "",
      requestCategory: "",
      requestType: "",
      WONo: "",
      GINNo: "",
      status: "",
    });
    const res = await trackPromise(
      API.get(`qc-module/api/v1/vehicle/newrequest`)
    );

    const finalData = res.filter((item, i) => {
      return (
        item.status === AppConstants.NEWREQUEST || item.status === "New Request" ||
        item.status === AppConstants.ONHOLD ||
        item.status === AppConstants.IN__PROGRESS
      );
    });
    console.log(finalData);
    setData(finalData);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          data.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requestID",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request Category",
      dataField: "requestCategory",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request Type",
      dataField: "requestType",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Requested By",
      dataField: "requestedBy",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Requested Date",
      dataField: "requestDate",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "WO No.",
      dataField: "orderID",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: WOFormatter,
    },
    {
      text: "GIN No.",
      dataField: "returnedQty",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: GINFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  const applyFilters = () => {
    fetchData();
  };

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>New Requests</span>
        </div>

        <ToolkitProvider keyField="id" data={data} columns={columns} search>
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(data, "New Requests")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form
                    onSubmit={submitHandler}
                    className="VMform"
                    style={{
                      height: "130px",
                      justifyContent: "flex-start",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                    }}
                  >
                    <div className="UHinputfields" style={{ padding: "0px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Category
                        </label>
                        <select
                          name="requestCategory"
                          value={formValue.requestCategory}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Request Category
                          </option>
                          {
                            uniquerequestCategory.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request ID
                        </label>
                        <select
                          name="requestID"
                          value={formValue.requestID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Request ID
                          </option>
                          {
                            uniquerequestID.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Requested By
                        </label>
                        <select
                          name="requestedBy"
                          value={formValue.requestedBy}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Requested By
                          </option>
                          {
                            uniquerequestedBy.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Requested Date
                        </label>
                        <input
                          type="date"
                          name="requestDate"
                          value={formValue.requestDate}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                        {/* <DatePicker
                        name="requestDate"
                        className="create-grn-date"
                        selected={date}
                        onChange={(date)=>{setDate(date)}}
                        isClearable
                        placeholderText="Request Date"
                        dateFormat="DD-MM-YYYY/HH:mm"
                        autoComplete="off"
                    /> */}
                      </div>
                    </div>
                    <div className="UHinputfields" style={{ padding: "0px" }}>
                      {formValue.requestCategory === "Finished Goods" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            WO No.
                          </label>
                          <select
                            name="WONo"
                            value={formValue.WONo}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected hidden>
                              WO No.
                            </option>
                            {
                              uniqueWONo.map((item) => (
                                <option>{item}</option>
                              ))
                            }
                          </select>
                        </div>
                      )}

                      {formValue.requestCategory === "Vehicle Returns" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            GIN No.
                          </label>
                          <select
                            name="GIN"
                            value={formValue.GINNo}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected hidden>
                              GIN No.
                            </option>
                            {
                              uniqueGINNo.map((item) => (
                                <option>{item}</option>
                              ))
                            }
                          </select>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected hidden>
                            Status
                          </option>
                          {
                            uniquestatus.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <button
                        onClick={() => applyFilters()}
                        type="submit"
                        id="apply"
                        style={{ marginTop: "20px" }}
                      >
                        Apply
                      </button>
                      <button
                        style={{ width: "100px", marginTop: "20px" }}
                        onClick={clearFilter}
                        id="clear"
                      >
                        Clear Filter
                      </button>
                    </div>
                  </form>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default VehicleNewRequest;
