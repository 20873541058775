import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";

const ViewSpecs = ({rowData,closeModal}) => {
  console.log(rowData)
  return (
    <div>
        <div className="container1">
          <div className="modalContainer" style={{height:"350px", width:"70%"}}>
          <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Returned Spares</h3>
          <div>
            <button className="action-btn" style={{ color: "blue" }}>
              <PrintIcon/>
              &nbsp;Print
            </button>
            <button className="action-btn" style={{ color: "blue" }}>
              <IosShareIcon/>
              &nbsp;Export
            </button>
          </div>

        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding:7,
          }}
        >
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
              color:"blue"
            }}
          >
            Job Card ID: {rowData.jobID}
          </span>
          
        </section>
        <div
          className="body"
          style={{
            display: "flex",
            height: "450px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section style={{ width: "100%" }}>
            <div style={{ overflow: "scroll" }}>
              <div id="POVendorHeading">
                <div style={{ width: "10%" }}>S. No.</div>
                <div style={{ width: "20%" }}>SKU Code</div>
                <div style={{ width: "20%" }}>Spare Part Name</div>
                <div style={{ width: "20%" }}>Part SNo.</div>
                <div style={{ width: "30%" }}>Return Reason</div>
              </div>
              <div>
                {rowData?.returnedSpares?.map((item, index) => {
                  return (
                    <div
                      id="POVendorContent"
                      style={{
                        borderBottom: "1px solid #999999",
                        boxShadow: "none",
                        padding: "0px",
                      }}
                    >
                      <div style={{ width: "10%", overflow: "scroll" }}>
                        {index + 1}
                      </div>
                      <div style={{ width: "20%", overflow: "scroll" }}>
                        {item.skuCode}
                      </div>
                      <div style={{ width: "20%", overflow: "scroll" }}>
                        {item.sparePartName}
                      </div>
                      <div style={{ width: "20%", overflow: "scroll" }}>
                        {item.partSno}
                      </div>
                      <div style={{ width: "30%", overflow: "scroll" }}>
                        {item.returnedReason}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
            
            <div className="footer">
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
          </div>
        </div>
    </div>
  );
};

export default ViewSpecs;
