import { faCheckCircle, faCross, faCrosshairs, faCut, faPause, faPauseCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Delete } from "@material-ui/icons";
import React, { useState } from "react";
import "../../../../components/modal/modal.scss";
import AppConstants from "../../../../utils/app-constants"
import Approve from './Approve'
import OnHold from "./OnHold";
import RejectPopUp from "./RejectPopUp";

const Actions = ({rowData,updatedData , rowIndex }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      approve: false,
      onHold: false,
      reject: false,
      release:false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
     
     
      {activeModal.approve && showModal && <Approve extraData="release" updatedData={updatedData} data={rowData} closeModal={setShowModal}/>}

      {activeModal.onHold && showModal && <OnHold updatedData={updatedData} data={rowData} closeModal={setShowModal}/>}

      {activeModal.reject && showModal && <RejectPopUp updatedData={updatedData}  data={rowData} closeModal={setShowModal} />}


      <div style={{ position: "relative" , marginTop: rowIndex <= 4 ? "0px" : "-145px", marginLeft: "50px",}}>
        <div className="action vendoraction">
          {rowData.status === "Pending At Finance" ? (
            <>
                  <div>
                    <span id="approve" onClick={(e) => clickHandler(e)}>
                    <FontAwesomeIcon icon={faCheckCircle} /> Approve
                    </span>
                  </div>
          <div>
              <span id="onHold" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faPauseCircle} />On Hold
              </span>
           </div>
           <div>
              <span id="reject" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faTimes} />  Reject
              </span>
            
          </div>            
            </>
            
          ):
          <>
           <div>
            <span id="approve" onClick={(e) => clickHandler(e)}>
            <FontAwesomeIcon icon={faCheckCircle} />  Release & Approve
            </span>
          </div>
          <div>
              <span id="reject" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faTimes} />  Reject
              </span>
            
          </div> 
          </>
          } 
        </div>
      </div>
    </div>
  );
};

export default Actions;
