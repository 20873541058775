import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckCircle, KeyboardBackspace } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'

const EditPartSno = ({ closeModal, comp, qty ,poData,compSno,index}) => {

    const [formValue, setFormValue] = useState(comp.componentSno);

    const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        let field = [...formValue];
        field[index][name] = value;
        setFormValue(field);
    };

    let addFormFields = () => {
        setFormValue([...formValue, { partSno: "" }]);
    };

    const removeFields = index => {
        const field = [...formValue];
        field.splice(index, 1);
        setFormValue(field);
    };

    const submitHandler = () => {
        const finalBody = formValue.map(item => ({
            ...item,
            vendorCity: poData.vendorCity,
            vendorName: poData.vendorName,
            vendorID: poData.vendorID,
            deliveryLocation: poData.deliveryLocation,
            unitPrice: comp.unitPrice
        }))
        console.log(finalBody)
        compSno(finalBody,index);
        closeModal(false)
    }

    return (
        <div className="container1">
            <div className="modalContainer" style={{ height: "500px", width: "70%", padding: '15px', justifyContent: 'flex-start' }}>
                <div className="header" style={{ width: '100%', justifyContent: 'flex-start' }}>
                    <h3>Add Part Serials</h3>
                </div>

                <section
                    style={{
                        width: '100%',
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "14px",
                        fontWeight: "500",
                        margin: "10px 0",
                        borderBottom: "2px solid #F2F2F2"
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                        <span>SKU Code: {comp.skuCode}</span>
                        <span>PO No.: {poData.POID}</span>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", padding: '0px' }}>
                        <span>Component Name: {comp.componentName}</span>
                    </div>

                </section>

                <div className="body" style={{ width: "100%", justifyContent: "flex-start" }}>

                    {formValue.map((item, index) => {
                        return (
                            <div style={{ display: "block" }}>
                                <label style={{ float: "left" }}>Serial Number {index + 1}</label><br />
                                <div style={{ display: "flex" }}>
                                    <input
                                        style={{ margin: "0px 0px 0px -55%" }}
                                        type="text"
                                        name="partSno"
                                        placeholder="Enter Part S.No"
                                        defaultValue={item.partSno}
                                        onChange={event => handleInputChange(event, index)}
                                    />
                                    <div onClick={() => removeFields(index)} style={{ color: "red", marginLeft: "8px", cursor: "pointer" }}><FontAwesomeIcon icon={faTrashAlt} /></div>
                                </div>
                            </div>
                        )
                    })}
                </div>


                <div
                    className="footer"
                    style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
                >
                    {/* <button
                        style={{ width: "104px", height: "42px", background: "#fff", color:"green", border:"1px solid green" }}
                        onClick={() => addFormFields()}
                    >
                        <FontAwesomeIcon icon={faPlus} style={{ fontSize: "25px" }}/>
                        Add More
                    </button> */}
                    <button
                        style={{ width: "104px", height: "42px", background: "#fff" }}
                        onClick={() => closeModal(false)}
                    >
                        <KeyboardBackspace style={{ fontSize: "25px", marginRight:"5px" }} />
                        Cancel
                    </button>
                    <button
                        style={{ width: "104px", height: "42px", marginRight:"5px" }}
                        onClick={submitHandler}
                    >
                        <CheckCircle />
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditPartSno;