import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import {
  successNotification,
  errorNotification,
  infoNotification,
} from "../../../utils/notifications";
import { IoMdRefresh } from "react-icons/io";
import auth from "../../../utils/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faUpload } from "@fortawesome/free-solid-svg-icons";

const BusinessEntitiesEdit = ({ updatedData, editData, closeModal }) => {
  console.log(editData.cities);
  const [panDoc, setPanDoc] = useState();
  const [gstDoc, setGSTDoc] = useState();
  const [dealerDoc, setDealerDoc] = useState();
  const [checkedCities, setCheckedCities] = useState(editData.cities);

  console.log("checkedCities-->", checkedCities);

  // const checkedCitiesJSON = JSON.stringify(checkedCities);
  // console.log("checkedCitiesJSON-->",checkedCitiesJSON)

  const [formValue, setFormValue] = useState({
    businessType: "",
    businessName: "",
    businessEmailID: "",
    businessContactNo: "",
    address: "",
    address1: "",
    panNo: "",
    gstin: "",
    dealerCertificateNo: "",
    contactPersonName: "",
    contactPersonNo: "",
    country: "",
    salesManager: "",
    state: "",
    city: "",
    cities: "",
    area: "",
    zipCode: "",
    status: "",
    reason: "",
    isd: "",
    std: "",
    entityID: "",
    panDoc: "",
    gstDoc: "",
    dealerDoc: "",
  });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileSize = selectedFile.size;
      const allowedFileSize = 5 * 1024 * 1024;

      if (fileSize <= allowedFileSize) {
        const allowedFileTypes = [
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          "application/pdf",
          "image/jpeg",
          "image/png",
          "image/gif",
        ];

        if (!allowedFileTypes.includes(selectedFile.type)) {
          infoNotification("Please select a valid file type.");
          return;
        }

        if (event.target.name == "panDoc") {
          setPanDoc(selectedFile);
        } else if (event.target.name == "gstDoc") {
          setGSTDoc(selectedFile);
        } else if (event.target.name == "dealerDoc") {
          setDealerDoc(selectedFile);
        }
      } else {
        infoNotification(
          "File size exceeds 5MB limit. Please select a smaller file."
        );
      }
    }
  };

  const inputChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormValue({
      entityID: editData?.entityID,
      businessType: editData?.businessType,
      businessName: editData?.businessName,
      businessEmailID: editData?.businessEmailID,
      businessContactNo: editData?.businessContactNo,
      address: editData?.address,
      address1: editData?.address1,
      panNo: editData?.panNo,
      gstin: editData?.gstin,
      dealerCertificateNo: editData?.dealerCertificateNo,
      contactPersonName: editData?.contactPersonName,
      contactPersonNo: editData?.contactPersonNo,
      country: editData?.country,
      salesManager: editData?.salesManager,
      state: editData?.state,
      city: editData?.city,
      cities: checkedCities,
      area: editData?.area,
      zipCode: editData?.zipCode,
      status: editData?.status,
      isd: editData?.isd,
      std: editData?.std,
      panDoc: panDoc,
      gstDoc: gstDoc,
      dealerDoc: dealerDoc,
    });
  }, []);

  const refresh = () => {
    updatedData();
  };

  console.log("formValue.businessEmailID --->", formValue.businessEmailID);
  console.log("form cities --->", formValue.cities);

  const submitHandler = async () => {
    const formData = new FormData();
    formData.append("entityID", formValue.entityID);
    formData.append("businessType", formValue.businessType);
    formData.append("businessName", formValue.businessName);
    formData.append("businessEmailID", formValue.businessEmailID);
    formData.append("businessContactNo", formValue.businessContactNo);
    formData.append("Address", formValue.address);
    formData.append("address1", formValue.address1);
    formData.append("panNo", formValue.panNo);
    formData.append("gstin", formValue.gstin);
    formData.append("dealerCertificateNo", formValue.dealerCertificateNo);
    formData.append("contactPersonName", formValue.contactPersonName);
    formData.append("contactPersonNo", formValue.contactPersonNo);
    formData.append("country", formValue.country);
    formData.append("salesManager", formValue.salesManager);
    formData.append("state", formValue.state);
    formData.append("city", formValue.city);
    formData.append("area", formValue.area);
    formData.append("zipCode", formValue.zipCode);
    formData.append("status", formValue.status);
    formData.append("isd", formValue.isd);
    formData.append("std", formValue.std);
    formData.append("panDoc", panDoc);
    formData.append("gstDoc", gstDoc);
    formData.append("dealerDoc", dealerDoc);
    checkedCities.forEach((item, index) => {
      for (const key in item) {
        formData.append(`cities[${index}][${key}]`, item[key]);
      }
    });

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    const resp = await trackPromise(
      API.put(
        "operations-module/api/v1/businessEntities/editBusinessEntities",
        formData
      )
    );
    const { success, message } = resp;
    if (success) {
      successNotification(message, { autoClose: 3000 });
      refresh();
      closeModal(false);
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [citiesData, setCitiesData] = useState([]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getCitiesData = async () => {
    const getCityData = await trackPromise(
      API.get(`operations-module/api/v1/businessEntities/cities`)
    );
    setCitiesData(getCityData);
  };
  useEffect(() => {
    getCitiesData();
  }, []);

  var expand = false;
  const showCitiesCheckboxes = (index) => {
    var checkbozes = document.getElementById("citiesCheckboxes");
    if (!expand) {
      checkbozes.style.display = "block";
      expand = true;
    } else {
      checkbozes.style.display = "none";
      expand = false;
    }
  };

  document.body.addEventListener("click", (event) => {
    const multiselect = document.querySelector(".multiselect");
    if (!multiselect.contains(event.target)) {
      // Click occurred outside the multiselect component
      if (expand) {
        // Close the checkboxes if they are currently expand
        var checkboxes = document.getElementById("citiesCheckboxes");
        checkboxes.style.display = "none";
        expand = false;
      }
    }
  });

  const closePrevState = () => {
    const checkboxes = document.querySelectorAll("#citiesCheckboxes");
    checkboxes.forEach((checkbox) => {
      checkbox.style.display = "none";
    });
    expand = false;
  };

  const onCheckboxChangeCity = (e, item) => {
    let tempInfo = [...checkedCities];
    if (e.target.checked == true) {
      if (e.target.checked == false) {
        tempInfo = tempInfo.filter((info) => info.cityId != item.cityId);
        e.target.checked == false;
      } else {
        tempInfo.push(item);
      }
    } else {
      if (e.target.checked == false) {
        tempInfo = tempInfo.filter((info) => info.cityId != item.cityId);
      } else {
        tempInfo.push(item);
      }
    }
    setCheckedCities(tempInfo);
  };

  const uniqueCities = Object.values(
    [...citiesData, ...editData.cities].reduce((acc, cur) => {
      acc[cur.cityId] = cur;
      return acc;
    }, {})
  );
  console.log(uniqueCities);

  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "62%", height: "80%" }}>
        <div className="header">
          <h3>Edit Business Details</h3>
        </div>

        <div
          className="body"
          style={{
            display: "flex",
            height: "300px",
            fontSize: "14px",
            overflow: "scroll",
            flexDirection: "row",
            padding: "5px 5px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "23px",
            }}
          >
            {/* <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    marginLeft:'-150px',
                  }}
                >
                  Business Level {" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  style={{ marginTop: "-10px" }}
                  className="sysAdminInput"
                  defaultValue={editData?.businessLevel}
                  disabled
                  name="businessLevel"
                  onChange={(e)=>inputChange(e)}
                />
              </div> */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-150px",
                }}
              >
                Business Type <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                defaultValue={editData?.businessType}
                disabled
                name="businessType"
                onChange={(e) => inputChange(e)}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-150px",
                }}
              >
                Business Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                defaultValue={editData?.businessName}
                name="businessName"
                onChange={(e) => inputChange(e)}
                disabled
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-107px",
                }}
              >
                Contact Person Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                defaultValue={editData?.contactPersonName}
                name="contactPersonName"
                onChange={(e) => inputChange(e)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "23px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-150px",
                }}
              >
                Business Email <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                defaultValue={editData?.businessEmailID}
                name="businessEmailID"
                onChange={(e) => inputChange(e)}
              />
            </div>

            {/* cities multiselect */}
            <div
              class="multiselect"
              style={{ width: "37%", marginLeft: "-9px" }}
            >
              <div class="selectBox">
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "-6px",
                    width: "100%",
                  }}
                >
                  <label
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      width: "auto",
                      marginLeft: "-145px",
                    }}
                  >
                    Select Cities <span style={{ color: "red" }}>*</span>
                  </label>
                </label>
                <div class="overSelect"></div>
              </div>
              <div onClick={() => showCitiesCheckboxes()}>
                <input
                  className="input-config"
                  style={{ width: "83%", margin: "-17px 0px 0px 0px" }}
                  type="text"
                  name="cities"
                  placeholder="Search Cities..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <div
                id="citiesCheckboxes"
                style={{
                  padding: "5px",
                  textAlign: "left",
                  margin: "10px 0px 0px 14px",
                  position: "absolute",
                  width: "16%",
                  marginLeft: "30px",
                }}
              >
                <div style={{ overflow: "scroll", height: "130px" }}>
                  {/* {citiesData?.map((item, index) => {
                    return (
                      <label key={item.cityId}>
                        <input
                          className="zoneCheckbox"
                          type="checkbox"
                          id={item.cityId}
                          onChange={(e) => onCheckboxChangeCity(e, item)}
                          checked={citiesData.some(dt1Item => dt1Item.City === item.City && checkedCities?.includes(item.City))}
                        />
                        <span style={{ marginLeft: "5px" }}>{item.City}</span>
                      </label>
                    );
                  })} */}

                  {uniqueCities?.map((item, index) => {
                    return (
                      <label key={item?.cityId}>
                        <input
                          className="zoneCheckbox"
                          type="checkbox"
                          id={item?.cityId}
                          onChange={(e) => onCheckboxChangeCity(e, item)}
                          checked={checkedCities?.some(
                            (el) => el.cityId === item.cityId
                          )}
                        />
                        <span style={{ marginLeft: "5px" }}>{item?.City}</span>
                      </label>
                    );
                  })}
                </div>
              </div>
              {/* <div
                id="citiesCheckboxes"
                style={{
                  width: "17%",
                  padding: "5px",
                  position: "absolute",
                  height: "34%",
                  overflow: "scroll",
                  marginTop: "10px",
                }}
              >
                {citiesData.map((item) => (
                  <label key={item.cityId}>
                    <input
                      className="zoneCheckbox"
                      type="checkbox"
                      id={item.cityId}
                      onChange={() => {
                        onCitiesCheckboxChange(item, index);
                      }}
                      checked={checkedCities === item.City}
                      // checked={checkedCities.some(
                      //       (el) => el.City === item.City
                      //     )}
                      //checked={citiesData.some(el => checkedCities.includes(el.City))}

                    />
                    <span style={{ marginLeft: "5px" }}>{item.City}</span>
                  </label>
                ))}
              </div> */}
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-150px",
                }}
              >
                Sales Manager <span style={{ color: "red" }}>*</span>
              </label>
              {/* <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                //defaultValue={editData?.salesManager}
                defaultValue={editData?.cities?.map(
                  (item) => item?.asmFullName
                )}
                disabled
                name="salesManager"
                onChange={(e) => inputChange(e)}
              /> */}

              <select
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                name="salesManager"
                onChange={(e) => inputChange(e)}
                value={editData?.salesManager || ""}
              >
                <option value="" disabled>
                  Sales Manager
                </option>
                {[
                  ...new Set(
                    editData?.cities?.map((item) => item?.asmFullName)
                  ),
                ].map((asmFullName, index) => (
                  <option key={index} value={asmFullName} disabled>
                    {asmFullName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "23px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-175px",
                }}
              >
                Address 1 <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                defaultValue={editData?.address}
                name="Address"
                onChange={(e) => inputChange(e)}
                onFocus={closePrevState}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-175px",
                }}
              >
                Address 2 <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                defaultValue={editData?.address1}
                name="address1"
                onChange={(e) => inputChange(e)}
              />
            </div>

            {/* <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    marginLeft:'-150px',
                  }}
                >
                  Country {" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  style={{ marginTop: "-10px" }}
                  className="sysAdminInput"
                  defaultValue={editData?.country}
                  disabled
                  name="country"
                  onChange={(e)=>inputChange(e)}
                />
              </div> */}

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-140px",
                }}
              >
                Zip Code/Postal <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                defaultValue={editData?.zipCode}
                name="zipCode"
                onChange={(e) => inputChange(e)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "23px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-200px",
                }}
              >
                State <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                defaultValue={editData?.state}
                disabled
                name="state"
                onChange={(e) => inputChange(e)}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-210px",
                }}
              >
                City <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                defaultValue={editData?.city}
                name="city"
                disabled
                onChange={(e) => inputChange(e)}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-112px",
                }}
              >
                Business Contact No <span style={{ color: "red" }}>*</span>
              </label>
              <div>
                <input
                  style={{ marginTop: "-10px", width: "50px" }}
                  className="sysAdminInput"
                  defaultValue={editData?.isd}
                  name="isd"
                  onChange={(e) => inputChange(e)}
                />
                <input
                  style={{ marginTop: "-10px", width: "50px" }}
                  className="sysAdminInput"
                  defaultValue={editData?.std}
                  name="std"
                  onChange={(e) => inputChange(e)}
                />
                <input
                  style={{ marginTop: "-10px", width: "119px" }}
                  className="sysAdminInput"
                  defaultValue={editData?.businessContactNo}
                  placeholder="Business Entity Name"
                  name="businessContactNo"
                  onChange={(e) => inputChange(e)}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "23px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-125px",
                }}
              >
                Contact Person No <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                defaultValue={editData?.contactPersonNo}
                name="contactPersonNo"
                onChange={(e) => inputChange(e)}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-210px",
                }}
              >
                PAN <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                defaultValue={editData?.panNo}
                disabled
                name="panNo"
                onChange={(e) => inputChange(e)}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-150px",
                }}
              >
                PAN Document <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                name="panDoc"
                onChange={(e) => handleFileChange(e)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "23px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-200px",
                }}
              >
                GSTIN <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                defaultValue={editData?.gstin}
                name="gstin"
                disabled
                onChange={(e) => inputChange(e)}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-135px",
                }}
              >
                GSTIN Document <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                name="gstDoc"
                onChange={(e) => handleFileChange(e)}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-135px",
                }}
              >
                Dealer Certificate <span style={{ color: "red" }}>*</span>
              </label>
              <input
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                defaultValue={editData?.dealerCertificateNo}
                name="dealerCertificateNo"
                onChange={(e) => inputChange(e)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "23px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  width: "auto",
                  marginLeft: "-87px",
                }}
              >
                Upload Dealer Certificate{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                name="dealerDoc"
                style={{ marginTop: "-10px" }}
                className="sysAdminInput"
                onChange={(e) => handleFileChange(e)}
              />
            </div>

            {/* <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    marginLeft:'-150px',
                  }}
                >
                  Area {" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  style={{ marginTop: "-10px" }}
                  className="sysAdminInput"
                  defaultValue={editData?.area}
                  placeholder="Area"
                  name="area"
                  // disabled={formValue.businessLevel=="Zonal Level"?false:true}
                  disabled
                  onChange={(e)=>inputChange(e)} 
                />
                
              </div>      
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "auto",
                    marginLeft:'-150px',
                  }}
                >
                  Zone/Region{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  style={{ marginTop: "-10px" }}
                  className="sysAdminInput"
                  defaultValue={editData?.zone}
                  placeholder="Zone"
                  name="zone"
                  disabled
                  onChange={(e)=>inputChange(e)}
                  
                />
              </div>      */}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "23px",
            }}
          ></div>
        </div>

        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button id="submit-button" onClick={submitHandler}>
            <FontAwesomeIcon icon={faUpload} /> Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessEntitiesEdit;
