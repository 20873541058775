import React, { useEffect, useState } from "react";
import { KeyboardBackspace } from "@material-ui/icons";
import MROInventory from "./mroInventoryAction";
import RejectionInventory from "./rejectionInventory";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { errorNotification } from "../../../utils/notifications";
import RAWInventory from "./rawInventoryAction";
import moment from "moment";

const SpareReturnQty = ({
  close,
  row,
  status,
  GRNID,
  GIN,
  tabName,
  requestID,
  tableRefresh,
}) => {
  const [qtyData, setQtyData] = useState({});
  const [mroShow, setMROShow] = useState();
  const [rawShow, setRAWShow] = useState();
  const [rejectionShow, setRejectionShow] = useState();

  const fetchCompSno = async () => {
    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/inventorygoodsverification/getComponentPartSno?GRNID=${GRNID}&componentID=${row.componentID}`
      )
    );
    console.log(resp);
    resp.success == false ? errorNotification(resp.message) : setQtyData(resp);
  };
  const fetchSparesSno = async () => {
    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/inventorygoodsverification/getSparePartSno?GIN=${GIN}&skuCode=${row.skuCode}`
      )
    );
    console.log(resp);
    resp.success == false ? errorNotification(resp.message) : setQtyData(resp);
  };
  useEffect(() => {
    {
      tabName == "components" ? fetchCompSno() : fetchSparesSno();
    }
  }, []);

  console.log(row);
  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "70%", padding: "15px" }}>
        <div className="header">
          <h3>View Details</h3>
        </div>

        <div
          className="body"
          style={{ width: "100%", display: "flex", fontSize: "14px" }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px",
              }}
            >
              <span>Component Name: {qtyData.componentName || qtyData.sparePartname}</span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px",
              }}
            >
              <span>SKU Code: {qtyData.skuCode}</span>
              {/* {tabName == "components" ? (
                <span>SKU Code: {qtyData.skuCode}</span>
              ) : (
                <span>Verification Date: {moment(qtyData?.partCheckDate).format("DD-MMM-YYYY")}</span>
              )} */}
            </div>
          </section>

          <section style={{ width: "100%" }}>
            <div id="POVendorHeading">
              <div
                style={{
                  width: "6%",
                  padding: "10px",
                  borderRight: "1px solid #cdcdcd",
                }}
              >
                S.No.
              </div>
              <div
                style={{
                  width: "12%",
                  padding: "10px",
                  borderRight: "1px solid #cdcdcd",
                }}
              >
                Part S.No.
              </div>
              <div
                style={{
                  width: "15%",
                  padding: "10px",
                  borderRight: "1px solid #cdcdcd",
                }}
              >
                Verification Status
              </div>
              <div
                style={{
                  width: "33%",
                  padding: "10px",
                  borderRight: "1px solid #cdcdcd",
                }}
              >
                Verification Reason
              </div>
              <div
                style={{
                  width: "34%",
                  padding: "10px",
                  borderRight: "1px solid #cdcdcd",
                }}
              >
                Action
              </div>
              <div
                style={{
                  width: "12%",
                  padding: "10px",
                  borderRight: "1px solid #cdcdcd",
                }}
              >
                Date
              </div>
            </div>
            <div>
              {(tabName == "components" ? qtyData.componentSno : qtyData.partSno)?.map((item, index) => (
                <div
                  id="POVendorContent"
                  style={{
                    borderBottom: "1px solid #999999",
                    boxShadow: "none",
                    padding: "0px",
                  }}
                  key={index}
                >
                  <div
                    style={{
                      width: "6%",
                      padding: "10px",
                      borderRight: "1px solid #cdcdcd",
                    }}
                  >
                    {index + 1}
                  </div>
                  <div
                    style={{
                      width: "12%",
                      padding: "10px",
                      borderRight: "1px solid #cdcdcd",
                    }}
                  >
                    {item.partSno}
                  </div>
                  {tabName == "spares" ? (
                    <div
                      style={{
                        width: "15%",
                        padding: "10px",
                        borderRight: "1px solid #cdcdcd",
                      }}
                    >
                      {item.status == "Passed" ? (
                        <button className="active-btn">
                          <span className="active-btn-font">Passed</span>
                        </button>
                      ) : item.status[0] == "Pending Verification" ? (
                        <button className="deactive-btn">
                          <span className="deactive-btn-font">Pending</span>
                        </button>
                      ) : item.status == "Failed" ? (
                        <button className="delete-btn">
                          <span className="delete-btn-font">Failed</span>
                        </button>
                      ) : (
                        item.status
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "15%",
                        padding: "10px",
                        borderRight: "1px solid #cdcdcd",
                      }}
                    >
                      {item.status ? (
                        item.status
                      ) : (
                        <button className="deactive-btn">
                          <span className="deactive-btn-font">Pending</span>
                        </button>
                      )}
                    </div>
                  )}
                  <div
                    style={{
                      width: "33%",
                      padding: "10px",
                      borderRight: "1px solid #cdcdcd",
                      overflowY: "auto",
                    }}
                  >
                    {item.issue && item.issue == "No Issue"
                      ? "-"
                      : item.issue && item.issue == "Other"
                        ? item.description
                        : item.issue}
                  </div>
                  {status == "Completed_Completed" && row.added == true ? (
                    <div
                      style={{
                        width: "34%",
                        padding: "10px",
                        borderRight: "1px solid #cdcdcd",
                      }}
                    >
                      {
                        item?.ComponentStatus ? <span style={item?.ComponentStatus == "Rejected" ? { color: "red" } : { color: "green" }}>{item?.ComponentStatus}</span> : <>
                          {item.status === "Passed" ? (
                            <div style={{ width: "100%", display: "flex" }}>
                              <button
                                disabled={item.lastAdded == true ? true : false}
                                style={{
                                  width: "100%",
                                  fontSize: "12px",
                                  color: "#0a18a3",
                                  cursor: "pointer",
                                  padding: "3px 9px",
                                  border: "1px solid #0a18a3",
                                  borderRadius: "4px",
                                  background: "white",
                                }}
                                onClick={() => setMROShow(index)}
                              >
                                {item.lastAdded == true
                                  ? item.lastAction
                                  : "Move To MRO"}
                              </button>
                              {tabName == "components" ? (
                                <button
                                  style={{
                                    width: "100%",
                                    fontSize: "12px",
                                    color: "#0a18a3",
                                    cursor: "pointer",
                                    padding: "3px 9px",
                                    border: "1px solid #0a18a3",
                                    borderRadius: "4px",
                                    background: "white",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() => setRAWShow(index)}
                                >
                                  Move To RAW
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <button
                              disabled={item.lastAdded == true ? true : false}
                              style={{
                                width: "100%",
                                fontSize: "12px",
                                color: "#0a18a3",
                                cursor: "pointer",
                                padding: "3px 9px",
                                border: "1px solid #0a18a3",
                                borderRadius: "4px",
                                background: "white",
                              }}
                              onClick={() => setRejectionShow(index)}
                            >
                              {item.lastAdded == true
                                ? item.lastAction
                                : "Move To Rejection"}
                            </button>
                          )}
                        </>
                      }

                      {mroShow === index && (
                        <MROInventory
                          close={close}
                          rowData={item}
                          requestID={requestID}
                          componentID={row.componentID}
                          skuCode={row.skuCode}
                          closeModal={setMROShow}
                          tableRefresh={tableRefresh}
                          partSnoTableRefresh={
                            tabName == "components"
                              ? fetchCompSno
                              : fetchSparesSno
                          }
                          tabName={tabName}
                        />
                      )}
                      {rawShow === index && (
                        <RAWInventory
                          close={close}
                          rowData={item}
                          requestID={requestID}
                          componentID={row.componentID}
                          closeModal={setRAWShow}
                          tableRefresh={tableRefresh}
                          partSnoTableRefresh={
                            tabName == "components"
                              ? fetchCompSno
                              : fetchSparesSno
                          }
                        />
                      )}
                      {rejectionShow === index && (
                        <RejectionInventory
                          close={close}
                          rowData={item}
                          requestID={requestID}
                          componentID={row.componentID}
                          skuCode={row.skuCode}
                          closeModal={setRejectionShow}
                          tableRefresh={tableRefresh}
                          partSnoTableRefresh={
                            tabName == "components"
                              ? fetchCompSno
                              : fetchSparesSno
                          }
                          tabName={tabName}
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "34%",
                        padding: "10px",
                        borderRight: "1px solid #cdcdcd",
                      }}
                    >
                      -
                    </div>
                  )}
                  <div
                    style={{
                      width: "12%",
                      padding: "10px",
                      borderRight: "1px solid #cdcdcd",
                    }}
                  >
                    {item.actionDate ? moment(item.actionDate).format("DD-MM-YYYY") : "-"}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
        <div
          className="footer"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <button
            style={{ width: "104px", height: "40px", background: "#fff" }}
            onClick={() => close(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default SpareReturnQty;
