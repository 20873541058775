import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faFilter,
  faRedoAlt,
  faTimesCircle,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import SearchBox from "../../../components/search/SearchBox";
import { errorNotification } from "../../../utils/notifications";
import handleExport from "../../../utils/exportExcel";
import RequisitionDetails from "./Action/RequisitionDetails";
import Action from "./Action/Action";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const RequisitionProgress = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState();
  const [indexTracker, setIndexTracker] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [formValue, setFormValue] = useState({
    requisitionID: "",
    serviceCenterID: "",
    serviceCenterName: "",
    serviceCenterName: "",
    serviceCenterLocation: "",
    storeManager: "",
    status: "",
    fromDate: "",
    toDate: "",
  });
  const [data, setData] = useState([]);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`storeManager-module/api/v1/order/getRequisition`)
    );
    console.log(resp);
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);


  const uniquerequisitionID = [...new Set(data.map((item) => item.requisitionID))];
  const uniqueserviceCenterID = [...new Set(data.map((item) => item.serviceCenterID))];
  const uniqueserviceCenterName = [...new Set(data.map((item) => item.serviceCenterName))];
  const uniqueserviceCenterLocation = [...new Set(data.map((item) => item.serviceCenterLocation))];
  const uniquestatus = [...new Set(data.map((item) => item.status))];





  const statusFormatter = (cell) => {
    return cell === "New Request" ? (
      <button className="reactive-btn" style={{width:"100%"}}>
        <span className="reactive-btn-font">New Request</span>
      </button>
    ) : cell === "On Hold" ? (
      <button className="deactive-btn" style={{width:"100%"}}>
        <span className="deactive-btn-font">On Hold</span>
      </button>
    ) : cell === "Requisition Rejected" ? (
      <button className="delete-btn" style={{width:"100%"}}>
        <span className="delete-btn-font">Requisition Rejected</span>
      </button>
    ) : cell === "PO Generated" ? (
      <button className="active-btn" style={{width:"100%"}}>
        <span className="active-btn-font">PO Generated</span>
      </button>
    ) : (
      cell
    );
  };

  const requisitionHandler = (rowIndex) => {
    setIsOpen(true);
    setIndexTracker(rowIndex);
  };

  const requisitionDetails = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            color: "blue",
            cursor: "pointer",
          }}
          onClick={() => requisitionHandler(rowIndex)}
        >
          View Details
        </p>
        {indexTracker === rowIndex && isOpen ? (
          <RequisitionDetails row={row} closeModal={setIsOpen} />
        ) : null}
      </div>
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && open ? (
          <Action updatedData={fetchReport} rowData={row} setOpen={setOpen} />
        ) : null}
      </div>
    );
  };

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      location: "",
      bomId: "",
      bomType: "",
      vehicleModel: "",
      vehicleVariant: "",
      status: "",
    });

    const resp = await trackPromise(API.get(`procurement-module/api/v1/boms`));
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setBomCostingData(resp);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  }

  const handleClick = (e) => {
    setShow(!show);
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          data.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Requisition ID ",
      dataField: "requisitionID",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes: "cellsOverflow",
    },
    {
      text: "Requisition Date",
      dataField: "requisitionDate",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "Requisition Details ",
      dataField: "requisitionDetails",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { isOpen, indexTracker },
      formatter: requisitionDetails,
    },
    {
      text: "Store Manager",
      dataField: "storeManagerName",
      headerAttrs: { width: "130px" },
      classes: "cellsOverflow",
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes: "cellsOverflow",
    },
    {
      text: "Service Center ID",
      dataField: "serviceCenterID",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes: "cellsOverflow",
    },
    {
      text: "Service Center Name",
      dataField: "serviceCenterName",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes: "cellsOverflow",
    },
    {
      text: "Service Center Location",
      dataField: "serviceCenterLocation",
      headerAttrs: { width: "250px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes: "cellsOverflow",
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  {
    return (
      <>
        <div>
          <div id="overlay" onClick={(e) => off(e)}></div>
          <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
            <span>Requisition Progress</span>
          </div>

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div>
                  <div style={{ float: "right", marginTop: "-42px" }}>
                    <div
                      style={{
                        display: "flex",
                        marginRight: "-15px",
                        height: "26px",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ width: "220px" }}>
                        <SearchBar
                          {...props.searchProps}
                          placeholder="Search..."
                          style={{
                            height: "25px",
                            // border: "1px solid #C6C6C6",
                            border: "none",
                            background: "white",
                            borderRadius: "3px",
                            marginRight: "-50px",
                          }}
                        />
                      </div>
                      <div onClick={clearFilter} className="filter-refresh">
                        <FontAwesomeIcon icon={faRedoAlt} />
                        &nbsp; Refresh
                      </div>
                      <div className="filter-refresh" onClick={handleClick}>
                        {" "}
                        <FontAwesomeIcon icon={faFilter} />
                        &nbsp; Filters
                      </div>
                      <div
                        className="filter-refresh"
                        onClick={() => handleExport(data, "manageUsers")}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp; Export
                      </div>
                      {inDashboard ? (
                        <div
                          className="filter-refresh"
                          onClick={() => inDashboard(false)}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  {show && (
                    <form
                      onSubmit={submitHandler}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        height: "135px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                      }}
                      className="UHinputfields VMform"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Requisition ID
                        </label>
                        <select
                          name="requisitionID"
                          value={formValue.requisitionID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Requisition ID
                          </option>
                          {
                            uniquerequisitionID.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Service Center ID
                        </label>
                        <select
                          name="serviceCenterID"
                          value={formValue.serviceCenterID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Service Center ID
                          </option>
                          {
                            uniqueserviceCenterID.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Service Center Name
                        </label>
                        <select
                          name="serviceCenterName"
                          value={formValue.serviceCenterName}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Service Center Name
                          </option>
                          {
                            uniqueserviceCenterName.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Service Center Location
                        </label>
                        <select
                          name="serviceCenterLocation"
                          value={formValue.serviceCenterLocation}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Service Center Location
                          </option>
                          {
                            uniqueserviceCenterLocation.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          {
                            uniquestatus.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          From
                        </label>
                        <input
                          type="date"
                          name="fromDate"
                          value={formValue.fromDate}
                          id=""
                          style={{ width: "100%", marginTop: "-5px" }}
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          To
                        </label>
                        <input
                          type="date"
                          name="toDate"
                          value={formValue.toDate}
                          id=""
                          style={{ width: "100%", marginTop: "-5px" }}
                          onChange={handleChange}
                        />
                      </div>

                      <div
                        style={{
                          width: "25%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          //  onClick={fetchReport}
                          style={{
                            width: "100px",
                            marginTop: "24px",
                            float: "left",
                          }}
                          id="apply"
                        >
                          Apply
                        </button>
                        <button
                          style={{
                            width: "100px",
                            marginTop: "24px",
                            float: "left",
                          }}
                          onClick={clearFilter}
                          id="clear"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </form>
                  )}
                  <br></br>
                </div>

                <BootstrapTable
                  wrapperClasses="table-responsive"
                  // rowClasses="text-nowrap"
                  rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                  pagination={paginationFactory({
                    hidePageListOnlyOnePage: true,
                    showTotal: true,
                    hideSizePerPage: true,
                    paginationSize: 2,
                    sizePerPage: "10",
                  })}
                  noDataIndication="No Data Found"
                  {...props.baseProps}
                />
              </>
            )}
          </ToolkitProvider>
        </div>
      </>
    );
  }
};

export default RequisitionProgress;
