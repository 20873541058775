import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { NavItem } from "reactstrap";
import AppConstants from "../../../utils/app-constants";
import SearchBox from "../../../../src/components/search/SearchBox";
import ViewUpdate from "./ViewUpdate";
import DatePicker from "react-datepicker";
import GINDetails from "./GINDetails";
import QtyDetails from "./qtyDetails";
import moment from "moment";
import handleExport from "../../../utils/exportExcel";
import auth from "../../../utils/auth";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";



const VehicleReturns = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [show, setShow] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [open, setOpen] = useState();
  const [openQty, setOpenQty] = useState();
  const [openDetails, setOpenDetails] = useState();

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `finance/api/v1/returns/vehiclereturn?requestID=${formValue.requestID}&requestedBy=${formValue.requestedBy}&requestDate=${formValue.requestDate}&GIN=${formValue.GIN}&status=${formValue.status}`
      )
    );
    console.log(resp);
    setUsersData(resp);
  };

  useEffect(() => {
    fetchReport();
  }, []);



  const uniquerequestID = [...new Set(UsersData.map((item) => item.requestID))]
  const uniquerequestedBy = [...new Set(UsersData.map((item) => item.requestedBy))]
  const uniquevGIN = [...new Set(UsersData.map((item) => item.GIN))]
  const uniquestatus = [...new Set(UsersData.map((item) => item.status))]



  const statusFormatter = (cell) => {
    return cell === AppConstants.NEWREQUEST ? (
      <button className="deactive-btn">
        <span className="deactive-btn-font">Pending at Inventory</span>
      </button>
    ) : cell === AppConstants.INPROGRESS ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">QC Verification</span>
      </button>
    ) : cell === AppConstants.VERIFICATION_COMPLETED ? (
      <button className="active-btn">
        {" "}
        <span className="active-btn-font">Verification Completed</span>
      </button>
    ) : cell === AppConstants.REQUEST_COMPLETED || cell == "Completed" ? (
      <button className="active-btn">
        {" "}
        <span className="active-btn-font">Request Completed</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);

    on();
  };

  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <div>{moment(row.requestDate).format("DD-MMM-YYYY")}</div>;
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          style={{
            fontSize: "12px",
            color: "#0a18a3",
            cursor: "pointer",
            width: "100px",
            height: "25px",
            padding: "3px 9px",
            border: "1px solid #0a18a3",
            borderRadius: "4px",
            background: "white",
          }}
          className="action-btn"
          onClick={() => actionHandler(rowIndex)}
        >
          View & Update
        </button>
        {actionShow === rowIndex && open ? (
          <ViewUpdate rowData={row} closeModal={setOpen} />
        ) : null}
      </div>
    );
  };

  const [formValue, setFormValue] = useState({
    requestID: "",
    requestedBy: "",
    requestDate: "",
    GIN: "",
    status: "",
  });

  const returnDetailsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "14px",
            color: "#0A18A3",
            cursor: "pointer",
            margin: "0px",
          }}
          onClick={() => setOpenDetails(rowIndex)}
        >
          {parseInt(cell)}
        </p>
        {openDetails === rowIndex && (
          <GINDetails rowData={row} closeModal={setOpenDetails} />
        )}
      </div>
    );
  };

  const qtyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "14px",
            color: "#0A18A3",
            cursor: "pointer",
            margin: "0px",
          }}
          onClick={() => setOpenQty(rowIndex)}
        >
          {row.vehicles.length}
        </p>
        {openQty === rowIndex && (
          <QtyDetails rowData={row} closeModal={setOpenQty} />
        )}
      </div>
    );
  };

  const totalFormatter = (cell, row, rowIndex, formatExtraData) => {
    const totalValue = row.vehicles.map((item, index) => {
      return item.subTotal;
    });

    const finalValue = totalValue.reduce((acc, item) => {
      return acc + parseInt(item);
    }, 0);

    return <p>{finalValue}</p>;
  };

  const segmentFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p>{row.vehicles[0].vehicleSegment}</p>
      </div>
    );
  };

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = (e) => {
    setFormValue({
      requestID: "",
      requestedBy: "",
      requestDate: "",
      GIN: "",
      status: "",
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };


  const handleClick = (e) => {
    setShow(!show);
  };

  const columns = [
    {
      text: "S.No.",
      // dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requestID",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      // classes: "alignment"
    },
    {
      text: "Request By",
      dataField: "requestedBy",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      classes: "cellsOverflow",
    },
    {
      text: "Request Date",
      // dataField: "HSNSACcode",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "GIN No.",
      dataField: "GIN",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { openDetails },
      formatter: returnDetailsFormatter,
      // classes: "alignment",
      // formatter: noCloudId
    },
    {
      text: "Segment",
      dataField: "vehicleType",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: segmentFormatter,
    },
    {
      text: "Returned Qty.",
      dataField: "vehicleModel",
      headerAttrs: { width: "250px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { openQty },
      formatter: qtyFormatter,
      // classes: "alignment",
      // formatter: noCloudId
    },
    {
      text: `Total Value(${auth.getUserInfo().currencySymbol})`,
      dataField: "vehicleType",
      headerAttrs: { width: "250px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: totalFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "220px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      // classes: "alignment"
      formatter: statusFormatter,
    },
    {
      text: "Action",
      // dataField: "title",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  let uniqueCategoryArray = [
    ...new Map(UsersData.map((item) => [item["category"], item])).values(),
  ];

  return (

    <>
      <div >
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Vehicle Returns</span>
        </div>

        <ToolkitProvider keyField="id" data={UsersData} columns={columns} search >

          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div style={{ display: "flex", marginRight: "-15px", height: "26px", marginTop: "10px", }}>
                    <div style={{ width: "220px" }}>

                      <SearchBar
                        {...props.searchProps}
                        placeholder='Search...'
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />

                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "manageUsers")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>

                {show && (
                  <>
                    <form
                      onSubmit={submitHandler}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        height: "135px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                      }}
                      className="UHinputfields VMform"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request ID
                        </label>
                        <select
                          name="requestID"
                          value={formValue.requestID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Request ID*
                          </option>
                          {uniquerequestID.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request By
                        </label>
                        <select
                          name="requestBy"
                          value={formValue.requestedBy}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Request By*
                          </option>
                          {uniquerequestedBy.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Date
                        </label>
                        <input
                          type="date"
                          onFocus={(e) => (e.currentTarget.type = "date")}
                          onBlur={(e) => (e.currentTarget.type = "text")}
                          name="requestDate"
                          value={formValue.requestDate}
                          onChange={handleChange}
                          placeholder="Request Date"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>GIN</label>
                        <select
                          name="GIN"
                          value={formValue.reGIN}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            GIN*
                          </option>
                          {uniquevGIN.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status*
                          </option>
                          {uniquestatus.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          width: "22%",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <button
                          onClick={fetchReport}
                          style={{ width: "100px", marginTop: "24px" }}
                          id="apply"
                        >
                          Apply
                        </button>
                        <button
                          style={{ width: "100px", marginTop: "24px" }}
                          onClick={clearFilter}
                          id="clear"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </form>
                  </>
                )}
                <br></br>

              </div>


              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default VehicleReturns;
