import { Close, KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import auth from "../../utils/auth";
import ReactToPrint from "react-to-print";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import moment from "moment";
import TermsView from "../TermsView/TermsView";

const DealerSparePO = ({ rowData, closeModal, type }) => {
  console.log("rowData spare", rowData);

  // const a = rowData?.requisitionDetails?.map((item, index)=>item?.)

  const [openTerm, setOpenTerm] = useState(true);
  const [UserData, setUserData] = useState([]);

  const getCompanyDetails = async () => {
    const resp = await trackPromise(
      API.get(
        `admin-module/api/v1/users/getDataWithTenantID?tenantID=${
          auth.getUserInfo().tenantID
        }`
      )
    );
    setUserData(resp);
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  let componentRef = null;

  const ceilTotal1 = rowData?.components?.reduce((accumulator, item) => {
    const itemTotal =
      item?.unitPrice * item?.requiredQty +
      (item?.taxPercentage
        ? (item?.unitPrice * item?.requiredQty * item?.taxPercentage) / 100
        : 0);
    return accumulator + itemTotal;
  }, 0);
  const total1 = (Math.ceil(ceilTotal1)).toLocaleString("en-IN");

  const ceilTotal2 = rowData?.vehicleData?.reduce((accumulator, item) => {
    const itemTotal =
      item?.unitPrice * item?.requiredQty +
      (item?.taxPercentage
        ? (item?.unitPrice * item?.requiredQty * item?.taxPercentage) / 100
        : 0);
    return accumulator + itemTotal;
  }, 0);
  const total2 = (Math.ceil(ceilTotal2)).toLocaleString("en-IN");

  const ceilTotal3 = rowData?.requisitionDetails?.reduce(
    (accumulator, item) => {
      const itemTotal =
        item?.priceToserviceCenter * item?.requiredQty +
        (item?.taxRate
          ? (item?.priceToserviceCenter * item?.requiredQty * item?.taxRate) / 100
          : 0);
      return accumulator + itemTotal;
    },
    0
  );
  const total4 = (Math.ceil(ceilTotal4)).toLocaleString("en-IN");


  const getFirstValidTotal = (...totals) => {
    for (let total of totals) {
        // Convert to number to check if it's valid
        const number = parseFloat(total.replace(/,/g, ''));
        if (!isNaN(number) && number !== 0) {
            return total; // Return the formatted total
        }
    }
    return "N/A"; // Default value if all are NaN
};




  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{
          width: "75%",
          height: "600px",
          padding: "15px",
          marginTop: "95px",
          marginBottom: "100px",
          borderRadius: "0px",
          borderTop: "none",
        }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Purchase Order</h3>
          <div>
            <ReactToPrint
              trigger={() => {
                return (
                  <button
                    style={{
                      color: "#0A18A3",
                      margin: "1px",
                      height: "27px",
                      width: "90px",
                      border: "none",
                    }}
                  >
                    Download
                  </button>
                );
              }}
              content={() => componentRef}
              documentTitle="Purchase Order Invoice"
              pageStyle="Print"
            />
          </div>
        </div>

        <div style={{ overflow: "scroll" }}>
          <div
            // className="body"
            style={{
              width: " 99%",
              height: "97%",
              display: "flex",
              margin: "5px",
              flexDirection: "column",
              justifyContent: "space-between",
              // pageBreakBefore: "always",
            }}
            ref={(el) => (componentRef = el)}
          >
            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Tata_logo.svg/1200px-Tata_logo.svg.png"
                      style={{
                        width: "60px",
                        height: "60px",
                        marginBottom: "30px",
                      }}
                      alt="Car"
                    />
                  </span>
                  <span>{UserData?.businessName}</span>
                  <span style={{ fontWeight: "100" }}>
                    {" "}
                    {UserData?.locations?.map(
                      (item) =>
                        item?.locationType === "Business" && (
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <span>Phone: {item?.businessContactNo}</span>
                            <span>{item?.address1 + " , " + item?.address2}</span>
                            <span>
                              {item?.city +
                                " , " +
                                item?.state +
                                " , " +
                                item?.country}
                            </span>
                          </span>
                        )
                    )}
                  </span>
                  <span style={{ fontWeight: "100" }}>
                    PAN : {UserData?.pan}
                  </span>
                  <span style={{ fontWeight: "100" }}>
                    GSTIN : {UserData?.gstin}
                  </span>
                  {/* <span>
                    Website :
                    <span
                      style={{ color: "#0A18A3", textDecoration: "underline" }}
                    >
                      {"www.tatamotors.com"}
                    </span>
                  </span> */}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      marginTop: "40px",
                      fontSize: "25px",
                      marginBottom: "15px",
                    }}
                  >
                    PURCHASE ORDER
                  </span>
                  <span style={{ fontWeight: "100" }}>
                    PO No. : <span>{rowData?.poNo}</span>
                  </span>
                  <span style={{ fontWeight: "100" }}>
                    PO Date :{" "}
                    {moment(rowData?.poDate).format("DD-MM-YYYY/HH:mm")}{" "}
                  </span>
                  <span style={{ fontWeight: "100" }}>
                  {`PO Value (${auth.getUserInfo().currencySymbol}) : `}
                  {getFirstValidTotal(total1, total2, total3, total4) || "N/A"}
                  </span>
                  {/* <span style={{fontWeight:"100"}}>Requested by :</span>
                  <span style={{fontWeight:"100"}}>{rowData?.requestBy}</span> */}
                </div>
              </div>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#0A18A3",
                  marginBottom: "2px",
                  height: "25px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      paddingLeft: "3px",
                      fontSize: "15px",
                    }}
                  >
                    Bill To :
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      // marginRight: "190px",
                      alignItems: "end",
                      fontSize: "15px",
                    }}
                  >
                    Ship To :
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <span style={{ fontWeight: "400" }}>
                    {rowData?.businessName ||
                      rowData?.billingAddress?.businessName}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    Phone:{" "}
                    <span>{rowData?.billingAddress?.contactPersonNo}</span>
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    {rowData?.billingAddress?.DealerAddress}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    {`PAN : ${
                      rowData?.panNo || rowData?.billingAddress?.panNo
                    }`}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    {`GSTIN : ${
                      rowData?.gstin || rowData?.billingAddress?.gstin
                    }`}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    // marginRight: "100px",
                  }}
                >
                  <span style={{ fontWeight: "400" }}>
                    {rowData?.shipmentAddress?.businessName ||
                      rowData?.showroomName}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    Phone:{" "}
                    <span>
                      {rowData?.shippingContactNo ||
                        rowData?.shipmentAddress?.contactPersonNumber}
                    </span>
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    {rowData?.shipmentAddress?.serviceCenterAddress ||
                      rowData?.shipmentAddress}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    GSTIN : {rowData?.shipmentAddress?.gstin}
                  </span>
                  {/* <span style={{ fontWeight: "400" }}>
                    {  rowData?.shipmentAddress?.serviceCenterAddress}
                  </span> */}
                </div>
              </div>
            </section>

            <section style={{ width: "100%", flexGrow: 1 }}>
              <span style={{ display: "flex", alignItems: "start" }}>
                Order Details :{" "}
              </span>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#0A18A3",
                  marginBottom: "2px",
                }}
              >
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "50px",
                      marginLeft: "5px",
                      padding: "2px",
                    }}
                  >
                    S.No
                  </span>
                </div>

                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "100px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    Item Code
                  </span>
                </div>

                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "250px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    Item Description
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "100px",
                      marginLeft: "10px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    Qty.
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "100px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    {`Unit Price(${auth.getUserInfo().currencySymbol})`}
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "100px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    HSN/SAC
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "start",
                      width: "100px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  >
                    {`Tax(${auth.getUserInfo().currencySymbol})`}
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <span
                    style={{
                      backgroundColor: "#0A18A3",
                      color: "white",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {`Total (${auth.getUserInfo().currencySymbol})`}
                  </span>
                </div>
              </div>

              <div style={{ fontWeight: "100" }}>
                {type == "components"
                  ? rowData?.components?.map((item, index) => (
                      <div
                        // id="POVendorContent"
                        style={{
                          display: "flex",
                          // justifyContent: "space-between",
                        }}
                        key={index}
                      >
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "50px",
                              marginLeft: "5px",
                            }}
                          >
                            {index + 1}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "5px",
                            }}
                          >
                            {item?.skuCode}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "250px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {item?.componentName}
                          </span>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "250px",
                              marginLeft: "5px",
                            }}
                          >
                            {`${item?.categoryName || item?.category} - ${
                              item?.subCategory
                            } - ${item?.sparePartName || item?.sparePartname}`}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "10px",
                              marginRight: "2px",
                            }}
                          >
                            {item?.requiredQuantity}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {item?.unitPrice}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {item?.hsnCode ||
                              item?.hsnSacCode ||
                              item?.HSNSACcode ||
                              rowData?.hsnCode ||
                              rowData?.hsnSacCode ||
                              rowData.HSNSACcode}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {/* {`${
                      (item?.unitPrice * item?.requiredQuantity * item?.taxPercentage) /
                      100
                    } (${item?.taxPercentage}%)`
                    } */}

                            {/* {`${
                              item.taxPercentage
                                ? `${
                                    (item?.unitPrice *
                                      item?.requiredQuantity *
                                      item?.taxPercentage) /
                                    100
                                  } ( ${item?.taxPercentage}%)`
                                : 0
                            }`} */}

                            {item.taxRate ? (
                              <>
                                {(
                                  (item.unitPrice *
                                    item.requiredQuantity *
                                    item.taxRate) /
                                  100
                                ).toFixed(2)}{" "}
                                ({item.taxRate}%)
                              </>
                            ) : (
                              <>
                                {item.taxPercentage ? (
                                  <>
                                    {(
                                      (item.unitPrice *
                                        item.requiredQuantity *
                                        item.taxPercentage) /
                                      100
                                    ).toFixed(2)}{" "}
                                    ({item.taxPercentage}%)
                                  </>
                                ) : (
                                  0
                                )}
                              </>
                            )}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {/* {item?.unitPrice * item?.requiredQuantity +
                          (item?.unitPrice * item?.requiredQuantity * item?.taxPercentage) /
                            100
                        } */}

                            {`${
                              item?.unitPrice * item?.requiredQuantity +
                              (item?.taxPercentage
                                ? (item?.unitPrice *
                                    item?.requiredQuantity *
                                    item?.taxPercentage) /
                                  100
                                : 0)
                            }`}
                          </span>
                        </div>
                      </div>
                    ))
                  : type == "requisitionDetails"
                  ? rowData?.requisitionDetails?.map((item, index) => (
                      <div
                        // id="POVendorContent"
                        style={{
                          display: "flex",
                          // justifyContent: "space-between",
                        }}
                        key={index}
                      >
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "50px",
                              marginLeft: "5px",
                            }}
                          >
                            {index + 1}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "5px",
                            }}
                          >
                            {item?.skuCode}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "250px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {item?.componentName}
                          </span>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "start",
                              width: "250px",
                              marginLeft: "5px",
                            }}
                          >
                            <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                                display: "flex",
                                justifyContent: "start",
                                width: "250px",
                                marginLeft: "5px",
                              }}
                            >
                              {`${item?.categoryName || item?.category} - ${
                                item?.subCategory
                              } - ${
                                item?.sparePartName || item?.sparePartname
                              }`}
                            </span>
                            <span
                              style={{
                                color: "black",
                                fontSize: "12px",
                                display: "flex",
                                justifyContent: "start",
                                width: "250px",
                                marginLeft: "5px",
                              }}
                            >
                              {item?.specifications?.map((el) => (
                                <span>{`${el?.specsType} - ${el?.specsValue} - ${el?.specsUnit}`}</span>
                              ))}
                            </span>
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "10px",
                              marginRight: "2px",
                            }}
                          >
                            {item?.requiredQty}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {item?.priceToserviceCenter.toLocaleString("en-IN")}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {item?.hsnCode ||
                              rowData?.hsnCode ||
                              item?.hsnSacCode ||
                              item?.HSNSACcode ||
                              rowData?.HSNSACcode}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {/* {`${
                      (item?.unitPrice * item?.requireQty * item?.taxPercentage) /
                      100
                    } (${item?.taxPercentage}%)`
                    } */}

                            {/* {`${
                            item?.taxRate
                              ? Math.ceil(
                                  (item?.priceToServiceCenter *
                                   item?.requiredQty *
                                   item?.taxRate) /
                                    100
                                ) + ` (${item?.taxRate}%)`
                              : 0
                          }`} */}

                            {Math.ceil(
                              item?.taxRate
                                ? ((item?.priceToserviceCenter *
                                    item?.requiredQty *
                                    item?.taxRate) /
                                    100).toLocaleString("en-IN")
                                : 0
                            )}
                            {` (${item?.taxRate}%)`}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {/* {item?.unitPrice * item?.requireQty +
                          (item?.unitPrice * item?.requireQty * item?.taxPercentage) /
                            100
                        } */}

                            {(Math.ceil(
                              item?.priceToserviceCenter * item?.requiredQty +
                                (item?.taxRate
                                  ? (item?.priceToserviceCenter *
                                      item?.requiredQty *
                                      item?.taxRate) /
                                    100
                                  : 0)
                            ).toLocaleString("en-IN"))}
                          </span>
                        </div>
                      </div>
                    ))
                  : type == "vehicleData"
                  ? rowData?.vehicleData?.map((item, index) => (
                      <div
                        // id="POVendorContent"
                        style={{
                          display: "flex",
                          // justifyContent: "space-between",
                        }}
                        key={index}
                      >
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "50px",
                              marginLeft: "5px",
                            }}
                          >
                            {index + 1}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "5px",
                            }}
                          >
                            {item?.skuCode || item?.vehicleSkuCode}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "250px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {`${
                              item?.categoryName ||
                              item?.category ||
                              item?.vehicleCategory
                            } - ${item?.subCategory} - ${
                              item?.sparePartName || item?.sparePartname
                            }`}
                          </span>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "250px",
                              marginLeft: "5px",
                            }}
                          >
                            {}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "10px",
                              marginRight: "2px",
                            }}
                          >
                            {item?.requiredQty}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {item?.unitPrice}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {item?.hsnCode || item?.HSNSACcode}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {/* {`${
                      (item?.unitPrice * item?.requiredQty * item?.taxPercentage) /
                      100
                    } (${item?.taxPercentage}%)`
                    } */}

                            {`${
                              item?.taxPercentage
                                ? `${Math.ceil(
                                    (item?.unitPrice *
                                      item?.requiredQty *
                                      item?.taxPercentage) /
                                      100
                                  )} (${item?.taxPercentage}%)`
                                : 0
                            }`}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <span
                            style={{
                              color: "black",
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "start",
                              width: "100px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                          >
                            {/* {item?.unitPrice * item?.requiredQty +
                          (item?.unitPrice * item?.requiredQty * item?.taxPercentage) /
                            100
                        } */}

                            {`${Math.ceil(
                              item?.unitPrice * item?.requiredQty +
                                (item?.taxPercentage
                                  ? (item?.unitPrice *
                                      item?.requiredQty *
                                      item?.taxPercentage) /
                                    100
                                  : 0)
                            )}`}
                          </span>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </section>

            <section
              className="POterms"
              style={{
                justifyContent: "space-between",
                backgroundColor: "rgb(10, 24, 163)",
                margin: "12px 0px 12px 0px",
              }}
            ></section>

            <section
              className="POterms"
              style={{
                justifyContent: "space-between",
                backgroundColor: "white",
              }}
            >
              <div style={{ width: "75%" }}>
                <div
                  style={{
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  Terms & Conditions :-
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    textAlign: "left",
                    display: "flex",
                    flex: "wrap",
                    fontWeight: "100",
                  }}
                >
                  <TermsView data={"Spares PO Terms"} />
                </div>

                {/* <div
                  style={{
                    fontSize: "11px",
                    textAlign: "left",
                    display: "flex",
                    flex: "wrap",
                    fontWeight:"100"
                  }}
                >
                  <ul>
                    <li>
                      Price should be inclusive of all charges, Taxes etc.
                    </li>
                    <li>
                      Delivery Period should be 7 days from our Purchase Order.
                    </li>
                    <li>Supply should be made door delivery</li>
                    <li>
                      Supplier should be responsible for any damage or loss of
                      Material
                    </li>
                  </ul>
                </div> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  width: "25%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    width: "130px",
                  }}
                >
                  <span>
                    {`Sub Total (${auth.getUserInfo().currencySymbol})`} :{" "}
                  </span>
                  {/* <span>
                    {`Shipping Charges(${auth.getUserInfo().currencySymbol})`} :{" "}
                  </span> */}
                </div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <span>{getFirstValidTotal(total1, total2, total3, total4) || "N/A"}</span>
                  {/* <span>{`500`}</span> */}
                </div>

                {/* <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-around",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Sub Total : <span style={{marginLeft:"3px"}}>{306}</span>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-around",
                    fontSize: "14px",
                  }}
                >
                  {`Shipping (${auth.getUserInfo().currencySymbol})`} :{" "}
                  <span style={{marginLeft:"3px"}}>{500}</span>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-around",
                    fontSize: "14px",
                  }}
                >
                  {`Grand Total(${auth.getUserInfo().currencySymbol})`} :{" "}
                  <span style={{marginLeft:"3px"}}>{306+500}</span>
                </div> */}
              </div>
            </section>

            <section
              className="POterms"
              style={{
                justifyContent: "end",
                backgroundColor: "rgb(10, 24, 163)",
                margin: "12px 0px 0px 0px",
                color: "white",
                border: "1px solid black",
              }}
            >
              <span>
                {" "}
                {`Grand Total : (${auth.getUserInfo().currencySymbol}) `}
                {getFirstValidTotal(total1, total2, total3, total4) || "N/A"}
              </span>
            </section>

            <section
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                margin: "10px 0",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span></span>
                <span style={{ marginTop: "70px", paddingRight: "23px" }}>
                  {" "}
                  Authorized Signatory
                </span>
              </div>
            </section>
          </div>
        </div>

        <div className="footer">
          <button
            style={{
              width: "104px",
              height: "35px",
              background: "#fff",
              cursor: "pointer",
            }}
            onClick={() => closeModal(false)}
          >
            <Close style={{ fontSize: "25px" }} />
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DealerSparePO;
