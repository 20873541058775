import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { NavItem } from "reactstrap";
import ViewVendorDetails from "./ViewVendorDetails";
import "../../../components/modal/modal.scss";
import SearchBox from "../../../components/search/SearchBox";
import { errorNotification } from "../../../utils/notifications";
import handleExport from "../../../utils/exportExcel";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const MappedComponentVendors = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [view, setView] = useState(false);
  const [actionShow, setActionShow] = useState();
  const [show, setShow] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [formValue, setFormValue] = useState({
    componentName: "",
    vendorName: "",
    vendorLocation: "",
    vendorPartNo: "",
  });

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `procurement-module/api/v1/mappings?componentName=${formValue.componentName}&vendorName=${formValue.vendorName}&vendorCity=${formValue.vendorLocation}&vendorPartCode=${formValue.vendorPartNo}`
      )
    );
    console.log(resp);
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const uniqueComponentName = [
    ...new Set(UsersData.map((data) => data.componentName)),
  ];
  const uniqueVendorName = [
    ...new Set(
      UsersData.flatMap((item) =>
        item.vendorDetails.map((data) => data.vendorName)
      )
    ),
  ];
  const uniqueVendorLocation = [
    ...new Set(
      UsersData.flatMap((item) =>
        item.vendorDetails.map((data) => data.vendorCity)
      )
    ),
  ];
  const uniqueVendorPartNo = [
    ...new Set(
      UsersData.flatMap((item) =>
        item.vendorDetails.map((data) => data.vendorPartCode)
      )
    ),
  ];

  console.log(uniqueVendorName);

  const actionHandler = (rowIndex) => {
    setView(!view);
    setActionShow(rowIndex);
     on();
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const actionFormatter = (cell, row, rowIndex) => {
    return (
      <div>
        <p
          style={{
            fontSize: "12px",
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => actionHandler(rowIndex)}
        >
          View & Edit Vendors
        </p>
        {actionShow === rowIndex && view && (
          <ViewVendorDetails
            updatedData={fetchReport}
            rowData={row}
            closeModal={setView}
          />
        )}
      </div>
    );
  };

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      componentName: "",
      vendorName: "",
      vendorLocation: "",
      vendorPartNo: "",
    });
    const resp = await trackPromise(
      API.get(`procurement-module/api/v1/mappings`)
    );
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setUsersData(resp);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Component Name",
      dataField: "componentName",
      headerAttrs: { width: "250px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      // classes: "alignment",
      // formatter: noCloudId
    },
    {
      text: "Component Category",
      dataField: "category",
      headerAttrs: { width: "250px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      // classes: "alignment"
    },
    {
      text: "Component Sub Category",
      dataField: "subCategory",
      headerAttrs: { width: "250px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      // classes: "alignment"
    },
    {
      text: "Component SKU Code",
      dataField: "skuCode",
      headerAttrs: { width: "250px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      // classes: "alignment",
      // formatter: noCloudId
    },
    {
      text: "Vendor Details",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { view, actionShow },
      formatter: actionFormatter,
    },
  ];


  
  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
  }

  return (
    //  <span>Mapped Component-Vendors</span>
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Mapped Component Vendors</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search"
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "Mapped Component Vendors")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form
                    onSubmit={submitHandler}
                    className="UHinputfields VMform"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "85px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Component Name
                      </label>
                      <select
                        name="componentName"
                        value={formValue.componentName}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          Component Name
                        </option>
                        {uniqueComponentName?.map((item) => {
                          return <option>{item}</option>;
                        })}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Vendor Name
                      </label>
                      <select
                        name="vendorName"
                        value={formValue.vendorName}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          Vendor Name
                        </option>
                        {/* {UsersData.map((item) => {
                return (
                  item.vendorDetails.map((item) => {
                    return (
                      <option>{item.vendorName}</option>
                    )
                  }))
              })} */}
                        {uniqueVendorName?.map((item) => {
                          return <option>{item}</option>;
                        })}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Vendor Location
                      </label>
                      <select
                        name="vendorLocation"
                        value={formValue.vendorLocation}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          Vendor Location
                        </option>
                        {uniqueVendorLocation?.map((item) => {
                          return <option>{item}</option>;
                        })}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Vendor Part No.
                      </label>
                      <select
                        name="vendorPartNo"
                        value={formValue.vendorPartNo}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          Vendor Part No.
                        </option>
                        {uniqueVendorPartNo?.map((item) => {
                          return <option>{item}</option>;
                        })}
                      </select>
                    </div>
                    <button
                      onClick={fetchReport}
                      id="apply"
                      style={{ marginTop: "22px", marginLeft: "10px" }}
                    >
                      Apply
                    </button>
                    <button
                      onClick={clearFilter}
                      id="clear"
                      style={{ marginTop: "22px" }}
                    >
                      Clear Filter
                    </button>
                  </form>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default MappedComponentVendors;
