import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import { successNotification, errorNotification } from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Delete = ({ updatedData, editData, closeModal, userType }) => {
  const [formValue, setFormValue] = useState({
    deleteReason: "",
    description: "",
  });
console.log(editData)

  const nsmDetails = {
    countryName:editData.countryName,
    nsmUserType:userType,
    nsmEmail:editData.nsmEmail,
    nsmStatus: "Delete",
    nsmReason: formValue.deleteReason,
    nsmDescription: formValue.description,
    
  };

  const zsmDetails = {
    countryName:editData.countryName,
    zone:editData.zones,
    zsmUserType:userType,
    zsmEmail:editData.zsmEmail,
    zsmStatus: "Delete",
    zsmReason: formValue.deleteReason,
    zsmDescription: formValue.description,
  };

  const asmDetails = {
    countryName:editData.countryName,
    zone:editData.zone,
    // area:editData.areas,
    asmUserType:userType,
    asmEmail:editData.asmEmail,
    asmStatus: "Delete",
    asmReason: formValue.deleteReason,
    asmDescription: formValue.description,
  };

  const refresh = () => {
    updatedData();
  };

  const submitHandler = async (formValue) => {
    if(formValue.deleteReason==""){
      errorNotification("Please enter delete reason", { autoclose: 2000 });
    }else{
       // National Sales
    if(userType== "National Sales"){
      const resp = await trackPromise(
        API.post(`operations-module/api/v1/salesUser/nsmStatus`, nsmDetails)
      );
  
      const { success, message } = resp;
  
      if (success) {
        closeModal(false);
        updatedData("","National Sales");
        return successNotification(message, { autoClose: 3000 });
      } else {
        errorNotification(message, { autoclose: 2000 });
      }
    };
    // Zonal Sales
    if(userType== "Zonal Sales"){
      const resp = await trackPromise(
        API.post(`operations-module/api/v1/salesUser/zsmStatus`, zsmDetails)
      );
  
      const { success, message } = resp;
  
      if (success) {
        closeModal(false);
        updatedData("","Zonal Sales");
        return successNotification(message, { autoClose: 3000 });
        
      } else {
        errorNotification(message, { autoclose: 2000 });
      }
    };
    // Area Sales
    if(userType== "Area Sales"){
      const resp = await trackPromise(
        API.post(`operations-module/api/v1/salesUser/asmStatus`, asmDetails)
      );
  
      const { success, message } = resp;
  
      if (success) {
        closeModal(false);
        updatedData("","Area Sales");
        return successNotification(message, { autoClose: 3000 });
        
      } else {
        errorNotification(message, { autoclose: 2000 });
      }
    };
    }
    }
   
  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Delete User</h3>
        </div>

        <div className="body">
          <label style={{display:'flex', flexDirection:'column', width:'50%'}}>
          <label style={{ fontSize: "13px", fontWeight: "500", width: "92px" }}>Delete Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="deleteReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{width:'100%', marginTop:'-5px'}}
            >
              <option disabled selected hidden>
                Delete Reason
              </option>
              <option value="Misconduct">Misconduct</option>
              <option value="Not an Active User">Not an Active User</option>
              <option value="Change of Role">Change of Role</option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.deleteReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Deactivation Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={auth.isSubAdmin() ? submitSubAdminHandler : submitHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Delete;
