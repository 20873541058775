import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Action from "./vendor-actions/VendorActions";
import "../../components/modal/modal.scss";
import "./Vendors.scss";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import AppConstants from "../../utils/app-constants";
import SearchBox from "../../components/search/SearchBox";
import { errorNotification } from "../../utils/notifications";
import handleExport from "../../utils/exportExcel";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ViewDetails from "./vendor-actions/ViewDetails";

const ManageVendors = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [open, setOpen] = useState(false);
  const [actionShow, setActionShow] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [UsersData, setUsersData] = useState([]);

  const [detailsShow, setDetailsShow] = useState();
  const [viewDetails, setViewDetails] = useState(false);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `procurement-module/api/v1/vendors?vendorName=${formValue.vendorName}&vendorEmail=${formValue.vendorEmail}&city=${formValue.vendorLocation}`
      )
    );
    console.log(resp);
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const statusFormatter = (cell) => {
    return cell === AppConstants.ACTIVE ? (
      <button className="active-btn">
        <span className="active-btn-font">Active</span>
      </button>
    ) : cell === AppConstants.DEACTIVATED ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">Deactivated</span>
      </button>
    ) : cell === AppConstants.REACTIVATED ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">Reactivated</span>
      </button>
    ) : cell === AppConstants.DELETED ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Deleted</span>
      </button>
    ) : cell === AppConstants.BLACKlISTED ? (
      <button className="reactive-btn">
        {" "}
        <span className="reactive-btn-font">Blacklisted</span>
      </button>
    ) : (
      cell
    );
  };

  const detailsHandler = (rowIndex) => {
    setDetailsShow(rowIndex);
    setViewDetails(true);
  };

  const documentFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => detailsHandler(rowIndex)}
          style={{ color: "blue" }}
        >
          View Details
        </button>
        {detailsShow === rowIndex && viewDetails ? (
          <ViewDetails rowData={row} closeModal={setViewDetails} />
        ) : null}
      </div>
    );
  };

  // const documentFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   return (
  //     <div>
  //       <p
  //         style={{
  //           fontSize: "12px",
  //           color: "#0A18A3",
  //           cursor: "pointer",
  //         }}
  //         onClick={""}
  //       >
  //         View Document
  //       </p>
  //     </div>
  //   );
  // };

  const [show, setShow] = useState(false);

  const handleClick = (e) => {
    setShow(!show);
  };

  function on() {
    console.log("on");
    document.getElementById("overlay").style.display = "block";
  }

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    on();
  };

  function off() {
    console.log("off");
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log(row);
    return row.status === AppConstants.DELETED ? (
      ""
    ) : (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon id="outside" icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && open ? (
          <Action updatedData={fetchReport} rowData={row} rowIndex={rowIndex} setIsOpen={setOpen} />
        ) : null}
      </div>
    );
  };

  const [formValue, setFormValue] = useState({
    vendorName: "",
    vendorEmail: "",
    vendorLocation: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      vendorName: "",
      vendorEmail: "",
      vendorLocation: "",
    });

    const resp = await trackPromise(
      API.get(`procurement-module/api/v1/vendors`)
    );
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setUsersData(resp);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Vendor Id",
      dataField: "vendorID",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vendor Business Name",
      dataField: "vendorName",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vendor Business Email ID",
      dataField: "vendorEmail",
      headerAttrs: { width: "250px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vendor Business Address",
      dataField: "address1",
      headerAttrs: { width: "250px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vendor Business Contact No.",
      dataField: "businessContactNo",
      headerAttrs: { width: "270px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "GSTIN No.",
      dataField: "gstin",
      headerAttrs: { width: "170px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "GSTIN Document.",
      dataField: "gstInDoc",
      headerAttrs: { width: "170px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { detailsShow, viewDetails },
      formatter: documentFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "140px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      // classes: "alignment"
      formatter: statusFormatter,
    },
    {
      text: "Action",
      // dataField: "title",
      headerAttrs: { width: "70px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  let uniqueVendorNameArray = [
    ...new Map(UsersData.map((item) => [item["vendorName"], item])).values(),
  ];
  let uniqueCityArray = [
    ...new Map(UsersData.map((item) => [item["city"], item])).values(),
  ];
  let uniqueEmailArray = [
    ...new Map(UsersData.map((item) => [item["vendorEmail"], item])).values(),
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Vendors</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "Manage Vendors")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form
                    onSubmit={submitHandler}
                    className="UHinputfields VMform"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "85px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "25%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Vendor Business Name
                      </label>
                      <select
                        name="vendorName"
                        value={formValue.vendorName}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected>
                          Vendor Business Name
                        </option>
                        {uniqueVendorNameArray.map((item) => {
                          return <option>{item.vendorName}</option>;
                        })}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "25%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Vendor Business Email
                      </label>
                      <select
                        name="vendorEmail"
                        value={formValue.vendorEmail}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected>
                          Vendor Business Email
                        </option>
                        {uniqueEmailArray.map((item) => {
                          return <option>{item.vendorEmail}</option>;
                        })}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "25%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Vendor Business Location
                      </label>
                      <select
                        name="vendorLocation"
                        value={formValue.vendorLocation}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected>
                          Vendor Business Location
                        </option>
                        {uniqueCityArray.map((item) => {
                          return <option>{item.city}</option>;
                        })}
                      </select>
                    </div>
                    <button
                      onClick={fetchReport}
                      id="apply"
                      style={{ marginTop: "22px", marginLeft: "10px" }}
                    >
                      Apply
                    </button>
                    <button
                      onClick={clearFilter}
                      id="clear"
                      style={{ marginTop: "22px" }}
                    >
                      Clear Filter
                    </button>
                  </form>
                )}
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ManageVendors;
