import React, { useState } from "react";
import EditManageOrder from "./EditManageOrder";
import MarkClosureManageOrder from "./MarkClosureManageOrder"
import SendToVendor_MO from "./SendToVendor_MO"
import RequestForApproval_MO from "./RequestForApproval_MO"
import "../../../components/modal/modal.scss";
import ViewStatus from "./viewStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEdit, faEye, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const ManageOrdersAction = ({ rowData , rowIndex}) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      edit: false,
      sendToVendor: false,
      requestForApproval: false,
      markClosure: false,
      viewStatus: false,
      fullyClosed: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {activeModal.edit && showModal && <EditManageOrder editData={rowData} closeModal={setShowModal} />}
      {activeModal.sendToVendor && showModal && <SendToVendor_MO editData={rowData} closeModal={setShowModal} />}
      {activeModal.requestForApproval && showModal && <RequestForApproval_MO editData={rowData} closeModal={setShowModal} />}
      {activeModal.markClosure && showModal && <MarkClosureManageOrder editData={rowData} closeModal={setShowModal} />}
      {activeModal.viewStatus && showModal && <ViewStatus viewData={rowData} closeModal={setShowModal} />}
      {/* {activeModal.fullyClosed && showModal && <FullyClosed editData={rowData} closeModal={setShowModal} />} */}


      <div style={ rowIndex === 0 || rowIndex === 1 ? { position: "relative" } : {position:"relative", marginTop:"-30px"}}>
       <div style={{ marginLeft: "-150px" ,width:"200px"}} className="action">
          {rowData.status === 'Rejected By Finance' && <div>
            <span id="edit" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </span>
          </div>}
          {rowData.status === 'Approved By Finance' && <div>
            <span id="sendToVendor" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faPaperPlane} /> Send To Vendor
            </span>
          </div>}
          {(rowData.status === 'On Hold By Finance' || rowData.status === 'Pending At Finance') && <div>
            <span id="requestForApproval" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faCheckCircle} /> Request For Approval
            </span>
          </div>}
          {(rowData.status === 'Partially Closed' || rowData.status === 'GRN Created') && <div>
            <span id="markClosure" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faCheckCircle} /> Mark Closure
            </span>
          </div>}
          {(rowData.status === 'Fully Closed' || rowData.status === 'Placed To Vendor') && <div>
            <span id="viewStatus" onClick={(e) => clickHandler(e)}>
              <FontAwesomeIcon icon={faEye} /> View Details
            </span>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default ManageOrdersAction;
