import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";
import ViewTermsDetails from "../../../OperationsWarrantyServices/Component/ViewTermsDetails";

const WarrantyDetails = ({ closeModal, rowData }) => {
  // const UsersData = row.requisitionDetails;
  const [openView , setOpenView] = useState()

  return (
    <div className="container1">
    {
      openView && <ViewTermsDetails rowdata={rowData} closeModal={setOpenView} data={"Vehicle Warranty Terms"} />
    }
      <div
        className="modalContainer"
        style={{ width: "50%", height: "500px", padding: "10px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Warranty Details</h3>
          <button
          onClick={()=>setOpenView(true)}
          style={{
            color: "#0A18A3",
            backgroundColor: "white",
            border: "1px solid #0A18A3",
            borderRadius: "3px",
            marginBottom:"6px"
          }}
        >
          View Terms
        </button>
        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding:7,
          }}
        >
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            SKU Code: {rowData.vehicleSkuCode}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Vehicle Model: {rowData.vehicleModel}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            Segment: {rowData.vehicleSegment}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Vehicle Variant: {rowData.vehicleVariant}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            Category: {rowData.vehicleCategory}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Vehicle Type: {rowData.vehicleType}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            Color: {rowData.vehicleColor}
          </span>
        </section>
        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: "start" }}>
              <h6 style={{ color: "blue" }}>Standard Warranty</h6>
            </div>

            <div>
                <Table>
                <tbody>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb"}}>Warranty Type</td>
                    <td>{rowData.standardWarranty.warrantyType}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb"}}>Distance</td>
                    <td>{rowData.standardWarranty.warrantyDistance}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb"}}>Duration & Units</td>
                    <td>{rowData.standardWarranty.warrantyDuration + rowData.standardWarranty.warrantyDurationUnit}</td>
                  </tr>
                </tbody>
              </Table>
        
            </div>

          </div>
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: "start" }}>
              <h6 style={{ color: "blue" }}>Extended Warranty</h6>
            </div>

            <div>
             {
              rowData?.extendedWarranty?.map((item)=>{
                return(
                  <>
                  <Table>
                <tbody>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb"}}>Warranty Type</td>
                    <td>{item.warrantyType}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb"}}>Distance</td>
                    <td>{item.warrantyDuration}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb"}}>Distance & Units</td>
                    <td>{item.warrantyDuration + item.warrantyDurationUnit}</td>
                  </tr>
                  <tr>
                    <td style={{backgroundColor:"#ebebeb"}}>Charges</td>
                    <td>{item.warrantyCharges}</td>
                  </tr>
                </tbody>
              </Table>
                  </>
                )
               
              })
             }
            </div>

          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <KeyboardBackspace /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default WarrantyDetails;
