import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Action from "../../pages/orders/action/Action";
import "../../components/modal/modal.scss";
import SearchBox from "../../components/search/SearchBox";
import { errorNotification } from "../../utils/notifications";
import ReactDatePicker from "react-datepicker";
import "./Inward.scss";
import SpareViewAct from "./action/spareViewAct";
import handleExport from "../../utils/exportExcel";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ManageCompRequest = ({ updatedTable, tabName, inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState();
  const [hsnData, setHsnData] = useState([]);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/inventorygoodsverification/getManageRequest?requestID=${formValue.requestId}&requestedDate=${formValue.requestDate}&POID=${formValue.pono}&GRNID=${formValue.grno}&requestCategory=${formValue.requestCategory}&requestType=${formValue.requestType}`
      )
    );
    console.log(resp);
    resp.success == false ? "" : setHsnData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, [updatedTable]);

  
  const uniqueRequestId = [...new Set(hsnData.map((item)=>item.requestID))]
  const uniqueGRNID = [...new Set(hsnData.map((item)=>item.GRNID))]
  const uniquePOID = [...new Set(hsnData.map((item)=>item.POID))]


  const [formValue, setFormValue] = useState({
    requestId: "",
    pono: "",
    grno: "",
    requestCategory: "",
    requestType: "",
    requestDate: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      requestId: "",
      pono: "",
      grno: "",
      requestCategory: "",
      requestType: "",
      requestDate: "",
    });
    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/inventorygoodsverification/getManageRequest`
      )
    );
    resp.success == false ? "" : setHsnData(resp);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const handleClick = (e) => {
    setShow(!show);
  };

  // const actionHandler = (rowIndex) => {
  //   setOpen(true);
  //   setActionShow(rowIndex);
  //   on();
  // };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          style={{
            width: "100px",
            fontSize: "12px",
            color: "#0a18a3",
            cursor: "pointer",
            padding: "3px 9px",
            border: "1px solid #0a18a3",
            borderRadius: "4px",
            background: "white",
          }}
          onClick={() => setActionShow(rowIndex)}
        >
          {row.status === "Pending At QC_New Request"
            ? "View & Act"
            : row.status === "On Hold_On Hold" ||
              row.status === "Rejected_Rejected"
            ? "View Details"
            : "View Updates"}
        </button>
        {actionShow === rowIndex &&
          (row.status === "Pending At QC_New Request" ||
            row.status === "On Hold_On Hold" ||
            row.status === "Rejected_Rejected" ||
            row.status === "In-Progress_In-Progress" ||
            row.status === "Completed_Completed") && (
            <SpareViewAct
              rowData={row}
              tabName={tabName}
              closeModal={setActionShow}
            />
          )}
        {/* {(actionShow === rowIndex && row.status === "On Hold" || row.status === "Rejected") && <SpareViewAct rowData={row} closeModal={setActionShow} />} */}
        {/* {(actionShow === rowIndex) && row.status === "In Progress" || row.status === "Completed" && <EditDetails_WIP rowData={row} closeModal={setActionShow} />}  */}
      </div>
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          hsnData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requestID",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "GRN NO.",
      dataField: "GRNID",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "PO No.",
      dataField: "POID",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request Category",
      dataField: "requestCategory",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Request Type",
      dataField: "requestType",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Received Components",
      dataField: "receivedComponents",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Received Units",
      dataField: "receivedUnits",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell) => {
        return cell == "Pending At QC_New Request" ? (
          <button className="pendingByProc-btn">
            <span className="pendingByProc-btn-font">Pending At QC</span>
          </button>
        ) : cell == "On Hold_On Hold" ? (
          <button className="deactive-btn">
            <span className="deactive-btn-font">On Hold</span>
          </button>
        ) : cell == "In-Progress_In-Progress" ? (
          <button className="reactive-btn">
            <span className="reactive-btn-font">In-Progress</span>
          </button>
        ) : cell == "Rejected_Rejected" ? (
          <button className="delete-btn">
            <span className="delete-btn-font">Rejected</span>
          </button>
        ) : cell == "Completed_Completed" ? (
          <button className="active-btn">
            <span className="active-btn-font">Completed</span>
          </button>
        ) : (
          cell
        );
      },
    },
    {
      text: "Last Update",
      dataField: "lastUpdatedDate",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "Reason",
      dataField: "hsnSacUpdatedOn",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "120px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Requests</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={hsnData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(hsnData, "manageUsers")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form
                    onSubmit={submitHandler}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      height: "135px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                    }}
                    className="UHinputfields VMform"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        {" "}
                        Request ID
                      </label>
                      <select
                        name="requestId"
                        value={formValue.requestId}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          Request ID
                        </option>
                        {uniqueRequestId?.map((item) => (
                          <option>{item}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        {" "}
                        GRN NO.
                      </label>
                      <select
                        name="grno"
                        value={formValue.grno}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          GRN NO.
                        </option>
                        {uniqueGRNID.map((item) => (
                          <option>{item}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        {" "}
                        PO No.
                      </label>
                      <select
                        name="pono"
                        value={formValue.pono}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          PO No.
                        </option>
                        {uniquePOID.map((item) => (
                          <option>{item}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        {" "}
                        Request Category
                      </label>
                      <input
                        type="text"
                        style={{ marginTop: "-5px", width: "100%" }}
                        name="requestCategory"
                        value={formValue.requestCategory}
                        onChange={handleChange}
                        placeholder="Request Category"
                        autoComplete="off"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        {" "}
                        Request Type
                      </label>
                      <input
                        type="text"
                        style={{ marginTop: "-5px", width: "100%" }}
                        name="requestType"
                        value={formValue.requestType}
                        onChange={handleChange}
                        placeholder="Request Type"
                        autoComplete="off"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        {" "}
                        Request Date
                      </label>
                      <input
                        type="date"
                        name="requestDate"
                        className="requestdate"
                        selected={formValue.requestDate}
                        onChange={(date) =>
                          setFormValue({ ...formValue, requestDate: date })
                        }
                        isClearable
                        placeholderText="Request Date"
                        dateFormat="DD-MM-YYYY/HH:mm"
                        autoComplete="off"
                        style={{ marginTop: "-5px", width: "100%" }}
                      />
                    </div>
                    <div
                      style={{
                        width: "46%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginLeft: "39px",
                      }}
                    >
                      <button
                        onClick={fetchReport}
                        style={{ width: "100px", marginTop: "24px" }}
                        id="apply"
                      >
                        Apply
                      </button>
                      <button
                        style={{ width: "100px", marginTop: "24px" }}
                        onClick={clearFilter}
                        id="clear"
                      >
                        Clear Filter
                      </button>
                    </div>
                  </form>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ManageCompRequest;
