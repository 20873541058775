import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import ViewSpecs from "../wo-viewspecs";
import "../workorder.scss"
const ViewComponents = ({ close, rowdata }) => {
  console.log(rowdata)
  const [specsShow, setSpecsShow] = useState(false);

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "982px", height: "426px", padding: "15px" }}
      >
        <div className="header">
          <h3>Components Details</h3>
        </div>

        <br />
        <div className="body" style={{ height: "300px" }}>
          <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
            <h6>WO ID:{rowdata.orderID}</h6>
            <h6>WO Type:{rowdata.workType}</h6>
          </div>
          <div style={{ overflow: "auto", height: "116px" }}>
            <table className="viewcomp_tbl" style={{ width: "100%" }} >
              <thead>
                <tr>
                  <th>Component Name</th>
                  <th>SKU Code</th>
                  <th>Category</th>
                  <th>Sub Category</th>
                  <th>Specification</th>
                  <th>Required Quantity</th>
                </tr>
              </thead>
              <tbody>
                {rowdata.hasOwnProperty('bomComponents') ?

                  rowdata.bomComponents.map((item) => {
                    return (
                      <tr>
                        <td className="leftcell">{item.componentName}</td>
                        <td>{item.skuCode}</td>
                        <td>{item.category}</td>
                        <td>{item.subCategory}</td>
                        <td>
                          <button
                            className="action-btn"
                            onClick={() => setSpecsShow(item.skuCode)}
                            style={{ color: "blue" }}>
                            View Specs
                          </button>
                          {specsShow === item.skuCode && <ViewSpecs selectedBom={item} close={setSpecsShow} />}
                        </td>
                        <td className="rightcell">100</td>
                      </tr>
                    )
                  }) :

                  rowdata.requiredComponents.map((item) => {
                    return (
                      <tr>
                        <td className="leftcell">{item.componentName}</td>
                        <td>{item.skuCode}</td>
                        <td>{item.category}</td>
                        <td>{item.subCategory}</td>
                        <td>
                          <button
                            className="action-btn"
                            onClick={() => setSpecsShow(item.skuCode)}
                            style={{ color: "blue" }}>
                            View Specs
                          </button>
                          {specsShow === item.skuCode && <ViewSpecs selectedBom={item} close={setSpecsShow} />}
                        </td>
                        <td className="rightcell">100</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
          {/* <div style={{ width: "100%", display: "flex", flexDirection: "column", textAlign: "left" }}>
            <h6>Request Reason</h6>
            <div className="reqreason">Components Exhaust Manifold  10 qty are damanged</div>
          </div> */}
        </div>

        <br />
        <div className="footer">
          <button onClick={() => close(false)}><KeyboardBackspace /> Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewComponents;