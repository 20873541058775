import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../work-order/workorder.scss";
import { KeyboardBackspace } from "@material-ui/icons";

const WoInProgressViewLabours = ({ close,labourdata }) => {

    const [viewSpecs,setViewSpecs]=useState();
    const [display,setDisplay]=useState(false);
    const [UsersData, setUsersData] = useState([]);


    const fetchReport = async () => {
      let body = {
        "orderID":labourdata?.orderID,
        "status": labourdata?.status,
        "requiredItemsType":"labour"
      }
      const resp = await trackPromise(API.post(`shop-floor-module/api/v1/shopFloor/getRequirementItemType`,body));
      console.log(resp);
      setUsersData(resp);
    };
    useEffect(() => {
      fetchReport();
    },[]);

  return (
    <>
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1170px", height: "521px", padding: "15px" }}
      >

        <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>Required Labours</h3>
          {/* <div>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Print 
          </button>
          <button 
             className="action-btn" 
             style={{color:"blue"}}>
             Export
          </button>
          </div> */}
        </div>

    <div className="body" style={{height:"340px",flexWrap:"nowrap"}}> 


 <div>  
      <table className="viewcomp_tbl" style={{width:"100%",fontSize:"15px", overflow: "auto"}} > 
            <tr>
                <th style={{fontSize:"small", fontWeight:"bold"}}>S No.</th>
                <th style={{fontSize:"small", fontWeight:"bold"}}>Labour Category</th>
                <th style={{fontSize:"small", fontWeight:"bold"}}>Labour Sub Category</th> 
                <th style={{fontSize:"small", fontWeight:"bold"}}>Labour Level</th>
                <th style={{fontSize:"small", fontWeight:"bold"}}>Required Labour</th>
                <th style={{fontSize:"small", fontWeight:"bold"}}>Required Labour Time</th>
                <th style={{fontSize:"small", fontWeight:"bold"}}>Received Labour</th>
                <th style={{fontSize:"small", fontWeight:"bold"}}>Received Labour Time</th>
                <th style={{fontSize:"small", fontWeight:"bold"}}>Remaining Labour</th>
                <th style={{fontSize:"small", fontWeight:"bold"}}>Remaining Labour Time</th>
              </tr>

              {UsersData?.map((item,index) => {
                  return (
                    <tr>
                      <td style={{fontSize:"small"}}className="leftcell">{index+1}</td>
                      <td style={{fontSize:"small"}}>{item?.laborCategory}</td>
                      <td style={{fontSize:"small"}}>{item?.laborSubCategory}</td>
                      <td style={{fontSize:"small"}}>{item?.laborLevel}</td>
                      <td style={{fontSize:"small"}}>{item?.requiredWorkers * parseInt(labourdata?.productionQuantity)}</td>
                      <td style={{fontSize:"small"}}>{item?.requiredDuration * parseInt(labourdata?.productionQuantity)}</td>
                      <td style={{fontSize:"small"}}>{item?.requiredWorkers * parseInt(labourdata?.productionQuantity)}</td>
                      <td style={{fontSize:"small"}}>{item?.requiredDuration * parseInt(labourdata?.productionQuantity)}</td>
                      <td style={{fontSize:"small"}}>0</td>
                      <td style={{fontSize:"small"}} className="rightcell">0</td>
                    </tr>
                  )
                })}

            </table>
        </div>

   </div>

        <br />
        <div className="footer">
          <button onClick={() => close(false)}><KeyboardBackspace />Back</button>
        </div>
      </div>
    </div>
    </>
  );
};

export default WoInProgressViewLabours;