const ROUTE_URLS = {
  HOME_ROUTE_URL: "/",
  LOGIN_ROUTE_URL: "/login",
  MAIN_APP_ROUTE_URL: "/app",
  HOMEDASHBOARD_ROUTE_URL: "/app/dashboard",
  SUBSCRIPTION_ROUTE_URL: "/app/subscription",
  SUPER_ADMIN_DASHBOARD_ROUTE_URL: "/app/Super Admin",
  OPERATIONS_DASHBOARD_ROUTE_URL: "/app/Operations",
  AREA_SALES_DASHBOARD_ROUTE_URL: "/app/Area Sales",
  SUB_ADMIN_DASHBOARD_ROUTE_URL: "/app/Sub Admin",
  PROCUREMENT_DASHBOARD_ROUTE_URL: "/app/Procurement",
  USER_ROUTE_URL: "/app/user",
  MANAGE_USER: "/app/user/manageUser",
  SUB_ADMIN_USER_ROUTE_URL: "/app/users",
  ROLES_MANAGEMENT_ROUTE_URL: "/app/rolesManagement",
  MASTER_CONFIGURATION_ROUTE_URL: "/app/masterConfiguration",
  ORGANIZATION_ROUTE_URL: "/app/organization",
  APP_WELCOME_ROUTE_URL: "/app/welcome",
  PAGE_404_ROUTE_URL: "/404",
  SETTINGS_TABS_ROUTE_URL: "/homeSettings",
  VEHICLES_ROUTE_URL: "/app/vehicles",
  VENDORS_ROUTE_URL: "/app/vendors",
  COMPONENTS_ROUTE_URL: "/app/components",
  MAPPING_ROUTE_URL: "/app/mapping",
  ASSEMBLY_ROUTE_URL: "/app/assembly",
  BOM_ROUTE_URL: "/app/bom",
  BOM_COSTING_ROUTE_URL: "/app/bomCosting",
  ORDERS_ROUTE_URL: "/app/orders",
  WORKORDER_ROUTE_URL: "/app/work-order",
  INVENTORY_ROUTE_URL: "/app/prodInventory",
  LABOUR_ROUTE_URL: "/app/labour",
  INVENTORY_DASHBOARD_ROUTE_URL: "/app/Inventory",
  INWARD_ROUTE_URL: "/app/inward",
  INVENTORY_INV_ROUTE_URL: "/app/inventory-inv",
  SHOPFLOOR_DASHBOARD_ROUTE_URL: "/app/Shop Floor",
  WO_SHOPFLOOR_ROUTE_URL: "/app/wo-shopfloor",
  INVENTORY_SHOPFLOOR_ROUTE_URL: "/app/inventory-shopfloor",
  MANAGEMENT_DASHBOARD_ROUTE_URL: "/app/Management",
  APPROVAL_ROUTE_URL: "/app/approval",
  STOCK_MANAGEMENT_ROUTE_URL: "/app/stock-management",
  OUTWARD_ROUTE_URL: "/app/outward",
  QUALITY_ROUTE_URL: "/app/quality",
  QC_DASHBOARD: "/app/Quality Control",
  WORKORDER_QC_ROUTE_URL: "/app/workorder-qc",
  PRICING_ROUTE_URL: "/app/pricing",
  INVENTORY_MANAGEMENT_ROUTE_URL: "/app/inventory-management",
  PO_REQUEST_ROUTE_URL: "/app/porequest",
  SALE_REQUEST_ROUTE_URL: "/app/salerequest",
  SALES_ROUTE_URL: "/app/sales",
  BUSINESS_INSIGHTS_ROUTE_URL: "/app/business-insights",
  FINANCE_INVENTORY_ROUTE_URL: "/app/financeInventory",
  FINANCE_RETURNS_ROUTE_URL: "/app/financeReturns",
  FINANCE_DASHBOARD_ROUTE_URL: "/app/Finance",
  PRODUCTION_DASHBOARD_ROUTE_URL: "/app/Production",
  DEALER_DASHBOARD_USER_ROUTE_URL: "/app/Dealer",
  BUSINESS_USER_ROUTE_URL: "/app/dealer/businessUser",
  VEHICLES_SPARES_ROUTE_URL: "/app/dealer/vehicleUser",
  REQUISITION_ROUTE_URL: "/app/dealer/requisition",
  SHOWROOMS_ROUTE_URL: "/app/dealer/showrooms",
  SHOPFLOOR_DASHBOARD_ROUTE_URL: "/app/shop Floor",
  INVOICE_PAYMEMT_ROUTE_URL: "/app/dealer/invoice_payment",
  MANAGEMENT_DASHBOARD_ROUTE_URL: "/app/Management",
  SERVICE_CENTER_DASHBOARD_ROUTE_URL: "/app/Service Center",
  SERVICE_CENTER_USER_ROUTE_URL: "/app/serviceCenter/users",
  SERVICE_CENTER_SPARES_ROUTE_URL: "/app/serviceCenter/spares",
  SERVICE_CENTER_ROUTE_URL: "/app/dealer/serviceCenter",
  SERVICE_CENTER_ORDERS_ROUTE_URL: "/app/serviceCenter/Orders",
  SERVICE_CENTER_INVENTORY_ROUTE_URL: "/app/serviceCenter/Inventory",
  SERVICE_CENTER_JOBS_ROUTE_URL: "/app/serviceCenter/Jobs",
  SERVICE_CENTER_RETURNS_ROUTE_URL: "/app/serviceCenter/returns",
  SHOWROOM_ROUTE_URL: "/app/Showroom",
  SHOWROOM_VEHICLE_BATTERIES_ROUTE_URL: "/app/showroom/Vehicle&Batteries",
  SHOWROOM_ORDERS_ROUTE_URL: "/app/showroom/Orders",
  SHOWROOM_RETURNS_ROUTE_URL: "/app/showroom/Returns",
  SHOWROOM_INVENTORY_ROUTE_URL: "/app/showroom/inventory",
  SHOWROOM_CUSTOMER_SALES_ROUTE_URL: "/app/showroom/customer&sales",
  SHOWROOM_VEHICLE_WARRANTY:"/app/showroom/vehicleInventory",
  STORE_MANAGER_HOME_ROUTE_URL: "/app/Store Manager",
  STORE_MANAGER_SPARES_ROUTE_URL: "/app/storeManager/spares",
  STORE_MANAGER_ORDER_ROUTE_URL: "/app/storeManager/orders",
  STORE_MANAGER_INVENTORY_ROUTE_URL: "/app/storeManager/inventory",
  STORE_MANAGER_SPARE_REQUEST_ROUTE_URL: "/app/storeManager/spareRequest",
  STORE_MANAGER_RETURNS_ROUTE_URL: "/app/storeManager/returns",
  SYSTEM_ADMIN_HOME_ROUTE_URL: "/app/System Admin",
  SYSTEM_ADMIN_SUBSCRIPTIONS_ROUTE_URL: "/app/systemAdmin/subscription",
  SYSTEM_ADMIN_BUSINESS_ENTITY_ROUTE_URL: "/app/systemAdmin/businessEntities",
  SYSTEM_ADMIN_INVOICE_AND_PAYMENT_ROUTE_URL: "/app/systemAdmin/invoiceAndPayment",
  SYSTEM_ADMIN_CONFIGURATIONS: "/app/systemAdmin/configurations",
  OPERATIONS_VEHICLE_AND_SPARES_URL: "/app/VehicleandSpares",
  OPERATIONS_BUSINESS_INVENTORY_AND_SALES_URL: "/app/Business Inventory & Sales",
  // AREA_SALES_HOME:"/app/home",
  AREA_VEHICLE_SPARES_URL: "/app/VehicleAndSpare",
  // AREA_SALES_OR_URL:"/app/Area Sales Order",
  AREA_SALES_ORDERS_ROUTE_URL: "/app/AreaSalesOrders",
  AREA_SALES_RETURNS_URL: "/app/Returns",
  AREA_SALES_BUSINESS_INVENTOR_ROUTE_URL: "/app/areaSalesBusinessInventory",
  AREA_SALES_BUSINESS_SALES_ROUTE_URL: "/app/areaSalesBusinessSales",
  // VEHICLE_AND_SPARES:"/app/VehicleAndSpare",
  OPERATIONS_SETTING_ROUTE_URL: "/app/OperationsSettings",
  OPERATIONS_SALES_USER: "/app/OperationUser",
  OPERATIONS_ZONE_MANAGEMENT_ROUTE_URL: "/app/zoneManagement",
  OPERATION_BUSINESS_ENTITIES: "/app/businessEntities",
  OERATION_WARRANTY_AND_SERVICES: "/app/warrantyAndServices",
  OPERATIONS_TEMPLATES:"/app/templates",
  SERVICE_ADVISOR_HOME_ROUTE_URL: "/app/Service Advisor",
  SERVICE_ADVISOR_SPARE_PARTS_URL: "/app/serviceAdvisor/SpareParts",
  SERVICE_ADVISOR_SERVICE_JOBS: "/app/serviceAdvisor/serviceJobs",
  SERVICE_ADVISOR_SPARE_RETURNS: "/app/serviceAdvisor/spareRequestsAndReturns",
  NOTIFICATION_VIEW_ROUTE_URL: "/app/notification/viewAll",
  DEALER_ONBOARD_FORM_ROUTE_URL: "/app/dealerOnboardForm/:dealerID",
  RESET_PASSWORD_ROUTE_URL: "/forgetpassword",
  DEALER_VEHICLE_WARRANTY:"/app/dealer/vehicleWarranty",
  ZONAL_SALES_HOME_ROUTE_URL: "/app/Zonal Sales",
  ZONAL_SALES_USER_ROUTE_URL: "/app/zonalSalesUser",
  ZONAL_SALES_VEHICLE_AND_SPARES_ROUTE_URL: "/app/zonalSalesVehicleAndSpares",
  ZONAL_SALES_ORDERS_ROUTE_URL: "/app/zonalSalesOrders",
  ZONAL_SALES_RETURNS_ROUTE_URL: "/app/zonalSalesReturns",
  ZONAL_SALES_BUSINESS_INVENTOR_ROUTE_URL: "/app/zonalSalesBusinessInventory",
  ZONAL_SALES_BUSINESS_SALES_ROUTE_URL: "/app/zonalSalesBusinessSales",

  NATIONAL_SALES_HOME_ROUTE_URL: "/app/National Sales",
  NATIONAL_SALES_USER_ROUTE_URL: "/app/nationalSalesUser",
  NATIONAL_SALES_VEHICLE_AND_SPARES_ROUTE_URL: "/app/nationalSalesVehicleAndSpares",
  NATIONAL_SALES_ORDERS_ROUTE_URL: "/app/nationalSalesOrders",
  NATIONAL_SALES_RETURNS_ROUTE_URL: "/app/nationalSalesReturns",
  NATIONAL_SALES_BUSINESS_INVENTOR_ROUTE_URL: "/app/nationalSalesBusinessInventory",
  NATIONAL_SALES_BUSINESS_SALES_ROUTE_URL: "/app/nationalSalesBusinessSales",
};

export const {
  LOGIN_ROUTE_URL,
  HOME_ROUTE_URL,
  HOMEDASHBOARD_ROUTE_URL,
  ORGANIZATION_ROUTE_URL,
  SUBSCRIPTION_ROUTE_URL,
  SUPER_ADMIN_DASHBOARD_ROUTE_URL,
  OPERATIONS_DASHBOARD_ROUTE_URL,
  AREA_SALES_DASHBOARD_ROUTE_URL,
  SUB_ADMIN_DASHBOARD_ROUTE_URL,
  PROCUREMENT_DASHBOARD_ROUTE_URL,
  SYSTEM_ADMIN_HOME_ROUTE_URL,
  SYSTEM_ADMIN_SUBSCRIPTIONS_ROUTE_URL,
  SYSTEM_ADMIN_BUSINESS_ENTITY_ROUTE_URL,
  SYSTEM_ADMIN_INVOICE_AND_PAYMENT_ROUTE_URL,
  SYSTEM_ADMIN_CONFIGURATIONS,
  USER_ROUTE_URL,
  MANAGE_USER,
  SUB_ADMIN_USER_ROUTE_URL,
  ROLES_MANAGEMENT_ROUTE_URL,
  MASTER_CONFIGURATION_ROUTE_URL,
  MANAGEMENT_DASHBOARD_ROUTE_URL,
  MAIN_APP_ROUTE_URL,
  PAGE_404_ROUTE_URL,
  VEHICLES_ROUTE_URL,
  VENDORS_ROUTE_URL,
  COMPONENTS_ROUTE_URL,
  MAPPING_ROUTE_URL,
  ASSEMBLY_ROUTE_URL,
  BOM_ROUTE_URL,
  BOM_COSTING_ROUTE_URL,
  ORDERS_ROUTE_URL,
  WORKORDER_ROUTE_URL,
  INVENTORY_ROUTE_URL,
  LABOUR_ROUTE_URL,
  INVENTORY_DASHBOARD_ROUTE_URL,
  INWARD_ROUTE_URL,
  INVENTORY_INV_ROUTE_URL,
  SHOPFLOOR_DASHBOARD_ROUTE_URL,
  WO_SHOPFLOOR_ROUTE_URL,
  INVENTORY_SHOPFLOOR_ROUTE_URL,
  // MANAGEMENT_DASHBOARD_ROUTE_URL,
  APPROVAL_ROUTE_URL,
  STOCK_MANAGEMENT_ROUTE_URL,
  // SHOPFLOOR_DASHBOARD_ROUTE_URL,
  OUTWARD_ROUTE_URL,
  QUALITY_ROUTE_URL,
  WORKORDER_QC_ROUTE_URL,
  PRICING_ROUTE_URL,
  INVENTORY_MANAGEMENT_ROUTE_URL,
  // INVENTORY_DASHBOARD_ROUTE_URL,
  PO_REQUEST_ROUTE_URL,
  SALE_REQUEST_ROUTE_URL,
  SALES_ROUTE_URL,
  BUSINESS_INSIGHTS_ROUTE_URL,
  FINANCE_INVENTORY_ROUTE_URL,
  QC_DASHBOARD,
  FINANCE_RETURNS_ROUTE_URL,
  FINANCE_DASHBOARD_ROUTE_URL,
  PRODUCTION_DASHBOARD_ROUTE_URL,
  DEALER_DASHBOARD_USER_ROUTE_URL,
  BUSINESS_USER_ROUTE_URL,
  VEHICLES_SPARES_ROUTE_URL,
  REQUISITION_ROUTE_URL,
  SHOWROOMS_ROUTE_URL,
  INVOICE_PAYMEMT_ROUTE_URL,
  // SERVICE_CENTER_JOBS_ROUTE_URL,
  SERVICE_CENTER_DASHBOARD_ROUTE_URL,
  SERVICE_CENTER_SPARES_ROUTE_URL,
  SERVICE_CENTER_ROUTE_URL,
  SERVICE_CENTER_USER_ROUTE_URL,
  SERVICE_CENTER_ORDERS_ROUTE_URL,
  SERVICE_CENTER_INVENTORY_ROUTE_URL,
  SERVICE_CENTER_JOBS_ROUTE_URL,
  SERVICE_CENTER_RETURNS_ROUTE_URL,
  SHOWROOM_ROUTE_URL,
  SHOWROOM_VEHICLE_BATTERIES_ROUTE_URL,
  SHOWROOM_ORDERS_ROUTE_URL,
  SHOWROOM_RETURNS_ROUTE_URL,
  SHOWROOM_INVENTORY_ROUTE_URL,
  SHOWROOM_CUSTOMER_SALES_ROUTE_URL,
  SHOWROOM_VEHICLE_WARRANTY,
  STORE_MANAGER_HOME_ROUTE_URL,
  STORE_MANAGER_SPARES_ROUTE_URL,
  STORE_MANAGER_ORDER_ROUTE_URL,
  STORE_MANAGER_INVENTORY_ROUTE_URL,
  STORE_MANAGER_SPARE_REQUEST_ROUTE_URL,
  STORE_MANAGER_RETURNS_ROUTE_URL,
  OPERATIONS_VEHICLE_AND_SPARES_URL,
  OPERATIONS_BUSINESS_INVENTORY_AND_SALES_URL,
  AREA_SALES_HOME,
  AREA_VEHICLE_SPARES_URL,
  // AREA_SALES_OR_URL,
  AREA_SALES_ORDERS_ROUTE_URL,
  AREA_SALES_RETURNS_URL,
  AREA_SALES_BUSINESS_INVENTOR_ROUTE_URL,
  AREA_SALES_BUSINESS_SALES_ROUTE_URL,
  VEHICLE_AND_SPARES,
  OPERATIONS_ZONE_MANAGEMENT_ROUTE_URL,
  OPERATIONS_SETTING_ROUTE_URL,
  OPERATIONS_SALES_USER,
  OPERATION_BUSINESS_ENTITIES,
  OERATION_WARRANTY_AND_SERVICES,
  OPERATIONS_TEMPLATES,
  SERVICE_ADVISOR_HOME_ROUTE_URL,
  SERVICE_ADVISOR_SPARE_PARTS_URL,
  SERVICE_ADVISOR_SPARE_RETURNS,
  SERVICE_ADVISOR_SPARE_RETURNS_URL,
  SERVICE_ADVISOR_SERVICE_JOBS,
  NOTIFICATION_VIEW_ROUTE_URL,
  DEALER_ONBOARD_FORM_ROUTE_URL,
  RESET_PASSWORD_ROUTE_URL,
  DEALER_VEHICLE_WARRANTY,
  ZONAL_SALES_HOME_ROUTE_URL,
  ZONAL_SALES_USER_ROUTE_URL,
  ZONAL_SALES_VEHICLE_AND_SPARES_ROUTE_URL,
  ZONAL_SALES_ORDERS_ROUTE_URL,
  ZONAL_SALES_RETURNS_ROUTE_URL,
  ZONAL_SALES_BUSINESS_INVENTOR_ROUTE_URL,
  ZONAL_SALES_BUSINESS_SALES_ROUTE_URL,
  NATIONAL_SALES_HOME_ROUTE_URL,
  NATIONAL_SALES_USER_ROUTE_URL,
  NATIONAL_SALES_VEHICLE_AND_SPARES_ROUTE_URL,
  NATIONAL_SALES_ORDERS_ROUTE_URL,
  NATIONAL_SALES_RETURNS_ROUTE_URL,
  NATIONAL_SALES_BUSINESS_INVENTOR_ROUTE_URL,
  NATIONAL_SALES_BUSINESS_SALES_ROUTE_URL
} = ROUTE_URLS;

export default ROUTE_URLS;
