import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import Table from "react-bootstrap/Table";

const ViewCustomerDetails = ({ rowData, closeModal }) => {
  console.log("rowData",rowData)
  return (
    <div>
      <div className="container1">
        <div className="modalContainer" style={{ height: "550px" }}>
          <div className="header">
            <h3>Customer Details</h3>
          </div>

          <div>
            <Table>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "#ebebeb" }}>Name</td>
                  <td>{rowData?.customerName ? (rowData?.customerName) : "N/A"}</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "#ebebeb" }}>Customer Email</td>
                  <td>{rowData?.customerEmail ? (rowData?.customerEmail) : "N/A"}</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "#ebebeb" }}>Mobile Number</td>
                  <td>{rowData?.customerPhone ? (rowData?.customerPhone) : "N/A"}</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "#ebebeb" }}>Address</td>
                  <td>{rowData?.customerAddress ? (rowData?.customerAddress) : "N/A"}</td>
                </tr>
                {/* <tr>
                  <td style={{ backgroundColor: "#ebebeb" }}>Address</td>
                  <td>{rowData.customerDetails.address1}</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "#ebebeb" }}>Country</td>
                  <td>{rowData.customerDetails.country}</td>
                </tr><tr>
                  <td style={{ backgroundColor: "#ebebeb" }}>State</td>
                  <td>{rowData.customerDetails.state}</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "#ebebeb" }}>City</td>
                  <td>{rowData.customerDetails.city}</td>
                </tr> 
                <tr>
                  <td style={{ backgroundColor: "#ebebeb" }}>Zip code</td>
                  <td>{rowData.customerDetails.zipCode}</td>
                </tr>
                */}
              </tbody>
            </Table>
          </div>

          <div className="body" style={{ display: 'flex', flexDirection: "row", flexWrap: "nowrap" }}>
            <ul type="none" className="viewspeclist" style={{ paddingLeft: "0px", textAlign: "left", fontSize: "14px" }}>
            </ul>
          </div>
          <div className="footer">
            <button onClick={() => closeModal(false)}><KeyboardBackspace /> Back</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCustomerDetails;
