import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import "../../work-order/workorder.scss";

const WoViewAssembly = ({ close, assemblydata }) => {

  const [viewSpecs, setViewSpecs] = useState();

  //   const totalPrice = () => {
  //     let total = 0;
  //     data.map(item => (
  //         total += ((item?.unitPrice * item?.releasedQty + item?.taxAmount))
  //     ))
  //     return total;
  // }

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "1205px", height: "465px", padding: "15px" }}
        >

          <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Assembly Services</h3>
            {/* <div>
              <button
                className="action-btn"
                style={{ color: "blue" }}>
                Print
              </button>
              <button
                className="action-btn"
                style={{ color: "blue" }}>
                Export
              </button>
            </div> */}
          </div>

          {/* <div className="body" style={{height:"340px"}}>  */}
          {/* <div style={{width:"100%",overflow:"auto",height:"325px"}}> */}


          <div className="shopfloortbl" style={{ width: "100%" }}>
            <table className="sfaction_tbl" style={{ width: "100%" }} >
              <thead>
                <tr>
                  <th width="5%">S.No.</th>
                  <th>Assembly Type</th>
                  <th>Assembly Name</th>
                  <th>Required Services</th>
                  <th style={{ width: "7%" }}>BOM Qty</th>
                  <th style={{ width: "10%" }}>BOM Duration</th>
                  <th>Avg Service/day</th>
                  <th>Avg Duration/day</th>
                  <th>Avg Production/day</th>
                </tr>
              </thead>
              {/* <hr className='line'/> */}
              <tbody>
                {assemblydata?.bomAssembly?.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td style={{ position: "relative", paddingBottom: "8%" }}>{item?.assemblyType}</td>
                      <td >{item?.assemblyName}</td>
                      <td>
                        {item?.assemblyServices?.map((item) => {
                          return (
                            <ul type="none" style={{ margin: "0", padding: "0" }}>
                              <li>{item?.serviceName}</li>
                            </ul>
                          )
                        })}
                      </td>
                      <td>
                        {item?.assemblyServices?.map((item) => {
                          return (
                            <ul type="none" style={{ margin: "0", padding: "0" }}>
                              <li>1</li>
                            </ul>
                          )
                        })}
                      </td>
                      <td>
                        {item?.assemblyServices?.map((item) => {
                          return (
                            <ul type="none" style={{ margin: "0", padding: "0" }}>
                              <li>{item?.requiredDuration}</li>
                            </ul>
                          )
                        })}
                      </td>
                      <td>
                        <ul type="none" style={{ margin: "0", padding: "0" }}>
                         <li>0</li>
                        </ul>
                      </td>
                      <td>
                        <ul type="none" style={{ margin: "0", padding: "0" }}>
                        <li>0</li>
                        </ul>
                      </td>
                      <td>
                      <ul type="none" style={{ margin: "0", padding: "0" }}>
                        <li>0</li>
                        </ul>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          {/* </div> */}
          {/* </div> */}

          <br />
          <div className="footer">
            <button onClick={() => close(false)}><KeyboardBackspace /> Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WoViewAssembly;