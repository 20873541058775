import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AppConstants from "../../utils/app-constants";
import RequisitionActions from "./RequisitionActions";
import RequisitionDetails from "./RequisitionDetails";
import { errorNotification } from "../../utils/notifications";

const { ExportCSVButton } = CSVExport;

const RequisitionProgress = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [viewSpecs, setViewSpecs] = useState();
  const [reqsData, setReqsData] = useState([]);
  const [open, setOpen] = useState(false);


  const [row, setRow] = useState();

  const fetchRequisition = async () => {
    const resp = await trackPromise(
      API.get(
        `showroom-module/api/v1/showroomOrders/requisition/getAllRequisitions`
      )
    );
    console.log(resp);
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setReqsData(resp);
  };
  useEffect(() => {
    fetchRequisition();
  }, []);



  const uniquecomponentName = [...new Set(reqsData.map((item) => item.componentName))]
  const uniqueskuCode = [...new Set(reqsData.map((item) => item.skuCode))]
  const uniquevendorPartName = [...new Set(reqsData.map((item) => item.vendorPartName))]
  const uniqueverifiedBy = [...new Set(reqsData.map((item) => item.verifiedBy))]




  // const fetchReport = async () => {
  //   const resp = await trackPromise(API.get(`http://localhost:3004/api/v1/BOM/all`));
  //   console.log(resp);
  //   setUsersData(resp);
  // };
  // useEffect(() => {
  //   fetchReport();
  // }, []);

  const specsHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    setViewSpecs(true);
  };

  const actionHandler = (rowIndex) => {
    setActionShow(rowIndex);
    setOpen(true);
    on();
  };

  const specsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => specsHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          View Details
        </button>
        {actionShow === rowIndex && viewSpecs ? (
          <RequisitionDetails
            rowData={row}
            status={true}
            closeModal={setViewSpecs}
          />
        ) : null}
      </div>
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow == rowIndex && open ? (
          <RequisitionActions rowData={row} />
        ) : null}
      </div>
    );
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    return cell == AppConstants.NEW_REQUEST || cell == "New Request" ? (
      <button className="reactive-btn" style={{ width: "100%" }}>
        <span className="reactive-btn-font">New Request</span>
      </button>
    ) : cell == AppConstants.ONHOLD ? (
      <button className="deactive-btn" style={{ width: "100%" }}>
        {" "}
        <span className="deactive-btn-font">On Hold</span>
      </button>
    ) : cell == AppConstants.REJECTED || cell == "Requisition Rejected" ? (
      <button className="delete-btn" style={{ width: "100%" }}>
        {" "}
        <span className="delete-btn-font">Requisition Rejected</span>
      </button>
    ) : cell == AppConstants.PO_GENERATED ? (
      <button className="active-btn" style={{ width: "100%" }}>
        {" "}
        <span className="active-btn-font">PO Generated</span>
      </button>
    ) : (
      cell
    );
  };

  function dateFormatter(cell) {
    if (!cell) {
      return "-";
    }
    return `${moment(cell).format("DD-MM-YYYY/HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Requisition ID",
      dataField: "requisitionID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Requisition Date",
      dataField: "requisitionDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatter: dateFormatter,
    },
    {
      text: "Requisition Details",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatExtraData: { viewSpecs, actionShow },
      formatter: specsFormatter,
    },
    {
      text: "Showroom ID",
      dataField: "showroomID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Showroom Name",
      dataField: "showroomName",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Showroom Location",
      dataField: "showroomLocation",
      classes: "cellsOverflow",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "250px" },
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { open, actionShow },
      formatter: actionFormatter,
    },
  ];

  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    componentName: "",
    skuCode: "",
    vendorPartName: "",
    verifiedBy: "",
    createdDate: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = (e) => {
    setFormValue({
      componentName: "",
      skuCode: "",
      vendorPartName: "",
      verifiedBy: "",
      createdDate: "",
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Requisition Progress</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={reqsData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={fetchRequisition} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>

                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form
                    id="abc"
                    onSubmit={submitHandler}
                    className="RA_block"
                    style={{ height: "120px", justifyContent: "flex-start" }}
                  >
                    <div className="bomfilter_fields">
                      <select
                        name="componentName"
                        value={formValue.componentName}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Component Name
                        </option>
                        {
                          uniquecomponentName.map((item) => (
                            <option>{item}</option>
                          ))
                        }
                      </select>

                      <select
                        name="skuCode"
                        value={formValue.skuCode}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          SKU Code
                        </option>
                        {
                          uniqueskuCode.map((item) => (
                            <option>{item}</option>
                          ))
                        }
                      </select>

                      <select
                        name="vendorPartName"
                        value={formValue.vendorPartName}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Vendor Part Name
                        </option>
                        {
                          uniquevendorPartName.map((item) => (
                            <option>{item}</option>
                          ))
                        }
                      </select>

                      <select
                        name="verifiedBy"
                        value={formValue.verifiedBy}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Verified By
                        </option>
                        {
                          uniqueverifiedBy.map((item) => (
                            <option>{item}</option>
                          ))
                        }
                      </select>

                      <input
                        type="text"
                        onFocus={(e) => (e.currentTarget.type = "date")}
                        onBlur={(e) => (e.currentTarget.type = "text")}
                        name="createdDate"
                        value={formValue.createdDate}
                        onChange={handleChange}
                        placeholder="Inventory Date"
                        autoComplete="off"
                      />

                      <div style={{ margin: "10px 0 0 770px" }}>
                        <button
                          type="submit"
                          id="apply"
                          style={{ width: "96px", height: "35px" }}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ width: "96px", height: "35px" }}
                        >
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default RequisitionProgress;
