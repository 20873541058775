import React, { useState } from "react";

const ViewSpecs = ({close,rowData}) => {
  return (
    <div>
        <div className="container1">
          <div className="modalContainer" style={{height:"350px"}}>
            <div className="header">
              <h3> Component Specification</h3>
            </div>
            <div style={{display:'flex', flexDirection:'column' ,alignItems:'flex-start'}}>
              <h6>Component Name:{rowData.componentName}</h6>
              <h6>Component Sku code: {rowData.skuCode}</h6>
              </div>
            <div className="body" style={{display:'flex',flexDirection:"row",flexWrap:"nowrap"}}>
                <ul type="none" className="viewspeclist" style={{paddingLeft:"0px", textAlign:"left",fontSize:"14px"}}>
                {rowData.specifications.map((item) => {
                  return(
                    <li>{`Component ${item.specsType} : ${item.specsValue}${item.specsUnit}`}</li>
                   )
                  })}
                  <li>Listed items</li>
                </ul>
            </div>
            <div className="footer">
              <button onClick={()=> close(false)}>Back</button>
            </div>
          </div>
        </div>
    </div>
  );
};

export default ViewSpecs;
