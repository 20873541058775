import React, { useState } from "react";
import auth from "../../../../utils/auth";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import { successNotification,errorNotification } from "../../../../utils/notifications";

const DeleteComponent = ({updatedData,editData,closeModal }) => {
  const [formValue, setFormValue] = useState({
    DeleteReason: "",
    description: "",
  });

  const editDetails  = {
    status:"Deleted",
    pricingId:editData.pricingId,
    reason: formValue.DeleteReason,
    ...(formValue.reactivationReason == "Other" ? {description:formValue.description}:{description:""})
    };

const refresh = () => {
  updatedData()
};

const submitHandler = async () => {
  if(formValue.DeleteReason){

    const resp = await trackPromise(API.put(`finance/api/v1/pricing/managehsnsac/status`, editDetails));
    
    const { success,message} = resp;
    
    if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        refresh()
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
  }
  else {
   return errorNotification("Please select Delete Reason", { autoclose: 2000 }); 
  }
};


  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Delete Component</h3>
        </div>

        <div className="body">
          <label>
            <select
              name="DeleteReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option disabled selected hidden>
                Delete Reason
              </option>
              <option value="In-correct HSN/SAC Code">
              In-correct HSN/SAC Code
              </option>
              <option value="No longer required">
              No longer required
              </option>
              <option value="Vehicle Model/Variant discontinued">
              Vehicle Model/Variant discontinued
              </option>
              <option value="Other">Other</option>
            </select>
          </label>

          {formValue.DeleteReason === "Other" ? 
          <label className="lgInput">
            Description
            <input
              type="text"
              style={{ width: "508px" }}
              name="description"
              value={formValue.description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Delete Reason"
              autoComplete="off"
            />
          </label> : "" }

        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={()=>submitHandler()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteComponent;
