import React, { useState } from "react";
import {
  faEdit,
  faBan,
  faTrash,
  faDotCircle,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../components/modal/modal.scss";
import PlaceToFinance from "./Actions/PlaceToFinance";
import PutOnHold from "./Actions/PutOnHold";
import Reject from "./Actions/Reject";
import ReleasePlaceToFinance from "./Actions/ReleasePlacetoFinance";
import RequestForProcess from "./Actions/RequestForProcess";
import ViewUpdates from "./Actions/ViewUpdates";
import ViewDetails from "./Actions/ViewDetails";
import RequestApproval from "./Actions/RequestApproval";
import PlaceGoodReleaseRequest from "./Actions/PlaceGoodReleaseRequest";

const Action = ({ updatedData, rowData, setIsOpen , rowIndex }) => {
  const [showModal, setShowModal] = useState(true);
  const [activeModal, setActiveModal] = useState({});

  const clickHandler = (e) => {
    setActiveModal({
      placeToFinance: false,
      putOnHold: false,
      reject: false,
      releasePlaceToFinance: false,
      requestForProcess: false,
      viewUpdate: false,
      viewDetails: false,
      requestApproval: false,
      placeGoodReleaseRequest: false,
      [e.target.id]: true,
    });
    setShowModal(true);
  };

  return (
    <div>
      {rowData.status == "New Request" || rowData.status == "PO Released"
        ? activeModal.placeToFinance &&
        showModal && (
          <PlaceToFinance
            updatedData={updatedData}
            rowData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""}

      {rowData.status == "New Request" || rowData.status == "PO Released"
        ? activeModal.putOnHold &&
        showModal && (
          <PutOnHold
            updatedData={updatedData}
            rowData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""}

      {rowData.status == "New Request" || rowData.status == "PO Released"
        ? activeModal.reject &&
        showModal && (
          <Reject
            updatedData={updatedData}
            rowData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""}

      {rowData.status === "PO On Hold"
        ? activeModal.releasePlaceToFinance &&
        showModal && (
          <ReleasePlaceToFinance
            updatedData={updatedData}
            rowData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""}

      {rowData.status === "Pending At Finance"
        ? activeModal.requestForProcess &&
        showModal && (
          <RequestForProcess
            updatedData={updatedData}
            rowData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""}

      {rowData.status === "In-Progress At Finance"
        ? activeModal.viewUpdate &&
        showModal && (
          <ViewUpdates
            updatedData={updatedData}
            rowData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""}

      {rowData.status === "Held By Finance"
        ? activeModal.viewDetails &&
        showModal && (
          <ViewDetails
            updatedData={updatedData}
            rowData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""}

      {rowData.status === "Held By Finance"
        ? activeModal.requestApproval &&
        showModal && (
          <RequestApproval
            updatedData={updatedData}
            rowData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""}

      {rowData.status === "Approved By Finance"
        ? activeModal.placeGoodReleaseRequest &&
        showModal && (
          <PlaceGoodReleaseRequest
            updatedData={updatedData}
            rowData={rowData}
            closeModal={setShowModal}
          />
        )
        : ""}

      <div
        className="action"
        style={{ position: "relative", marginTop: rowIndex <= 4 ? "0px" : "-100px" }}
        onBlur={() => setIsOpen(false)}
      >
        <div
          className="action vendoraction"
          style={{ marginLeft: "-50px", marginTop: "0px", width: "200px" }}
        >
          {rowData.status === "New Request" ? (
            <div>
              {" "}
              <span id="placeToFinance" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Place to Finance
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status === "New Request" ? (
            <div>
              <span id="putOnHold" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Put On Hold
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status === "New Request" ? (
            <div>
              <span id="reject" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Reject
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status === "PO On Hold" ? (
            <div>
              <span id="releasePlaceToFinance" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Release & Place to Finance
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status === "Pending At Finance" ? (
            <div>
              <span id="requestForProcess" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Request For Process
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status === "In-Progress At Finance" ? (
            <div>
              <span id="viewUpdate" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> View Update
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status === "Held By Finance" ? (
            <div>
              <span id="viewDetails" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> View Details
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status === "Held By Finance" ? (
            <div>
              <span id="requestApproval" onClick={(e) => clickHandler(e)}>
                <FontAwesomeIcon icon={faEdit} /> Request Approval
              </span>
            </div>
          ) : (
            ""
          )}

          {rowData.status === "Approved By Finance" ? (
            <div>
              <span
                id="placeGoodReleaseRequest"
                onClick={(e) => clickHandler(e)}
              >
                <FontAwesomeIcon icon={faEdit} /> Place Good Release Request
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Action;
