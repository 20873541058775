import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import SearchBox from "../../../components/search/SearchBox";
import SparePartInventoryDetails from "../Actions/SparePartInventoryDetails";
import SpareWarrantyDetails from "../Actions/SpareWarrantyDetails";
import ViewSpecs from "../Actions/ViewSpecs";
const { ExportCSVButton } = CSVExport;

const Spares = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [viewSpecs, setViewSpecs] = useState();
  const [warrantyShow, setWarrantyShow] = useState();
  const [viewWarranty, setViewWarranty] = useState();
  const [viewData, setViewData] = useState();
  const [viewAvailablQty, setViewAvailablQty] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState();

  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    skuCode: "",
    sparePartName: "",
    category: "",
    subCategory: "",
  });

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `zonalSales-module/api/v1/vehicleAndSpares/spares?skuCode=${formValue.skuCode}&&sparePartName=${formValue.sparePartName}&&category=${formValue.category}&&subCategory=${formValue.subCategory}`
      )
    );
    console.log(resp);
    setUsersData(resp);
  };

  useEffect(() => {
    fetchReport();
  }, []);




  const uniquesparePartname = [...new Set(UsersData.map((item) => item.sparePartName))];
  const uniqueskuCode = [...new Set(UsersData.map((item) => item.skuCode))];
  const uniquecategory = [...new Set(UsersData.map((item) => item.category))];
  const uniquesubCategory = [...new Set(UsersData.map((item) => item.subCategory))];





  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async (e) => {
    setFormValue({
      skuCode: "",
      sparePartName: "",
      category: "",
      subCategory: "",
    });

    const resp = await trackPromise(
      API.get(`zonalSales-module/api/v1/vehicleAndSpares/spares`)
    );
    setUsersData(resp);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const specsHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    setViewSpecs(true);
  };

  const specsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => specsHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          View Details
        </button>
        {actionShow === rowIndex && viewSpecs ? (
          <ViewSpecs rowData={row} status={true} close={setViewSpecs} />
        ) : null}
      </div>
    );
  };

  const warrantyHandler = (row, rowIndex) => {
    setWarrantyShow(rowIndex);
    setRow(row);
    setViewWarranty(true);
  };

  const warrantyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => warrantyHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          View Details
        </button>
        {warrantyShow === rowIndex && viewWarranty ? (
          <SpareWarrantyDetails
            rowData={row}
            status={true}
            closeModal={setViewWarranty}
          />
        ) : null}
      </div>
    );
  };

  const availableQtyHandler = (row, rowIndex) => {
    setViewData(rowIndex);
    setRow(row);
    setViewAvailablQty(true);
  };

  const availableQtyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => availableQtyHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          {row?.availableQty?.length}
        </button>
        {viewData === rowIndex && viewAvailablQty ? (
          <SparePartInventoryDetails
            rowData={row}
            rowIndex={rowIndex}
            status={true}
            closeModal={setViewAvailablQty}
          />
        ) : null}
      </div>
    );
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Spare Part Name",
      dataField: "sparePartName",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "SKU Code",
      dataField: "skuCode",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Category",
      dataField: "category",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Sub-Category",
      dataField: "subCategory",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Specification",
      dataField: "specification",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatExtraData: { viewSpecs, actionShow },
      formatter: specsFormatter,
    },
    {
      text: "Dealer Price",
      dataField: "priceToserviceCenter",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Dealer Margin",
      dataField: "serviceCenterMargin",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Part MRP",
      dataField: "sparePartMrp",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Available Qty",
      dataField: "availableQty",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatExtraData: { viewAvailablQty, viewData },
      formatter: availableQtyFormatter,
    },
    {
      text: "Warranty",
      dataField: "dealerPrice",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "250px" },
      formatExtraData: { viewWarranty, warrantyShow },
      formatter: warrantyFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Spares</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "manageUsers")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <div
                    className="UHinputfields"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                      height: "auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          SKU Code
                        </label>
                        <select
                          name="skuCode"
                          value={formValue.skuCode}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            SKU Code
                          </option>
                          {
                            uniqueskuCode.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Spare Part Name
                        </label>
                        <select
                          name="sparePartName"
                          value={formValue.sparePartName}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Spare Part Name
                          </option>
                          {
                            uniquesparePartname.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Category
                        </label>
                        <select
                          name="category"
                          value={formValue.category}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Category
                          </option>
                          {
                            uniquecategory.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "1%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Sub Category
                        </label>
                        <select
                          name="subCategory"
                          value={formValue.subCategory}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Sub Category
                          </option>
                          {
                            uniquesubCategory.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>

                      <div
                        style={{
                          justifyContent: "end",
                          width: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <button
                          id="apply"
                          style={{ marginTop: "22px", marginLeft: "10px" }}
                          onClick={() => fetchReport()}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ marginTop: "22px", width: "auto" }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default Spares;
