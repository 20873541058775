import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../components/modal/modal.scss";
import moment from "moment";
import "../../pages/vendors/Vendors.scss";
import AppConstants from "../../utils/app-constants";
// import SearchBox from "./SearchBox/SearchBox";
import handleExport from "../../utils/exportExcel";
import { useHistory } from "react-router-dom";
import ReturnRequestAction from "./returnRequestAction/returnRequestAction";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ReturnRequest = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState();
  const [filterData, setFilterData] = useState();
  let history = useHistory();

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `serviceCenter-module/api/v1/returns/RPIreturns?requestID=${formValue.requestID}&status=${formValue.status}&email=${formValue.email}`
      )
    );
    console.log(resp);
    let filteredUserData = resp.filter(
      (item) => item.status != "Deleted" && item.role != "Super Admin"
    );
    console.log(filteredUserData);
    setUsersData(filteredUserData);
  };
  useEffect(() => {
    fetchReport();
  }, []);



  const uniquerequestID  = [...new Set(UsersData?.map((item)=>item.requestID))]
  const uniquestatus  = [...new Set(UsersData?.map((item)=>item.status))]



  const statusFormatter = (cell, row, rowIndex) => {
    return cell == "Returns Settled" ? (
      <button className="active-btn" style={{width:"100%"}}>
        <span className="active-btn-font">Returns Settled</span>
      </button>
    ) : cell == "Pending at Sales" ? (
      <button className="deactive-btn" style={{width:"100%"}}>
        <span className="deactive-btn-font">Pending at Sales</span>
      </button>
    ) : cell == "In-Progress" ? (
      <button className="reactive-btn" style={{width:"100%"}}>
        <span className="reactive-btn-font">In-Progress</span>
      </button>
    ) : cell == "On-Hold" ? (
      <button className="delete-btn" style={{width:"100%"}}>
        <span className="delete-btn-font">On-Hold</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.status === AppConstants.DELETED ? (
      ""
    ) : (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && isOpen ? (
          <ReturnRequestAction rowData={row} setIsOpen={setIsOpen} rowIndex={rowIndex} />
        ) : null}
      </div>
    );
  };

  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    requestID: "",
    requestDate: "",
    storeManagerName: "",
    storeManagerEmail: "",
    status: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async (e) => {
    setFormValue({
      requestID: "",
      requestDate: "",
      storeManagerName: "",
      storeManagerEmail: "",
      status: "",
    });
    // const resp = await trackPromise(
    //     API.get(
    //         `admin-module/api/v1/users`
    //     )
    // );
    // setUsersData(resp);
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requestID",
      headerAttrs: { width: "170px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Request Date",
      dataField: "rpiDate",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "Store Manager Name",
      dataField: "storeManagerName",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Store Manager Email",
      dataField: "storeManagerEmail",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Returned Spares Qty",
      dataField: "returnedData",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex) => {
        return row.returnedData.length;
      },
    },
    {
      text: "Returned Units Qty",
      dataField: "returnedUnits",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex) => {
        return row.returnedUnits;
      },
    },
    {
      text: "Return Value",
      dataField: "returnValue",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: statusFormatter,
    },
    {
      text: "RPI No.",
      dataField: "rpiNo",
      headerAttrs: { width: "170px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "60px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Return Requests</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "manageUsers")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <div
                    className="UHinputfields"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "85px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Request ID
                      </label>
                      <select
                        name="requestID"
                        value={formValue.requestID}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected>
                          Request ID
                        </option>
                        {
                          uniquerequestID.map((item)=>(
                            <option>{item}</option>
                          ))
                        }
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Request Date
                      </label>
                      <input
                        type="date"
                        name="requestDate"
                        value={formValue.requestDate}
                        onChange={handleChange}
                        placeholder="Enter Date"
                        autoComplete="off"
                        style={{ marginTop: "-5px", width: "100%" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Store Manager Name
                      </label>
                      <input
                        type="text"
                        name="storeManagerName"
                        value={formValue.storeManagerName}
                        onChange={handleChange}
                        placeholder="Enter Name"
                        autoComplete="off"
                        style={{ marginTop: "-5px", width: "100%" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Store Manager Email
                      </label>
                      <input
                        type="text"
                        name="storeManagerEmail"
                        value={formValue.storeManagerEmail}
                        onChange={handleChange}
                        placeholder="Enter Email"
                        autoComplete="off"
                        style={{ marginTop: "-5px", width: "100%" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Status
                      </label>
                      <select
                        name="status"
                        value={formValue.status}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected>
                          Status
                        </option>
                        {
                          uniquestatus.map((item)=>(
                            <option>{item}</option>
                          ))
                        }
                      </select>
                    </div>
                    <button
                      onClick={""}
                      id="apply"
                      style={{ marginTop: "22px", marginLeft: "10px" }}
                    >
                      Apply
                    </button>
                    <button
                      onClick={clearFilter}
                      id="clear"
                      style={{ marginTop: "22px" }}
                    >
                      Clear Filter
                    </button>
                  </div>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ReturnRequest;
