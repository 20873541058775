import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { KeyboardBackspace } from "@material-ui/icons";
import "../../work-order/workorder.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "../BomCosting.scss";

const ViewDetailsLabour = ({ close, rowdata }) => {
    const [UsersData, setUsersData] = useState([]);

    // const fetchReport = async () => {
    //     let body = {
    //         "orderID": rowdata.orderID,
    //         "status": rowdata.status,
    //         "requiredItemsType": "components"
    //     }
    //     const resp = await trackPromise(API.post(`shop-floor-module/api/v1/shopFloor/getRequirementItemType`, body));
    //     console.log(resp);
    //     setUsersData(resp);
    // };
    // useEffect(() => {
    //     fetchReport();
    // }, []);

    let compData = [{
        "_id": { "$oid": "6229b056664f049747d14b7d" },
        "laborCategory": "Unskilled labor",
        "laborSubCategory": "Loading",
        "laborLevel": "Fresher",
        "laborCost": "200",
        "laborTime": "5 hrs",
        "reqLabor": "2"
    },
    {
        "_id": { "$oid": "6229b056664f049797d14b7d" },
        "laborCategory": "skilled labor",
        "laborSubCategory": "Unloading",
        "laborLevel": "Experienced",
        "laborCost": "100",
        "laborTime": "9 hrs",
        "reqLabor": "5"
    }];

    const [blankForm, setBlankForm] = useState(compData);

    return (
        <>
            <div className="container1">
                <div
                    className="modalContainer"
                    style={{ width: "1104px", height: "485px", padding: "15px" }}
                >
                    <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>Required Components</h3>
                        <div>
                            <button
                                className="action-btn"
                                style={{ color: "blue" }}>
                                Print
                            </button>
                            <button
                                className="action-btn"
                                style={{ color: "blue" }}>
                                Export
                            </button>
                        </div>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "16px",
                            fontWeight: "500",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Total Labour Count: { }</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Total Time: { }</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Total Cost: { }</span>
                        </div>
                    </div>
                    <div className="body" style={{ height: "300px" }}>
                        <div style={{ width: "100%", overflow: "auto", height: "285px" }}>
                            <table className="viewcomp_tbl" style={{ width: "100%" }} >
                                <thead>
                                    <tr>
                                        <th style={{ width: "6%" }}>S.No.</th>
                                        <th style={{ width: "15%" }}>Labor Category</th>
                                        <th style={{ width: "20%" }}>Labor Sub Category</th>
                                        <th style={{ width: "15%" }}>Labor Level</th>
                                        <th style={{ width: "10%" }}>Req. Count</th>
                                        <th style={{ width: "8%" }}>Labor Unit</th>
                                        <th style={{ width: "8%" }}>Cost</th>
                                        <th style={{ width: "15%" }}>Sub Total</th>
                                    </tr>
                                </thead>

                                {blankForm.map((item, index) => {
                                    return (
                                        <tr>
                                            <td style={{ width: "6%" }} className="leftcell">{index + 1}</td>
                                            <td style={{ width: "15%" }}>{item.laborCategory}</td>
                                            <td style={{ width: "15%" }}>{item.laborSubCategory}</td>
                                            <td style={{ width: "15%" }}>{item.laborLevel}</td>
                                            <td style={{ width: "10%" }}>{item.reqLabor}</td>
                                            <td style={{ width: "8%" }}>{item.laborTime}</td>
                                            <td style={{ width: "8%" }}>{item.laborCost}</td>
                                            <td style={{ width: "15%" }} className="rightcell">{(item.reqLabor * item.laborCost)}</td>
                                        </tr>
                                    )
                                })}
                            </table>
                        </div>
                    </div>

                    <br />
                        <div className="footer">
                            <button className="btn-footer" onClick={() => close(false)}><KeyboardBackspace style={{ fontSize: "25px" }} /> Back</button>
                        </div>
                </div>
            </div>
        </>
    );
};

export default ViewDetailsLabour;