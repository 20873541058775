import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import { successNotification, errorNotification } from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { IoMdRefresh } from "react-icons/io";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BusinessEntitiesDeactivate = ({ updatedData, editData, closeModal,entityID}) => {
  const [formValue, setFormValue] = useState({
    deactivatedReason: "",
    status: "",
  });

console.log("entityID= h============>")
console.log(editData.entityID)

  const editDetails = {
    entityID:entityID,
    businessEmailID: editData.businessEmailID,
    status: "Deactivated",
    deactivatedReason: formValue.deactivatedReason,
    reasonComment: formValue.description,
  };

  const refresh = () => {
    updatedData();
  };

  const notificationBody = {
    "target": "bellnotification",
    "receivers": {
        "type": "users",
        "to": ["164913412080887"]
    },
    "message": {
        "notification": {
            "title": "Deactivated",
            "body": "User 164913412080887 is Deactivated."
        }
    }
}

  const sendNotification = async() => {
    const resp = await trackPromise(API.post(`messages/api/v1/sendwebnotification`, notificationBody)
    );
  }

  const submitHandler = async () => {
    console.log(formValue);
    const resp = await trackPromise(
      API.put(`operations-module/api/v1/businessEntities/${editDetails.entityID}`, editDetails)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
      sendNotification();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };



  const submitOperationHandler = async () => {
    console.log(formValue);
    const resp = await trackPromise(
      API.put(`operations-module/api/v1/businessEntities/${editDetails.entityID}`, editDetails)
    );

    console.log(resp)
    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Deactivate User</h3>
        </div>

        <div className="body">
          <label
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label
              style={{ fontSize: "13px", fontWeight: "500", width: "83px" }}
            >
              Deactivation Reason<span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="deactivatedReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: "100%", marginTop: "-5px" }}
            >
              <option disabled selected hidden>
                Deactivation Reason
              </option>
              <option value="Abuse of Terms & Conditions">
              Abuse of Terms & Conditions
              </option>
              <option value="Misconduct">
              Misconduct
              </option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.deactivatedReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Deactivation Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={submitOperationHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessEntitiesDeactivate;
