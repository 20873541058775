import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import { successNotification,errorNotification } from "../../../../utils/notifications";
import "../../../../components/modal/modal.scss";

const Reject = ({ updatedData,editData, closeModal, componentID }) => {
    
    const editDetails = {
        "componentID": componentID,
        "vendorID": editData.vendorID
        };
    
    const refresh = () => {
      updatedData()
    };
    
    const submitHandler = async () => {
      const resp = await trackPromise(API.post(`procurement-module/api/v1/mappings/deleteMapping`, editDetails));
    
      const { success, message } = resp;
    
      if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        refresh()
      } else {
        errorNotification(message, { autoclose: 2000 });
      }
    };
    
    return (
        <div className='container1'>
            <div className="modalContainer" style={{
                display:'flex',
                justifyContent:'space-evenly',
                alignItems:'center',
                width:'400px', height:'237px'}}>
            <div>
                Are you sure you want to delete? 
            </div>

            <div className="footer">
                <button onClick={() => closeModal(false)}>Back</button>
                <button id='submit-button' onClick={submitHandler}>Confirm</button>
            </div>
        </div>
        </div>
    );
};

export default Reject;
