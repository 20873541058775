import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { successNotification, errorNotification } from "../../../utils/notifications"


const UpdateProgress = ({ rowData, item, closeModal, updatedData, extraData }) => {
  console.log(item)
  const [formValue, setFormValue] = useState({
    HoldReason: "",
    description: "",
    status: ""
  });
  const [passButton, setPassButton] = useState(false);
  const [failButton, setFailButton] = useState(false);

  console.log(formValue)

  const editDetails = {
    requestID: rowData.requestID,
    vehicleID: item.vehicleID,
    SNO: item?.SNO,
    ...(rowData.requestCategory == "Finished Goods" && { orderID: item.orderID }),
    ...(formValue.status === "Failed" && { reason: formValue.HoldReason }),
    ...(formValue.status === "Failed" && formValue.HoldReason == "Other" && { reasonDescription: formValue.description }),
    status: formValue.status
  };


  const refresh = () => {
    updatedData();
  };

  const submitHandler = async () => {
    console.log(editDetails);
    if (formValue.HoldReason == '' && formValue.status == 'Failed') {
      errorNotification('Please select failed reason', { autoClose: 3000 });
      return;
    }
    const resp = await trackPromise(API.post(`qc-module/api/v1/vehicle/inprogress/status`, editDetails));

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh()
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  const handleInputChange = (event) => {
    if (event.target.value === "Failed") {
      setPassButton(false)
      setFailButton(true)
      if (formValue.HoldReason === '') {
        errorNotification('Please select failed reason', { autoClose: 3000 });
      }
      else {
        const field = { ...formValue, status: event.target.value }
        setFormValue(field)
      }

    }
    else {
      setPassButton(true)
      setFailButton(false)
      const obj = {
        status: event.target.value
      }
      setFormValue(obj)
    }
  }

  return (
    <div className="container1">
      <div className="modalContainer" style={{ height: "400px", width: "700px" }}>
        <div className="header" style={{ display: "flex", justifyContent: "flex-start" }}>
          <h3 style={{ marginLeft: "10px", marginTop: "15px" }}>Update Progress</h3>

        </div>
        <section
          style={{
            width: '100%',
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            padding: "20px"
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Vehicle Model : {extraData == "extra" ? item.vehicleModel : item.vehicleModel}</span>
            <span>VIN : {extraData == "extra" ? item.VIN : item.VIN}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Vehicle Variant : {extraData == "extra" ? item.vehicleVariant : item.vehicleVariant}</span>
            <span>BIN : {extraData == "extra" ? item.BIN : item.BIN}</span>
          </div>

        </section>

        <div className="body" style={{ width: "100%", height: "80%", display: "flex", justifyContent: "flex-start", padding: "20px" }}>

          <label style={{ width: "100%", display: 'flex', justifyContent: "space-between", }}>

            <select
              name="HoldReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option disabled selected hidden>
                Select Reason*
              </option>
              <option value="Testing Equipment not ready">
                Testing Equipment not ready
              </option>
              <option value="Electricity Issue">
                Electricity Issue
              </option>
              <option value="Others">Others</option>
            </select><br />
            <div style={{ display: "flex" }}>
              <button
                onClick={event => handleInputChange(event)}
                name="status"
                className={failButton ? "redButton" : "defaultButton"}
                value="Failed"
              >
                Failed
              </button>
              &nbsp;
              <button

                onClick={event => handleInputChange(event)}
                name="status"
                className={passButton ? "greenButton" : "defaultButton"}
                value="Passed"
              >
                Passed
              </button>
            </div>
          </label>

          {formValue.HoldReason === "Others" ?
            <label className="lgInput" style={{ display: "flex", flexDirection: "column" }}>
              Other Reason
              <input
                type="text"
                style={{ width: "500px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({ ...formValue, [e.target.name]: e.target.value })
                }
                placeholder="Hold Reason*"
                autoComplete="off"
              />
            </label> : ""}



        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={() => { submitHandler() }}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateProgress;
