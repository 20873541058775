import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import auth from "../../../utils/auth";
import { errorNotification, successNotification } from "../../../utils/notifications";

const RequestApproval = ({ closeModal, rowdata }) => {

  const submitHandler = async () => {
    let finalBody = {
      "role": ["Production"],
      "title": "Production request",
      "body": `Shop Floor user ${auth.getUserInfo().fullName} have requested for action on Add On Request ${rowdata.requestID} aligned with WO ${rowdata.orderID}`,
      "requestID": rowdata.requestID
    }
    const resp = await trackPromise(API.post(`inventory-module/api/v1/grn/notification`, finalBody));
    const { success, message } = resp;
    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 3000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer" style={{ width: "305px", height: "172px", padding:"0" }}>
        <div className="body" style={{ flexDirection: "column", fontSize: "18px", textAlign: "center" }}>
          <span>Are you sure to request for the</span>
          <span>approval of this WO?</span>
        </div>
        <div className="footer" style={{ justifyContent: "center", marginBottom: "16px" }}>
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestApproval;
