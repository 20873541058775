import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import Action from "../../components/modal/Action";
import "../../../components/modal/modal.scss";
import auth from "../../../utils/auth";
import moment from "moment";
import "../../../pages/vendors/Vendors.scss";
import AppConstants from "../../../utils/app-constants";
import SearchBox from "../../../components/search/SearchBox";
import Action from "./Action.jsx";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

// import ShowroomAction from "./actions/showroomAction";
// import ServiceCenterAction from "./actions/ServiceCenterAction";

const ManageJobCards = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState();

  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    jobCardID: "",
    jobType: "",
    vehicleRegNo: "",
    serviceType: "",
    status: "",
    fromDate: "",
    toDate: "",
  });

  console.log("formValue-->", formValue)

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`serviceAdvisor-module/api/v1/serviceJobs/manageJobCards?jobID=${formValue.jobCardID}&jobType=${formValue.jobType}&vehicleRegNo=${formValue.vehicleRegNo}&serviceType=${formValue.serviceType}&status=${formValue.status}&fromDate=${formValue.fromDate} &toDate=${formValue.toDate}`)
    );
    console.log(resp);
    let filterData = resp.filter((item) => {
      return item.status != "Job Fully-Closed";
    });
    setUsersData(filterData);
  };
  useEffect(() => {
    fetchReport();
  }, []);


  console.log("UsersData-->",UsersData)

  const uniquejobCardID = [...new Set(UsersData?.map((item) => item.jobID))]
  const uniquejobType = [...new Set(UsersData?.map((item) => item.jobType))]
  const uniquevehicleRegNo = [...new Set(UsersData?.map((item) => item.vehicleRegNo))]
  const uniquecategory = [...new Set(UsersData?.map((item) => item.serviceDetails.serviceType))]
  const uniquedateRange = [...new Set(UsersData?.map((item) => item.dateRange))]
  const uniquestatus = [...new Set(UsersData?.map((item) => item.status))]

  const statusFormatter = (cell, row, rowIndex) => {
    console.log(cell);
    return cell === AppConstants.NEW_JOB_CARD ? (
      <button className="newJobCard-btn">
        <span className="newJobCard-btn-font">New Job Card</span>
      </button>
    ) : cell === AppConstants.FULL_SPARE_ASSIGNED ? (
      <button className="active-btn">
        <span className="active-btn-font">Full Spare Assigned</span>
      </button>
    ) : cell === AppConstants.SPARES_REQUESTED ? (
      <button className="sparesRequested-btn">
        <span className="sparesRequested-btn-font">Spares Requested</span>
      </button>
    ) : cell === AppConstants.PARTIAL_SPARE_ASSIGNED ? (
      <button className="partialSpareAssigned-btn">
        <span className="partialSpareAssigned-btn-font">
          Partial Spare Assigned
        </span>
      </button>
    ) : cell === AppConstants.FULL_SPARE_ASSIGNED ? (
      <button className="fullSpareAssigned-btn">
        <span className="fullSpareAssigned-btn-font">Full Spare Assigned</span>
      </button>
    ) : cell === AppConstants.JOB_IN_PROGRESS ? (
      <button className="jobInProgress-btn">
        <span className="jobInProgress-btn-font">Job In Progress</span>
      </button>
    ) : cell === AppConstants.JOB_ON_HALT ? (
      <button className="jobOnHalt-btn">
        <span className="jobOnHalt-btn-font">Job on Halt</span>
      </button>
    ) : cell === AppConstants.JOB_RESUMED ? (
      <button className="jobResumed-btn">
        <span className="jobResumed-btn-font">Job Resumed</span>
      </button>
    ) : cell === AppConstants.JOB_CLOSED ||
      cell == AppConstants.JOB_FULLY_CLOSED ? (
      <button className="jobClosed-btn">
        <span className="jobClosed-btn-font">Job Closed</span>
      </button>
    ) : cell == "Job Re-Opened" ? (
      <button className="jobRe-Opened-btn">
        <span className="jobRe-Opened-btn-font">Job Re-Opened</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.status === AppConstants.DELETED ? (
      ""
    ) : (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && isOpen ? (
          <Action
            updatedData={fetchReport}
            rowData={row}
            setIsOpen={setIsOpen}
            rowIndex={rowIndex}
          />
        ) : null}
      </div>
    );
  };

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async(e) => {
    setFormValue({
      jobCardID: "",
      jobType: "",
      vehicleRegNo: "",
      serviceType: "",
      status: "",
      fromDate: "",
      toDate: "",
    });

    const resp = await trackPromise(
      API.get(`serviceAdvisor-module/api/v1/serviceJobs/manageJobCards`)
    );
    console.log(resp);
    let filterData = resp.filter((item) => {
      return item.status != "Job Fully-Closed";
    });
    setUsersData(filterData);
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Job Card ID",
      dataField: "jobID",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Service Type",
      dataField: "serviceType",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex) => {
        return row.serviceDetails.serviceCategory;
      },
    },
    {
      text: "Vehicle Reg. No.",
      dataField: "vehicleRegNo",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Customer Name",
      dataField: "customerName",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex) => {
        return row.customerDetails.customerName;
      },
    },
    {
      text: "Job Date",
      dataField: "jobDate",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "Technician Name",
      dataField: "TechnicianName",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex) => {
        return row.serviceDetails.technicianName;
      },
    },
    {
      text: "Status",
      dataField: "status",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: statusFormatter,
    },
    {
      text: "Last Update",
      dataField: "lastUpdateDate",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "Reason",
      dataField: "reason",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex) => {
        if (cell) {
          return cell;
        } else {
          return "-";
        }
      },
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "60px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Job Cards</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>

                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>

                {show && (
                  <div
                    className="UHinputfields"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "135px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Job Card ID
                        </label>
                        <select
                          name="jobCardID"
                          value={formValue.jobCardID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Job Card ID
                          </option>
                          {
                            uniquejobCardID.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Job Type
                        </label>
                        <select
                          name="text"
                          value={formValue.jobType}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Job Type
                          </option>
                          {
                            uniquejobType.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Vehicle Reg No
                        </label>
                        <select
                          name="vehicleRegNo"
                          value={formValue.vehicleRegNo}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Reg No
                          </option>
                          {
                            uniquevehicleRegNo.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        Service Type
                        </label>
                        <select
                          name="serviceType"
                          value={formValue.serviceType}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                          Service Type
                          </option>
                          {
                            uniquecategory.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          From
                        </label>
                        <input
                          type="date"
                          name="fromDate"
                          defaultValue={formValue.fromDate}
                          onChange={handleChange}
                          placeholder="From"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          To
                        </label>
                        <input
                          type="date"
                          name="toDate"
                          defaultValue={formValue.toDate}
                          onChange={handleChange}
                          placeholder="From"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          {
                            uniquestatus.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          justifyContent: "end",
                          width: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <button
                          id="apply"
                          style={{ marginTop: "22px", marginLeft: "10px" }}
                        onClick={fetchReport}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ marginTop: "22px", width: "auto" }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ManageJobCards;
