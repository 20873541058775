import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";
import { KeyboardBackspace, CheckCircle } from "@material-ui/icons";
import auth from "../../utils/auth";
import PurchaseOrderTemplateInvoice from "./PurchaseOrderTemplateInvoice";
import POTemplateInvoice from "./POTemplateInvoice";
import TermsView from "../TermsView/TermsView";

const PurchaseOrder = ({
  poData,
  productionQty,
  bom,
  closeModal,
  purchaseData,
  bomComponents,
  bomLocation
}) => {
  const [inVoice, setInVoice] = useState(false);

  console.log("purchaseData-->",purchaseData)


  let estdTime = [];
  let duration = [];
  console.log(estdTime);
  purchaseData.map((purchaseItem) => {
    purchaseItem?.vendorDetails?.map((data) => {
      data.deliveryDetails.map((item) => {
        if (
          bom.bomLocation === item.deliveryLocation &&
          data.vendorID === purchaseItem.vendorID
        ) {
          estdTime.push(item.estdTime);
          duration.push(item.duration);
        }
      });
    });
  });

  var componentsArray = purchaseData.map((data) => ({
    vendorPartName: data.vendorPartName,
    vendorPartCode: data.vendorPartCode,
    vendorID: data.vendorID,
    vendorName: data.vendorName,
    vendorAddress1: data.address1,
    vendorAddress2: data.address2,
    vendorBusinessContactNo: data.businessContactNo,
    gstin: data.gstin,
    vendorCity: data.vendorCity,
    componentID: data.componentID,
    componentName: data.componentName,
    skuCode: data.skuCode,
    category: data.category,
    subCategory: data.subCategory,
    componentQuantity: data.componentQuantity,
    specifications: data.specifications,
    hsnSacCode: data.hsnSacCode,
    taxRate: data.taxRate,
    estdTime: estdTime[0],
    tenantID: data.tenantID,
    duration: duration[0],
    unitPrice: data.unitPrice,
    requiredQuantity: data.reqComponentQty,
    totalPriceWOTaxed: data.unitPrice * data.reqComponentQty,
    taxAmount: (data.unitPrice * data.reqComponentQty * data.taxRate) / 100,
    totalPriceTaxed:
      data.unitPrice * data.reqComponentQty +
      (data.unitPrice * data.reqComponentQty * data.taxRate) / 100,
  }));
  console.log(componentsArray);

  console.log({ ...bom, components: componentsArray });
  let Obj = { ...bom, components: componentsArray };
  console.log(Obj);

  const totalPrice = () => {
    let total = 0;
    purchaseData?.map(
      (item) =>
      (total +=
        item.unitPrice * item.reqComponentQty +
        (item.unitPrice * item.reqComponentQty * item.taxRate) / 100)
    );
    return total;
  };

  let PoObj = {
    BOMID: Obj.BOMID,
    bomLocation: Obj.bomLocation,
    bomLevel: Obj.bomLevel,
    bomName: Obj.bomName,
    vehicleModel: Obj.vehicleModel,
    vehicleVariant: Obj.vehicleVariant,
    createdBy: Obj.createdBy,
    createdDate: Obj.createdDate,
    approvedBy: Obj.approvedBy,
    mgmtApprovalDate: Obj.mgmtApprovalDate,
    productionQuantity: productionQty,
    vendorID: Obj.components[0].vendorID,
    vendorName: Obj.components[0].vendorName,
    vendorAddress1: Obj.components[0].vendorAddress1,
    vendorAddress2: Obj.components[0].vendorAddress2,
    vendorBusinessContactNo: Obj.components[0].vendorBusinessContactNo,
    gstin: Obj.components[0].gstin,
    vendorCity: Obj.components[0].vendorCity,
    deliveryLocation: Obj.bomLocation,
    components: Obj.components,
    totalPrice: totalPrice(),
    tenantID: Obj.tenantID
  };




  poData(PoObj);
  console.log("PoObj-->",PoObj);

  const generatePO = async () => {
    let poData = PoObj;
    console.log("poData-->",poData)
    setInVoice(true);
    const resp = await trackPromise(
      API.post(`procurement-module/api/v1/orders`, poData)
    );
    console.log(resp);
    if (resp.success) {
      // closeModal(false);
      successNotification(resp.message, { autoClose: 10000 });
    } else {
      errorNotification(resp.message, { autoClose: 10000 });
    }
  };

  console.log(bomComponents)
  
  const specData = bomComponents?.map((item)=>item.specifications?.map((spec)=>(spec.specsType+" "+spec.specsValue+" "+spec.specsUnit)))
  
  console.log(specData)


  const [tentData ,setTentData] = useState([])


  const getCompanyDetails = async () => {
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/users/getDataWithTenantID?tenantID=${PoObj.tenantID}`)
    );
    setTentData(resp)
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);




  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1180px", height: "625px", padding: "15px" }}
      >
        <div className="header">
          <h3>Purchase Order</h3>
        </div>

        <div
          className="body"
          style={{
            display: "flex",
            height: "500px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Icon</span>
              <span style={{ color: "#0A18A3" }}>PO: 265324125</span>
            </div> */}

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Buyer Name : {tentData.fullName}</span>
              
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                Buyer Address : {
                   tentData?.locations?.map((item)=>(
                    item.locationType==="Business" && (item.address1+" "+ item.address2+" "+ item.city+" "+ item.state+" "+ item.country))
                   )}
              </span>
              <span>Requested By : {tentData.email} </span>
            </div>
          </section>

          <section
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{
                backgroundColor: "#0A18A3",
                color: "white",
                width: "100px",
                // borderBottomRightRadius: "8px",
                paddingLeft: "12px",
              }}>Vendor Name</span>
              <span style={{
                backgroundColor: "#0A18A3",
                color: "white",
                width: "70px",
                // borderBottomLeftRadius: "8px",
                paddingLeft: "22px",
              }}>Ship to </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>{PoObj.vendorName}</span>
              {/* <span>{PoObj.bomName}</span> */}
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>{PoObj.vendorCity}</span>
              <span>{PoObj.bomLocation}</span>
            </div>

            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "400",
              }}
            >
              <span>+91 236548921</span>
              <span>+91 326945789</span>
            </div> */}
          </section>

          <section style={{ width: "100%" }}>
            <div id="POVendorHeading">
              <div style={{ width: "13%" }}>Component Name</div>
              <div style={{ width: "9%" }}>SKU Code</div>
              <div style={{ width: "13%" }}>Vendor Part Name</div>
              <div style={{ width: "13%" }}>Vendor Part Code</div>
              <div style={{ width: "20%" }}>Description</div>
              <div style={{ width: "8%" }}>#HSN/SAC</div>
              <div style={{ width: "9%" }}>{`Unit Price(${auth.getUserInfo().currencySymbol
                })`}</div>
              <div style={{ width: "10%" }}>Required Qty.</div>
              <div style={{ width: "9%" }}>{`Qty. Price(${auth.getUserInfo().currencySymbol
                })`}</div>
              <div style={{ width: "8%" }}>{`Tax(${auth.getUserInfo().currencySymbol
                })`}</div>
              <div style={{ width: "10%" }}>{`Total Price(${auth.getUserInfo().currencySymbol
                })`}</div>
            </div>
            <div>
              {purchaseData.map((item, index) => (
                <div
                  id="POVendorContent"
                  style={{
                    borderBottom: "1px solid #999999",
                    boxShadow: "none",
                  }}
                  key={index}
                >
                  <div style={{ width: "13%" }}>{item.componentName}</div>
                  <div style={{ width: "9%" }}>{item.skuCode}</div>
                  <div style={{ width: "13%" }}>{item.vendorPartName}</div>
                  <div style={{ width: "13%" }}>{item.vendorPartCode}</div>
                  <div style={{ width: "20%" }}>{specData[0]}</div>
                  <div style={{ width: "8%" }}>{item.hsnSacCode}</div>
                  <div style={{ width: "9%" }}>{item.unitPrice}</div>
                  <div style={{ width: "10%" }}>{item.reqComponentQty}</div>
                  <div style={{ width: "9%" }}>
                    {item.unitPrice * item.reqComponentQty}
                  </div>
                  <div style={{ width: "8%" }}>{`${(item.unitPrice * item.reqComponentQty * item.taxRate) / 100
                    } (${item.taxRate}%)`}</div>
                  <div style={{ width: "10%" }}>
                    {item.unitPrice * item.reqComponentQty +
                      (item.unitPrice * item.reqComponentQty * item.taxRate) /
                      100}
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section className="POterms">
            <div style={{ width: "900px" }}>
              <div style={{ fontWeight: "500", textAlign: "left" }}>
                Terms & Conditions :-
              </div>
              <div
                style={{ width: "700px", fontSize: "10px", textAlign: "left"}}
              >
              <TermsView data="PROC. PO Terms" color="#ebebeb"/>
              </div>
            </div>
            <div style={{ width: "250px" }}>
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-around",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Sub Total : <span>{totalPrice()}</span>
              </div>
              {/* <div
                style={{ flexDirection: "row", justifyContent: "space-around" }}
              >
                {`Shipping (${auth.getUserInfo().currencySymbol})`} :{" "}
                <span>{500}</span>
              </div> */}
            </div>
          </section>

          <section
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              background: "#0A18A3",
              borderRadius: "5px",
              margin: "10px 0",
              height: "35px",
            }}
          >
            <div
              style={{
                width: "230px",
                color: "white",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {`Grand Total(${auth.getUserInfo().currencySymbol})`} :{" "}
              <span>{totalPrice()}</span>
            </div>
          </section>
        </div>

        <div className="footer">
          <button
            style={{ width: "104px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            Back
          </button>
          <button
            style={{ marginRight: "0", width: "120px", height: "42px" }}
            onClick={generatePO}
            id="submit-button"
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Proceed
          </button>
          {inVoice ? (
            <POTemplateInvoice
              poData={poData}
              productionQty={productionQty}
              bom={bom}
              PoObj={PoObj}
              purchaseData={purchaseData}
              closeModal={setInVoice}
              totalPrice={totalPrice}
              tentData={tentData}
              specData={specData}
              bomLocation={bomLocation}
            />
          ) : (
            " "
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrder;
