import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { KeyboardBackspace } from "@material-ui/icons";
import "../../work-order/workorder.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "../BomCosting.scss";
import auth from "../../../utils/auth";

const ViewEditLabour = ({ close, rowdata }) => {
    const [UsersData, setUsersData] = useState([]);
    const [onEditClick, setEditClick] = useState();
    const [editAction, setEditAction] = useState();
    const [editCheck, setEditCheck] = useState();
    const [laborObj, setLaborObj] = useState({});
    const [blankForm, setBlankForm] = useState([]);
    const [laborSubCategoryData, setSubcategoryData] = useState([]);
    const [laborCategoryValue, setLaborCategoryValue] = useState();

    const fetchReport = async () => {
        const resp = await trackPromise(API.get(`procurement-module/api/v1/boms/viewLabors?BOMID=${rowdata.BOMID}`));
        console.log(resp);
        setLaborObj(resp);
        setBlankForm(resp.labors);
    };
    useEffect(() => {
        fetchReport();
    }, []);

    const handleInputChange = async (event, index) => {
        const { name, value } = event.target;
        const field = [...blankForm];
        field[index][name] = value;

        if (name == 'laborCategory') {
            setLaborCategoryValue(value);
        }
        if (name == 'laborLevel') {
            const resp = await trackPromise(API.get(`production-module/api/v1/bom/fetchLaborSubCategory?laborCategory=${laborCategoryValue}&laborLevel=${value}`));
            setSubcategoryData(resp);
        }
        if (name == 'laborSubCategory') {
            const selectedData = laborSubCategoryData.filter((item) => item.laborSubCategory == value);
            console.log(selectedData);
            field[index]["laborID"] = selectedData[0].laborID;
        }

        setBlankForm(field);
    };

    const actionHandler = (index) => {
        setEditAction(index);
        setEditClick(true);
    };

    const submitEdit = (index) => {
        console.log(blankForm)
        setEditAction(index);
        setEditCheck(true);
    };

    const submitHandler = () => {
        console.log(blankForm)
    };

    return (
        <>
            <div className="container1">
                <div
                    className="modalContainer"
                    style={{ width: "90%", height: "485px", padding: "15px" }}
                >
                    <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>Required Labor</h3>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "16px",
                            fontWeight: "500",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Total Labour Count: {laborObj.totalLabors}</span>
                        </div>
                        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Total Time: { }</span>
                        </div> */}
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>{`Total Cost (${auth.getUserInfo().currencySymbol})`}: {laborObj.totalLaborsCost}</span>
                        </div>
                    </div>
                    <div className="body" style={{ height: "300px" }}>
                        <div style={{ width: "100%", overflow: "auto", height: "285px" }}>
                            <table className="viewcomp_tbl" style={{ width: "100%" }} >
                                <thead>
                                    <tr>
                                        <th style={{ width: "6%" }}>S.No.</th>
                                        <th style={{ width: "15%" }}>Labor Category</th>
                                        <th style={{ width: "13%" }}>Labor Level</th>
                                        <th style={{ width: "20%" }}>Labor Sub Category</th>
                                        <th style={{ width: "10%" }}>Req. Count</th>
                                        <th style={{ width: "8%" }}>Req. unit</th>
                                        <th style={{ width: "10%" }}>{`Cost(${auth.getUserInfo().currencySymbol})`}</th>
                                        <th style={{ width: "15%" }}>{`Sub Total(${auth.getUserInfo().currencySymbol})`}</th>
                                        {/* <th style={{ width: "8%" }}>Action</th> */}
                                    </tr>
                                </thead>

                                {blankForm.map((item, index) => {
                                    return (
                                        <tr>
                                            <td style={{ width: "6%" }} className="leftcell">{index + 1}</td>
                                            {editAction === index && onEditClick ?
                                                <td style={{ width: "15%" }}>
                                                    <select
                                                        style={{ width: "100%", height: "30px" }}
                                                        name="laborCategory"
                                                        defaultValue={item.laborCategory}
                                                        onChange={(e) => handleInputChange(event, index)}
                                                    >
                                                        {/* <option value='' selected disabled>
                                                            Labour Category
                                                        </option> */}
                                                        <option>Skilled</option>
                                                        <option>Unskilled</option>
                                                        <option>Professional</option>
                                                    </select>
                                                </td> :
                                                <td style={{ width: "15%" }}>{item.laborCategory}</td>}

                                            {editAction === index && onEditClick ?
                                                <td style={{ width: "15%" }}>
                                                    <select
                                                        style={{ width: "100%", height: "30px" }}
                                                        name="laborLevel"
                                                        defaultValue={item.laborLevel}
                                                        onChange={(e) => handleInputChange(event, index)}
                                                    >
                                                        {/* <option value='' disabled selected>
                                                            Labour Level
                                                        </option> */}
                                                        <option>Fresher</option>
                                                        <option>Intermediate</option>
                                                        <option>Experienced</option>
                                                    </select>
                                                </td> :
                                                <td style={{ width: "15%" }}>{item.laborLevel}</td>}

                                            {editAction === index && onEditClick ?
                                                <td style={{ width: "15%" }}>
                                                    <select
                                                        style={{ width: "100%", height: "30px" }}
                                                        name="laborSubCategory"
                                                        defaultValue={item.laborSubCategory}
                                                        onChange={(e) => handleInputChange(event, index)}
                                                    >
                                                        <option value={item.laborSubCategory} selected disabled>{item.laborSubCategory}</option>
                                                        {
                                                            laborSubCategoryData.map((item, index) => (
                                                                <option key={index}>{item.laborSubCategory}</option>
                                                            ))}
                                                    </select>
                                                </td> :
                                                <td style={{ width: "15%" }}>{item.laborSubCategory}</td>}

                                            {editAction === index && onEditClick ?
                                                <td style={{ width: "10%" }}>
                                                    <input
                                                        className="unit-price-input"
                                                        type="text"
                                                        name="requiredWorkers"
                                                        value={item.requiredWorkers}
                                                        placeholder="req Labor"
                                                        onChange={event => handleInputChange(event, index)}
                                                    />
                                                </td> :
                                                <td style={{ width: "10%" }}>{item.requiredWorkers}</td>}

                                            {editAction === index && onEditClick ?
                                                <td style={{ width: "8%" }}>
                                                    <input
                                                        className="unit-price-input"
                                                        type="text"
                                                        name="requiredDuration"
                                                        value={item.requiredDuration}
                                                        placeholder="Labor Time"
                                                        onChange={event => handleInputChange(event, index)}
                                                    />
                                                </td> :
                                                <td style={{ width: "8%" }}>{`${item.requiredDuration} ${item.laborDuration}`}</td>}

                                            {editAction === index && onEditClick ?
                                                <td style={{ width: "8%" }}>
                                                    <input
                                                        className="unit-price-input"
                                                        type="text"
                                                        name="avgLaborCost"
                                                        defaultValue={item.avgLaborCost}
                                                        placeholder="Labor Cost"
                                                        onChange={event => handleInputChange(event, index)}
                                                    />
                                                </td> :
                                                <td style={{ width: "8%" }}>{`${item.avgLaborCost} per ${item.laborDuration}`}</td>}

                                            <td className="rightcell" style={{ width: "15%" }}>{`${parseInt(item.requiredWorkers) * parseInt(item.avgLaborCost)} per ${item.laborDuration}`}</td>

                                            {/* {editAction === index && onEditClick ?
                                                <td style={{ width: "8%" }} className="rightcell">
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <span onClick={() => setEditClick(false)} style={{ color: "red", cursor: "pointer", fontSize: "20px" }}><FontAwesomeIcon icon={faTimesCircle} /></span>
                                                        <span onClick={() => submitEdit(index)} style={{ color: "green", cursor: "pointer", fontSize: "20px" }}><FontAwesomeIcon icon={faCheckCircle} /></span>
                                                    </div>
                                                </td> :
                                                <td style={{ width: "8%" }} className="rightcell">
                                                    <button
                                                        onClick={() => actionHandler(index)}
                                                        style={{ color: "#0A18A3", backgroundColor: "white", border: "1px solid #0A18A3", borderRadius: "3px", fontSize: "15px" }}
                                                    >
                                                        Edit
                                                    </button>
                                                </td>} */}
                                        </tr>
                                    )
                                })}
                            </table>
                        </div>
                    </div>

                    <br />

                    {onEditClick ?
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <button className="btn-footer" onClick={() => close(false)}><KeyboardBackspace style={{ fontSize: "25px" }} /> Back</button>
                            <div>
                                <button className="btn-footer" onClick={() => close(false)}><FontAwesomeIcon icon={faTimesCircle} /> Cancel</button>
                                <button onClick={submitHandler} className="btn-footer" ><FontAwesomeIcon icon={faCheckCircle} />Update</button>

                            </div>
                        </div>
                        :
                        <div className="footer">
                            <button className="btn-footer" onClick={() => close(false)}><KeyboardBackspace style={{ fontSize: "25px" }} /> Back</button>
                        </div>}
                </div>
            </div>
        </>
    );
};

export default ViewEditLabour;