import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../../components/modal/modal.scss";
import auth from "../../../utils/auth";
import moment from "moment";
import "../../../pages/vendors/Vendors.scss";
import AppConstants from "../../../utils/app-constants";
import SearchBox from "../../../components/search/SearchBox";
import Action from "./Component/Action";
import PODetails from "./Component/PODetails";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const NewRequest = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [poOpen, setPoOpen] = useState(false);
  const [open, setOpen] = useState();
  const [formValue, setFormValue] = useState({
    requestID: "",
    poNo: "",
    poBy: "",
    status: "",
    from: "",
    to: "",
  });

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `zonalSales-module/api/v1/orders/spareNewRequest?requisitionID=${formValue.requestID}&&poNo=${formValue.poNo}&&poBy=${formValue.poBy}&&status=${formValue.status}&&startDate=${formValue.from}&&endDate=${formValue.to}`
      )
    );
    console.log(resp);
    let filteredData = resp.filter((item) => {
      return (
        item.status == "Approved By Finance" ||
        item.status == "Approved" ||
        item.status == "PO On Hold" ||
        item.status == "Pending At Finance" ||
        item.status == "New Request" ||
        item.status == "Held By Finance"
      );
    });
    setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);






  const uniquerequestID = [...new Set(UsersData.map((item) => item.requestID))];
  const uniquepoNo = [...new Set(UsersData.map((item) => item.poNo))];
  const uniquepoBy = [...new Set(UsersData.map((item) => item.poBy))];
  const uniquestatus = [...new Set(UsersData.map((item) => item.status))];





  const statusFormatter = (cell, row, rowIndex) => {
    return cell === AppConstants.APPROVED_BY_FINANCE || cell == "Approved" ? (
      <button className="active-btn" style={{ width: "100%" }}>
        <span className="active-btn-font">Approved By Finance</span>
      </button>
    ) : cell === AppConstants.PO_ON_HOLD ? (
      <button className="deactive-btn" style={{ width: "100%" }}>
        {" "}
        <span className="deactive-btn-font">PO On Hold</span>
      </button>
    ) : cell === AppConstants.PENDING_AT_FINANCE ? (
      <button className="deactive-btn" style={{ backgroundColor: "#FFDBC7", width: "100%" }}>
        {" "}
        <span className="deactive-btn-font" style={{ color: "#A43E23" }}>
          Pending At Finance
        </span>
      </button>
    ) : cell === "New Request" ? (
      <button className="reactive-btn" style={{ width: "100%" }}>
        {" "}
        <span className="reactive-btn-font">New Request</span>
      </button>
    ) : cell === AppConstants.HELD_BY_FINANCE ? (
      <button className="delete-btn" style={{ width: "100%" }}>
        {" "}
        <span className="delete-btn-font">Held By Finance</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }

  const detailHandler = (row, rowIndex) => {
    setOpen(rowIndex);
    setPoOpen(true);
  };

  const poDetailFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "14px",
            color: "blue",
            cursor: "pointer",
          }}
          onClick={() => detailHandler(row, rowIndex)}
        >
          View Details
        </p>
        {open === rowIndex && poOpen ? (
          <PODetails rowData={row} closeModal={setPoOpen} />
        ) : null}
      </div>
    );
  };

  const actionHandler = (rowIndex) => {
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && isOpen ? (
          <Action
            updatedData={fetchReport}
            rowData={row}
            setIsOpen={setIsOpen}
            rowIndex={rowIndex}
          />
        ) : null}
      </div>
    );
  };

  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const clearFilter = async (e) => {
    setFormValue({
      requestID: "",
      poNo: "",
      poBy: "",
      status: "",
      from: "",
      to: "",
    });
    const resp = await trackPromise(
      API.get(`zonalSales-module/api/v1/orders/spareNewRequest`)
    );
    let filteredData = resp.filter((item) => {
      return (
        item.status == "Approved By Finance" ||
        item.status == "Approved" ||
        item.status == "PO On Hold" ||
        item.status == "Pending At Finance" ||
        item.status == "New Request" ||
        item.status == "Held By Finance"
      );
    });
    setUsersData(filteredData);
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  const requestedToFormatter = (cell, row, rowIndex) => {
    return <span>{`${row?.salesManager}(${row?.userType})`}</span>;
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requisitionID",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Requested To",
      dataField: "requisitionID",
      classes: "cellsOverflow",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: requestedToFormatter,
    },
    {
      text: "PO No.",
      dataField: "poNo",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "PO By",
      dataField: "poBy",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "PO Date",
      dataField: "poDate",
      classes: "cellsOverflow",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "PO Details",
      //   dataField: "",
      classes: "cellsOverflow",
      headerAttrs: { width: "300px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { isOpen, poOpen },
      formatter: poDetailFormatter,
    },
    {
      text: "PO Value",
      dataField: "poValue",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Status",
      dataField: "status",
      classes: "cellsOverflow",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: statusFormatter,
    },
    {
      text: "Hold Reason",
      dataField: "reason",
      classes: "cellsOverflow",
      headerAttrs: { width: "250px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Last Updated",
      dataField: "lastUpdated",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "100px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Spare Orders</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <div
                    className="UHinputfields"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "135px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request ID
                        </label>
                        <select
                          name="requestID"
                          value={formValue.requestID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Request ID
                          </option>
                          {
                            uniquerequestID.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          PO No.
                        </label>
                        <select
                          name="poNo"
                          value={formValue.poNo}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            PO No.
                          </option>
                          {
                            uniquepoNo.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          PO By
                        </label>
                        <select
                          name="poBy"
                          value={formValue.poBy}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            PO By
                          </option>
                          {
                            uniquepoBy.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          {
                            uniquestatus.map((item) => (
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          From
                        </label>
                        <input
                          type={"date"}
                          name="from"
                          className="create-grn-date"
                          selected={formValue.from}
                          onChange={handleChange}
                          placeholderText="From"
                          dateFormat="DD-MM-YYYY/HH:mm"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          {" "}
                          To
                        </label>
                        <input
                          type={"date"}
                          name="from"
                          className="create-grn-date"
                          selected={formValue.to}
                          onChange={handleChange}
                          placeholderText="To"
                          dateFormat="DD-MM-YYYY/HH:mm"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          justifyContent: "end",
                          width: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <button
                          id="apply"
                          style={{ marginTop: "22px", marginLeft: "10px" }}
                          onClick={fetchReport}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ marginTop: "22px", width: "auto" }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default NewRequest;
