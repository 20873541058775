import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import '../../../../components/modal/modal.scss'
import { successNotification, errorNotification } from "../../../../utils/notifications";
import auth from "../../../../utils/auth";

const ReactivateScUser = ({ updatedData, editData, closeModal, tabName }) => {
  const [formValue, setFormValue] = useState({
    reactivationReason: "",
    description: "",
  });

  const submitHandler = async () => {
    console.log(formValue);
    let editDetails = {
      userID: editData.userID,
      reason: formValue.reactivationReason,
      description: formValue.description,
      status: "Reactivated"
    }
    const resp = await trackPromise(API.post(`serviceCenter-module/api/v1/user/status`, editDetails));

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      updatedData();
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Deactivate</h3>
        </div>

        <div className="body">
          <label style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "83px" }}>Reactivation Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="reactivationReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: '100%', marginTop: '-5px' }}
            >
              <option value="" disabled selected>Select Reactivation Reason</option>
              <option value="Activity Resumes">
                Activity Resumed
              </option>
              <option value="Services Required">
                Services Required
              </option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.reactivationReason === "Others" ?
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({ ...formValue, [e.target.name]: e.target.value })
                }
                placeholder="Deactivation Reason"
                autoComplete="off"
              />
            </label> : ""}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReactivateScUser