import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { KeyboardBackspace } from "@material-ui/icons";
import "../../work-order/workorder.scss";
import ViewSpecs from "../../wo-shopfloor/wo-sf-actions/wo-sf-viewspecs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faEdit,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import "../BomCosting.scss";
import auth from "../../../utils/auth";

const ViewEditAssembly = ({ close, rowdata }) => {
  const [UsersData, setUsersData] = useState([]);
  const [onEditClick, setEditClick] = useState(false);
  const [editAction, setEditAction] = useState();
  const [showEdited, setEdited] = useState(false);
  const [assemblyObj, setAssemblyObj] = useState({});
  const [blankForm, setBlankForm] = useState([]);

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `procurement-module/api/v1/boms/viewAssemblies?BOMID=${rowdata.BOMID}`
      )
    );
    console.log(resp);
    setAssemblyObj(resp.assemblies[0]);
    setBlankForm(resp.assemblies[0].assemblyServices);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  // let assemblyObj = {
  //     "assemblyType": "Main Assembly",
  //     "assemblyName": "service-1",
  //     "assemblyServices": [{
  //         "id": "1",
  //         "serviceName": "Converyer Belt",
  //         "reqTime": "2",
  //         "time": "hrs",
  //         "cost": "1500"
  //     }, {
  //         "id": "2",
  //         "serviceName": "Crane",
  //         "reqTime": "4",
  //         "time": "day",
  //         "cost": "2000"
  //     }]
  // };

  // const [blankForm, setBlankForm] = useState(assemblyObj.assemblyServices);

  const submitEdit = (index) => {
    setEditClick(false);
    setEdited(index);
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const field = [...blankForm];
    field[index][name] = value;
    setBlankForm(field);
  };

  const submitHandler = () => {
    let assemblyObjFinal = {
      assemblyType: assemblyObj.assemblyType,
      assemblyName: assemblyObj.assemblyName,
      assemblyServices: blankForm,
    };
    console.log(assemblyObjFinal);
  };

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "1260px", height: "400px", padding: "15px", fontSize:"small" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Required Assembly</h3>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Total Services: {blankForm.length}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                {`Total Cost (${auth.getUserInfo().currencySymbol})`}:{" "}
                {rowdata.totalAssemblyCost}
              </span>
            </div>
          </div>
          <div className="body" style={{ height: "300px" }}>
            <div style={{ width: "100%", overflow: "auto", height: "210px" }}>
              <table
                className="viewcomp_tbl"
              >
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>Assembly Type</th>
                    <th style={{ width: "10%" }}>Assembly Name</th>
                    <th style={{ width: "20%" }}>Required Services</th>
                    <th style={{ width: "10%" }}>Required unit</th>
                    <th style={{ width: "10%" }}>{`Cost (${auth.getUserInfo().currencySymbol
                      })`}</th>
                    <th style={{ width: "15%" }}>{`Sub Total (${auth.getUserInfo().currencySymbol
                      })`}</th>
                  </tr>
                </thead>
                <tr>
                  <td className="leftcell" style={{ width: "10%" }}>
                    {assemblyObj.assemblyType}
                  </td>
                  <td style={{ width: "10%" }}>{assemblyObj.assemblyName}</td>

                  <td style={{ width: "20%" }}>
                    {blankForm.map((item, index) => (
                      <ul style={{ padding: "0" }}>
                        {onEditClick ? (
                          <li>
                            <select
                              style={{
                                width: "100%",
                                height: "30px",
                                margin: "0",
                              }}
                              name="serviceName"
                              defaultValue={item.serviceName}
                              onChange={(event) =>
                                handleInputChange(event, index)
                              }
                            >
                              <option>Crane</option>
                              <option>Converyer Belt</option>
                            </select>
                          </li>
                        ) : (
                          <li>{item.serviceName}</li>
                        )}
                      </ul>
                    ))}
                  </td>

                  <td style={{ width: "10%" }}>
                    {blankForm.map((item, index) => (
                      <ul style={{ padding: "0" }}>
                        {onEditClick ? (
                          <div style={{ display: "flex" }}>
                            <li
                              style={{ width: "100%", listStyleType: "none" }}
                            >
                              <input
                                style={{
                                  width: "60%",
                                  height: "30px",
                                  margin: "0",
                                }}
                                name="reqTime"
                                defaultValue={item.reqTime}
                                onChange={(event) =>
                                  handleInputChange(event, index)
                                }
                              />
                            </li>
                            <li
                              style={{ width: "100%", listStyleType: "none" }}
                            >
                              <select
                                style={{
                                  width: "90%",
                                  height: "30px",
                                  margin: "0",
                                }}
                                name="time"
                                defaultValue={item.time}
                                onChange={(event) =>
                                  handleInputChange(event, index)
                                }
                              >
                                <option>hrs</option>
                                <option>day</option>
                              </select>
                            </li>
                          </div>
                        ) : (
                          <li>
                            {item.timeDuration} {item.unit}
                          </li>
                        )}
                      </ul>
                    ))}
                  </td>

                  <td style={{ width: "10%" }}>
                    {blankForm.map((item, index) => (
                      <ul style={{ padding: "0" }}>
                        {onEditClick ? (
                          <li>
                            <select
                              style={{
                                width: "100%",
                                height: "30px",
                                margin: "0",
                              }}
                              name="cost"
                              defaultValue={item.cost}
                              onChange={(event) =>
                                handleInputChange(event, index)
                              }
                            >
                              <option>2000</option>
                              <option>1500</option>
                            </select>
                          </li>
                        ) : (
                          <li>{item.serviceCost} {item.unit}</li>
                        )}
                      </ul>
                    ))}
                  </td>

                  <td className="rightcell" style={{ width: "6%" }}>
                    {blankForm.map((item) => (
                      <ul style={{ padding: "0" }}>
                        <li>{parseInt(item.timeDuration) * parseInt(item.serviceCost)}</li>
                      </ul>
                    ))}
                  </td>
                  {/* {onEditClick ?
                                                <div style={{marginTop:"3px"}}>
                                                    <button
                                                        onClick={() => submitEdit}
                                                        style={{ color: "#0A18A3", backgroundColor: "white", border: "1px solid #0A18A3", borderRadius: "3px", fontSize: "15px", width: "90px", margin: "110px 0px 0px -98px" }}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} /> Save
                                                    </button>
                                                    <button
                                                        onClick={() => setEditClick(false)}
                                                        style={{ color: "#0A18A3", backgroundColor: "white", border: "1px solid #0A18A3", borderRadius: "3px", fontSize: "15px", width: "90px", margin: "110px 0px 0px -190px" }}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} /> Cancel
                                                    </button>
                                                </div>
                                                :
                                                <button
                                                    onClick={() => setEditClick(true)}
                                                    style={{ color: "#0A18A3", backgroundColor: "white", border: "1px solid #0A18A3", borderRadius: "3px", fontSize: "15px", width: "60px", margin: "110px 0px 0px -68px" }}
                                                >
                                                    <FontAwesomeIcon icon={faEdit} /> Edit
                                                </button>
                                            } */}
                </tr>
              </table>
            </div>
          </div>
          <br />
          {onEditClick ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button className="btn-footer" onClick={() => close(false)}>
                <KeyboardBackspace style={{ fontSize: "25px" }} /> Back
              </button>
              <div>
                <button className="btn-footer" onClick={() => close(false)}>
                  <FontAwesomeIcon icon={faTimesCircle} /> Cancel
                </button>
                <button onClick={submitHandler} className="btn-footer">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Update
                </button>
              </div>
            </div>
          ) : (
            <div className="footer">
              <button className="btn-footer" onClick={() => close(false)}>
                <KeyboardBackspace style={{ fontSize: "25px" }} /> Back
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewEditAssembly;
