import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import JobCardDetails from "./JobCardDetails";
import moment from "moment";


const ViewDetails = ({ closeModal, rowData , setIsOpen}) => {
  // const UsersData = row.requisitionDetails;
  console.log("rowData-->",rowData)

  const [openSpare, setOpenSpare] = useState();
  const [spareData, setSpareData] = useState();

  // const Data = [
  //   ...(rowData?.serviceDetails?.additionalSpares || []),
  //   ...(rowData?.serviceDetails?.mandatoryReplacements || [])
  // ];
  // console.log(Data)


  const jobCardFormatter = (rowData) => {
    setOpenSpare(true);
    setSpareData(rowData);
  };


  const closeHandler = ()=>{
    setIsOpen(false)
    closeModal(false)
  }




  return (
    <div className="container1">

      {openSpare ? (
        <JobCardDetails rowData={spareData} closeModal={setOpenSpare} />
      ) : (
        ""
      )}
      <div
        className="modalContainer"
        style={{ width: "65%", height: "400px", padding: "15px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Requested Spares</h3>
        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding: 7,
          }}
        >
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            Vehicle Reg. No: {rowData.vehicleRegNo}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Job Card Date: {moment(rowData.jobDate).format("DD-MMM-YYYY/HH:mm")}
          </span>
          <span
            onClick={() => jobCardFormatter(rowData)}
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
              color: "blue",
              cursor:"pointer"
            }}
          >
            Job Card ID: {rowData.jobID}
          </span>


        </section>
        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div>
              <Table>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>S.No</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>SKU Code</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>Spare Part Name</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>Category</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>Sub-Category</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>Requested Qty.</td>
                  </tr>
                  {
                    rowData?.inspectionsChecks?.spares?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.skuCode}</td>
                          <td>{item?.componentName}</td>
                          <td>{item?.category}</td>
                          <td>{item?.subCategory}</td>
                          <td>{item?.requiredQty}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimesCircle} /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;

