import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AppConstants from "../../utils/app-constants";

import POViewDetailsClosed from "./actions/poviewdetailsClosed";
import SearchBox from "../../components/search/SearchBox";
import handleExport from "../../utils/exportExcel";
import DealerVehiclePO from "../RequisitionOrders/DealerVehiclePO";
import POViewDetails from "../po-request/components/actions/POViewDetails";

// const { ExportCSVButton } = CSVExport;

const PurchaseOrder = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [actionShow, setActionShow] = useState();
  const [open, setOpen] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [poOpen, setPoOpen] = useState(false);
  const [openQty, setOpenQty] = useState();
  const [row, setRow] = useState();

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`management/api/v1/inventory/purchaseorder`)
    );
    console.log(resp);
    setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  

  const uniquePOID = [...new Set(UsersData.map(item => item.POID))];
  const uniqueBOMID = [...new Set(UsersData.map(item => item.BOMID))];
  const uniquebomLocation = [...new Set(UsersData.map(item => item.bomLocation))];
  const uniquestatus = [...new Set(UsersData.map(item => item.status))];





  const statusFormatter = (cell) => {
    return cell === AppConstants.PENDINGATFINANCE ? (
      <button className="delete-btn">
        <span className="delete-btn-font">Pending at Finance</span>
      </button>
    ) : cell === AppConstants.APPROVEDBYFINANCE ? (
      <button className="reactive-btn">
        <span className="reactive-btn-font">Approved by Finance</span>
      </button>
    ) : cell === AppConstants.HOLDBYFINANCE ? (
      <button className="deactive-btn">
        <span className="deactive-btn-font">Hold by Finance</span>
      </button>
    ) : cell === AppConstants.PLACEDTOVENDOR ? (
      <button className="reactive-btn">
        <span className="reactive-btn-font">Placed to Vendor</span>
      </button>
    ) : cell === AppConstants.PARTIALLYCLOSED ? (
      <button className="pendingByProc-btn">
        <span className="pendingByProc-btn-font">Partially Closed</span>
      </button>
    ) : cell === AppConstants.FULLYCLOSED ? (
      <button className="active-btn">
        <span className="active-btn-font">Fully Closed</span>
      </button>
    ) : cell === AppConstants.REJECTED ? (
      <button className="delete-btn">
        <span className="delete-btn-font">Rejected</span>
      </button>
    ) : (
      cell
    );
  };

  const actionHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    setOpen(true);
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          onClick={() => actionHandler(row, rowIndex)}
          style={{
            color: "#0A18A3",
            backgroundColor: "white",
            border: "1px solid #0A18A3",
            borderRadius: "3px",
          }}
        >
          View Details
        </button>
        {actionShow === rowIndex &&
        (row.status == AppConstants.PARTIALLYCLOSED ||
          row.status == AppConstants.FULLYCLOSED) &&
        open ? (
          <POViewDetailsClosed
            data={row}
            status={true}
            closeModal={setOpen}
          />
        ) : actionShow === rowIndex && open ? (
          <POViewDetails data={row} status={true} closeModal={setOpen} />
        ) : null}
      </div>
    );
  };


  const qtyFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <p
          style={{
            fontSize: "14px",
            color: "blue",
            cursor: "pointer",
            margin: "0px",
          }}
          onClick={() => setOpenQty(rowIndex)}
        >
          {row.POID}
        </p>
        {openQty === rowIndex && (
          <DealerVehiclePO rowData={row} closeModal={setOpenQty} />
        )}
      </div>
    );
  };


  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "PO No.",
      dataField: "POID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      // formatExtraData: { openQty },
      // formatter: qtyFormatter,
    },
    {
      text: "PO Requested By",
      dataField: "requestedBy",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "250px" },
    },
    {
      text: "PO Date",
      dataField: "PODate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: dateFormatter,
    },
    {
      text: "BOM ID",
      dataField: "BOMID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "BOM Name",
      dataField: "bomName",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "BOM Location",
      dataField: "bomLocation",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Status",
      dataField: "status",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  const [formValue, setFormValue] = useState({
    POID: "",
    PODate: "",
    BOMID: "",
    bomLocation: "",
    status: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      POID: "",
      PODate: "",
      BOMID: "",
      bomLocation: "",
      status: "",
    });
    const resp = await trackPromise(
      API.get(`management/api/v1/inventory/purchaseorder`)
    );
    setUsersData(resp);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Purchase Order</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "purchase order")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <>
                    <form
                      onSubmit={submitHandler}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        height: "135px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                      }}
                      className="UHinputfields VMform"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          PO Number
                        </label>
                        <select
                          name="POID"
                          value={formValue.POID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            PO Number
                          </option>
                          {
                            uniquePOID.map((item)=>{
                              <option>{item}</option>
                            })
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          PO Date
                        </label>
                        <input
                          type="date"
                          onFocus={(e) => (e.currentTarget.type = "date")}
                          onBlur={(e) => (e.currentTarget.type = "text")}
                          name="PODate"
                          value={formValue.PODate}
                          onChange={handleChange}
                          placeholder="PO Date"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          BOM ID
                        </label>
                        <select
                          name="BOMID"
                          value={formValue.BOMID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            BOM ID
                          </option>
                          {
                            uniqueBOMID.map((item)=>{
                              <option>{item}</option>
                            })
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          BOM Location
                        </label>
                        <select
                          name="bomLocation"
                          value={formValue.bomLocation}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            BOM Location
                          </option>
                          {
                            uniquebomLocation.map((item)=>{
                              <option>{item}</option>
                            })
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status*
                          </option>
                          {
                            uniquestatus.map((item)=>{
                              <option>{item}</option>
                            })
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          width: "22%",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <button
                          onClick={fetchReport}
                          style={{ width: "100px", marginTop: "24px" }}
                          id="apply"
                        >
                          Apply
                        </button>
                        <button
                          style={{ width: "100px", marginTop: "24px" }}
                          onClick={clearFilter}
                          id="clear"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </form>
                  </>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default PurchaseOrder;
