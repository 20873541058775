import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  faFilter,
  faRedoAlt,
  faTimesCircle,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import SearchBox from "../../../components/search/SearchBox";
import DealerActions from "./DealerActions";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import auth from "../../../utils/auth";
import ViewSalesUnits from "./ViewQty";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import handleExport from "../../../utils/exportExcel"

function ServiceCenterWise({ inDashboard }) {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [openQty, setOpenQty] = useState();
  const [open, setOpen] = useState(false);
  const [actionShow, setActionShow] = useState();
  const [UsersData, setUsersData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showroomDataWithId, setShowroomData] = useState([]);
  const [selectedShowroomValue, setSelectedShowroomValue] = useState("All");
  const [filtersDropdown, setFiltersDropdown] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [formValue, setFormValue] = useState({
    sparePartname: "",
    skuCode: "",
    category: "",
    subCategory: "",
    from: "",
    to: "",
    serviceCenterUserID: "",
    serviceCenterLocation: "",
  });

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `dealer-module/api/v1/serviceCenters/sales/serviceCenterWise?sparePartname=${formValue.sparePartname}&skuCode=${formValue.skuCode}&category=${formValue.category}&subCategory=${formValue.subCategory}&from=${formValue.from}&to=${formValue.to}&serviceCenterUserID=${formValue.serviceCenterUserID == "All" ? "" : formValue.serviceCenterUserID}&serviceCenterLocation=${formValue.serviceCenterLocation}`
      )
    );
    console.log(resp.data);
    setTableData(resp.data);
    // setUsersData(resp);
    setShowroomData(resp.data);
    const respo = await trackPromise(
      API.get(`dealer-module/api/v1/serviceCenters/sales/serviceCenterWise`)
    );
    setDropdownData(respo.data);
    setFiltersDropdown(respo.data);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const uniqueserviceCenterId = [
    ...new Set(dropdownData.map((item) => item.serviceCenterUserID)),
  ];
  const uniqueserviceCenterLocation = [
    ...new Set(filtersDropdown?.map((item) => item.serviceCenterLocation)),
  ];
  const uniquesparePartname = [
    ...new Set(filtersDropdown?.map((item) => item.sparePartname)),
  ];
  const uniqueskuCode = [...new Set(filtersDropdown?.map((item) => item.skuCode))];
  const uniquecategory = [...new Set(filtersDropdown?.map((item) => item.category))];
  const uniquesubCategory = [
    ...new Set(filtersDropdown?.map((item) => item.subCategory)),
  ];

  const { totalCount, totalPrice } = tableData.reduce(
    (acc, curr) => {
      const count = parseInt(curr.count);
      const price = parseInt(curr.priceToserviceCenter);
      acc.totalCount += count;
      acc.totalPrice += count * price;
      return acc;
    },
    { totalCount: 0, totalPrice: 0 }
  );

  const actionHandler = (rowIndex) => {
    setActionShow(rowIndex);
    setOpen(true);
    on();
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow == rowIndex && open ? (
          <DealerActions rowData={row} />
        ) : null}
      </div>
    );
  };

  const handleInputChange = (event) => {
    formValue.serviceCenterUserID = event.target.value;
    setSelectedShowroomValue(event.target.value);
    let filteredData = tableData.filter(
      (item) => item.serviceCenterUserID == event.target.value
    );
    if (event.target.value == "All") {
      fetchReport();
      setFiltersDropdown(dropdownData)
    } else {
      setShowroomData(filteredData);
      setFiltersDropdown(filteredData)
      fetchReport();
    }
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const handleChange = (e) => {
    console.log(e.target.name);
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  // const qtyFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   console.log(cell);
  //   return (
  //     <div>
  //       <p
  //         style={{
  //           fontSize: "14px",
  //           color: "blue",
  //           cursor: "pointer",
  //         }}
  //         onClick={() => setOpenQty(rowIndex)}
  //       >
  //         {cell?.length}
  //       </p>
  //       {openQty === rowIndex && (
  //         <ViewSalesUnits row={row} closeModal={setOpenQty} />
  //       )}
  //     </div>
  //   );
  // };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "50px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return tableData.findIndex(function (value, idx, arr) {
          return value == row;
        }) + 1
      },
      sort: true,
    },
    {
      text: "SKU Code",
      dataField: "skuCode",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Spare Part Name",
      dataField: "sparePartname",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Category",
      dataField: "category",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Sub Category",
      dataField: "subCategory",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Sold Units",
      dataField: "count",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { openQty },
      // formatter: qtyFormatter,
    },
    {
      text: `Unit Price (${auth.getUserInfo().currencySymbol})`,
      dataField: "priceToserviceCenter",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row) => {
        return row.priceToserviceCenter.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "decimal",
        })
      }
    },
    {
      text: `Sales Value (${auth.getUserInfo().currencySymbol})`,
      dataField: "priceToserviceCenter",
      classes: "cellsOverflow",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row) => {
        if (!cell) {
          return "-";
        } else {
          return (parseInt(row.count) * parseInt(row.priceToserviceCenter).toLocaleString("en-IN", {
            maximumFractionDigits: 0,
            style: "decimal",
          }));
        }
      },
    },
    // {
    //   text: "Actions",
    //   // dataField: "",
    //   headerAttrs: { width: "150px" },
    //   headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    //   formatExtraData: { open, actionShow },
    //   formatter: actionFormatter,
    // },
  ];

  const clearFilter = async (e) => {
    setFormValue({
      sparePartname: "",
      skuCode: "",
      category: "",
      subCategory: "",
      from: "",
      to: "",
      serviceCenterUserID: "",
      serviceCenterLocation: "",
    });
    setSelectedShowroomValue("All");
    setFiltersDropdown(dropdownData);
    const resp = await trackPromise(
      API.get(`dealer-module/api/v1/serviceCenters/sales/serviceCenterWise`
      )
    );
    console.log(resp);
    resp.success == false
      ? errorNotification(resp.message, { autoClose: 3000 })
      : setTableData(resp.data);
  };

  // const refresh = async (e) => {
  //   setFormValue({
  //     sparePartname: "",
  //     skuCode: "",
  //     category: "",
  //     subCategory: "",
  //     from: "",
  //     to: "",
  //     serviceCenterUserID: "",
  //     serviceCenterLocation: "",
  //   });
  //   setShowroomData(tableData);
  //   document.getElementById("id").value = "";
  //   document.getElementById("location").value = "";
  //   const resp = await trackPromise(
  //     API.get(`dealer-module/api/v1/serviceCenters/sales/serviceCenterWise`)
  //   );
  //   console.log(resp);
  //   resp.success == false
  //     ? errorNotification(resp.message, { autoClose: 3000 })
  //     : setTableData(resp.data);
  // };
  let dataToExport = tableData.map((item) => {
    return {
      "SKU Code": item.skuCode,
      "Spare Part Name": item.sparePartname,
      "Category": item.category,
      "Sub Category": item.subCategory,
      "Solid Units": item.count || "-",
      "Unit Price (₹)": item.priceToserviceCenter || "-",
      "Sales Value (₹)": parseInt(item.count) * parseInt(item.priceToserviceCenter) || "-",
    }
  })
  return (
    <>
      <ToolkitProvider keyField="id" data={tableData} columns={columns} search>
        {(props) => (
          <>
            <div className="bussiness">
              <div className="heading">
                <span>Service Center Wise</span>
              </div>
              <div
                type="button"
                className="btn btn-md"
                style={{ float: "right", marginTop: "-42px" }}
              >
                <div
                  style={{
                    display: "flex",
                    marginRight: "-18px",
                    height: "26px",
                  }}
                >
                  <div style={{ width: "199px" }}>
                    <SearchBar
                      {...props.searchProps}
                      placeholder="Search..."
                      style={{
                        height: "25px",
                        // border: "1px solid #C6C6C6",
                        border: "none",
                        background: "white",
                        borderRadius: "3px",
                        marginRight: "-50px",
                      }}
                    />
                  </div>
                  <div onClick={clearFilter} className="filter-refresh">
                    <FontAwesomeIcon icon={faRedoAlt} />
                    &nbsp; Refresh
                  </div>
                  <div className="filter-refresh" onClick={handleClick}>
                    {" "}
                    <FontAwesomeIcon icon={faFilter} />
                    &nbsp; Filters
                  </div>
                  <div
                    className="filter-refresh"
                    onClick={() => handleExport(dataToExport, "Service Center Wise")}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                    &nbsp; Export
                  </div>
                  {inDashboard ? (
                    <div
                      className="filter-refresh"
                      onClick={() => inDashboard(false)}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="mainShowroomInvDiv">
              {/* RIGHT SIDE BOX */}

              <div
                className="rightDetailsBox"
                style={{ width: "100%", marginLeft: "0px" }}
              >
                {show && (
                  <div
                    id="abc"
                    className="RA_block"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      height: "125px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                      marginBottom: "1%",
                    }}
                  >
                    <div
                      className="bomfilter_fields"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "23%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Spare Part Name
                        </label>
                        <select
                          name="sparePartname"
                          value={formValue.sparePartname}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Spare Part Name
                          </option>
                          {uniquesparePartname.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "23%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          SKU Code
                        </label>
                        <select
                          name="skuCode"
                          value={formValue.skuCode}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            SKU Code
                          </option>
                          {uniqueskuCode.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "23%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Category
                        </label>
                        <select
                          name="category"
                          value={formValue.category}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Category
                          </option>
                          {uniquecategory.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "23%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Sub Category
                        </label>
                        <select
                          name="subCategory"
                          value={formValue.subCategory}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Sub Category
                          </option>
                          {uniquesubCategory.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "23%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          From
                        </label>
                        <input
                          type="date"
                          name="form"
                          value={formValue.from}
                          style={{ marginTop: "-5px", width: "100%" }}
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "23%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          To
                        </label>
                        <input
                          type="date"
                          name="to"
                          value={formValue.to}
                          style={{ marginTop: "-5px", width: "100%" }}
                          onChange={handleChange}
                        />
                      </div> */}
                      <div
                        style={{
                          width: "100%",
                          textAlign: "end",
                          marginTop: "15px",
                        }}
                      >
                        <button
                          type="submit"
                          id="apply"
                          style={{ width: "96px", height: "35px" }}
                          onClick={fetchReport}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ width: "96px", height: "35px" }}
                        >
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#F3F3F3",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "0",
                      padding: "0",
                      width: "23%",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        marginLeft: "2.5%",
                      }}
                      htmlFor="serviceCenterUserID"
                    >
                      Select Service Center ID
                    </label>
                    <select
                      name="serviceCenterUserID"
                      id="serviceCenterUserID"
                      className="input-config"
                      value={formValue.serviceCenterUserID}
                      style={{
                        width: "100%",
                        height: "25px",
                        background: "#FFFFFF",
                        border: "1px solid #CDCDCD",
                        marginTop: "-2%",
                      }}
                      onChange={(event) => handleInputChange(event)}
                    >
                      <option value="All" selected>
                        All
                      </option>
                      {uniqueserviceCenterId?.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                  <span
                    style={{
                      marginTop: "2%",
                      color: "rgb(54, 54, 54)",
                      fontWeight: "500",
                    }}
                  >
                    Service Center Name:{" "}
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      {selectedShowroomValue == "All"
                        ? "All"
                        : showroomDataWithId[0]?.serviceCenterName}
                    </span>

                  </span>
                  <h6
                    style={{
                      margin: "5px",
                      color: "rgb(54, 54, 54)",
                      marginTop: "2%",
                    }}
                  >
                    Total Spares Sold : <span style={{ fontSize: "18px", fontWeight: "600" }}>{totalCount}</span>
                  </h6>
                  <h6
                    style={{
                      margin: "5px",
                      color: "rgb(54, 54, 54)",
                      marginTop: "2%",
                    }}
                  >
                    {` Total Sales value (${auth.getUserInfo().currencySymbol})`} :{" "}
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      {totalPrice.toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                        style: "decimal",
                      })}
                    </span>

                  </h6>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <BootstrapTable
                    wrapperClasses="table-responsive mis-table"
                    rowClasses="text-nowrap"
                    keyField="id"
                    data={tableData}
                    noDataIndication="No Data Found"
                    rowStyle={{ border: "1px solid #CDCDCD" }}
                    columns={columns}
                    pagination={paginationFactory({
                      hidePageListOnlyOnePage: true,
                      showTotal: true,
                      hideSizePerPage: true,
                      paginationSize: 2,
                      sizePerPage: "10",
                    })}
                    {...props.baseProps}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </>
  );
}

export default ServiceCenterWise;
