import React, { useState } from "react";
import {
    KeyboardBackspace,
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisH
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { errorNotification, successNotification } from "../../utils/notifications";
import auth from "../../utils/auth";

const GenerateShip = ({ closeModal, fetchData, rowData }) => {
    console.log(rowData)
    const [openQuant, setOpenQuant] = useState(false);
    const [state, setState] = useState({
        rpiNo: rowData.rpiNo,
        shipmentBy: "",
        shipmentNo: "",
        shipmentDate: "",
        deliveryVehicleNo: "",
        deliveryPersonName: "",
        deliveryPersonPhone: ""
    });
    const [open, setOpen] = useState();
    const [actionShow, setActionShow] = useState()

    console.log(state)

    let submitHandler = async () => {
        let resp = await trackPromise(API.put(`/showroom-module/api/v1/returns/generateGIN`, state))
        if (resp.success == true) {
            fetchData()
            closeModal(false)
            successNotification(resp.message);
        } else {
            errorNotification(resp.message);
        }
    }

    return (
        <div className="container1">
            <div id="overlay" onClick={(e) => off(e)}></div>
            <div
                className="modalContainer"
                style={{ width: "737px", height: "450px", padding: '15px' }}
            >
                <div className="header">
                    <h3>Return Shipment</h3>
                </div>


                <section
                    style={{
                        width: '100%',
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "14px",
                        fontWeight: "500",
                        margin: "10px 0",
                        fontFamily: "sans-serif",
                        fontWeight: "420",
                        padding: '5px'
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>RPI No. : {rowData.rpiNo}</span>
                        <span>Return Qty. : {rowData.returnQty}</span>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>RPI Date : {rowData.rpiDate.slice(0, 10)}</span>
                        <span>{`Return Value (${auth.getUserInfo().currencySymbol})`} : {rowData.totalAmount}</span>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>Sales Manager : {rowData?.salesManagerEmail}</span>
                    </div>
                </section>

                <br />
                <div
                    className="body"
                    style={{ display: "flex", height: '300px', fontSize: "14px", overflow: 'scroll', margin: "-5% 0px 0px -2%" }}
                >
                    <div style={{ display: "flex", flexWrap: "wrap", margin: "21px" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                            <label><span>Shipment By</span></label>
                            <input
                                name="shipmentBy"
                                style={{ width: "95%", marginTop:"-5px" }}
                                className="input-config"
                                onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })}
                                placeholder="Shipment By"
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                            <label><span>Shipment No.</span></label>
                            <input name="shipmentNo" style={{ width: "95%", marginTop:"-5px" }} className="input-config" onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })} placeholder="Shipment No" />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                            <label><span>Shipment Date</span></label>
                            <input name="shipmentDate" type="date" style={{ width: "128%",marginTop:"-5px" }} className="input-config" onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })} />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                            <label><span>Delivery Vehicle No.</span></label>
                            <input name="deliveryVehicleNo" style={{ width: "95%", marginTop:"-5px" }} className="input-config" onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })} placeholder="Vehicle No" />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                            <label><span>Delivery Person Name</span></label>
                            <input name="deliveryPersonName" style={{ width: "95%", marginTop:"-5px" }} className="input-config" onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })} placeholder="Delivery Person Name" />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                            <label><span>Delivery Person Phone</span></label>
                            <input name="deliveryPersonPhone" type="text" pattern = "[0-9]*"style={{ width: "95%", marginTop:"-5px" }} className="input-config" onChange={(e) => setState({ ...state, [e.target.name]: e.target.value })} placeholder="Delivery Person Phone" />
                        </div>
                    </div>


                </div>

                <div className="footer">
                    <button
                        style={{
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                            padding: "5px 15px",
                            marginRight: "10px",
                            color: "#0A18A3",
                        }}
                        onClick={() => closeModal(false)}
                    >
                        <KeyboardBackspace style={{ fontSize: "17px" }} />
                        Back
                    </button>
                    <button
                        style={{
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                            padding: "5px 15px",
                            marginRight: "10px",
                            backgroundColor: "blue",
                            color: "white",
                        }}
                        onClick={() => submitHandler()}
                    >
                        Generate GIN
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GenerateShip;