import { KeyboardBackspace } from "@material-ui/icons";
import React from "react";
const ViewLabour = ({ close, rowdata }) => {
  console.log(rowdata)
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "982px", height: "426px", padding: "15px" }}
      >
        <div className="header">
          <h3>Labour Details</h3>
        </div>

        <br />
        <div className="body" style={{ height: "300px" }}>
          <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
            <h6>WO ID:{rowdata.orderID}</h6>
            <h6>WO Type:{rowdata.workType}</h6>
          </div>
          <div style={{ overflow: "auto", height: "116px" }}>
            <table className="viewcomp_tbl" style={{ width: "100%" }} >
              <thead>
                <tr>
                  <th>Labour</th>
                  <th>Labour Level</th>
                  <th>Required Time</th>
                  <th>Required Labour</th>
                </tr>
              </thead>
              <tbody>
                {rowdata.hasOwnProperty('bomLabor') ?
                  rowdata.bomLabor.map((item) => {
                    console.log(item)
                    return (
                      <tr>
                        <td className="leftcell">{item.laborCategory}</td>
                        <td>{item.laborLevel}</td>
                        <td>{`${item.requiredDuration} ${item.laborDuration}`}</td>
                        <td className="rightcell">{item.requiredWorkers}</td>
                      </tr>
                    )
                  }) :
                  rowdata.requiredLabor.map((item) => {
                    return (
                      <tr>
                        <td className="leftcell">{item.laborCategory}</td>
                        <td>{item.laborLevel}</td>
                        <td>{item.requiredDuration + item.Duration}</td>
                        <td className="rightcell">{item.requiredWorkers}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
{/* 
          <div style={{ width: "100%", display: "flex", flexDirection: "column", textAlign: "left" }}>
            <h6>Request Reason</h6>
            <div className="reqreason">Components Exhaust Manifold  10 qty are damanged</div>
          </div> */}
        </div>

        <br />
        <div className="footer">
          <button onClick={() => close(false)}><KeyboardBackspace /> Back</button>
        </div>
      </div>
    </div>
  );
};

export default ViewLabour;