import React, { useState } from "react";
import auth from "../../../../utils/auth";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import { successNotification,errorNotification } from "../../../../utils/notifications";

const ReactivateComponent = ({ closeModal, editData, updatedData }) => {
    const [formValue, setFormValue] = useState({
        reactivationReason: "",
        description: ""
    });

    const editDetails  = {
      status:"Reactivated",
      pricingId:editData.pricingId,
      reason: formValue.reactivationReason,
      ...(formValue.reactivationReason == "Other" ? {description:formValue.description}:{description:""})
      };

      const refresh = () => {
        updatedData()
    };
  
   

  const submitHandler = async () => {
    const resp = await trackPromise(API.put(`finance/api/v1/pricing/managehsnsac/status`, editDetails));
    
    const { success,message} = resp;
    
    if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        refresh()
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
  };

    return (
        <div className="container1">
            <div className='modalContainer'>
            <div className="header">
                <h3>Reactivate Component</h3>
            </div>

            <div className="body">
          <label>
            <select
              name="reactivationReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            >
              <option disabled selected hidden>
                Reactivation Reason
              </option>
              <option value=" Deactivation Revoked">
              Deactivation Revoked
              </option>
              <option value="Required Now">
              Required Now
              </option>
              <option value="Other">Other</option>
            </select>
          </label>
            
          {formValue.reactivationReason === "Other" ?   
          <label className="lgInput">
            Description
            <input
              type="text"
              style={{ width: "508px" }}
              name="description"
              value={formValue.description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Reactivation Reason"
              autoComplete="off"
            />
          </label> : ""}

        </div>
            <div className="footer">
                <button onClick={() => closeModal(false)}>Cancel</button>
                <button id='submit-button' onClick={submitHandler}>Submit</button>
            </div>
        </div>
        </div>
        
    );
};

export default ReactivateComponent;
