import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import UserChargesConfiguration from "./Components/UserChargesConfiguration";
// import SubscriptionPlan from "./Components/SubscriptionPlans";
// import ManageSubscription from "./Components/ManageSubscriptionPlan";
// import BusinessSubscription from "./Components/BusinessSubscription";

const UserChargesConfiguration = React.lazy(()=>import("./Components/UserChargesConfiguration"))
const SubscriptionPlan = React.lazy(()=>import("./Components/SubscriptionPlans"))
const ManageSubscription = React.lazy(()=>import("./Components/ManageSubscriptionPlan"))
const BusinessSubscription = React.lazy(()=>import("./Components/BusinessSubscription"))



const tabs = [
  {
    id: "SYM1T1",
    label: TabLabels.USER_CHARGES_CONFIGURATION,
    Component: UserChargesConfiguration,
  },
  {
    id: "SYM1T2",
    label: TabLabels.SUBSCRIPTION_PLAN,
    Component: SubscriptionPlan,
  },
  {
    id: "SYM1T3",
    label: TabLabels.MANAGE_SUBSCRIPTION,
    Component: ManageSubscription,
  },
  {
    id: "SYM1T4",
    label: TabLabels.BUSINESS_SUBSCRIPTION,
    Component: BusinessSubscription,
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map((tab) => tab.ID);
  return tabs.filter((tab) => tabIds.includes(tab.id));
};

const SystemAdminSubscription = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default SystemAdminSubscription;
