import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../../components/modal/modal.scss";
import { successNotification, errorNotification } from "../../../utils/notifications";
import auth from "../../../utils/auth";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Reactivate = ({ updatedData, editData, closeModal }) => {
  const [formValue, setFormValue] = useState({
    reactivationReason: "",
    description: "",
  });

  const editBody = { ...editData, requestAction: "Reactivation Requested", requestedReason: formValue.reactivationReason, requestedDescription: formValue.description };

  const refresh = () => {
    updatedData();
  }

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(`nationalSales-module/api/v1/users/requestAction`, editBody)
    );
    const { success, message } = resp;
    if (success) {
      closeModal(false);
      successNotification(message);
      refresh();
    } else {
      errorNotification(message);
    }
  }


  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Reactivate User</h3>
        </div>

        <div className="body">
          <label style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "83px" }}>Reactivate Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="reactivationReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: '100%', marginTop: '-5px' }}
            >
              <option disabled selected hidden>
                Reactivation Reason
              </option>
              <option value="Activity Resumed">Activity Resumed</option>
              <option value="Technical Issues resolved">
                Technical Issues resolved
              </option>
              <option value="Returned from leaves">Returned from leaves</option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.reactivationReason === "Others" ? (
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder="Reactivation Reason"
                autoComplete="off"
              />
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} /> Cancel
          </button>
          <button
            id="submit-button"
            onClick={submitHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reactivate;
