import React from "react";
import { Tabs } from "react-simple-tabs-component";
import { TabLabels } from "../../utils/app-constants";
import "../settings-tabs/tabs.scss";
import auth from "../../utils/auth";
// import RawInventory from "./raw-inventory";
// import WIP_Inventory from "./wip-inventory";
// import FinishedGoods from "./finished_goods/finished-goods";
// import MRO_Inventory from "./mro-inventory";
// import RejectionInventory from "./rejection-inventory";

const RawInventory = React.lazy(()=>import("./raw-inventory"))
const WIP_Inventory = React.lazy(()=>import("./wip-inventory"))
const FinishedGoods = React.lazy(()=>import("./finished_goods/finished-goods"))
const MRO_Inventory = React.lazy(()=>import("./mro-inventory"))
const RejectionInventory = React.lazy(()=>import("./rejection-inventory"))



const tabs = [
  {
    id:"INM2T1",
    label: TabLabels.RAW_INVENTORY_INV,
    Component: RawInventory
  },
  {
    id:"INM2T2",
    label: TabLabels.WIP_INVENTORY_INV,
    Component: WIP_Inventory
  },
  {
    id:"INM2T3",
    label: TabLabels.FINISHED_GOODS_INV,
    Component: FinishedGoods
  },
  {
    id:"INM2T4",
    label: TabLabels.MRO_INVENTORY_INV,
    Component: MRO_Inventory
  },
  {
    id:"INM2T5",
    label: TabLabels.REJECTION_INVENTORY_INV,
    Component: RejectionInventory
  },
];

const getTabs = (tabs) => {
  let tabIds = auth.getUserInfo().tabID.map(tab => tab.ID);
  return tabs.filter(tab => tabIds.includes(tab.id));
};

const Inventory_INV = () => {
  let tabsToShow = getTabs(tabs);
  return (
    <div className="app-layout__card">
      <Tabs tabs={tabsToShow} type="pills" />
    </div>
  );
};

export default Inventory_INV;