import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFilter, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../components/modal/modal.scss";
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ViewComponentSNo from "./inventory-actions/inv-componentsno";
import { errorNotification } from "../../utils/notifications";
import handleExport from "../../utils/exportExcel";

// const { ExportCSVButton } = CSVExport;

const RejectedVehicle = () => {
    const { SearchBar } = Search;
    const [vehicleData, setVehicleData] = useState([]);
    const [show, setShow] = useState(false);
    const [formValue, setFormValue] = useState({
        orderID: "",
        vehicleModel: "",
        vehicleColor: "",
        addedDate: ""
    });

    const fetchReport = async () => {
        const resp = await trackPromise(API.get(`production-module/api/v1/Inventory/rejectedVehicle?orderID=${formValue.orderID}&vehicleModel=${formValue.vehicleModel}&vehicleColor=${formValue.vehicleColor}&addedDate=${formValue.addedDate}`));
        console.log(resp);
        resp.success == false ? errorNotification(resp.message, { autoClose: 3000 }) : setVehicleData(resp);
    };
    useEffect(() => {
        fetchReport();
    }, []);

    const columns = [
        {
            text: "S.No",
            dataField: "id",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "50px" },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return vehicleData.findIndex(function (value, idx, arr) { return value == row }) + 1;
            },
            sort: true,
        },
        {
            text: "WO ID",
            dataField: "orderID",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "150px" },
        },
        {
            text: "Category",
            dataField: "vehicleCategory",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "150px" },
        },
        {
            text: "Segment",
            dataField: "vehicleSegment",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "150px" },
        },
        {
            text: "Model",
            dataField: "vehicleModel",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "150px" },
        },
        {
            text: "Variant",
            dataField: "vehicleVariant",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "150px" },
        },
        {
            text: "Type",
            dataField: "vehicleType",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "150px" },
        },
        {
            text: "Color",
            dataField: "vehicleColor",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "150px" },
        },
        {
            text: "SNO",
            dataField: "SNO",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "150px" },
        },
        {
            text: "VIN",
            dataField: "VIN",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "150px" },
        },
        {
            text: "BIN",
            dataField: "BIN",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "150px" },
        },
        {
            text: "Rejected Reason",
            dataField: "reason",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "200px" },
            formatter: (cell, row, rowIndex) => {
                if (row.reason == "Others") {
                    return row.reasonDescription
                } else {
                    return row.reason
                }
            }
        },
        {
            text: "Verification By",
            dataField: "addedBy",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "200px" },
        },
        {
            text: "Verification Date",
            dataField: "addedDate",
            headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
            headerAttrs: { width: "150px" },
            formatter: (cell) => {
                let date = cell;
                if (!cell) {
                    return "N/A";
                }
                return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
            }
        }
    ];

    const handleChange = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };
    const clearFilter = async (e) => {
        setFormValue({
            orderID: "",
            vehicleModel: "",
            vehicleColor: "",
            addedDate: ""
        });

        const resp = await trackPromise(API.get(`production-module/api/v1/Inventory/rejectedVehicle`));
        resp.success == false ? errorNotification(resp.message, { autoClose: 3000 }) : setVehicleData(resp);
    };
    const submitHandler = (e) => {
        e.preventDefault();
        console.log(formValue);
        console.log("Filter Applied!");
    };
    const handleClick = (e) => {
        setShow(!show);
    };

    let uniqueOrderIdArray = [...new Map(vehicleData.map((item) => [item["orderID"], item])).values()];
    let uniqueModel = [...new Map(vehicleData.map((item) => [item["vehicleModel"], item])).values()];
    let uniqueColor = [...new Map(vehicleData.map((item) => [item["vehicleColor"], item])).values()];

    return (
        <>
            <div >
                <div id="overlay" onClick={(e) => off(e)}></div>
                <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
                    <span>Rejected Vehicles</span>
                </div>

                <ToolkitProvider keyField="id" data={vehicleData} columns={columns} search >

                    {(props) => (
                        <>
                            <div>
                                <div style={{ float: "right", marginTop: "-42px" }}>
                                    <div style={{ display: "flex", marginRight: "-15px", height: "26px", marginTop: "10px", }}>
                                        <div style={{ width: "220px" }}>

                                            <SearchBar
                                                {...props.searchProps}
                                                placeholder='Search...'
                                                style={{
                                                    height: "25px",
                                                    // border: "1px solid #C6C6C6",
                                                    border: "none",
                                                    background: "white",
                                                    borderRadius: "3px",
                                                    marginRight: "-50px",
                                                }}
                                            />

                                        </div>
                                        <div onClick={clearFilter} className="filter-refresh">
                                            <FontAwesomeIcon icon={faRedoAlt} />
                                            &nbsp; Refresh
                                        </div>
                                        <div className="filter-refresh" onClick={handleClick}>
                                            {" "}
                                            <FontAwesomeIcon icon={faFilter} />
                                            &nbsp; Filters
                                        </div>
                                        <div
                                            className="filter-refresh"
                                            onClick={() => handleExport(vehicleData, "manageUsers")}
                                        >
                                            <FontAwesomeIcon icon={faDownload} />
                                            &nbsp; Export
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>

                                {show && (
                                    <>
                                        <form
                                            id="abc"
                                            onSubmit={submitHandler}
                                            className="RA_block"
                                            style={{
                                                height: "80px",
                                                justifyContent: "flex-start",
                                                display: "flex",
                                                flexWrap: "wrap",
                                                flexDirection: "row",
                                                backgroundColor: "#F7F7F7",
                                                padding: "5px",
                                            }}
                                        >
                                            <div className="bomfilter_fields">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "19%",
                                                    }}
                                                >
                                                    <label style={{ fontSize: "13px", fontWeight: "500" }}>
                                                        WO ID
                                                    </label>
                                                    <select name="orderID"
                                                        value={formValue.orderID}
                                                        onChange={handleChange}
                                                        style={{ marginTop: "-5px", width: "100%" }}
                                                    >
                                                        <option value="" disabled selected>WO ID</option>
                                                        {uniqueOrderIdArray.map((item) => {
                                                            return (
                                                                <option>{item.orderID}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "19%",
                                                    }}
                                                >
                                                    <label style={{ fontSize: "13px", fontWeight: "500" }}>
                                                        Vehicle Model
                                                    </label>
                                                    <select
                                                        // required={true}
                                                        name="vehicleModel"
                                                        value={formValue.vehicleModel}
                                                        onChange={handleChange}
                                                        style={{ marginTop: "-5px", width: "100%" }}
                                                    >
                                                        <option value="" disabled selected>
                                                            Vehicle Model
                                                        </option>
                                                        {uniqueModel.map((item) => {
                                                            return (
                                                                <option>{item.vehicleModel}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "19%",
                                                    }}
                                                >
                                                    <label style={{ fontSize: "13px", fontWeight: "500" }}>
                                                        Vehicle Color
                                                    </label>
                                                    <select
                                                        // required={true}
                                                        name="vehicleColor"
                                                        value={formValue.vehicleColor}
                                                        onChange={handleChange}
                                                        style={{ marginTop: "-5px", width: "100%" }}
                                                    >
                                                        <option value="" disabled selected>
                                                            Vehicle Color
                                                        </option>
                                                        {uniqueColor.map((item) => {
                                                            return (
                                                                <option>{item.vehicleColor}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "19%",
                                                    }}
                                                >
                                                    <label style={{ fontSize: "13px", fontWeight: "500" }}>
                                                        Added Date
                                                    </label>
                                                    <input
                                                        type="text"
                                                        onFocus={(e) => (e.currentTarget.type = "date")}
                                                        onBlur={(e) => (e.currentTarget.type = "text")}
                                                        name="addedDate"
                                                        value={formValue.addedDate}
                                                        onChange={handleChange}
                                                        placeholder="Added Date"
                                                        autoComplete="off"
                                                        style={{ marginTop: "-5px", width: "100%" }}
                                                    />
                                                </div>
                                                <div style={{ margin: "21px 0px 0px 0px" }}>
                                                    <button
                                                        type="submit"
                                                        id="apply"
                                                        style={{ width: "96px", height: "35px" }}
                                                        onClick={fetchReport}
                                                    >
                                                        Apply
                                                    </button>
                                                    <button
                                                        onClick={clearFilter}
                                                        id="clear"
                                                        style={{ width: "96px", height: "35px" }}
                                                    >
                                                        Clear Filters
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </>
                                )}
                                <br></br>

                            </div>


                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                // rowClasses="text-nowrap"
                                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                                pagination={paginationFactory({
                                    hidePageListOnlyOnePage: true,
                                    showTotal: true,
                                    hideSizePerPage: true,
                                    paginationSize: 2,
                                    sizePerPage: "10",
                                })}
                                noDataIndication="No Data Found"
                                {...props.baseProps}
                            />
                        </>
                    )}
                </ToolkitProvider>
            </div>
        </>
    );
}
export default RejectedVehicle