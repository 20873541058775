import React, { useEffect, useState } from "react";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
  ConfirmationNumber,
} from "@material-ui/icons";
import moment from "moment";

const AssignedSpares = ({ closeModal, rowData }) => {
  console.log(rowData);
  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "600px", height: "420px", padding: "15px" }}
      >
        <div className="header" style={{ marginTop: "-10px" }}>
          <h3>Assigned Spares</h3>
        </div>
        <br />
        <div
          className="body"
          style={{
            display: "flex",
            height: "450px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontSize: "13px", fontWeight: "500" }}>
                SKU Code: {rowData.skuCode}
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontSize: "13px", fontWeight: "500" }}>
                Spare Part Name: {rowData.sparePartName}
              </span>
            </div>
            <br />
            <div style={{ overflow: "scroll" }}>
              <div id="POVendorHeading">
                <div style={{ width: "10%" }}>S. No.</div>
                <div style={{ width: "30%" }}>Part S.No</div>
                <div style={{ width: "30%" }}>PO Number</div>
                <div style={{ width: "30%" }}>Assignment Date</div>
              </div>
              <div>
                  {rowData.spares.map((item, index) => {
                    return (
                      <div
                        id="POVendorContent"
                        style={{
                          borderBottom: "1px solid #999999",
                          boxShadow: "none",
                          padding: "0px",
                        }}
                      >
                        <div style={{ width: "10%", overflow: "scroll" }}>
                          {index + 1}
                        </div>
                        <div style={{ width: "30%", overflow: "scroll" }}>
                          {item?.partSno}
                        </div>
                        <div style={{ width: "30%", overflow: "scroll" }}>
                          {item?.poNo}
                        </div>
                        <div style={{ width: "30%", overflow: "scroll" }}>
                          {moment(item?.assignmentDate).format("DD-MMM-YYYY/HH:mm")}
                        </div>
                      </div>
                    );
                  })}
               
              </div>
            </div>
          </section>
        </div>

        <div
          className="footer"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%", textAlign: "end" }}>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace style={{ fontSize: "20px" }} />
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignedSpares;
