import React from 'react'
import {
    CancelOutlined,
    CheckCircle,
  } from "@material-ui/icons";
import { successNotification,errorNotification } from "../../../../utils/notifications"
import API from "../../../../utils/api";
import { trackPromise } from "react-promise-tracker";




const Approve = ({closeModal, data, updatedData,extraData}) => {
const [remarks, setRemarks] = React.useState("");
  console.log(data)

  const refresh = () => {
    updatedData();
  }

  const submitHandler = async () => {
    const resp = await trackPromise(API.put(`finance/api/v1/newrequest/actionstatus`, {
        BOMID:data.BOMID,
        POID:data.POID,
        status:"Approved By Finance",
        remarks:remarks
    }))  
           
    const { success,message} = resp;

    if (success) {

      closeModal(false)
        successNotification(message, { autoClose: 3000 });
        refresh()
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
  }

  return (
    <div className='container1'>
            <div className="modalContainer" style={
                {width:'400px',height:'265px'}
            }>

            <div className="body" style={{justifyContent:'center',fontSize:'16px',paddingTop:"21px",  fontWeight:"500"}}>
                    Are you sure to {extraData == "release" && (`release &`)} Approve this PO ?
                
            </div>
            <div style={{
              width:"87%",
              display:"flex",
              flexDirection:"column",
              justifyContent:"start",
              textAlign:"start",
              margin:"0% 6%",
              fontSize:"14px",
              fontWeight:"500"
            }}>

              <label htmlFor="">Add Remarks</label>
              <input type="text" name="remarks" value={remarks} style={{width:"100%", border:"1px solid black"}} placeholder='Add Remarks' onChange={(e)=>setRemarks(e.target.value)}/>
            </div>
            <div className="footer" style={{justifyContent:"center"}}>
          <button
            style={{
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              marginRight: "10px",
              background: 'white',
              color: "#0A18A3",
            }}
            onClick={() => closeModal(false)}
          >
            <CancelOutlined style={{ fontSize: "17px" }} />
            Cancel
          </button>
          <button
            style={{
              background: "#0A18A3",
              border: "1px solid #0A18A3",
              borderRadius: "3px",
              padding: "5px 10px",
              color: "white",
            }}
            onClick={()=>{submitHandler()}}
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            Confirm
          </button>

        </div>
        </div>
        </div>
  )
}

export default Approve;