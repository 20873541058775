import React from 'react'
import './SearchBox.scss'
import { Search } from "@material-ui/icons";
import { useState, useEffect } from 'react'

const SearchBox = ({ data, setData, setFilterData }) => {

  const [search, setSearch] = useState('')

  const submitHandler = () => {
    if (search == '') {
      setData(data)
      setFilterData()
    }
    else {
      const reg = new RegExp(search, 'i')
      const filterData = data.filter((item, i) => {
        return item.requestCategory.match(reg) || item.requestID.match(reg) || item.requestType.match(reg)
          || item.requestedBy.match(reg) || item.status.match(reg)
      })
      setFilterData(filterData)
    }
  }


  return (
    <div className='searchBoxOutside'>
      <Search onClick={() => { submitHandler() }} style={{ color: 'white' }} />
      <input className="searchBoxField" value={search} onChange={(e) => setSearch(e.target.value)} type="text" name="search" placeholder='Search...' />
    </div>
  )
}

export default SearchBox