import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AppConstants from "../../../utils/app-constants";
import RequisitionActions from "./RequisitionActions";
import RequisitionDetails from "./RequisitionDetails";
import SpareRequisitionDetails from "../SpareRequisitionDetails";

const { ExportCSVButton } = CSVExport;

const SpareNewRequisition = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [viewSpecs, setViewSpecs] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState();

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `dealer-module/api/v1/requisitionAndOrder/spares?requisitionID=${formValue.requestID}&serviceCenterID=${formValue.serviceCenterID}&serviceCenterName=${formValue.serviceCenterName}&serviceCenterLocation=${formValue.serviceCenterLocation}&status=${formValue.status}&requisitionDate=${formValue.requestDate}`
      )
    );
    console.log(resp);
    let filtered = resp.filter((item) => item.status != "PO Generated");
    setUsersData(filtered);
  };
  useEffect(() => {
    fetchReport();
  }, []);




  const uniquerequisitionID = [...new Set(UsersData.map((item) => item.requisitionID))]
  const uniqueserviceCenterID = [...new Set(UsersData.map((item) => item.serviceCenterID))]
  const uniqueserviceCenterName = [...new Set(UsersData.map((item) => item.serviceCenterName))]
  const uniqueserviceCenterLocation = [...new Set(UsersData.map((item) => item.serviceCenterLocation))]
  const uniquestatus = [...new Set(UsersData.map((item) => item.status))]





  const specsHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    setViewSpecs(true);
  };

  const actionHandler = (rowIndex) => {
    setActionShow(rowIndex);
    setOpen(true);
    on();
  };

  const specsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => specsHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          View Details
        </button>
        {actionShow === rowIndex && viewSpecs ? (
          <SpareRequisitionDetails
            rowData={row}
            status={true}
            closeModal={setViewSpecs}
            updatedData={fetchReport}
          />
        ) : null}
      </div>
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.status == "New Request" || row.status == "On Hold") {
      return (
        <div>
          <button
            className="action-btn"
            onClick={() => actionHandler(rowIndex)}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </button>
          {actionShow == rowIndex && open ? (
            <RequisitionActions
              extraData="spare"
              rowData={row}
              updatedData={fetchReport}
              actionShow={setActionShow}
              rowIndex={rowIndex}
            />
          ) : null}
        </div>
      );
    }
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    return cell === "New Request" ? (
      <button className="reactive-btn">
        <span className="reactive-btn-font">New Request</span>
      </button>
    ) : cell === AppConstants.ONHOLD ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">On Hold</span>
      </button>
    ) : cell === "Requisition Rejected" ? (
      <button className="delete-btn">
        {" "}
        <span className="delete-btn-font">Requisition Rejected</span>
      </button>
    ) : cell === "PO Generated" ? (
      <button className="active-btn">
        {" "}
        <span className="active-btn-font">PO Generated</span>
      </button>
    ) : (
      cell
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requisitionID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Request Date",
      dataField: "requisitionDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: dateFormatter,
    },
    {
      text: "Requisition Details",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "180px" },
      formatExtraData: { viewSpecs, actionShow },
      formatter: specsFormatter,
    },
    {
      text: "Service Center ID",
      dataField: "serviceCenterID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Service Center Name",
      dataField: "serviceCenterName",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Service Center Location",
      dataField: "serviceCenterLocation",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "250px" },
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: statusFormatter,
    },
    {
      text: "Reason",
      dataField: "reason",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      classes: "cellsOverflow",
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { open, actionShow },
      formatter: actionFormatter,
    },
  ];

  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    requestID: "",
    requestDate: "",
    serviceCenterID: "",
    serviceCenterName: "",
    serviceCenterLocation: "",
    status: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = (e) => {
    setFormValue({
      requestID: "",
      requestDate: "",
      serviceCenterID: "",
      serviceCenterName: "",
      serviceCenterLocation: "",
      status: "",
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>New PO Requisitions</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "manageUsers")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form
                    id="abc"
                    className="RA_block"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      height: "125px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="bomfilter_fields"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request ID
                        </label>
                        <select
                          name="requestID"
                          value={formValue.requestID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Request ID
                          </option>
                          {uniquerequisitionID.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Date
                        </label>
                        <input
                          name="requestDate"
                          type="date"
                          value={formValue.requestDate}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Service Center ID
                        </label>
                        <select
                          name="serviceCenterID"
                          value={formValue.serviceCenterID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Service Center ID
                          </option>
                          {uniqueserviceCenterID.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Service Center Name
                        </label>
                        <select
                          name="serviceCenterName"
                          value={formValue.serviceCenterName}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Service Center Name
                          </option>
                          {uniqueserviceCenterName.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Service Center Location
                        </label>
                        <select
                          name="serviceCenterLocation"
                          value={formValue.serviceCenterLocation}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Service Center Location
                          </option>
                          {uniqueserviceCenterLocation.map((item) => {
                            return (
                              <option>{item}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          {uniquestatus.map((item) => {
                            return <option>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div
                        style={{
                          width: "80%",
                          textAlign: "end",
                          marginTop: "auto",
                        }}
                      >
                        <button
                          onClick={fetchReport}
                          id="apply"
                          style={{ width: "96px", height: "35px" }}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ width: "96px", height: "35px" }}
                        >
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default SpareNewRequisition;
