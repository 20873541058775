import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import "./homesettings.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faPlusCircle,
  faTimesCircle,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import ProductionLocation from "./productionLocation";
import BusinessLocation from "./bussinessLocation";
import EditLocation from "./showEdit";
import RemoveLocation from "./removeLocation";
import {
  errorNotification,
  infoNotification,
  successNotification,
} from "../../utils/notifications";
import auth from "../../utils/auth";
import { MdOutlineCancel } from "react-icons/md";

const BussinessProfile = () => {
  const [prodLocation, setProdLocation] = useState();
  const [showEdit, setEditLocation] = useState();
  const [showEditProd, setEditProdLocation] = useState();
  const [showRemoveProd, setRemoveProdLocation] = useState();
  const [showEditStorage, setEditStorageLocation] = useState();
  const [businessUserInfo, setBusinessUserInfo] = useState({});
  const [prodArray, setProdArray] = useState([]);
  const [bussArray, setBussArray] = useState([]);
  const [storageArray, setStorageArray] = useState([]);
  const [storageLocation, setStorageLocation] = useState();
  const [bussLocation, setBussLocation] = useState();
  const [onEditClick, setEditClick] = useState(false);
  const [actionShow, setActionShow] = useState();
  const [panfile, setPanFile] = useState();
  const [gstfile, setGstFile] = useState();
  const [businessLogo, setBusinessLogo] = useState();

  const fetchReport = async () => {
    const response = await trackPromise(
      API.get(`admin-module/api/v1/users/getBusinessUserInfo`)
    );
    console.log(response);
    setBusinessUserInfo(response);
    setFormValue({
      businessName: response?.businessName,
      businessEmail: response?.email,
      pan: response?.pan,
      gstin: response?.gstin,
      panDoc: panfile,
      gstinDoc: gstfile,
      businessLogo: businessLogo,
    });
    let prodLocationArray = response.locations.filter(
      (item) => item.locationType == "Production"
    );
    setProdArray(prodLocationArray);
    let bussLocationArray = response.locations.filter(
      (item) => item.locationType == "Business"
    );
    setBussArray(bussLocationArray);
    let storageLocationArray = response.locations.filter(
      (item) => item.locationType == "Storage"
    );
    setStorageArray(storageLocationArray);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  // console.log(prodArray, bussArray, storageArray);

  console.log("-->", businessLogo)

  const [formValue, setFormValue] = useState({});

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) {
  //     const fileSize = selectedFile.size;
  //     const allowedFileSize = 5 * 1024 * 1024; // 5MB in bytes

  //     if (fileSize <= allowedFileSize) {
  //       if (event.target.name == "panDoc") {
  //         setPanFile(selectedFile);
  //       } if (event.targe.name == "businessLogo") {
  //         setBusinessLogo(selectedFile)
  //       } else {
  //         setGstFile(selectedFile)
  //       }
  //     } else {
  //       infoNotification('File size exceeds 5MB limit. Please select a smaller file.');
  //     }
  //   }
  // };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("pp-->", event.target.name)
    if (selectedFile) {
      const fileSize = selectedFile.size;
      const allowedFileSize = 5 * 1024 * 1024; // 5MB in bytes

      if (fileSize <= allowedFileSize) {
        // Validate file types
        const allowedFileTypes = [
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          "application/pdf",
          "image/jpeg",
          "image/png",
          "image/gif",
        ];

        if (!allowedFileTypes.includes(selectedFile.type)) {
          infoNotification("Please select a valid file type.");
          return;
        }

        // Assign file based on input name
        if (event.target.name === "panDoc") {
          setPanFile(selectedFile);
        }
        if (event.target.name == "gstinDoc") {
          setGstFile(selectedFile);
        }
        if (event.target.name === "businessLogo") {
          setBusinessLogo(selectedFile);
        }
      } else {
        infoNotification(
          "File size exceeds 5MB limit. Please select a smaller file."
        );
      }
    }
  };



  const clear = () => {
    document.getElementById("panNo").value = "";
    document.getElementById("gstNo").value = "";
    document.getElementById("pancard").value = "";
    document.getElementById("gstcard").value = "";
    document.getElementById("business").value = "";
  };



  const validateInputs = () => {
    // Define regex patterns
    const panRegex = /^[a-zA-Z0-9]{10}$/;
    //const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1-9A-Z][0-9A-Z]$/;
    const gstinRegex = /^[a-zA-Z0-9]{15}$/;
    const emailRegex = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/;
    const businessNameRegex = /^[a-zA-Z0-9\s]+$/;

    if (!formValue.businessName.trim()) {
      errorNotification("Business name is required.");
      return false;
    } else if (!businessNameRegex.test(formValue.businessName.trim())) {
      errorNotification(
        "Business name should contain only alphanumeric characters and spaces."
      );
      return false;
    }

    if (!emailRegex.test(formValue.businessEmail.trim())) {
      errorNotification("Please enter a valid business email address.");
      return false;
    }

    if (!panRegex.test(formValue.pan.trim())) {
      errorNotification("Please enter a valid PAN number.");
      return false;
    }

    if (!gstinRegex.test(formValue.gstin.trim())) {
      errorNotification("Please enter a valid GSTIN number.");
      return false;
    }

    // Validate file inputs
    if (!panfile) {
      errorNotification("Please upload PAN document.");
      return false;
    }

    if (!gstfile) {
      errorNotification("Please upload GSTIN document.");
      return false;
    }

    if (!businessLogo) {
      errorNotification("Please upload business logo.");
      return false;
    }

    return true;
  };

  const handleUploadClick = async () => {
    // if (!validateInputs()) {
    //   return;
    // }

    const formData = new FormData();
    formData.append("userID", auth.getUserInfo().userID);
    formData.append("businessName", formValue.businessName);
    formData.append("bussinessEmail", formValue.businessEmail);
    formData.append("pan", formValue.pan);
    formData.append("gstin", formValue.gstin);
    formData.append("panDoc", panfile);
    formData.append("gstinDoc", gstfile);
    formData.append("businessLogo", businessLogo);
    const resp = await trackPromise(
      API.put("admin-module/api/v1/admin/update", formData)
    );
    const { success, message } = resp;
    if (success) {
      successNotification(message);
    } else {
      errorNotification(message);
    }
  };

  let check = businessUserInfo?.locations?.some((item) => {
    return item.locationType == "Business";
  });

  console.log(check);

  return (
    <>
      <div className="bussiness">
        <h4 className="heading">Bussiness Profile</h4>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            marginLeft: "5px",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "24%",
              marginRight: "10px",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "5px",
              }}
            >
              Business Name
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              disabled={onEditClick == true ? false : true}
              className="input-config"
              id="business"
              type="text"
              style={{
                width: "100%",
                outline: "none",
                marginTop: "-8px",
                marginLeft: "0px",
              }}
              name="businessName"
              defaultValue={formValue?.businessName}
              placeholder="Business Name"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "24%",
              marginRight: "10px",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "5px",
              }}
            >
              Business Email
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              disabled
              className="input-config"
              style={{
                width: "100%",
                outline: "none",
                marginTop: "-8px",
                color: "grey",
              }}
              type="text"
              name="businessEmail"
              value={formValue?.businessEmail}
              placeholder="Bussiness E-mail Id"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "24%",
              marginRight: "10px",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "5px",
              }}
            >
              PAN No.
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              disabled={onEditClick == true ? false : true}
              className="input-config"
              style={{ width: "100%", outline: "none", marginTop: "-8px" }}
              type="text"
              id="panNo"
              name="pan"
              defaultValue={formValue.pan}
              placeholder="PAN No."
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "24%",
              marginRight: "10px",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "5px",
              }}
            >
              PAN Card
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              disabled={onEditClick == true ? false : true}
              className="input-config"
              style={{
                width: "100%",
                outline: "none",
                marginTop: "-8px",
                padding: "14px",
              }}
              type="file"
              name="panDoc"
              id="pancard"
              placeholder="Pan card"
              value={formValue.panDoc}
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: "5px",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "24%",
              marginRight: "10px",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "0px",
              }}
            >
              GSTIN No.
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              disabled={onEditClick == true ? false : true}
              style={{
                width: "100%",
                outline: "none",
                marginTop: "-8px",
                marginLeft: "0px",
              }}
              className="input-config"
              type="text"
              name="gstin"
              id="gstNo"
              defaultValue={formValue.gstin}
              placeholder="GSTIN No."
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "24%",
              marginRight: "10px",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "0px",
              }}
            >
              GSTIN Document
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              disabled={onEditClick == true ? false : true}
              style={{
                width: "100%",
                outline: "none",
                marginTop: "-8px",
                marginLeft: "0px",
                padding: "14px",
              }}
              className="input-config"
              id="gstcard"
              type="file"
              name="gstinDoc"
              value={formValue.gstinDoc}
              placeholder="GSTIN Document"
              onChange={handleFileChange}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "24%",
              marginRight: "10px",
            }}
          >
            <label
              style={{
                fontSize: "13px",
                fontWeight: "500",
                width: "auto",
                marginLeft: "0px",
              }}
            >
              Business Logo
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              disabled={onEditClick == true ? false : true}
              style={{
                width: "100%",
                outline: "none",
                marginTop: "-8px",
                marginLeft: "0px",
                padding: "14px",
              }}
              className="input-config"
              id="gstcard"
              type="file"
              name="businessLogo"
              value={formValue.businessLogo}
              placeholder="Business Logo"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: "5px",
            flexDirection: "row",
            width: "100%",
            justifyContent: "end"
          }}
        >
          <div style={{ margin: "22px 0px 0px 0px" }} className="save-cancel">
            <div className="cancel-button">
              <button
                type="button"
                className="btn btn-icon-label"
                onClick={() => setEditClick(true)}
              >
                <div
                  style={{
                    fontSize: "14px",
                    color: "#0A18A3",
                    fontWeight: "500",
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} /> Edit
                </div>
              </button>
            </div>
            <div className=" cancel-button">
              <button
                disabled={onEditClick == true ? false : true}
                className="btn btn-icon-label"
                onClick={handleUploadClick}
                style={{ border: "none" }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    color: "#0A18A3",
                    fontWeight: "500",
                  }}
                >
                  <FontAwesomeIcon icon={faSave} />
                  Update
                </div>
              </button>
            </div>
            {/* <div className=" cancel-button">
                            <button disabled={onEditClick == true ? false : true} className="btn btn-icon-label" onClick={onFileUpload}>
                                <div style={{ fontSize: "14px", color: "#0A18A3", fontWeight: "500" }}><FontAwesomeIcon icon={faSave} />Upload File</div>
                            </button>
                        </div> */}
            <div className="cust-reset  cancel-button">
              <button
                type="button"
                className="btn btn-icon-label"
                onClick={clear}
              >
                <div
                  style={{
                    fontSize: "14px",
                    color: "#0A18A3",
                    fontWeight: "500",
                  }}
                >
                  <FontAwesomeIcon icon={faTimesCircle} /> Cancel
                </div>
              </button>
            </div>
          </div>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderTop: "1px solid lightgray",
            marginLeft: "-15px",
          }}
        >
          <div style={{ margin: "31px 0px 0px 16px" }}>
            <button
              disabled={bussArray.length > 0 ? true : false}
              onClick={() => setBussLocation(true)}
              className="cancel-button"
              style={{ background: "white", color: bussArray.length > 0 ? "grey" : "#0A18A3", border: bussArray.length > 0 ? '1px solid grey' : '' }}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Add Business Location
            </button>
            {bussLocation && (
              <BusinessLocation
                updatedData={fetchReport}
                locationType={"Business"}
                closeModal={setBussLocation}
              />
            )}
            <button
              disabled={check == false ? true : false}
              onClick={() => setProdLocation(true)}
              className="cancel-button"
              style={{ background: "white", color: "#0A18A3" }}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Add Production Location
            </button>
            {prodLocation && (
              <ProductionLocation
                updatedData={fetchReport}
                locationType={"Production"}
                closeModal={setProdLocation}
              />
            )}
            <button
              disabled={check == false ? true : false}
              onClick={() => setStorageLocation(true)}
              className="cancel-button"
              style={{ background: "white", color: "#0A18A3" }}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Add Storage Location
            </button>
            {storageLocation && (
              <ProductionLocation
                updatedData={fetchReport}
                locationType={"Storage"}
                closeModal={setStorageLocation}
              />
            )}
          </div>
        </div>

        {/* ---------------Business Location------------------- */}

        {bussArray.length == 0 ? (
          ""
        ) : (
          <div>
            <br />
            <small>Business Location</small>
            <hr></hr>
            <div className="body" style={{ display: "flex", gap: "16px" }}>
              {bussArray.map((item) => {
                return (
                  <div
                    style={{
                      width: "350px",
                      height: "140px",
                      borderRadius: "5px",
                      boxShadow: "0px 0px 12px rgb(0 0 0 / 15%)",
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "16px",
                      fontWeight: "500",

                    }}
                  >
                    <div
                      style={{
                        width: "350px",
                        height: "140px",
                        borderRadius: "5px",
                        boxShadow: "0px 0px 12px rgb(0 0 0 / 15%)",
                        display: "flex",
                        flexDirection: "column",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Location ID : {item.locationID}</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Country : {item.country}</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>State : {item.city}</span>
                      </div>
                      <div
                        className="footer"
                        style={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "start",
                          marginTop: "10px",
                        }}
                      >
                        <button
                          onClick={() => setEditLocation(true)}
                          style={{
                            width: "100%",
                            background: "white",
                            color: "#0A18A3",
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                          }}
                        >
                          View & Edit
                        </button>
                        {showEdit && (
                          <EditLocation
                            locationObj={item}
                            updatedData={fetchReport}
                            locationType={"Business"}
                            closeModal={setEditLocation}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* ---------------Production Location------------------- */}

        {prodArray.length == 0 ? (
          ""
        ) : (
          <div>
            <br />
            <small>Production Locations</small>
            <hr></hr>
            <div className="body"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                overflowX: "scroll",
                padding: "12px",
                margin: "12px",
                height: "220px",
                width: "95%",
              }}>
              {prodArray.map((item, index) => {
                const editHandler = (index) => {
                  setEditProdLocation(true);
                  setActionShow(index);
                };
                const removeHandler = (index) => {
                  setRemoveProdLocation(true);
                  setActionShow(index);
                };

                return (
                  <div
                    style={{
                      width: "350px",
                      height: "140px",
                      borderRadius: "5px",
                      boxShadow: "0px 0px 12px rgb(0 0 0 / 15%)",
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "16px",
                      fontWeight: "500",

                    }}
                  >
                    <div
                      style={{
                        width: "350px",
                        height: "140px",
                        borderRadius: "5px",
                        boxShadow: "0px 0px 12px rgb(0 0 0 / 15%)",
                        display: "flex",
                        flexDirection: "column",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Location ID : {item.locationID}</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Country : {item.country}</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>State : {item.city}</span>
                      </div>
                      <div
                        className="footer"
                        style={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "start",
                          marginTop: "10px",
                        }}
                      >
                        <button
                          onClick={() => editHandler(index)}
                          style={{
                            // width: "50%",
                            background: "white",
                            color: "#0A18A3",
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                          }}
                        >
                          View & Edit
                        </button>
                        {actionShow === index && showEditProd && (
                          <EditLocation
                            locationObj={item}
                            updatedData={fetchReport}
                            locationType={"Production"}
                            closeModal={setEditProdLocation}
                          />
                        )}
                        <button
                          onClick={() => removeHandler(index)}
                          style={{
                            // width: "50%",
                            background: "white",
                            color: "red",
                            border: "1px solid red",
                            borderRadius: "3px",
                          }}
                          id="submit-button"
                        >
                          Remove
                        </button>
                        {actionShow === index && showRemoveProd && (
                          <RemoveLocation
                            locationObj={item}
                            updatedData={fetchReport}
                            locationType={"Production"}
                            closeModal={setRemoveProdLocation}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* ---------------Storage Location------------------- */}

        {storageArray.length == 0 ? (
          ""
        ) : (
          <div>
            <br />
            <small>Storage Locations</small>
            <hr></hr>
            <div className="body"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                overflowX: "scroll",
                padding: "12px",
                margin: "12px",
                height: "220px",
                width: "95%",
              }}>
              {storageArray.map((item, index) => {
                const actionStorageHandler = (index) => {
                  setEditStorageLocation(true);
                  setActionShow(index);
                };
                const removeHandler = (index) => {
                  setRemoveProdLocation(true);
                  setActionShow(index);
                };
                return (
                  <div
                    style={{
                      width: "350px",
                      height: "140px",
                      borderRadius: "5px",
                      boxShadow: "0px 0px 12px rgb(0 0 0 / 15%)",
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "16px",
                      fontWeight: "500",

                    }}
                  >
                    <div
                      style={{
                        width: "350px",
                        height: "140px",
                        borderRadius: "5px",
                        boxShadow: "0px 0px 12px rgb(0 0 0 / 15%)",
                        display: "flex",
                        flexDirection: "column",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Location ID : {item.locationID}</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Country : {item.country}</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>State : {item.city}</span>
                      </div>
                      <div
                        className="footer"
                        style={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "start",
                          marginTop: "10px",
                        }}
                      >
                        <button
                          style={{
                            // width: "50%",
                            background: "white",
                            color: "#0A18A3",
                            border: "1px solid #0A18A3",
                            borderRadius: "3px",
                          }}
                          onClick={() => actionStorageHandler(index)}
                        >
                          View & Edit
                        </button>
                        {actionShow === index && showEditStorage && (
                          <EditLocation
                            locationObj={item}
                            updatedData={fetchReport}
                            locationType={"Storage"}
                            closeModal={setEditStorageLocation}
                          />
                        )}
                        <button
                          onClick={() => removeHandler(index)}
                          style={{
                            // width: "50%",
                            background: "white",
                            color: "red",
                            border: "1px solid red",
                            borderRadius: "3px",
                          }}
                          id="submit-button"
                        >
                          Remove
                        </button>
                        {actionShow === index && showRemoveProd && (
                          <RemoveLocation
                            locationObj={item}
                            updatedData={fetchReport}
                            locationType={"Storage"}
                            closeModal={setRemoveProdLocation}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BussinessProfile;
