const NotificationMsgs = {
  success: {
    update: "Successfully updated",
    import: "Successfully imported",
    upload: "Successfully uploaded",
    row: {
      add: "Row added successfully",
      delete: "Row(s) deleted successfully",
      update: "Row updated successfully",
    },
  },
  info: {
    "no.rows.select.delete": "Select at least one row to delete",
  },
  error: {
    generic: "Something bad happened!",
  },
};

const SidebarLabels = {
  VEHICLE_WARRANTY:"Vehicle Warranty",
  BUSINESS_INVENTORY: "Business Inventory",
  BUSINESS_SALES: "Business Sales",
  SPARE_REQUEST: "Spare Request",
  SERVICE_JOBS: "Service Jobs",
  SPARE_PARTS_RETURN_AND_REQUESTS: "Spare Parts & Requests",
  WARRANTY_AND_SERVICES: "Warranty & Services",
  TEMPLATES:"Templates",
  SALES_USER: "Sales Users",
  VEHICLES_AND_SPARES: "Vehicle & Spares",
  BUSINESS_INVENTORY_AND_SALES: "Business Inventory & Sales",
  VEHICLE_AND_SPARES: "Vehicle & Spares",
  ZONE_MANAGEMENT: "Zone Management",
  DASHBOARD: "Dashboard",
  CONFIGURATION: "Configurations",
  SCAN: "Config Scan",
  RETURNS: "Returns",
  RETURN_SETTLED: "Return Settled",
  SUBSCRIPTIONS: "Subscriptions",
  JOB_REQUESTS: "Job Request",
  SPARE_PARTS: "Spare Parts",
  SHOWROOM_VEHICLE_BATTERIES: "Vehicle & Batteries",
  PO_REQUEST: "Proc. PO Request",
  SALE_REQUEST: "Sales PO Request",
  SERVICE_CENTER: "Service Centers",
  JOBS: "Jobs",
  HOME: "Home",
  SUBSCRIPTION: "Subscriptions",
  BUSINESS_ENTITIES: "Business Entities",
  USER: "Users",
  FINANCE_RETURNS: "Returns",
  BUSINESS_USERS: "Business Users",
  ROLE_MANAGEMENT: "Roles Management",
  MASTER_CONFIGURATION: "Master Configuration",
  VEHICLES: "Vehicles",
  VENDORS: "Vendors",
  COMPONENTS: "Components",
  MAPPING: "Mapping",
  ASSEMBLY: "Assembly",
  BOM: "Bill of Material",
  BOM_COSTING: "BOM Costing",
  ORDERS: "Orders",
  RETURNS: "Returns",
  ORDER_SHIPPED: "Order-Shipped",
  WORKORDER: "Work Order",
  INVENTORY: "Inventory",
  LABOUR: "Labour",
  INWARD: "Inward",
  INVENTORY_INV: "Inventory",
  APPROVAL: "Approval",
  STOCKMANAGEMENT: "Stock Management",
  OUTWARD: "Outward",
  VERIFICATION: "Verification Request",
  WORKORDERQC: "Work Order & QC",
  PRICING: "Pricing",
  SALES: "Sales",
  BUSINESS_INSIGHTS: "Business Insights",
  VEHICLE_SPARES: "Vehicle & Spares",
  REQUISITION_ORDERS: "Requisition & Orders",
  SHOWROOMS: "Showrooms",
  INVOICES_PAYMENTS: "Invoices & Payments",
  INVOICES_REQUESTED: "Invoice Requested",
  INVOICES_GENERATED: "Invoice Generated",
  SPARES: "Spares",
  CUSTOMER_SALES: "Customer & Sales",
  CREATE_JOB_CARD: "Create Job Cads",
  MANAGE_JOB_CARD: "Manage Job Cads",
  JOBS_HISTORY: "Jobs History",
  SERVICE_ADVISOR_SPARE_RETURNS: "Spare Returns",
  PLACE_RETURNS: "Place Returns",
  MANAGE_RETURNS: "Manage Returns",
};

const TabLabels = {
  VEHICLE_WARRANTY :"Vehicle Warranty",
  SKUWISE_INVENTORY:"SKU Wise Inventory",
  DETAIL_VEHICLE_INVENTORY:"Detailed Vehicle Inventory",
  ADD_CITIES: "Add Cities",
  MANAGE_CITIES: "Manage Cities",
  ADD_COMPONENT_CONFIGURATION: "Add Component Configuration",
  MANAGE_COMPONENT_CONFIGURATION: "Manage Component Configuration",
  ADD_ASSEMBLY_SERVICES: "Add Assembly Services",
  MANAGE_ASSEMBLY_SERVICES: "Manage Assembly Services",
  COMPONENTS_CONFIGURATION: "Component Configuration",
  LABOR: "Labor",
  ASSEMBLY_SERVICES: "Assembly Services",
  SERVICE_CENTER_WISE_SALES: "Service Center Wise Sales",
  DEALER_WISE_SALES: "Dealer Wise Sales",
  SHOWROOM_WISE_SALES: "Showroom Wise Sales",
  DEALER_WISE_SPARES: "Dealer Wise Spares",
  SERVICE_CENTER_WISE_SPARES: "Service Center Wise Spares",
  DEALER_WISE_VEHICLE: "Dealer Wise Vehicle",
  SHOWROOM_WISE_VEHICLE: "Showroom Wise Vehicle",
  ORDER_RETURNS: "Order Returns",
  SPARE_ORDER: "Spare Orders",
  VEHICLE_ORDER: "Vehicle Orders",
  SPARE_RETURNS: "Spare Returns",
  VEHICLE_RETURNS: "Vehicle Returns",
  ADD_WARRANTY: "Add Warranty",
  ADD_SPARE_WARRANTY: "Add Spare Warranty",
  MANAGE_WARRANTY: "Manage Warranty",
  ADD_INSURANCE: "Add Insurance",
  MANAGE_INSURANCE: "Manage Insurance",
  VEHICLE_WARRANTY: "Vehicle Warranty",
  VEHICLE_AND_SPARES: "Vehicle & Spares",
  VEHICLE: "Vehicle",
  SPARES: "Spares",
  SPARE_WARRANTY: "Spare Warranty",
  SERVICES: "Services",
  ORDER_SHIPPED: "Order-Shipped",
  SERVICE_MASTER: "Service Master",
  ADD_SERVICE_MASTER: "Add Service Master",
  SERVICE_DETAILS: "Service Details",
  MANAGE_SERVICE_MASTER: "Manage Service Master",
  SERVICE_MANUAL: "Service Manuals",
  INSURANCE: "Insurance",
  BUSINESS_ENTITIES_HISTORY: "Business Entities History",
  INVITE_BUSINESS_ENTITIES: "Invite Business Entities",
  USER_HISTORY_SALES: "User History - Sales",
  REQUESTED_UPDATES: "Requested  Updates",
  MANAGE_SALES_USERS: "Manage Sales Users",
  ADD_SALES_USER: "Add Sales Users",
  MANAGE_USER_CHARGES: "Manage User Charges",
  ADD_USER_CHARGES: "Add User Charges",
  ADD_COMPONENT_CONFIGURATION: "Add Component Configuration",
  MANAGE_COMPONENT_CONFIGURATION: "Manage Component Configuration",
  BUSINESS_ENTITIES: "Business Entities",
  ONBOARD_BUSINESS_ENTITIES: "Onboard Business Entities",
  INVOICES_AND_PAYMENTS: "Invoice & Payments",
  MANAGE_BUSINESS_ENTITIES: "Manage Business Entities",
  VERIFY_BUSINESS_DETAILS: "Verify Business Details",
  ONBOARD_BUSINESS_ENTITIES: "Onboard Business Entities",
  BUSINESS_SUBSCRIPTION: "Business Subscriptions",
  MANAGE_SUBSCRIPTION: "Manage Subscription Plan",
  SUBSCRIPTION_PLAN: "Create Subscription Plan",
  SUBSCRIBE_PLAN: "Upgrade Plan",
  SUBSCRIBED_PLAN: "Current Plan",
  USER_CHARGES_CONFIGURATION: "User Charges Configuration",
  ADD_USER: "Add Users",
  USERS: "Users",
  ASSIGNED_SPARES: "Assigned Spares",
  SPARES_ASSIGNMENTS: "Spares Assignment",
  ADD_JOB_RETURNS: "Add Job Returns",
  ADD_INVENTORY_RETURNS: "Add Inventory Returns",
  MANAGE_RETURNS: "Manage Returns",
  SPARE_PARTS: "Spare Parts",
  SERVICE_JOBS: "Service Jobs",
  PLACE_RETURNS: "Place Returns",
  PLACE_REQUISITIONS: "Place Requisitions",
  ADD_RETURNS: "Add Returns",
  RETURN_PROGRESS: "Return Progress",
  REQUISITION_PROGRESS: "Requisition Progress",
  PRODUCTION_REQUEST: "Production Request",
  REQUISITION: "Requisitions",
  VEHICLE_VERIFICATION: "Vehicle Verification",
  BATTERY_VERIFICATION: "Battery Verification",
  DETAILED_SPARES_INVENTORY: "Detailed Spares Inventory",
  SKU_WISE_INVENTORY: "SKU Wise Inventory",
  GOODS_ARRIVAL: "Goods Arrival",
  VEHICLE_ARRIVAL: "Vehicle Arrival",
  BATTERY_ARRIVAL: "Battery Arrival",
  SERVICE_CENTER_WISE: "Service Center Wise",
  SPARE_PART_WISE: "Spare Part Wise",
  MANAGE_USER: "Manage Users",
  USER_HISTORY: "Users History",
  BATTERIES: "Batteries",
  NEW_REQUISITION: "New Requisitions",
  PO_HISTORY: "PO History",
  PO_PROGRESS: "PO Progress",
  SERVICE_JOBS: "Service Jobs",
  HYBRID: "Hybrid Vehicle",
  STANDARD: "Standard Vehicle",
  CREATE_RETURN_NOTE: "Create Return Note",
  RETURN_NOTE_HISTORY: "Return Note History",
  RETURN_HISTORY: "Return History",
  REQUEST_APPROVAL: "Requested Approvals",
  COMPONENTS_RETURN: "Components Returns",
  VEHICLE_RETURN: "Vehicle Returns",
  SPARES_RETURN: "Spares Returns",
  RETURN_NOTES: "Return Notes",
  WIP_INVENTORY: "WIP Inventory",
  DELEGATE_RIGHTS: "Delegate Rights",
  MANAGE_ROLES: "Manage Roles",
  VEHICLE_ORDERS: "Vehicle Orders",
  SPARE_ORDERS: "Spare Orders",
  ADD_VENDOR: "Add Vendors",
  MANAGE_VENDOR: "Manage Vendors",
  ADD_COMPONENTS: "Add Components",
  MANAGE_COMPONENTS: "Manage Components",
  COMPONENT_VENDORS_MAPPING: "Component-Vendors Mapping",
  MAP_COMPONENT_VENDORS: "Map Component-Vendors",
  MAPPED_COMPONENT_VENDORS: "Mapped Component-Vendors",
  LABOR_COSTING: "Labor Costing",
  LABOR: "Labor",
  ADD_LABOR_COSTING: "Add Labor Costing",
  MANAGE_LABOR_COSTING: "Manage Labor Costing",
  SPARE_PRICES: "Spare Prices",
  ASSEMBLY: "Assembly",
  COMPONENTS_CONFIGURATION: "Components Configuration",
  ASSEMBLY_COSTING: "Assembly Costing",
  ADD_ASSEMBLY_AND_SUB_ASSEMBLY_COSTING: "Add Assembly/Sub-Assembly Costing",
  MANAGE_ASSEMBLY_AND_SUB_ASSEMBLY_COSTING:
    "Manage Assembly/Sub-Assembly Costing",
  ADD_HSN_SAC: "HSN/SAC Management",
  PLACE_ORDERS: "Place Orders",
  SPARE_PRICING: "Spare Pricing",
  MANAGE_ORDERS: "Manage Orders",
  GOOD_RECEIVED_NOTE: "Good Received Note",
  ORDER_HISTORY: "Order History",
  RETURN_HISTORY: "Return History",
  Good_RETURN_MANAGEMENT: "Good Return Management",
  ADD_VEHICLES: "Add Vehicles",
  VEHICLE_MASTER: "Vehicle Master",
  MANAGE_VEHICLES: "Manage Vehicles",
  ADD_ASSEMBLY: "Add Assembly",
  CONFIGURE_ASSEMBLY: "Configure Assembly Services",
  ADD_SERVICES: "Add Services",
  MANAGE_SERVICES: "Manage Services",
  MANAGE_ASSEMBLY: "Manage Assembly",
  ADD_BOM: "Add BOM",
  MANAGE_BOM: "Manage BOM",
  CREATE_WORKORDER: "Create Work Order",
  MANAGE_WORKORDER: "Manage Work Order",
  MANAGE_ADDON: "Manage Add-On/Replacement BOM Requests",
  ADD_LABOUR: "Add Labor",
  MANAGE_LABOUR: "Manage Labor",
  WIP_INVENTORY: "WIP Inventory",
  FINISHED_GOODS_STATUS: "Finished Goods Status",
  FINISHED_GOODS_INVENTORY: "Finished Goods Inventory",
  RETURN_INVENTORY: "Return/Damage Inventory",
  REJECTED_VEHICLE: "Rejected Vehicles",
  CREATE_GRN: "Create GRN",
  MANAGE_GRN: "Manage GRN",
  GOODS_VERIFICATION: "Goods Verification",
  VEHICLES_RETURNS: "Vehicles Returns",
  NEW_REQUESTS: "New Requests",
  MANAGE_REQUESTS: "Manage Requests",
  COMPONENTS_RETURNS: "Components Returns",
  NEW_RETURN: "New Return",
  MANAGE_RETURN: "Manage Return",
  ASSIGNED_WORKORDER: "Assigned Work Order",
  MANAGED_WORKORDER: "Managed Work Order",
  FINISHED_GOODS_VERIFICATION: "Finished Goods Verification",
  RAW_INVENTORY_INV: "Raw Inventory",
  WIP_INVENTORY_INV: "WIP Inventory",
  FINISHED_GOODS_INV: "Finished Goods",
  MRO_INVENTORY_INV: "MRO Inventory",
  REJECTION_INVENTORY_INV: "Rejection Inventory",
  ADDONREQUESTS: "Add On Requests",
  PLACEADDONREQUESTS: "Place Add On Requests",
  MANAGEADDONREQUESTS: "Manage Add On Requests",
  FINISHEDGOODS: "Finished Goods",
  BOM: "BOM",
  VEHICLEPRICE: "Vehicle Price",
  SPAREPARTSPRICE: "Spare Parts Price",
  STANDARD_VEHICLE: "Standard Vehicle",
  HYBRID_VEHICLE: "Hybrid Vehicle",
  MANAGE_REJECTED: "Manage Rejected Components",
  REJECTED_HISTORY: "Returned Components",
  DEAD_COMPONENTS: "Dead/Scrapped Components",
  VEHICLE_OUTWARD: "Vehicle Outward",
  COMPONENT_OUTWARD: "Component Outward",
  NEW_REQUEST: "New Request",
  MANAGE_REQUEST: "Manage Request",
  ORDER_HISTORY: "Order History",
  RETURN_REQUEST: "Return Requests",
  MANAGED_RETURN: "Managed Return",
  PLACE_RETURN_REQUEST: "Place Return Request",
  CONFIGURE_STOCKLEVELS: "Configure Stock Levels",
  CONFIGURED_STOCKLEVELS: "Configured Stock Levels",
  COMPONENTS_SPARES: "Component & Spares",
  VEHICLES: "Vehicles",
  COMPONENTS: "Components",
  SPARES: "Spares",
  FINISHEdGOODS: "Finished Goods",
  VERIFICATIONHISTORY: "Verification History",
  VEHICLE: "Vehicle",
  WORKORDERS: "Work Orders",
  QUALITY_CONTROL: "Quality Control",
  HSN: "HSN/SAC",
  VEHICLE: "Vehicle",
  ADD_HSN: "Add HSN/SAC",
  MANAGE_HSN: "Manage HSN/SAC",
  VEHICLE_PRICING: "Vehicle Pricing",
  VEHICLE_QC: "Vehicle QC",
  REQUEST_HISTORY: "Request History",
  COMPONENT_QC: "Component QC",
  PURCHASEORDER: "Purchase Order",
  GRN: "GRN",
  VEHICLE_SALES: "Vehicle Sales",
  SPAREPARTS_SALES: "Spare Parts Sales",
  DEALERS: "Dealers",
  SHOWROOMS: "Showrooms",
  SERVICECENTERS: "Service Centers",
  ORDERS: "Orders",
  INVENTORY: "Inventory",
  RETURNS: "Returns",
  SALES: "Sales",
  CUSTOMERS: "Customers",
  CUSTOMER_QUERY: "Customer Queries",
  VEHICLE_INVENTORY: "Vehicle Inventory",
  SPARE_INVENTORY: "Spare Inventory",
  VEHICLE_RETURNS: "Vehicle Returns",
  SPARE_RETURNS: "Spare Returns",
  SPARE_SALES: "Spare Sales",
  AREA_SALES_HOME: "Home",
  ADD_BUSINESS_USER: "Add Business Users",
  MANAGE_BUSINESS_USER: "Manage Business Users",
  SHOWROOMS: "Showrooms",
  SERVICE_CENTERS: "Service Centers",
  SHOWROOM_WISE: "Showroom Wise",
  VEHICLE_WISE: "Vehicle Wise",
  PURCHASE_INVOICE: "Purchase Invoice",
  SALES_INVOICE: "Sales Invoice",
  PAYMENTS: "Payments",
  SHOWROOM_PURCHASE: "Showroom Purchase",
  SERVICE_CENTER_PURCHASE: "Service Center Purchase",
  SHOWROOM_SALES: "Showroom Sales",
  SERVICE_CENTER_SALES: "Service Center Sales",
  VEHICLE_INVENTORY: "Vehicle Inventory",
  BATTERY_INVENTORY: "Battery Inventory",
  CUSTOMER_ENQUIRY: "Customer Enquiry",
  VEHICLE_BOOKING: "Vehicle Booking",
  VEHICLE_SALES: "Vehicle Sales",
  SALES_HISTORY: "Sales History",
  SALES_REPORT: "Sales Report",
  ADD_CUSTOMER_ENQUIRY: "Add Customer Enquiry",
  MANAGE_CUSTOMER_ENQUIRY: "Manage Customer Enquiry",
  ADD_VEHICLE_BOOKING: "Add Vehicle Booking",
  MANAGE_VEHICLE_BOOKING: "Manage Vehicle Booking",
  ADD_VEHICLE_SALES: "Add Vehicle Sales",
  MANAGE_VEHICLE_SALES: "Manage Vehicle Sales",
  ADD_ZONES: "Add Zones & Areas",
  AREA_SALES_HOME: "Home",
  MANAGE_ZONES: "Manage Zones & Areas",
  VEHICLE_INVENTORY: "Vehicle Inventory",
  VEHICLE_SALES: "Vehicle Sales",
  SPARES_INVENTORY: "Spare Inventory",
  SPARE_SALES: "Spare Sales",
  CREATE_JOB_CARD: "Create Job Cards",
  MANAGE_JOB_CARD: "Manage Job Cards",
  JOBS_HISTORY: "Jobs History",
  SALES_REPORT: "Sales Report",
  SERVICE_ADVISOR_SPARE_RETURNS: "Spare Request & Return",
  PLACE_RETURNS: "Place Returns",
  MANAGE_RETURNS: "Manage Returns",
};

const AppConstants = {
  FULLINPROGRESS: "Full In Progress",
  NEWREQUEST: "New Request",
  APPROVED_N: "Completed",
  REQUEST_COMPLETED: "Request Completed",
  RETURNS_REQUEST_PLACED: "Returns Request Placed",
  PO_REJECTED: "PO Rejected",
  PO_COMPLETED: "PO Completed",
  FULL_SHIPMENT_DONE: "Full Shipment Done",
  FULL_INVENTORY_RELEASED: "Full Inventory Released",
  PARTIAL_SHIPMENT_DONE: "Partial Shipment Done",
  PARTIAL_INVENTORY_RELEASED: "Partial Inventory Released",
  HOLD_BY_INVENTORY: "Hold by Inventory",
  PENDING_AT_INVENTORY: "Pending at Inventory",
  PENDINGATINVENTORY: "Pending at Inventory",
  IN_PROGRESS_AT_FINANCE: "In-Progress At Finance",
  IN_PROGRESS_AT_INVENTORY: "In-Progress at Inventory",
  CN_GENERATED: "CN Generated",
  PENDING_AT_FINANCE: "Pending at Finance",
  PO_ON_HOLD: "PO On Hold",
  ONHOLD: "On Hold",
  HELD_BY_FINANCE: "Held by Finance",
  NEW_JOB_CARD: "New Job Card",
  FULL_SPARES_ASSIGNED: "Full Spares Assigned",
  JOB_FULLY_CLOSED: "Job Fully-Closed",
  FULLY_CLOSED: "Fully Closed",
  NEW_SPARE_REQUEST: "New Spares Request",
  PARTIAL_SPARES_ASSIGNED: "Partial Spares Assigned",
  REJECT: "Reject",
  DELETE: "Delete",
  REACTIVATE: "Reactivated",
  DEACTIVATE: "Deactivated",
  VERIFIED: "Verified",
  RENEWED: "Renewed",
  EXPIRED: "Expired",
  RENEWAL_PENDING: "Renewal Pending",
  VERIFY: "Verify",
  PENDING_AT_MANAGEMENT: "Pending at Management",
  REJECTED_BY_MANAGEMENT: "Rejected By Management",
  APPROVED_BY_MANAGEMENT: "Approved By Management",
  ON_HOLD_BY_MANAGEMENT: "On Hold By Management",
  ADDED_TO_INVENTORY: "Added to Inventory",
  APPROVEDBYMANAGEMENT: "Approved By Management",
  ADDED: "Added",
  ORDER_SHIPPED: "Order Shipped",
  NOT_ADDED: "Not Added",
  ACTIVE: "Active",
  APPROVE: "Approve",
  RETURN_REQUEST_PLACED: "Return Request Placed",
  REQUEST_FOR_INVOICE: "Invoice-Pending",
  NEW_JOB: "New Job",
  ON_HALT: "On-Halt",
  PO_GENERATED: "PO Generated",
  RETURN_SETTLED: "Return Settled",
  RETURN_REQUEST_PLACED: "Return Request Placed",
  NEW_JOB: "New Job",
  ON_HALT: "On-Halt",
  PO_GENERATED: "PO Generated",
  REACTIVATED: "Reactivated",
  DEACTIVATED: "Deactivated",
  DISCONTINUED: "Discontinued",
  REJECTED_REJECTED: "Rejected_Rejected",
  COMPLETED_COMPLETED: "Completed_Completed",
  MODIFICATION_REQUESTED: "Modification Requested",
  MODIFICATION_REJECTED: "Modification Rejected",
  RETURN_NOTE_GENERATED: "Return Note Generated",
  BLACKlISTED: "Blacklisted",
  QC_VERIFICATION: "QC Verification",
  VERIFICATION_COMPLETED: "Verification Completed",
  REQUEST_COMPLETED: "Request Completed",
  DELETED: "Deleted",
  PENDINGBYPROC: "Pending At Procurement",
  PENDINGBYMANAGEMENT: "Pending At Management",
  HOLD_MANAGEMENT: "On Hold By Management",
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTEDBYFINANCE: "Rejected By Finance",
  APPROVED_BY_FINANCE: "Approved By Finance",
  VERIFICATION_COMPLETED: "Verification Completed",
  REJECTED: "Rejected",
  ACCEPTED: "Accepted",
  ACCEPTED_ACCEPTED: "Accepted_Accepted",
  REJECTED_NOT_ACCEPTED: "Rejected_Not Accepted",
  ACCEPTED_WIP: "Accepted_WO in Progress",
  ACCEPTED_HALTED: "Accepted_Work Halted",
  ACCEPTED_RESUMED: "Accepted_Work Resumed",
  ASSIGNED: "Assigned",
  ASSIGNED_NEWWORKORDER: "Assigned_New Work Order",
  REASSIGNED: "Reassigned",
  PENDINGATQC: "Pending at QC",
  COMPLETED: "Completed",
  ONHOLD: "On Hold",
  ON_HOLD: "On Hold_On Hold",
  FULLADDITION: "Full Addition",
  PARTIALADDITION: "Partial Addition",

  ONHOLDBYFINANCE: "On Hold By Finance",
  RETURNS_REQUEST_PLACED: "Returns Request Placed",
  RECEIVED: "Received",
  NOTRECEIVED: "Not Received",
  PASSED: "Passed",
  FAILED: "Failed",
  NEW_REQUEST: "Pending at QC_New Request",
  NEW_REQUEST_NEW: "Pending At QC_New Request",
  PENDING_MANAGEMENT: "Pending at Management",
  NEWWORKORDER: "New Work Order",
  WOTOPROGRESS: "WO to Progress",
  WORKHALTED: "Work Halted",
  WORKRESUMED: "Work Resumed",
  NOTACCEPTED: "Not Accepted",
  WOCOMPLETED: "Accepted_WO Completed",
  NEWLYADDEDGOODS: "Goods Added For Verification",
  VERIFIEDGOODS: "Verified Goods",
  INVENTORYRELEASEPENDING: "Accepted_Accepted",
  ACCEPTEDWOINPROGRESS: "Accepted_WO in Progress",
  ACCEPTEDWORK_RESUMED: "Accepted_Work Resumed",
  ACCEPTED_WO_COMPLETED: "Accepted_WO Completed",
  ACCEPTEDWORKHALTED: "Accepted_Work Halted",
  PRODUCTIONAPPROVALPENDING: "Production Approval Pending",
  PENDINGATINVENTORY: "Pending at Inventory",
  PARTIALQTYRELEASED: "Partial Qty Released",
  FULLQTYRELEASED: "Full Qty Released",
  REJECTEDBYPRODUCTION: "Rejected By Production",
  REJECTEDBYINVENTORY: "Rejected By Inventory",
  ADDED_TO_RETURNS: "Added to Returns",
  ADDED_TO_MRO: "Added To MRO Inventory",
  ADDED_TO_RAW: "Added To RAW Inventory",
  ADDED_TO_DEAD: "Added To Dead/Scrap",
  PARTIALASSIGNMENT: "Partial Assignment",
  FULLASSIGNMENT: "Full Assignment",
  PARTIALRELEASE: "Partial Release",
  PARTIALRELEASE_INPROGRESS: " Partial Release In Progress",
  PARTIALORDERCOMPLETED: "Partial Order Completed",
  FULLRELEASE: "Full Release",
  FULLRELEASE_INPROGRESS: " Full Release In Progress",
  FULLORDERCOMPLETED: "Full Order Completed",
  IN_PROGRESS: "In-Progress",
  PARTIAL_IN_PROGRESS: "Partial In Progress",
  FULL_IN_PROGRESS: "Full In Progress",
  ORDER_DELIVERED: "Order Delivered",
  INVOICES_REQUESTED: "Invoice Requested",
  INVOICES_GENERATED: "Invoice Generate",
  INVOICES_GENERATE: "Invoice Generate",
  PR_INVOICES_GENERATED: "Invoice Generated",
  INVOICE_PENDING: "Invoice Pending",
  INVOICE_PENDING_INSIDE: "Invoice-Pending",
  NEW_RETURN: "New Return",
  RPI_PENDING: "Return Purchase Invoice Pending",
  PENDINGATPROCUREMENT: "Pending at Procurement",
  RPI_GENERATED: "RPI Generated",
  READYTOSHIP: "Ready To Ship",
  SHIPPEDTOVENDOR: "Shipped To Vendor",
  DELIVEREDTOVENDOR: "Delivered To Vendor",
  GOODS_ADDED_VERIFICATION: "New Request",
  VERIFICATION_INPROGRESS: "In-Progress",
  VERIFICATION_COMPLETE: "Completed",
  VERIFICATION_PASSED: "Verification Passed",
  VERIFICATION_FAILED: "Verification Failed",
  VERIFICATION_PENDING: "Verification Pending",
  INPROGRESS: "In Progress",
  IN__PROGRESS: "In-Progress",
  REQUEST_PROCESSED: "Request Processed",
  PENDINGATSHOPFLOOR: "Pending at Shop Floor",
  ACCEPTEDBYSHOPFLOOR: "Accepted by Shop Floor",
  REJECTEDBYSHOPFLOOR: "Rejected by Shop Floor",
  WOINPROGRESS: "WO in Progress",
  WOHALTED: "WO Halted",
  WORESUMED: "WO Resumed",
  WOCOMPLETED: "WO Completed",
  PENDINGATFINANCE: "Pending at Finance",
  APPROVEDBYFINANCE: "Approved by Finance",
  HOLDBYFINANCE: "Hold by Finance",
  HOLD_AT_FINANCE: "Hold at Finance",
  PLACEDTOVENDOR: "Placed to Vendor",
  PARTIALLYCLOSED: "Partially Closed",
  FULLYCLOSED: "Fully Closed",
  PENDINGATSALES: "Pending at Sales",
  RECEIVED_FOR_VERIFICATION: "Received for Verification",
  ADDED_TO_FINISHEDGOODS: "Added to Finished Goods",
  SENT_FOR_ENHANCEMENT: "Sent for Enhancement",
  ACTION: {
    REACTIVATE: "Reactivate",
    DEACTIVATE: "Deactivate",
    DELETE: "Delete",
  },
  AUTH_TOKEN_KEY: "Authorization",
  USER_KEY: "ROOT_ADMIN",
  CONFIG_KEY: "CONFIG",
  CRED_KEY: "CREDENTIALS",
  SCAN_STATUS_RECORD: "scanStatusRecords",
  RAZORPAY_KEY_ID: process.env.RAZORPAY_KEY_ID,
  ROLES: {
    SUPER_ADMIN_KEY: "SUPER-ADMIN",
    ADMIN_KEY: "ADMIN",
  },
  PAGE_SIZE: 10,
  REQUEST_REJECTED: "Request Rejected",
  ConfirmDeletePrompt: "Are you sure you want to Delete ?",
  CODEMACHINE_SUPPORT_EMAIL: "support@codemachine.us",
  CODEMACHINE_SALES_EMAIL: "sales@codemachine.us",

  NEW_JOB_CARD: "New Job Card",
  SPARES_REQUESTED: "Spares Requested",
  PARTIAL_SPARE_ASSIGNED: "Partial Spares Assigned",
  FULL_SPARE_ASSIGNED: "Full Spares Assigned",
  JOB_IN_PROGRESS: "Job In Progress",
  JOB_ON_HALT: "Job On-Halt",
  JOB_RESUMED: "Job Resumed",
  JOB_CLOSED: "Job Closed",
  JOB_FULLY_CLOSED: "Job Fully-Closed",
  JOB_RE_OPENED: "Job Re-Opened",
  JOB_CLOSED: "Job Closed",
  RETURNS_PROCEED: "Returns Processed",
  PENDING_AT_STORE: "Pending at Store",
};

export default AppConstants;
export { SidebarLabels, TabLabels };
