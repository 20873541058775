import React, { useState } from "react";
import "../../work-order/workorder.scss";
import ViewSpecs from "../../bom/bom-viewspecs";
import { KeyboardBackspace } from "@material-ui/icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const WoViewComponents = ({ close, compdata, productionQuantity }) => {
const quantity = parseInt(productionQuantity)

  const [viewSpecs, setViewSpecs] = useState();

  console.log(compdata);
  // const columns = [
  //   {
  //     text: "S.No.",
  //     dataField: "id",
  //     headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
  //     headerAttrs: { width: "50px" },
  //     formatter: (cell, row, rowIndex, formatExtraData) => {
  //       return (
  //         compdata?.bomComponents?.findIndex(function (value, idx, arr) {
  //           return value == row;
  //         }) + 1
  //       );
  //     },
  //     sort: true,
  //   },
  //   {
  //     text: "Component Name",
  //     dataField: "componentName",
  //     headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
  //     headerAttrs: { width: "180px" },
  //   },
  //   {
  //     text: "SKU Code",
  //     dataField: "skuCode",
  //     headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
  //     headerAttrs: { width: "150px" },
  //   },
  //   {
  //     text: "Category",
  //     dataField: "category",
  //     headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
  //     headerAttrs: { width: "200px" },
  //   },
  //   {
  //     text: "Sub Category",
  //     dataField: "subCategory",
  //     headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
  //     headerAttrs: { width: "150px" },
  //   },
  //   {
  //     text: "Specification",
  //     dataField: "0",
  //     headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
  //     headerAttrs: { width: "150px" },
  //   },
  //   {
  //     text: "Bom Qty",
  //     dataField: "componentQuantity",
  //     headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
  //     headerAttrs: { width: "150px" },
  //   },
  //   {
  //     text: "Required Qty",
  //     dataField: "0", 
  //     headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
  //     headerAttrs: { width: "300px" },
  //     formatExtraData:{quantity},
  //     formatter: (cell, row, rowIndex, formatExtraData) => {
  //       const quantity = formatExtraData?.quantity || 1;
  //       return quantity * row?.componentQuantity; 
  //     }
  //   },
  //   // {
  //   //   text: "Avg Release / day",
  //   //   dataField: "",
  //   //   headerStyle: { fontWeight: "500", borderTop: "1p  x solid #CDCDCD" },
  //   //   headerAttrs: { width: "250px" },
  //   // },
  //   // {
  //   //   text: "Avg Production / day",
  //   //   dataField: "",
  //   //   headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
  //   //   headerAttrs: { width: "150px" },
      
  //   // }
  // ];

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "1170px", height: "521px", padding: "15px" }}
        >

          <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Required Components</h3>
          </div>

          <div className="body" style={{ height: "340px", flexWrap: "nowrap" }}>
            <div style={{ width: "100%", overflow: "auto", height: "325px" }}>
            {/* <BootstrapTable
                wrapperClasses="table-responsive"
                data={compdata?.bomComponents}
                 keyField="id"
                columns={columns}
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
              /> */}
              <table className="viewcomp_tbl">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>SKU Code</th>
                    <th>Component Name</th>
                    <th>Category</th>
                    <th>Sub Category</th>
                    <th>Specification</th>
                    <th>Bom Qty</th>
                    <th>Required Qty</th>
                    <th>Avg Release <br /> /day</th>
                    <th>Avg Production <br /> /day</th>
                  </tr>
                </thead>
                {compdata?.bomComponents?.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td className="leftcell">{index + 1}</td>
                        <td>{item?.skuCode} </td>
                        <td>{item?.componentName}</td>
                        <td>{item?.category}</td>
                        <td>{item?.subCategory}</td>
                        <td>{<span onClick={() => setViewSpecs(index)}
                          style={{ color: "#0a18a3", fontWeight: "500", cursor: 'pointer' }}>
                          View Specs</span>}
                        </td>
                        <td>{item?.componentQuantity}</td>
                        <td>{quantity*item?.componentQuantity}</td>
                        <td>0</td>
                        <td className="rightcell">0</td>
                      </tr>
                      {viewSpecs === index && <ViewSpecs rowData={item} close={setViewSpecs} />}
                    </>)
                })}
              </table>
            </div>
          </div>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}> <KeyboardBackspace /> Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WoViewComponents;