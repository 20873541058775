import React, { useState } from "react";
import auth from "../../../../utils/auth";

const ViewAssemblyBom = ({ close, assemblydata }) => {

  const duration = assemblydata?.map((item, index) => {
    return item?.Duration
  })

  let count = 0;
  assemblydata?.map((item, index) => {
    item?.assemblyServices?.map((data, index) => {
      count++;
    })
  })

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "770px", height: "465px", padding: "15px" }}
        >

          <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Bom Assembly Details</h3>
            <div>
              <button
                className="action-btn"
                style={{ color: "blue" }}>
                Print
              </button>
              <button
                className="action-btn"
                style={{ color: "blue" }}>
                Export
              </button>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", fontSize: "12px", fontWeight: "450" }}>
            <span>Total Service : {count}</span>
          </div>


          {/* <div className="body" style={{height:"340px"}}>  */}
          {/* <div style={{width:"100%",overflow:"auto",height:"325px"}}> */}


          <div className="shopfloortbl" style={{ width: "100%" }}>
            <table className="sfaction_tbl" style={{ width: "100%" }} >
              <thead>
                <tr>
                  <th>Assembly Type</th>
                  <th>Assembly Name</th>
                  <th>Required Services</th>
                  <th>Required Duration({duration[0]})</th>
                  <th>{`Cost / ${duration[0]}(${auth.getUserInfo().currencySymbol}) :`}</th>
                  <th>{`Sub Total(${auth.getUserInfo().currencySymbol}) :`}</th>
                </tr>
              </thead>
              {/* <hr className='line'/> */}
              <tbody>
                {assemblydata?.map((item, index) => {
                  return (
                    <tr>
                      <td>{item?.assemblyType}</td>
                      <td >{item?.assemblyName}</td>
                      <td>
                        {item?.assemblyServices?.map((data, index) => {
                          return (
                            <span>
                              {data?.serviceName}
                            </span>
                          )
                        })}
                      </td>
                      <td>
                        {item?.assemblyServices?.map((data, index) => {
                          return (
                            <span>
                              {item?.timeDuration}
                            </span>
                          )
                        })}
                      </td>
                      <td>{item?.assemblyServices?.map((data, index) => {
                        return (
                          <span>
                            {item?.serviceCost}
                          </span>
                        )
                      })}</td>
                      <td>{item?.assemblyServices?.map((data, index) => {
                        return (
                          <span>{parseInt(item?.timeDuration) * parseInt(item?.serviceCost)} </span>
                        )
                      })}</td>
                    </tr>
                  )
                })}

              </tbody>
            </table>
          </div>

          <section style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', borderRadius: '5px', margin: '5px 0', height: "35px" }}>
            <div style={{ width: '200px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', fontSize: '14px', fontWeight: '500' }}>
              {`Total Price(${auth.getUserInfo().currencySymbol}) :`}
              <span style={{ width: '100px', background: '#ebebeb', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                {assemblydata?.map((item) => {
                  return item?.assemblyServices?.map((data, index) => {
                    return (
                      <span>{parseInt(item?.timeDuration) * parseInt(item?.serviceCost)} </span>
                    )
                  })
                })}
              </span>
            </div>
          </section>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}> <KeyboardBackspace />Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAssemblyBom;