import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { NavItem } from "reactstrap";
import Action from "./action/Action";
import "../../../components/modal/modal.scss";
import SearchBox from "../../../components/search/SearchBox";
import data from './Data'
import { errorNotification } from "../../../utils/notifications";
import handleExport from "../../../utils/exportExcel";
import auth from "../../../utils/auth";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";


const ManageLaborCosting = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [show, setShow] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [open, setOpen] = useState(false);
  const [formValue, setFormValue] = useState({
    laborCategory: "",
    laborSubCategory: "",
    laborLevel: "",
  });

  const fetchReport = async () => {
    const resp = await trackPromise(API.get(`procurement-module/api/v1/costings/getAllLaborCostings?laborCategory=${formValue.laborCategory}&laborSubCategory=${formValue.laborSubCategory}&laborLevel=${formValue.laborLevel}`));
    console.log(resp);
    resp.success == false ? errorNotification(resp.message, { autoClose: 3000 }) : setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const statusFormatter = () => {
    return (
      <button className="active-btn">
        <span className="btn-font">Active</span>
      </button>
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  };

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon id="outside" icon={faEllipsisH} />
        </button>
        {(actionShow === rowIndex) && open ? (
          <Action rowData={row} updated={fetchReport} status={true} setIsOpen={setOpen} rowIndex={rowIndex} />
        ) : null}
      </div>
    );
  };

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      laborCategory: "",
      laborSubCategory: "",
      laborLevel: "",
    });
    const resp = await trackPromise(API.get(`procurement-module/api/v1/costings/getAllLaborCostings`));
    resp.success == false ? errorNotification(resp.message, { autoClose: 3000 }) : setUsersData(resp);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "35px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return UsersData.findIndex(function (value, idx, arr) { return value == row }) + 1;
      },
      sort: true,
    },
    {
      text: "Labor Category",
      dataField: "laborCategory",
      headerAttrs: { width: "110px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' }
      // classes: "alignment",
      // formatter: noCloudId
    },
    {
      text: "Labor Sub Category",
      dataField: "laborSubCategory",
      headerAttrs: { width: "130px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' }
      // classes: "alignment"
    },
    {
      text: "Labor Level",
      dataField: "laborLevel",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' }
      // classes: "alignment"
    },
    {
      text: `Avg Labor Cost (${auth.getUserInfo().currencySymbol})`,
      dataField: "avgLaborCost",
      headerAttrs: { width: "130px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
      formatter:(row,cell)=>{
        return  cell.avgLaborCost + " " + cell.time
      }
      // classes: "alignment",
      // formatter: noCloudId
    },
    {
      text: "Action",
      // dataField: "title",
      headerAttrs: { width: "100px" },
      headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  let uniqueLaborCategoryArray = [...new Map(UsersData.map((item) => [item["laborCategory"], item])).values()];
  let uniqueLaborSubCategoryArray = [...new Map(UsersData.map((item) => [item["laborSubCategory"], item])).values()];
  let uniqueLaborLevelArray = [...new Map(UsersData.map((item) => [item["laborLevel"], item])).values()];

  return (

    <>
      <div >
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Labor Cost</span>
        </div>

        <ToolkitProvider keyField="id" data={UsersData} columns={columns} search >

          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-39px" }}>
                  <div style={{ display: "flex", marginRight: "-15px", height: "26px", marginTop: "10px", }}>
                    <div style={{ width: "220px" }}>

                      <SearchBar
                        {...props.searchProps}
                        placeholder='Search...'
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />

                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "Manage Labor Cost")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form onSubmit={submitHandler} className="UHinputfields VMform" style={{ display: 'flex', flexDirection: 'row', height: '85px', backgroundColor: '#F7F7F7', padding: '5px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                      <label style={{ fontSize: "13px", fontWeight: "500", }}>Labor Category</label>
                      <select name="laborCategory" value={formValue.laborCategory} onChange={handleChange} style={{ marginTop: '-5px', width: '100%' }}>
                        <option value="" disabled selected hidden>
                          Labor Category
                        </option>
                        {uniqueLaborCategoryArray.map((item, id) => (
                          <option key={id}>{item.laborCategory}</option>
                        ))}
                      </select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                      <label style={{ fontSize: "13px", fontWeight: "500", }}>Labor Sub Category</label>
                      <select name="laborSubCategory" value={formValue.laborSubCategory} onChange={handleChange} style={{ marginTop: '-5px', width: '100%' }}>
                        <option value="" disabled selected hidden>
                          Labor Sub Category
                        </option>
                        {uniqueLaborSubCategoryArray.map((item, id) => (
                          <option key={id}>{item.laborSubCategory}</option>
                        ))}
                      </select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                      <label style={{ fontSize: "13px", fontWeight: "500", }}> Labor Level</label>
                      <select name="laborLevel" value={formValue.laborLevel} onChange={handleChange} style={{ marginTop: '-5px', width: '100%' }}>
                        <option value="" disabled selected hidden>
                          Labor Level
                        </option>
                        {uniqueLaborLevelArray.map((item, id) => (
                          <option key={id}>{item.laborLevel}</option>
                        ))}
                      </select>
                    </div>
                    <button onClick={fetchReport} id="apply" style={{ marginTop: '22px', marginLeft: '10px' }}>
                      Apply
                    </button>
                    <button onClick={clearFilter} id="clear" style={{ marginTop: '22px' }}>
                      Clear Filter
                    </button>
                  </form>
                )}
                <br></br>

              </div>


              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ManageLaborCosting;