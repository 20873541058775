import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "react-bootstrap/Table";
import { successNotification, errorNotification } from "../../../../utils/notifications";



const SparePartInventoryDetails = ({ closeModal, rowData, rowIndex }) => {


  const [openSpare, setOpenSpare] = useState();
  const [spareData, setSpareData] = useState();

  const submitHandler = async () => {
    const resp = await trackPromise(
      API.post(``)
    );

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      successNotification(message, { autoClose: 3000 });
      refresh();
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };


  const jobCardFormatter = (rowData) => {
    setOpenSpare(true);
    setSpareData(rowData);
  };




  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "55%", height: "400px", padding: "15px" }}
      >
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Spare Part Inventory Details</h3>
        </div>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding: 7,
          }}
        >
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            Spare Part Name : {rowData.sparePartname}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Category : {rowData.category}
          </span>
          <span
            style={{
              width: "50%",
              textAlign: "start",
              justifyContent: "start",
            }}
          >
            SKU Code : {rowData.skuCode}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Sub Category: {rowData.subCategory}
          </span>
          <span
            style={{ width: "50%", textAlign: "end", justifyContent: "end" }}
          >
            Available Qty.: {rowData?.availableQty?.length}
          </span>
        </section>
        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div>
              <Table>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: "#ebebeb" }}>S.No</td>
                    <td style={{ backgroundColor: "#ebebeb" }}>Serial Number</td>
                  </tr>

                  {
                    rowData?.availableQty?.map((item, index) => {
                      return (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.componentID}</td>
                          </tr>
                        </>
                      )
                    })

                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <KeyboardBackspace /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default SparePartInventoryDetails;

