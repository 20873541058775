import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import VerifyEdit from "./verifyEdit";
import auth from "../../utils/auth";
import { errorNotification, successNotification } from "../../utils/notifications";

const EditLocation = ({
  closeModal,
  locationType,
  updatedData,
  locationObj,
}) => {
  const [showVerify, setVerify] = useState(false);
  const [businessUserInfo, setBusinessUserInfo] = useState({});
  const [countryData, setCountryData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [selectedStdCode, setStdCode] = useState("");
  const [selectedCity, setSelectedCity] = useState([]);

  const fetchReport = async () => {
    const response = await trackPromise(
      API.get(
        `admin-module/api/v1/users/viewLocationByID?locationID=${locationObj.locationID}`
      )
    );
    console.log(response);
    let dataObj = {
      locationType: locationType,
      address1: response.address1,
      address2: response.address2,
      country: response.country,
      state: response.state,
      city: response.city,
      zipcode: response.zipcode,
      isd: response.isd,
      std: response.std,
      businessContactNo: response.businessContactNo,
      timezone: response.timezone,
      currency: response.currency,
      mobileNumber:response.mobileNumber,
    };
    setBusinessUserInfo(dataObj);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  const countryAPi = async () => {
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/users/getAllCountries`)
    );
    console.log(resp);
    setCountryData(resp);
  };
  useEffect(() => {
    countryAPi();
  }, []);

  const handleInputChange = (event) => {
    setBusinessUserInfo({
      ...businessUserInfo,
      [event.target.name]: event.target.value,
    });
    let std = selectedCity.find(
      (std) => std.PostOfficeName == event.target.value
    );
    setStdCode(std.StdCode);
  };

  const handleCountryChange = async (event) => {
    setBusinessUserInfo({
      ...businessUserInfo,
      [event.target.name]: event.target.value,
    });
    let countryName = event.target.value;
    const resp = await trackPromise(
      API.get(
        `admin-module/api/v1/users/getCountryDataByID?countryName=${countryName}`
      )
    );
    setSelectedCountry(resp[0]);
  };

  const handleZipcodeChange = async (event) => {
    setBusinessUserInfo({
      ...businessUserInfo,
      [event.target.name]: event.target.value,
    });
    let zipCode = event.target.value;
    const resp = await trackPromise(
      API.get(`admin-module/api/v1/users/getDataByZipCode?zipcode=${zipCode}`)
    );
    setSelectedState(resp.state[0]);
    setSelectedCity(resp.city);
  };


  console.log("businessUserInfo-->",businessUserInfo)

  const validateInputs = () => {
    // Validate address1
    if (!businessUserInfo.address1) {
      errorNotification("Address is required.");
      return false;
    }
  
    // Validate country
    if (!businessUserInfo.country) {
      errorNotification("Country is required.");
      return false;
    }
  
    // Validate city
    if (!businessUserInfo.city) {
      errorNotification("City is required.");
      return false;
    }
  
    // Validate state
    if (!businessUserInfo.state) {
      errorNotification("State/Province is required.");
      return false;
    }
  
    // Validate zipcode
    if (!businessUserInfo.zipcode) {
      errorNotification("Zipcode/Postal is required.");
      return false;
    }
  
    // Validate business contact number
    if (!businessUserInfo.businessContactNo) {
      errorNotification("Business Contact Number is required.");
      return false;
    }
  
    // Validate mobile number
    if (!businessUserInfo.mobileNumber) {
      errorNotification("Mobile Number is required.");
      return false;
    }
  
    // Validate address1 format
    if (!/^[a-zA-Z0-9\s,'-]*$/.test(businessUserInfo.address1)) {
      errorNotification("Address must contain only alphanumeric characters and special characters like , ' -");
      return false;
    }
  
    // Validate address2 format if provided
    if (businessUserInfo.address2 && !/^[a-zA-Z0-9\s,'-]*$/.test(businessUserInfo.address2)) {
      errorNotification("Address (Optional) must contain only alphanumeric characters and special characters like , ' -");
      return false;
    }
  
    // Validate country format
    if (!/^[a-zA-Z\s]*$/.test(businessUserInfo.country)) {
      errorNotification("Country must contain only alphabets and spaces.");
      return false;
    }
  
    // Validate city format
    if (!/^[a-zA-Z\s]*$/.test(businessUserInfo.city)) {
      errorNotification("City must contain only alphabets and spaces.");
      return false;
    }
  
    // Validate state format
    if (!/^[a-zA-Z\s]*$/.test(businessUserInfo.state)) {
      errorNotification("State/Province must contain only alphabets and spaces.");
      return false;
    }
  
    // Validate business contact number format
    if (!/^[0-9]*$/.test(businessUserInfo.businessContactNo)) {
      errorNotification("Business Contact Number must contain only digits.");
      return false;
    }
  
    // Validate mobile number format
    if (!/^[0-9]*$/.test(businessUserInfo.mobileNumber)) {
      errorNotification("Mobile Number must contain only digits.");
      return false;
    }
  
    return true;
  };
  

  const submitHandler = () => {

    if (!validateInputs()) {
      return;
    }


    if (
        !businessUserInfo.businessContactNo &&
        !businessUserInfo.mobileNumber
    ) {
        errorNotification("Please provide either Business Contact Number or Mobile Number.");
    } else {
        setVerify(true);
    }
}


  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "765px", height: "450px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>View & Edit {locationType} Location</h3>
          </div>

          <div className="body" style={{ height: "340px", marginTop: "25px" }}>
            <div style={{ display: "flex", width: "100%" }}>
              <label style={{ fontSize: "13px", fontWeight: "500" }}>
                Address<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                style={{ margin: "19px 0px 5px -57px" }}
                className="input-config"
                type="text"
                name="address1"
                defaultValue={businessUserInfo.address1}
                placeholder="Address"
                onChange={(event) => handleInputChange(event)}
              />
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginLeft: "14px",
                  width: "18%",
                }}
              >
                Address <span>(Optional)</span>
              </label>
              <br />
              <input
                style={{ margin: "19px 0px 5px -136px" }}
                className="input-config"
                type="text"
                name="address2"
                defaultValue={businessUserInfo.address2}
                placeholder="Address"
                onChange={(event) => handleInputChange(event)}
              />
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginLeft: "14px",
                  width: "18%",
                }}
              >
                Country <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                style={{ margin: "19px 0px 5px -136px" }}
                name="country"
                value={businessUserInfo.country}
                className="input-config"
                onChange={(event) => handleCountryChange(event)}
              >
                {countryData.map((item) => (
                  <option>{item.countryName}</option>
                ))}
              </select>
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <label style={{ fontSize: "13px", fontWeight: "500" }}>
                Zipcode/Postal<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                style={{ margin: "19px 0px 5px -99px" }}
                name="zipcode"
                defaultValue={businessUserInfo.zipcode}
                className="input-config"
                onBlur={(event) => handleZipcodeChange(event)}
              />
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginLeft: "16px",
                }}
              >
                City<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                style={{ margin: "19px 0px 5px -34px" }}
                required={true}
                name="city"
                defaultValue={selectedCity.PostOfficeName}
                className="input-config"
                onChange={(event) => handleInputChange(event)}
              >
                <option disabled selected hidden>
                  {selectedCity.length != 0
                    ? selectedCity.PostOfficeName
                    : businessUserInfo.city}
                </option>
                {selectedCity.map((item) => (
                  <option>{item.PostOfficeName}</option>
                ))}
              </select>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginLeft: "15px",
                }}
              >
                State/Province<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                disabled
                style={{ margin: "19px 0px 5px -98px" }}
                className="input-config"
                type="text"
                name="state"
                defaultValue={
                  Object.keys(selectedState).length !== 0
                    ? selectedState.stateName
                    : businessUserInfo.state
                }
                placeholder="State"
                onChange={(event) => handleInputChange(event)}
              />
            </div>

            <div style={{ display: "flex", width: "100%" }}>
              <label
                style={{ fontSize: "13px", fontWeight: "500", width: "23%" }}
              >
                Contact Number
              </label>
              <br />
              <div style={{ display: "flex" }}>
                <input
                  style={{ width: "11%", margin: "19px 86px 0px -171px" }}
                  className="input-config"
                  type="text"
                  name="isd"
                  placeholder="ISD"
                  defaultValue={
                    Object.keys(selectedCountry).length === 0
                      ? businessUserInfo.isd
                      : selectedCountry.phoneCode
                  }
                  onChange={(event) => handleInputChange(event)}
                />
                <input
                  style={{ width: "14%", margin: "19px 86px 0px -83px" }}
                  className="input-config"
                  type="text"
                  name="std"
                  placeholder="STD"
                  defaultValue={
                    selectedCity.length != 0
                      ? selectedStdCode
                      : businessUserInfo.std
                  }
                  onChange={(event) => handleInputChange(event)}
                />
                <input
                  style={{ margin: "19px 18px 5px -83px", width: "26%" }}
                  className="input-config"
                  type="text"
                  maxLength="8"
                  name="businessContactNo"
                  defaultValue={businessUserInfo.businessContactNo}
                  placeholder="Contact Number"
                  onChange={(event) => handleInputChange(event)}
                />
              </div>

              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginLeft: "-370px",
                  width: "13%",
                }}
              >
                Mobile Number
              </label>
              <br />
              <input
                style={{ margin: "19px 10px 5px -97px" }}
                className="input-config"
                type="text"
                name="mobileNumber"
                defaultValue={businessUserInfo.mobileNumber}
                placeholder="Mobile Number"
                maxLength={10}
                onChange={(event) => handleInputChange(event)}
              />

                 <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                //   marginLeft: "-370px",
                  width: "13%",
                }}
              >
                Time Zone<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                style={{ margin: "19px 10px 5px -97px" }}
                className="input-config"
                type="text"
                name="timezone"
                defaultValue={
                  Object.keys(selectedCountry).length === 0
                    ? businessUserInfo.timezone
                    : selectedCountry.timezones &&
                      selectedCountry.timezones.gmtOffsetName +
                        " " +
                        selectedCountry.timezones.abbreviation
                }
                placeholder="Time Zone"
                onChange={(event) => handleInputChange(event)}
              />
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <label
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginRight: "-22px",
                }}
              >
                {`Currency (${auth.getUserInfo().currencySymbol})`}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                style={{ margin: "19px 0px 5px -53px", width: "32%" }}
                name="currency"
                defaultValue={
                  Object.keys(selectedCountry).length === 0
                    ? businessUserInfo.currency
                    : selectedCountry.currency
                }
                placeholder="Currency"
                onChange={(event) => handleInputChange(event)}
              />
            </div>
          </div>

          <br />
          <div className="footer">
            <button onClick={() => closeModal(false)}>
              <FontAwesomeIcon icon={faTimesCircle} /> Cancel
            </button>
            <button id="submit-button" onClick={submitHandler}>
              <FontAwesomeIcon icon={faCheckCircle} /> Save
            </button>
            {showVerify && (
              <VerifyEdit
                updatedData={updatedData}
                closeBussModal={closeModal}
                closeModal={setVerify}
                verifyDetails={businessUserInfo}
                locationID={locationObj.locationID}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditLocation;
