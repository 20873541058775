import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import "../../work-order/workorder.scss";

const WoViewLabours = ({ close, labordata }) => {

  const [viewSpecs, setViewSpecs] = useState();
  const [display, setDisplay] = useState(false);

  console.log(labordata)

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "1170px", height: "521px", padding: "15px" }}
        >

          <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Required Labours</h3>
            {/* <div>
              <button
                className="action-btn"
                style={{ color: "blue" }}>
                Print
              </button>
              <button
                className="action-btn"
                style={{ color: "blue" }}>
                Export
              </button>
            </div> */}
          </div>

          <div className="body" style={{ height: "340px", flexWrap: "nowrap" }}>


            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <label htmlFor="lbrcount" className="wo_labels" style={{ marginRight: "20px" }}>
                    <input type="radio"
                      name="labor"
                      id="lbrcount"
                      checked={display == false}
                      onClick={e => setDisplay(false)}
                      style={{ width: "15px" }}
                    />
                    View By Labour Count</label>

                  <label htmlFor="lbrtime" className="wo_labels" >
                    <input type="radio"
                      name="labor"
                      id="lbrtime"
                      // checked={display == true}
                      onClick={e => setDisplay(true)}
                      style={{ width: "15px" }}
                    />
                    View By Labour Time</label>
                </div>
              </div>

              <table className="viewcomp_tbl" style={{ width: "100%", fontSize: "15px" }} >
                <tr>
                  <th>Labour Type</th>
                  <th>Labour Level</th>
                  <th>BOM Labour Count</th>
                  <th>BOM Labour Count Time</th>
                  <th>Required Labour Count</th>
                  <th>Required Labour Time</th>
                  <th>Avg Labour/day</th>
                  <th>Avg Labour Time/day</th>
                  <th>Avg Production/day</th>

                </tr>
                {labordata.bomLabor.map((item) => {
                  var x = item?.requiredWorkers
                  var y = item?.requiredWorkers * parseInt(labordata?.productionQuantity)
                  var m = item?.requiredDuration
                  var n = item?.requiredDuration * parseInt(labordata?.productionQuantity)
                  return (
                    <tr>
                      <td className="leftcell">{item?.laborCategory}</td>
                      <td>{item?.laborLevel}</td>
                      <td>{item?.requiredWorkers} nos.</td>
                      <td>{item?.requiredDuration} {item?.Duration}</td>
                      <td>{display ? x : y}</td>
                      <td>{display ? n : m} {item?.Duration}</td>
                      <td>0</td>
                      <td>0</td>
                      <td className="rightcell">0</td>
                    </tr>
                  )
                })}
              </table>
            </div>

          </div>

          <br />
          <div className="footer">
            <button onClick={() => close(false)}><KeyboardBackspace /> Back</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WoViewLabours;