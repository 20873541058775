import { KeyboardBackspace } from "@material-ui/icons";
import React, { useState } from "react";
import auth from "../../../../utils/auth";
import ViewSpecs from "./ViewSpecs";
import moment from 'moment';

const RequisitionDetails = ({ closeModal, row }) => {
  const UsersData = row.requisitionDetails;
  const [viewSpecs, setViewSpecs] = useState(false);
  const [specsData, setSpecsData] = useState({});

  const handleViewSpecs = (item, index) => {
    setViewSpecs(true);
    setSpecsData(item);
  };

  const sumTotal = UsersData.reduce(
    (accumulator, currentValue) => accumulator + parseInt(currentValue.requiredQty),
    0
  );

  return (
    <div className="container1">
      {viewSpecs && <ViewSpecs specs={specsData} closeModal={setViewSpecs} />}
      <div
        className="modalContainer"
        style={{ width: "75%", height: "400px", padding: "15px" }}
      >
        <div className="header">
          <h3>Spare Part Requisition Details</h3>
        </div><br />
        <section style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "100%" }}>
          <span style={{ width: "50%", textAlign: "start", justifyContent: "start" }}>Requisition ID: {row.requisitionID}</span>
          <span style={{ width: "50%", textAlign: "end", justifyContent: "end" }}>Total Spares Requested: {UsersData.length}</span>
          <span style={{ width: "50%", textAlign: "start", justifyContent: "start" }}>Requisition Date: {moment(UsersData.requisitionDate).format("DD-MMM-YYYY/HH:mm")}</span>
          <span style={{ width: "50%", textAlign: "end", justifyContent: "end" }}>Total Units Requested: {sumTotal}</span>
          <span style={{ width: "50%", textAlign: "start", justifyContent: "start" }}>Requested To: {row.requestedTo}</span>
        </section>
        <br />
        <div className="body">
          <div style={{ width: "100%" }}>
            <div
              id="costingTableHeading"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div style={{ width: "15%" }}>SKU Code</div>
              <div style={{ width: "15%" }}>Spare Part Name</div>
              <div style={{ width: "15%" }}>Category</div>
              <div style={{ width: "15%" }}>Sub Category</div>
              <div style={{ width: "15%" }}>Specification</div>
              <div style={{ width: "15%" }}>{`Dealer Price (${auth.getUserInfo().currencySymbol
                })`}</div>
              <div style={{ width: "15%" }}>Requested Qty.</div>
             
            </div>
            <div style={{ height: "230px", overflowY: "scroll" }}>
              {UsersData?.map((item, index) => (
                <div
                  id="costingTableContent"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ overflowY: "auto", width: "15%" }}>
                    {item.skuCode}
                  </div>
                  <div style={{ overflowY: "auto", width: "15%" }}>
                    {item.sparePartname}
                  </div>
                  <div style={{ overflowY: "auto", width: "15%" }}>
                    {item.category}
                  </div>
                  <div style={{ overflowY: "auto", width: "15%" }}>
                    {item.subCategory}
                  </div>
                  <div style={{ overflowY: "auto", width: "15%" }}>
                    <span
                      onClick={() => handleViewSpecs(item, index)}
                      style={{
                        color: "#0a18a3",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      View Specs
                    </span>
                  </div>
                  <div style={{ overflowY: "auto", width: "15%" }}>
                    {item.priceToserviceCenter}
                  </div>
                  <div style={{ overflowY: "auto", width: "15%" }}>
                    {item.requiredQty}
                  </div>
                 
                </div>
              ))}
            </div>
          </div>
        </div>

        <br />
        <div className="footer">
          <button onClick={() => closeModal(false)}>
            <KeyboardBackspace /> Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequisitionDetails;
