import React, { useState } from "react";
import "../../work-order/workorder.scss";
import ViewSpecs from "../../bom/bom-viewspecs";
import { KeyboardBackspace } from "@material-ui/icons";
import PrintIcon from '@mui/icons-material/Print';
import IosShareIcon from '@mui/icons-material/IosShare';
import auth from "../../../utils/auth";

const ViewComponentsBom = ({ close, compdata, bomLocation, viewComponentTotal }) => {
  const [viewSpecs, setViewSpecs] = useState();

console.log(compdata)


  // Total Component Qty
  const totalComp = compdata.map((item) => {
    return item.componentQuantity;
  });
  const initialValue = 0;
  const totalCompsum = totalComp.reduce((a, b) => a + b, initialValue);

  // Vendor Part Name
  const vendorPartNameData = (item) => {
    return item.vendorDetails.map((vendorItem) => {
      if (vendorItem.selected == true) {
        return vendorItem.vendorPartName;
      }
    });
  };

  // Vendor Part Code
  const vendorPartCodeData = (item) => {
    return item.vendorDetails.map((vendorItem) => {
      if (vendorItem.selected == true) {
        return vendorItem.vendorPartCode;
      }
    });
  };

  // Vendor Unit Price
  const vendorUnitPriceData = (item) => {
    return item.vendorDetails.map((vendorItem) => {
      if (vendorItem.selected == true) {
        return vendorItem.deliveryDetails.map((deliveryItem) => {
          if (deliveryItem.deliveryLocation == bomLocation) {
            return deliveryItem.unitPrice.toLocaleString('en-IN');
          }
        });
      }
    });
  };

  // Component Qty
  const componentQty = (item) => {
    return item.vendorDetails.map((vendorItem) => {
      if (vendorItem.selected == true) {
        return vendorItem.deliveryDetails.map((deliveryItem) => {
          if (deliveryItem.deliveryLocation == bomLocation) {
            return (deliveryItem.unitPrice * item.componentQuantity).toLocaleString('en-IN');
          }
        });
      }
    });
  };

  // Total Cost
  let sum = 0;
  const totalPrice = (item) => {
    return item.vendorDetails.map((vendorItem) => {
      if (vendorItem.selected == true) {
        return vendorItem.deliveryDetails.map((deliveryItem) => {
          if (deliveryItem.deliveryLocation == bomLocation) {
            sum += deliveryItem.unitPrice * item.componentQuantity;
          }
        });
      }
    });
  };

  return (
    <>
      <div className="container1">
        <div
          className="modalContainer"
          style={{ width: "1170px", height: "521px", padding: "15px" }}
        >
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h3>Required Components</h3>
            <div>
              <button className="action-btn" style={{ color: "blue" }}>
               <PrintIcon/>&nbsp; Print
              </button>
              <button className="action-btn" style={{ color: "blue" }}>
                <IosShareIcon/>&nbsp;Export
              </button>
            </div>
          </div>

          <div className="body" style={{ height: "340px", flexWrap: "nowrap" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "500",
                marginTop: "10px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Total Component :- &nbsp; {totalCompsum}</span>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{`Total Component Price (${auth.getUserInfo().currencySymbol})`} :- &nbsp;{viewComponentTotal.toLocaleString('en-IN')}</span>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              overflow: "scroll",
              height: "325px",
              marginTop: "-268px",
              fontSize:"12px",
              fontWeight:"5"
            }}
          >
            <table className="viewcomp_tbl" style={{ overflow: "scroll" }}>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Component Name</th>
                  <th>SKU Code</th>
                  <th>Category</th>
                  <th>Sub Category</th>
                  <th>Specification</th>
                  <th>Vendor Part Name</th>
                  <th>Vendor Part Code</th>
                  <th>{`Unit Price (${auth.getUserInfo().currencySymbol})`}</th>
                  <th>Qty.</th>
                  <th>{`Sub Total (${auth.getUserInfo().currencySymbol})`}</th>
                </tr>
              </thead>
              {compdata.map((item, index) => {
                totalPrice(item);
                return (
                  <>
                    <tr>
                      <td className="leftcell">{index + 1}</td>
                      <td>{item.componentName}</td>
                      <td>{item.skuCode} </td>
                      <td>{item.category}</td>
                      <td>{item.subCategory}</td>
                      <td>
                        {
                          <span
                            onClick={() => setViewSpecs(index)}
                            style={{
                              color: "#0a18a3",
                              fontWeight: "500",
                              cursor: "pointer",
                            }}
                          >
                            View Specs
                          </span>
                        }
                      </td>
                      <td>{vendorPartNameData(item)}</td>
                      <td>{vendorPartCodeData(item)}</td>
                      <td>₹{vendorUnitPriceData(item)}</td>
                      <td>{item.componentQuantity}</td>
                      <td className="rightcell">₹{componentQty(item)}</td>
                    </tr>
                    {viewSpecs === index && (
                      <ViewSpecs rowData={item} close={setViewSpecs} />
                    )}
                  </>
                );
              })}
            </table>

            <section
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                borderRadius: "5px",
                margin: "5px 0",
                height: "35px",
              }}
            >
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {`Total Price (${auth.getUserInfo().currencySymbol})`} :
                <span
                  style={{
                    width: "100px",
                    background: "#ebebeb",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  ₹{sum.toLocaleString('en-IN')}
                </span>
              </div>
            </section>
          </div>
          <br />
          <div className="footer">
            <button onClick={() => close(false)}>
              <KeyboardBackspace /> Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewComponentsBom;
