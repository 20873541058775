import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../../../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AppConstants from "../../../../../utils/app-constants";
import ReturnActions from "./ReturnActions";
import SearchBox from "../../../../../components/search/SearchBox";
import RpiDetails from "./RpiDetails";
import auth from "../../../../../utils/auth";

const { ExportCSVButton } = CSVExport;

const ReturnProgress = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [viewSpecs, setViewSpecs] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [open, setOpen] = useState(false);

  const [row, setRow] = useState();
  const [formValue, setFormValue] = useState({
    rpiNumber:"",
    status:"",
    date:""
  });
  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`storeManager-module/api/v1/returns/getAllRPI?rpiNo=${formValue.rpiNumber}&&status=${formValue.status}&&date=${formValue.date}`)
    );
    let filteredData = resp?.filter((item) => {
      return (item.status == "RPI Generated" || item.status == "Returns Request Placed" || item.status == "Return Settled" || item.status == "Return Note Generated")
    })
    setUsersData(filteredData);
  };

  useEffect(() => {
    fetchReport();
  }, []);



  const uniquerpiNo = [...new Set(UsersData?.map((item) => item.rpiNo))];
  const uniquStatus = [...new Set(UsersData?.map((item) => item.status))];


  const specsHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    setViewSpecs(true);
  };

  const actionHandler = (rowIndex) => {
    setActionShow(rowIndex);
    setOpen(true);
    on();
  };

  const rpiFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <span
          className="action-btn"
        // onClick={() => specsHandler(row, rowIndex)}
        // style={{ color: "#0A18A3" }}
        >
          {row.rpiNo}
        </span>
        {actionShow === rowIndex && viewSpecs ? (
          <RpiDetails rowdata={row} status={true} closeModal={setViewSpecs} />
        ) : null}
      </div>
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow == rowIndex && open ? (
          <ReturnActions rowData={row} rowIndex={rowIndex}/>
        ) : null}
      </div>
    );
  };

  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    return cell === AppConstants.RPI_GENERATED ? (
      <button className="reactive-btn">
        <span className="reactive-btn-font">RPI Generated</span>
      </button>
    ) : cell === AppConstants.RETURNS_REQUEST_PLACED  || cell === "Returns Request Placed"  ? (
      <button className="deactive-btn">
        {" "}
        <span className="deactive-btn-font">Return Request Placed</span>
      </button>
    ) : cell === AppConstants.RETURN_SETTLED || cell == "Return Note Generated" ? (
      <button className="active-btn">
        {" "}
        <span className="active-btn-font">Return Settled</span>
      </button>
    ) : (
      cell
    );
  };

  function dateFormatter(cell) {
    let date = cell
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "RPI No.",
      dataField: "rpiNo",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatExtraData: { viewSpecs },
      formatter: rpiFormatter,
    },
    {
      text: "RPI Date",
      dataField: "rpiDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: dateFormatter,
    },
    {
      text: "Return Units",
      dataField: "returnedUnits",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: `Return Value (${auth.getUserInfo().currencySymbol})`,
      dataField: "returnValue",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Status",
      dataField: "status",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { open, actionShow },
      formatter: actionFormatter,
    },
  ];

  const [show, setShow] = useState(false);
  

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async(e) => {
    setFormValue({
      rpiNumber:"",
      status:"",
      date:""
    });
    const resp = await trackPromise(
      API.get(`storeManager-module/api/v1/returns/getAllRPI`)
    );
    let filteredData = resp?.filter((item) => {
      return (item.status == "RPI Generated" || item.status == "Returns Request Placed" || item.status == "Return Settled" || item.status == "Return Note Generated")
    })
    setUsersData(filteredData);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Return Progress</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <div
                  id="abc"
                  // onSubmit={submitHandler}
                  className="RA_block"
                  style={{
                    height: "100px",
                    justifyContent: "flex-start",
                    backgroundColor: "#F7F7F7",
                  }}
                >
                  <div
                    className="bomfilter_fields"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "25%",
                      }}
                    >
                      <label htmlFor="" style={{fontSize:"12px", fontWeight:"500"}}>
                        RPI Number
                      </label>
                      <select
                      style={{width:"95%", marginTop:"-2%"}}
                        name="rpiNumber"
                        value={formValue.rpiNumber}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          RPI Number
                        </option>
                        {uniquerpiNo.map((item) => (
                          <option>{item}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "25%",
                      }}
                    >
                      <label htmlFor="" style={{fontSize:"12px", fontWeight:"500"}}>
                        Status
                      </label>
                      <select
                        name="status"
                        style={{width:"95%", marginTop:"-2%"}}
                        value={formValue.status}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Status
                        </option>
                        {uniquStatus.map((item) => (
                          <option>{item}</option>
                        ))}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "25%",
                      }}
                    >
                      <label htmlFor="" style={{fontSize:"12px", fontWeight:"500"}}>Date Range</label>
                      <input
                      style={{width:"95%", marginTop:"-2%"}}
                        type="date"
                        name="date"
                        placeholder="RPI Date"
                        onFocus="(this.type = 'date')"
                        onBlur="(this.type='text')"
                        onChange={handleChange}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "25%",
                        marginTop:"2%"
                      }}
                    >
                      <button
                        type="submit"
                        id="apply"
                        onClick={fetchReport}
                        style={{ width: "96px", height: "35px" }}
                      >
                        Apply
                      </button>
                      <button
                        onClick={clearFilter}
                        id="clear"
                        style={{
                          width: "96px",
                          height: "35px",
                          marginLeft: "5px",
                        }}
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>
                </div>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ReturnProgress;
