import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  KeyboardBackspace,
  CheckCircle,
  HighlightOffOutlined,
  ConfirmationNumber,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";
import {
  faEdit,
  faBan,
  faShare,
  faPauseCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import auth from "../../../utils/auth";
import ConfirmationBox from "../ConfirmationBox";
import VehiclePurchaseOrderSummary from "./VehiclePurchaseOrderSummary";
import POPreview from "../../POPreview/POPreview"

const GeneratePO = ({ closeModal, rowData, updatedData , OpenOne , actionShow}) => {
  const [openModal, setOpenModal] = useState(false);
  const [unitPrice, setUnitPrice] = useState();
  const [battery, setBattery] = useState();
  const [billingAddress, setBillingAddress] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState({
    DealerAddress: "",
    dealerName: "",
    gstin: ""
  });
  const [selectedShippingAddress, setSelectedShippingAddress] = useState({});


  console.log("----->", billingAddress.map((item) => item))
  console.log("billingAddress-->", billingAddress)
  console.log("selectedBillingAddress-->", selectedBillingAddress)

  const handleInputChange = (e) => {
    console.log("-->", e.target);
    console.log(e.target.value);
    if (e.target.name == "billingAddress") {
      const filtData = billingAddress.filter((item) => item.DealerAddress == e.target.value)
      setSelectedBillingAddress(filtData[0]);
    }
    if (e.target.name == "shippindAddress") {
      let shippingObject = shippingAddress.filter((item) => {
        return item?.showroomID == e.target.value;
      });
      console.log(...shippingObject);
      setSelectedShippingAddress(...shippingObject);
    }
  };

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(`dealer-module/api/v1/requisitionAndOrder/address`)
    );
    console.log(resp);
    setShippingAddress(resp);
    const resp2 = await trackPromise(
      API.get(`dealer-module/api/v1/requisitionAndOrder/getDealerAddress`)
    );
    console.log(resp2);
    setBillingAddress(resp2);
  };
  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "1200px", height: "500px", padding: "15px" }}
      >
        <div className="header">
          <h3>Generate Purchase Order</h3>
        </div>
        <br />
        <div
          className="body"
          style={{
            display: "flex",
            height: "450px",
            fontSize: "14px",
            overflow: "scroll",
          }}
        >
          <section style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Showroom Name : {rowData?.showroomName}</span>
              <span>Requisition Number : {rowData?.requisitionID}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* <span>
                Total Vehicle Requested : {rowData?.vehicleData.length}{" "}
              </span> */}
              <span>Address : {rowData?.showroomLocation}</span>
            </div>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Total Vehicle Requested : {rowData?.vehicleData.length} </span>
                        </div> */}

            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div>Select Billing Address</div>
                <select
                  required={true}
                  name="billingAddress"
                  style={{ width: "100%" }}
                  className="input-config"
                  onChange={(event) => handleInputChange(event)}
                >
                  <option className="locationOption" value="" disabled selected>
                    Select Billing Address
                  </option>
                  {billingAddress?.map((item, index) => {
                    console.log("item-->", item)
                    return (
                      <option value={item?.DealerAddress}>
                        {item?.DealerAddress}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div>Select Shipping Address</div>
                <select
                  required={true}
                  name="shippindAddress"
                  style={{ width: "100%" }}
                  className="input-config"
                  onChange={(event) => handleInputChange(event)}
                >
                  <option className="locationOption" value="" disabled selected>
                    Select Shipping Address
                  </option>
                  {shippingAddress.map((item) => (
                    <option value={item?.showroomID}>
                      {item?.showroomAddress}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <br />
            <div style={{ overflow: "scroll" }}>
              <div id="POVendorHeading" style={{ width: "100%" }}>
                <div style={{ width: "3%" }}>S. No.</div>
                <div style={{ width: "9%" }}>Vehicle SKU</div>
                <div style={{ width: "8%" }}>Segment</div>
                <div style={{ width: "9%" }}>Category</div>
                <div style={{ width: "9%" }}>Vehicle Model</div>
                <div style={{ width: "9%" }}>Vehicle Variant</div>
                <div style={{ width: "9%" }}>Vehicle Type</div>
                <div style={{ width: "9%" }}>Color</div>
                <div style={{ width: "13%" }}>
                  Vehicle Unit Price {`(${auth.getUserInfo().currencySymbol})`}
                </div>               

                <div style={{ width: "9%" }}>Requested Units</div>
                <div style={{ width: "9%" }}>
                  Sub Total {`(${auth.getUserInfo().currencySymbol})`}
                </div>
              </div>
              {rowData?.vehicleData.map((item, index) => {
                return (
                  <div>
                    <div
                      id="POVendorContent"
                      style={{
                        borderBottom: "1px solid #999999",
                        width: "100%",
                        boxShadow: "none",
                        padding: "0px",
                        height: "30px",
                      }}
                      key={1}
                    >
                      <div style={{ width: "3%" }}>{index + 1}</div>
                      <div style={{ width: "9%" }}>{item?.vehicleSkuCode}</div>
                      <div style={{ width: "8%" }}>{item?.vehicleSegment}</div>
                      <div style={{ width: "9%" }}>{item?.vehicleCategory}</div>
                      <div style={{ width: "9%" }}>{item?.vehicleModel}</div>
                      <div style={{ width: "9%" }}>{item?.vehicleVariant}</div>
                      <div style={{ width: "9%" }}>{item?.vehicleType}</div>
                      <div style={{ width: "9%" }}>{item?.vehicleColor}</div>
                      <div style={{ width: "13%" }}>{item?.unitPrice}</div>
                      <div style={{ width: "9%" }}>{item?.requiredQty}</div>
                      <div style={{ width: "9%" }}>
                        {parseInt(item?.unitPrice) * parseInt(item?.requiredQty)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>

        <div className="footer">
          <div>
            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => closeModal(false)}
            >
              <KeyboardBackspace style={{ fontSize: "25px" }} />
              Back
            </button>

            <button
              style={{ width: "104px", height: "42px", background: "#fff" }}
              onClick={() => setOpenModal(true)}
            >
              <CheckCircle style={{ fontSize: "25px" }} />
              Proceed
            </button>
          </div>
        </div>
      </div>

      {openModal && (
        <POPreview
          verify="GeneratePO"
          closeModal={setOpenModal}
          OpenOne={OpenOne}
          OpenTwo={closeModal}
          actionShow={actionShow}
          rowData={rowData}
          selectedShippingAddress={selectedShippingAddress}
          selectedBillingAddress={selectedBillingAddress}
          updatedData={updatedData}
          type="vehicleData"
          data="Vehicle PO Terms"
        />
      )}
    </div>
  );
};

export default GeneratePO;
