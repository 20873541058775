import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import moment from "moment";
import {
  errorNotification,
  successNotification,
} from "../../utils/notifications";
import handleExport from "../../utils/exportExcel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faFilter,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../components/modal/modal.scss";
import SearchBox from "../../components/search/SearchBox";
import ManageGRNDetails from "./action/ManageGRNDetails";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ManageGRN = () => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState();
  const [grnData, setGRNData] = useState([]);

  const GRN = async () => {
    const resp = await trackPromise(
      API.get(
        `inventory-module/api/v1/grn?GRNID=${formValue.GRNID}&GRNCreatedDate=${formValue.GRNCreatedDate}&POID=${formValue.POID}&PODate=${formValue.purchaseDate}&vendorName=${formValue.vendorName}`
      )
    );
    console.log(resp);
    setGRNData(resp);
    if (resp.success) {
      successNotification(resp.message, { autoClose: 10000 });
    } else {
      errorNotification(resp.message, { autoClose: 10000 });
    }
  };
  useEffect(() => {
    GRN();
  }, []);

  const uniqueGRNID = [...new Set(grnData.map((item) => item.GRNID))]
  const uniquePOID = [...new Set(grnData.map((item) => item.POID))]
  const uniqueVendorName = [...new Set(grnData.map((item) => item.vendorName))]
  console.log(uniqueGRNID)




  const [formValue, setFormValue] = useState({
    GRNID: "",
    GRNCreatedDate: "",
    purchaseID: "",
    purchaseDate: "",
    vendorName: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      GRNID: "",
      GRNCreatedDate: "",
      purchaseID: "",
      purchaseDate: "",
      vendorName: "",
    });
    const resp = await trackPromise(API.get(`inventory-module/api/v1/grn`));
    setGRNData(resp);
    if (resp.success) {
      successNotification(resp.message, { autoClose: 10000 });
    } else {
      errorNotification(resp.message, { autoClose: 10000 });
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const actionHandler = (rowIndex) => {
    setOpen(true);
    setActionShow(rowIndex);
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          style={{
            fontSize: "12px",
            color: "#0a18a3",
            cursor: "pointer",
            padding: "3px 9px",
            border: "1px solid #0a18a3",
            borderRadius: "4px",
            background: "white",
          }}
          onClick={() => actionHandler(rowIndex)}
        >
          View & Edit Details
        </button>
        {actionShow === rowIndex && open ? (
          <ManageGRNDetails rowData={row} closeModal={setOpen} />
        ) : null}
      </div>
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          grnData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "GRN Number",
      dataField: "GRNID",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "GRN Date",
      dataField: "GRNCreatedDate",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "Goods Received Date",
      dataField: "GRNGoodsReceivedDate",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "Remarks",
      dataField: "remarks",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell, row) => {
        if (!cell) {
          return "-"
        } else {
          return cell
        }
      }
    },
    {
      text: "PO Number",
      dataField: "POID",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "PO Date",
      dataField: "PODate",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: (cell) => {
        let date = cell;
        if (!cell) {
          return "N/A";
        }
        return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
      },
    },

    {
      text: "Vendor Name",
      dataField: "vendorName",
      headerAttrs: { width: "180px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Last Update",
      dataField: "GRNCreatedDate",
      headerAttrs: { width: "150px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: dateFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "200px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage GRN</span>
        </div>

        <ToolkitProvider keyField="id" data={grnData} columns={columns} search>
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(grnData, "manageUsers")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <form
                    onSubmit={submitHandler}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      height: "144px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                    }}
                    className="UHinputfields VMform"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        GRN Number
                      </label>
                      <select
                        name="GRNID"
                        value={formValue.GRNID}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          GRN Number
                        </option>
                        {
                          uniqueGRNID.map((item) => (
                            <option>{item}</option>
                          ))
                        }
                      </select>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        GRN Date
                      </label>
                      <input
                        type={"date"}
                        name="GRNCreatedDate"
                        className="create-grn-date"
                        selected={formValue.GRNCreatedDate}
                        onChange={(date) =>
                          setFormValue({ ...formValue, GRNCreatedDate: date })
                        }
                        isClearable
                        placeholderText="GRN Date"
                        dateFormat="DD-MM-YYYY/HH:mm"
                        autoComplete="off"
                        style={{ marginTop: "-5px", width: "100%" }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        PO Number
                      </label>
                      <select
                        name="purchaseID"
                        value={formValue.purchaseID}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          PO Number
                        </option>
                        {
                          uniquePOID.map((item) => (
                            <option>{item}</option>
                          ))
                        }
                      </select>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        PO Date
                      </label>
                      <input
                        type="date"
                        name="purchaseDate"
                        className="create-grn-date"
                        selected={formValue.purchaseDate}
                        onChange={(date) =>
                          setFormValue({ ...formValue, purchaseDate: date })
                        }
                        isClearable
                        placeholderText="PO Date"
                        dateFormat="DD-MM-YYYY/HH:mm"
                        autoComplete="off"
                        style={{ marginTop: "-5px", width: "100%" }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "24%",
                      }}
                    >
                      <label style={{ fontSize: "13px", fontWeight: "500" }}>
                        {" "}
                        Vendor Name
                      </label>
                      <select
                        name="vendorName"
                        value={formValue.vendorName}
                        onChange={handleChange}
                        style={{ marginTop: "-5px", width: "100%" }}
                      >
                        <option value="" disabled selected hidden>
                          Vendor Name
                        </option>
                        {
                          uniqueVendorName.map((item) => (
                            <option>{item}</option>
                          ))
                        }
                      </select>
                    </div>
                    <div
                      style={{
                        width: "70%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "22px",
                        marginLeft: "30px",
                      }}
                    >
                      <button type="submit" id="apply" onClick={GRN}>
                        Apply
                      </button>
                      <button
                        style={{ width: "100px" }}
                        onClick={clearFilter}
                        id="clear"
                      >
                        Clear Filter
                      </button>
                    </div>
                  </form>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ManageGRN;
