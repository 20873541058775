import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "../../components/modal/modal.scss";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AppConstants from "../../utils/app-constants";
import NG_ViewDetails from "./actions/ng-viewDetails";
import RG_ViewDetails from "./actions/rg-viewdetails";
import handleExport from "../../utils/exportExcel";

const { ExportCSVButton } = CSVExport;

const VehicleQC = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [open, setOpen] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    requestID: "",
    requestType: "",
    requestedBy: "",
    requestDate: "",
    GINno: "",
    status: "",
  });

  const [row, setRow] = useState();

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `management/api/v1/bom/qualitycontrol/qcvehicle?requestID=${formValue.requestID}&requestType=${formValue.requestType}&requestDate=${formValue.requestDate}&requestedBy=${formValue.requestedBy}&GIN=${formValue.GINno}&status=${formValue.status}`
      )
    );
    const final = resp.flat();
    let filteredData = final;

    const compareDates = (a, b) => {
      const dateA = new Date(a.requestDate);
      const dateB = new Date(b.requestDate);
      return dateB - dateA; // Sort in descending order (latest first)
    };

    // Sort the array based on the latest requestedDate
    filteredData.sort(compareDates);
    console.log(filteredData);
    setUsersData(filteredData);
  };

  useEffect(() => {
    fetchReport();
  }, []);

  
  

  const uniquerequestID = [...new Set(UsersData.map(item => item.requestID))];
  const uniquerequestType = [...new Set(UsersData.map(item => item.requestType))];
  const uniquerequestedBy = [...new Set(UsersData.map(item => item.requestedBy))];
  const uniqueGIN = [...new Set(UsersData.map(item => item.GIN))];
  const uniquestatus = [...new Set(UsersData.map(item => item.status))];






  const statusFormatter = (cell) => {
    return cell === AppConstants.NEWREQUEST || cell ==="New Request" ? (
      <button className="reactive-btn">
        <span className="reactive-btn-font">New Request</span>
      </button>
    ) : cell === AppConstants.ONHOLD ? (
      <button className="deactive-btn">
        <span className="deactive-btn-font">On Hold</span>
      </button>
    ) : cell === AppConstants.VERIFICATION_INPROGRESS ? (
      <button className="deactive-btn" style={{width:"100%"}}>
        <span className="deactive-btn-font">Verification in Progress</span>
      </button>
    ) : cell === AppConstants.COMPLETED ? (
      <button className="active-btn">
        <span className="active-btn-font">Completed</span>
      </button>
    ) : cell === "Passed" ? (
      <button className="active-btn">
        <span className="active-btn-font">Passed</span>
      </button>
    ) : cell === AppConstants.REJECTED ? (
      <button className="delete-btn">
        <span className="delete-btn-font">Rejected</span>
      </button>
    ) : (
      cell
    );
  };

  const actionHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    setOpen(true);
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          onClick={() => actionHandler(row, rowIndex)}
          style={{
            color: "#0A18A3",
            backgroundColor: "white",
            border: "1px solid #0A18A3",
            borderRadius: "3px",
          }}
        >
          View Details
        </button>
        {actionShow === rowIndex &&
        open &&
        row.requestType == "Verification" ? (
          <NG_ViewDetails rowdata={row} status={true} closeModal={setOpen} />
        ) : actionShow === rowIndex &&
          open &&
          row.requestType == "Re-Verification" ? (
          <RG_ViewDetails rowdata={row} status={true} closeModal={setOpen} />
        ) : null}
      </div>
    );
  };

  const typeFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.requestType == "Verification" ? (
      <p>New Goods Verification</p>
    ) : row.requestType == "Re-Verification" ? (
      <p>Return Goods Verification</p>
    ) : null;
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Request ID",
      dataField: "requestID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Request Type",
      dataField: "requestType",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: typeFormatter,
    },
    {
      text: "Requested By",
      dataField: "requestedBy",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Request Date",
      dataField: "requestDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
      formatter: dateFormatter,
    },
    {
      text: "WO ID",
      dataField: "orderID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "GIN",
      dataField: "GIN",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },

    {
      text: "Status",
      dataField: "status",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: statusFormatter,
    },
    {
      text: "Action",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, open },
      formatter: actionFormatter,
    },
  ];

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      requestID: "",
      requestType: "",
      requestedBy: "",
      requestDate: "",
      GINno: "",
      status: "",
    });
    const resp = await trackPromise(
      API.get(`management/api/v1/bom/qualitycontrol/qcvehicle`)
    );
    const final = resp.flat();
    setUsersData(final);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    fetchReport();
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Vehicle QC</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    <div
                      className="filter-refresh"
                      onClick={() => handleExport(UsersData, "Vehicle QC")}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp; Export
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <>
                    <form
                      onSubmit={submitHandler}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        height: "135px",
                        backgroundColor: "#F7F7F7",
                        padding: "5px",
                      }}
                      className="UHinputfields VMform"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request ID
                        </label>
                        <select
                          name="requestID"
                          value={formValue.requestID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Request ID
                          </option>
                          {
                            uniquerequestID.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Type
                        </label>
                        <select
                          name="requestType"
                          value={formValue.requestType}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Request Type
                          </option>
                          {
                            uniquerequestType.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Requested By
                        </label>
                        <select
                          name="requestedBy"
                          value={formValue.requestedBy}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Requested By
                          </option>
                          {
                            uniquerequestedBy.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Request Date
                        </label>
                        <input
                          type="text"
                          onFocus={(e) => (e.currentTarget.type = "date")}
                          onBlur={(e) => (e.currentTarget.type = "text")}
                          name="requestDate"
                          value={formValue.requestDate}
                          onChange={handleChange}
                          placeholder="Request Date"
                          autoComplete="off"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          GIN No.
                        </label>
                        <select
                          name="GINno"
                          value={formValue.GINno}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            GIN No.
                          </option>
                          {
                            uniqueGIN.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "24%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          {
                            uniquestatus.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          width: "22%",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <button
                          onClick={fetchReport}
                          style={{ width: "100px", marginTop: "24px" }}
                          id="apply"
                        >
                          Apply
                        </button>
                        <button
                          style={{ width: "100px", marginTop: "24px" }}
                          onClick={clearFilter}
                          id="clear"
                        >
                          Clear Filter
                        </button>
                      </div>
                    </form>
                  </>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default VehicleQC;
