import moment from "moment";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import "../../settings-tabs/homesettings.scss";
import "../../../pages/wo-shopfloor/shopfloor.scss"
import { KeyboardBackspace } from "@material-ui/icons";
import ViewEditComponents from "./viewEditComponent";
import ViewEditLabour from "./viewEditLabour";
import ViewEditAssembly from "./viewEditAssembly";
import auth from "../../../utils/auth";

const ViewEdit = ({ rowData, close, updatedData }) => {

    const [viewComp, setViewComp] = useState(false);
    const [viewLbr, setViewLbr] = useState(false);
    const [viewAssm, setViewAssm] = useState(false);

    // const viewDetails = async () => {
    //     let body = {
    //         "orderID": rowData.orderID,
    //         "productionQuantity": rowData.productionQuantity,
    //     }
    //     const response = await trackPromise(API.post(`shop-floor-module/api/v1/shopFloor/showViewDetailsOfWorkOrder`, body));
    //     console.log(response);
    //     setViewDetailsData(response);
    // };
    // useEffect(() => {
    //     viewDetails();
    // }, []);

    const showDetails = (item) => {
        setShowPassedQty(true)
        setItem(item)
    }
    return (
        <>
            {viewComp && <ViewEditComponents rowdata={rowData} close={setViewComp} />}
            {viewLbr && <ViewEditLabour rowdata={rowData} close={setViewLbr} />}
            {viewAssm && <ViewEditAssembly rowdata={rowData} close={setViewAssm} />}

            <div className="bussiness">
                <h4 className="heading">View Details (Request ID : {"0254648"})</h4>
            </div>

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "16px",
                    fontWeight: "500",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>BOM Details</span>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "auto", height: "30px", padding: "5px", color: "green", backgroundColor: "rgba(17, 120, 27, 0.15)", borderRadius: "3px", marginRight: "10px" }}>Approved Date : {!rowData.approveDate ? "-" : moment(rowData.approveDate).format("DD-MMM-YYYY/HH:mm")}</div>
                        <div style={{ width: "auto", height: "30px", padding: "5px", color: "blue", backgroundColor: "rgba(17, 120, 27, 0.15)", borderRadius: "3px", marginRight: "10px" }}>{`Total BOM Cost (${auth.getUserInfo().currencySymbol})`} : {rowData.totalBomCost}</div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "16px",
                    fontWeight: "500",
                    border: "2px solid #EBEBEB",
                    borderRadius: "5px",
                    padding: "5px",
                    marginTop: "6px"
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>BOM ID: {rowData.BOMID}</span>
                    <span>BOM Version: {rowData.bomVersion}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>BOM Name: {rowData.bomName}</span>
                    <span>BOM Level: {rowData.bomLevel}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>BOM Location: {rowData.bomLocation}</span>
                    <span>
                        Created By :
                        <button
                            className="action-btn"
                            style={{ color: "blue" }}><span style={{ color: "#0A18A3" }}>{rowData.createdBy}</span></button>
                    </span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>BOM Types: {rowData.bomTypes}</span>
                    <span>Created Date: {moment(rowData.createdDate).format("DD-MMM-YYYY/HH:mm")}</span>
                </div>
            </div>
            <br />
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "16px",
                    fontWeight: "500",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Vehicle Details</span>
                </div>
            </div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "16px",
                    fontWeight: "500",
                    border: "2px solid #EBEBEB",
                    borderRadius: "5px",
                    padding: "5px"
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Vehicle Category: {rowData.vehicleCategory}</span>
                    <span>Vehicle Variant: {rowData.vehicleVariant}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Vehicle Segment: {rowData.vehicleSegment}</span>
                    <span>Vehicle Type: {rowData.vehicleType}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Vehicle Model: {rowData.vehicleModel}</span>
                    <span>Vehicle Drawing: {rowData.drawingUpload}</span>
                </div>
            </div>
            <br />
            <h6>Edit BOM Details</h6>
            <div className="shopfloortbl">
                <table className="sfaction_tbl" style={{ width: "100%" }} >
                    <thead>
                        <tr>
                            <th>BOM Components</th>
                            <th>BOM Labours</th>
                            <th>BOM Assembly</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <button
                                    onClick={() => setViewComp(true)}
                                    className="action-btn"
                                    style={{ color: "blue" }}>
                                    View & Edit Components
                                </button>
                            </td>
                            <td>
                                <button
                                    onClick={() => setViewLbr(true)}
                                    className="action-btn"
                                    style={{ color: "blue" }}>
                                    View Labour
                                </button>
                            </td>
                            <td>
                                <button
                                    onClick={() => setViewAssm(true)}
                                    className="action-btn"
                                    style={{ color: "blue" }}>
                                    View Assembly
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style={{ float: "right", marginTop: "10px", width: "auto" }}>

                <div className="cust-reset  cancel-button">
                    <button
                        type="button"
                        className="btn btn-icon-label"
                        onClick={() => close(false)}
                    >
                        <div style={{ fontSize: "14px", color: "#0A18A3", fontWeight: "500" }}><KeyboardBackspace />Back</div>
                    </button>
                </div>
            </div>
        </>
    )
}

export default ViewEdit;