import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { KeyboardBackspace } from "@material-ui/icons";
import "../../work-order/workorder.scss";
import ViewSpecs from "../../wo-shopfloor/wo-sf-actions/wo-sf-viewspecs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle, faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "../BomCosting.scss";

const ViewDetailsComponents = ({ close, rowdata }) => {
    const [specsShow, setSpecsShow] = useState(false);
    const [UsersData, setUsersData] = useState(undefined);

    const fetchReport = async () => {
        const resp = await trackPromise(API.get(`procurement-module/api/v1/boms/viewComponents?BOMID=${rowdata.BOMID}`));
        console.log(resp);
        let updatedValue = resp;
        setUsersData({...UsersData,...updatedValue});
    };
    useEffect(() => {
        fetchReport();
    }, []);
    
    console.log(UsersData);
    return (
        <>
            <div className="container1">
                <div
                    className="modalContainer"
                    style={{ width: "1104px", height: "465px", padding: "15px" }}
                >
                    <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>Required Components</h3>
                        <div>
                            <button
                                className="action-btn"
                                style={{ color: "blue" }}>
                                Print
                            </button>
                            <button
                                className="action-btn"
                                style={{ color: "blue" }}>
                                Export
                            </button>
                        </div>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "16px",
                            fontWeight: "500",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Total Components: { UsersData && UsersData.totalComponents}</span>
                            <span>Priority Level: { }</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>Total Cost: { UsersData && UsersData.totalComponentsCost}</span>
                            <span>Delivery Location: { UsersData && UsersData.bomLocation}</span>
                        </div>
                    </div>
                    <div className="body" style={{ height: "300px" }}>
                        <div style={{ width: "100%", overflow: "auto", height: "285px" }}>
                            <table className="viewcomp_tbl" style={{ width: "100%" }} >
                                <thead>
                                    <tr>
                                        <th style={{ width: "5%" }}>S.No.</th>
                                        <th style={{ width: "15%" }}>Component Name</th>
                                        <th style={{ width: "8%" }}>SKU Code</th>
                                        <th style={{ width: "14%" }}>Category</th>
                                        <th style={{ width: "10%" }}>Sub Category</th>
                                        <th style={{ width: "8%" }}>Specification</th>
                                        <th style={{ width: "7%" }}>Req Qty.</th>
                                        <th style={{ width: "15%" }}>Vendor Name</th>
                                        <th style={{ width: "10%" }}>Unit Price</th>
                                        <th style={{ width: "8%" }}>Sub Total</th>
                                    </tr>
                                </thead>

                                {UsersData && UsersData.components.map((item, index) => {
                                    return (
                                        <tr>
                                            <td style={{ width: "4%" }} className="leftcell">{index + 1}</td>
                                            <td style={{ width: "13%" }}>{item.componentName}</td>
                                            <td style={{ width: "8%" }}>{item.skuCode}</td>
                                            <td style={{ width: "14%" }}>{item.category}</td>
                                            <td style={{ width: "10%" }}>{item.subCategory}</td>
                                            <td style={{ width: "8%" }}>
                                                <button
                                                    onClick={() => setSpecsShow(true)}
                                                    className="action-btn"
                                                    style={{ color: "blue" }}>
                                                    View Specs
                                                </button>
                                                {specsShow && <ViewSpecs rowData={item} close={setSpecsShow} />}
                                            </td>
                                            <td style={{ width: "6%" }}>{item.componentQuantity}</td>
                                            <td style={{ width: "13%" }}>{item.vendorName}</td>

                                            <td style={{ width: "10%" }}>₹{item.unitPrice}</td>
                                            <td style={{ width: "9%" }} className="rightcell" >₹{(item.requiredQty * item.unitPrice)}</td>
                                        </tr>
                                    )
                                })}
                            </table>
                        </div>
                    </div>

                    <br />


                    <div className="footer">
                        <button className="btn-footer" onClick={() => close(false)}><KeyboardBackspace style={{ fontSize: "25px" }} /> Back</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewDetailsComponents;