import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { errorNotification, successNotification } from "../../../utils/notifications";

const Reject = ({ closeModal, rowData, updatedData, extraData }) => {
  const [formValue, setFormValue] = useState({
    rejectReason: "",
    description: "",
  });

  const refresh = () => {
    updatedData();
  }

  const submitHandler = async () => {
    const body = {

      "status": "Rejected",

      "requisitionID": rowData.requisitionID,

      "reason": formValue.rejectReason,

      "reasonDescription": formValue.description

    };
    if(formValue.rejectReason){
      const resp = await trackPromise(API.put(`dealer-module/api/v1/requisitionAndOrder/vehicles`, body));
      { resp.success ? successNotification(resp.message) : errorNotification(resp.message) }
      closeModal(false)
      refresh()
    }else{
      errorNotification("Please enter reject reason", { autoClose: 10000 });
    }
   
  };

  const spareSubmitHandler = async () => {
    const body = {

      "status": "Rejected",

      "requisitionID": rowData.requisitionID,

      "reason": formValue.rejectReason,

      "reasonDescription": formValue.description

    };
    if(formValue.rejectReason){
      const resp = await trackPromise(API.put(`dealer-module/api/v1/requisitionAndOrder/spares`, body));
      { resp.success ? successNotification(resp.message) : errorNotification(resp.message) }
      closeModal(false)
      refresh()
    }else{
      errorNotification("Please enter reject reason", { autoClose: 10000 });
    }
    
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Reject Request</h3>
        </div>
        <div className="body">
          <label style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "53px" }}>Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="rejectReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: '100%', marginTop: '-5px' }}
            >
              <option disabled selected hidden>
                Select Reason
              </option>
              <option value="Showroom Address Not Updated">
                Showroom Address Not Updated
              </option>
              <option value="Excess Stock Available">
                Excess Stock Available
              </option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.rejectReason === "Others" ? (
            <label className="lgInput" style={{ fontSize: "13px", fontWeight: "500" }}>
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({ ...formValue, [e.target.name]: e.target.value })
                }
                placeholder="Reject Reason*"
                autoComplete="off"
              />
            </label>

          ) : null}

        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" 
          onClick={extraData == "spare" ? spareSubmitHandler : submitHandler}
          disabled={formValue.rejectReason == "" ? true : false}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reject;
