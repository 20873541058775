import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import AppConstants from "../../../utils/app-constants";
import handleExport from "../../../utils/exportExcel";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BomViewandUpdate from "./actions/bom-viewandupdate";
import "./hover.scss";
import { ro } from "date-fns/locale";
import SearchBox from "../../../components/search/SearchBox";

const { ExportCSVButton } = CSVExport;

const Bom = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [show, setShow] = useState(false);
  const [actionShow, setActionShow] = useState();
  const [openPending, setOpenPending] = useState(false);
  const [openOnhold, setOpenOnhold] = useState(false);
  const [UsersData, setUsersData] = useState([]);

  const [row, setRow] = useState();

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `finance/api/v1/porequest/bom?BOMID=${formValue.BOMID}&bomTypes=${formValue.bomType}&requestedBy=${formValue.requestedBy}&bomApprovalReqDate=${formValue.requestDate}&status=${formValue.status}`
      )
    );
    console.log(resp);
    const finalData = resp.filter((item) => {
      return (
        item.status == AppConstants.PENDING ||
        item.status == AppConstants.HOLD_MANAGEMENT ||
        item.status == AppConstants.APPROVEDBYMANAGEMENT ||
        item.status == AppConstants.REJECTED
      );
    });
    setUsersData(finalData);
  };
  useEffect(() => {
    fetchReport();
  }, []);


  const uniqueBOMID = [...new Set(UsersData.map((item)=>item.BOMID))]
  const uniquebomTypes = [...new Set(UsersData.map((item)=>item.bomTypes))]
  const uniquebomApprovalReqBy = [...new Set(UsersData.map((item)=>item.bomApprovalReqBy))]
  const uniquestatus = [...new Set(UsersData.map((item)=>item.status))]




  const statusFormatter = (cell) => {
    return cell === AppConstants.PENDING ? (
      <button className="pendingByProc-btn">
        <span className="pendingByProc-btn-font">Pending</span>
      </button>
    ) : cell === AppConstants.HOLD_MANAGEMENT ? (
      <button className="deactive-btn">
        <span className="deactive-btn-font">On Hold</span>
      </button>
    ) : cell === AppConstants.APPROVEDBYMANAGEMENT ? (
      <button className="active-btn">
        <span className="active-btn-font">Approved</span>
      </button>
    ) : cell === AppConstants.REJECTED ? (
      <button className="delete-btn">
        <span className="delete-btn-font">Rejected</span>
      </button>
    ) : (
      cell
    );
  };

  const holdFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (row.status == AppConstants.HOLD_MANAGEMENT) {
      return (
        <p className="hovertext">
          {row.reason == "Other" ? row.reasonDescription : row.reason}
        </p>
      );
    }
  };

  const actionHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    if (row.status == AppConstants.PENDING) {
      setOpenPending(true);
    } else {
      setOpenOnhold(true);
    }
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          onClick={() => actionHandler(row, rowIndex)}
          style={{
            color: "#0A18A3",
            backgroundColor: "white",
            border: "1px solid #0A18A3",
            borderRadius: "3px",
          }}
        >
          View Details
        </button>
        {/* {actionShow === rowIndex && open ? (
          <ViewDetailsManagedWO rowData={row} status={true} close={setOpen} />
        ):null} */}
      </div>
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "N/A";
    }
    return `${moment(date).format("DD-MMM-YYYY,HH:mm")}`;
  }

  const handleClick = (e) => {
    setShow(!show);
  };

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "50px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1 + ".";
      },
      sort: true,
    },
    {
      text: "BOM ID",
      dataField: "BOMID",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "180px" },
    },
    {
      text: "BOM Type",
      dataField: "bomTypes",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Requested By",
      dataField: "bomApprovalReqBy",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
    },
    {
      text: "Request Date",
      dataField: "bomApprovalReqDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: dateFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "180px" },
      formatter: statusFormatter,
    },
    {
      text: "Reason",
      dataField: "reason",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "200px" },
      formatter: holdFormatter,
    },
    {
      text: "Action Date",
      dataField: "actionDate",
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      headerAttrs: { width: "150px" },
    },
    {
      text: "Action",
      // dataField: "title",
      headerAttrs: { width: "150px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, openPending, openOnhold },
      formatter: actionFormatter,
    },
  ];

  const [formValue, setFormValue] = useState({
    BOMID: "",
    bomType: "",
    requestedBy: "",
    requestCategory: "",
    requestDate: "",
    status: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const clearFilter = async (e) => {
    setFormValue({
      BOMID: "",
      bomType: "",
      requestedBy: "",
      requestCategory: "",
      requestDate: "",
      status: "",
    });
    const resp = await trackPromise(API.get(`finance/api/v1/porequest/bom`));
    const finalData = resp.filter((item) => {
      return (
        item.status == AppConstants.PENDING ||
        item.status == AppConstants.HOLD_MANAGEMENT ||
        item.status == AppConstants.APPROVEDBYMANAGEMENT ||
        item.status == AppConstants.REJECTED
      );
    });
    setUsersData(finalData);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
  };

  if (openPending == true) {
    return <BomViewandUpdate rowdata={row} close={setOpenPending} />;
  } else if (openOnhold == true) {
    return <BomViewandUpdate rowdata={row} close={setOpenOnhold} />;
  } else {
    return (
      <>
        <div>
          <div id="overlay" onClick={(e) => off(e)}></div>
          <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
            <span>BOM</span>
          </div>

          <ToolkitProvider
            keyField="id"
            data={UsersData}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div>
                  <div style={{ float: "right", marginTop: "-39px" }}>
                    <div
                      style={{
                        display: "flex",
                        marginRight: "-15px",
                        height: "26px",
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ width: "220px" }}>
                        <SearchBar
                          {...props.searchProps}
                          placeholder="Search"
                          style={{
                            height: "25px",
                            // border: "1px solid #C6C6C6",
                            border: "none",
                            background: "white",
                            borderRadius: "3px",
                            marginRight: "-50px",
                          }}
                        />
                      </div>
                      <div onClick={clearFilter} className="filter-refresh">
                        <FontAwesomeIcon icon={faRedoAlt} />
                        &nbsp; Refresh
                      </div>
                      <div className="filter-refresh" onClick={handleClick}>
                        {" "}
                        <FontAwesomeIcon icon={faFilter} />
                        &nbsp; Filters
                      </div>
                      <div
                        className="filter-refresh"
                        onClick={() => handleExport(UsersData, "New Request")}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                        &nbsp; Export
                      </div>
                      {inDashboard ? (
                        <div
                          className="filter-refresh"
                          onClick={() => inDashboard(false)}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  {show && (
                    <>
                      <form
                        onSubmit={submitHandler}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          height: "135px",
                          backgroundColor: "#F7F7F7",
                          padding: "5px",
                        }}
                        className="UHinputfields VMform"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            BOM ID
                          </label>
                          <select
                            name="BOMID"
                            value={formValue.BOMID}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              BOM ID
                            </option>
                            {uniqueBOMID.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            BOM Type
                          </label>
                          <select
                            name="bomType"
                            value={formValue.bomType}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              BOM Type
                            </option>
                            {uniquebomTypes.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Requested By
                          </label>
                          <select
                            name="requestedBy"
                            value={formValue.requestedBy}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Requested By
                            </option>
                            {uniquebomApprovalReqBy.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Request Date
                          </label>
                          <input
                            type="date"
                            onFocus={(e) => (e.currentTarget.type = "date")}
                            onBlur={(e) => (e.currentTarget.type = "text")}
                            name="requestDate"
                            value={formValue.requestDate}
                            onChange={handleChange}
                            placeholder="Request Date"
                            autoComplete="off"
                            style={{ marginTop: "-5px", width: "100%" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "24%",
                          }}
                        >
                          <label
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            Status
                          </label>
                          <select
                            name="status"
                            value={formValue.status}
                            onChange={handleChange}
                            style={{ marginTop: "-5px", width: "100%" }}
                          >
                            <option value="" disabled selected>
                              Status
                            </option>
                            {uniquestatus.map((item) => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>
                        <div
                          style={{
                            width: "46%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <button
                            onClick={fetchReport}
                            style={{ width: "100px", marginTop: "24px" }}
                            id="apply"
                          >
                            Apply
                          </button>
                          <button
                            style={{ width: "100px", marginTop: "24px" }}
                            onClick={clearFilter}
                            id="clear"
                          >
                            Clear Filter
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                  <br></br>
                </div>

                <BootstrapTable
                  wrapperClasses="table-responsive"
                  // rowClasses="text-nowrap"
                  rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                  pagination={paginationFactory({
                    hidePageListOnlyOnePage: true,
                    showTotal: true,
                    hideSizePerPage: true,
                    paginationSize: 2,
                    sizePerPage: "10",
                  })}
                  noDataIndication="No Data Found"
                  {...props.baseProps}
                />
              </>
            )}
          </ToolkitProvider>
        </div>
      </>
    );
  }
};
export default Bom;
