import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import '../../../../components/modal/modal.scss'
import { successNotification, errorNotification } from "../../../../utils/notifications";
import auth from "../../../../utils/auth";

const DeactivateScUser = ({ updatedData, editData, closeModal, tabName }) => {
  console.log(tabName)
  const [formValue, setFormValue] = useState({
    deactivationReason: "",
    description: "",
  });

  const submitShowroomHandler = async () => {
    console.log(formValue);
    // const resp = await trackPromise(API.post(`admin-module/api/v1/users/deactivate`, editDetails));

    // const { success,message} = resp;

    // if (success) {
    //     closeModal(false);
    //     successNotification(message, { autoClose: 3000 });
    //     updatedData();
    // } else {
    //     errorNotification(message, { autoclose: 2000 });
    // }
  };

  const submitServiceCenterHandler = async () => {
    let editDetails = {
      userID: editData.userID,
      reason: formValue.deactivationReason,
      description: formValue.description,
      status: "Deactivated"
    }
    const resp = await trackPromise(API.post(`serviceCenter-module/api/v1/user/status`, editDetails));

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      updatedData();
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Deactivate</h3>
        </div>

        <div className="body">
          <label style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "83px" }}>Deactivation Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="deactivationReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: '100%', marginTop: '-5px' }}
            >
              <option value="" disabled selected>Select Deactivation Reason</option>
              <option value="Temporary Deactivation">Temporary Deactivation</option>
              <option value="Misconduct">Misconduct</option>
              <option value="Revenue Loss">Revenue Loss</option>

              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.deactivationReason === "Others" ?
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({ ...formValue, [e.target.name]: e.target.value })
                }
                placeholder="Deactivation Reason"
                autoComplete="off"
              />
            </label> : ""}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={tabName == "serviceCenter" ? submitServiceCenterHandler : submitShowroomHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeactivateScUser