import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faFilter,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import Action from "../../components/modal/Action";
import "../../../components/modal/modal.scss";
import auth from "../../../utils/auth";
import moment from "moment";
import "../../../pages/vendors/Vendors.scss";
import AppConstants from "../../../utils/app-constants";
import SearchBox from "../../../components/search/SearchBox";
import Action from "./Action.jsx";
import ViewSpecs from "./ViewSpecs";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const ManageReturns = ({ inDashboard }) => {
  const { SearchBar } = Search;
  const [actionShow, setActionShow] = useState();
  const [viewSpecs, setViewSpecs] = useState();
  const [UsersData, setUsersData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState();
  const [row, setRow] = useState();
  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState({
    jobCardID: "",
    jobType: "",
    vehicleRegNo: "",
    status: "",
    from: "",
    to: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const fetchReport = async () => {
    const resp = await trackPromise(
      API.get(
        `serviceAdvisor-module/api/v1/serviceRequestAndReturns/manageReturns?jobID=${formValue.jobCardID}&&jobType=${formValue.jobType}&&vehicleRegistrationNo=${formValue.vehicleRegNo}&&status=${formValue.status}&&from=${formValue.from}&&to=${formValue.to}`
      )
    );
    console.log(resp);
    setUsersData(resp);
  };
  useEffect(() => {
    fetchReport();
  }, []);



  const uniquejobID = [...new Set(UsersData.map((item)=>item.jobID))]
  const uniquejobType = [...new Set(UsersData.map((item)=>item.jobType))]
  const uniquevehicleRegistrationNo = [...new Set(UsersData.map((item)=>item.vehicleRegistrationNo))]
  const uniquestatus = [...new Set(UsersData.map((item)=>item.status))]





  const clearFilter = async (e) => {
    setFormValue({
      jobCardID: "",
      jobType: "",
      vehicleRegNo: "",
      status: "",
      from: "",
      to: "",
    });

    const resp = await trackPromise(
      API.get(
        `serviceAdvisor-module/api/v1/serviceRequestAndReturns/manageReturns`
      )
    );
    setUsersData(resp);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(formValue);
    console.log("Filter Applied!");
  };

  const handleClick = (e) => {
    setShow(!show);
  };

  const specsHandler = (row, rowIndex) => {
    setActionShow(rowIndex);
    setRow(row);
    setViewSpecs(true);
  };

  const specsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="action-btn"
          onClick={() => specsHandler(row, rowIndex)}
          style={{ color: "#0A18A3" }}
        >
          View Details
        </button>
        {actionShow === rowIndex && viewSpecs ? (
          <ViewSpecs rowData={row} status={true} closeModal={setViewSpecs} />
        ) : null}
      </div>
    );
  };

  const statusFormatter = (cell, row, rowIndex) => {
    return cell === AppConstants.RETURNS_PROCEED ? (
      <button className="active-btn">
        <span className="active-btn-font">Returns Processed</span>
      </button>
    ) : cell === AppConstants.PENDING_AT_STORE ? (
      <button className="deactive-btn">
        <span className="deactive-btn-font">Pending at Store</span>
      </button>
    ) : (
      cell
    );
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  }

  function off() {
    document.getElementById("overlay").style.display = "none";
    setIsOpen(false);
  }

  const actionHandler = (rowIndex) => {
    setIsOpen(true);
    setActionShow(rowIndex);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.status === AppConstants.DELETED ? (
      ""
    ) : (
      <div>
        <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {actionShow === rowIndex && isOpen ? (
          <Action
            updatedData={fetchReport}
            rowData={row}
            setIsOpen={setIsOpen}
          />
        ) : null}
      </div>
    );
  };

  function dateFormatter(cell) {
    let date = cell;
    if (!cell) {
      return "-";
    }
    return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`;
  }

  const columns = [
    {
      text: "S.No.",
      dataField: "id",
      headerAttrs: { width: "60px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          UsersData.findIndex(function (value, idx, arr) {
            return value == row;
          }) + 1
        );
      },
      sort: true,
    },
    {
      text: "Job Card ID",
      dataField: "jobID",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Job Type",
      dataField: "jobType",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Job Date",
      dataField: "jobDate",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "Vehicle Reg. No.",
      dataField: "vehicleRegistrationNo",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
    },
    {
      text: "Return Date",
      dataField: "returnDate",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "Returned Spare",
      dataField: "returnSpares",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { viewSpecs, actionShow },
      formatter: specsFormatter,
    },
    {
      text: "Status",
      dataField: "status",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: statusFormatter,
    },
    {
      text: "Last Update",
      dataField: "lastUpdated",
      classes: "cellsOverflow",
      headerAttrs: { width: "200px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatter: dateFormatter,
    },
    {
      text: "Action",
      dataField: "action",
      headerAttrs: { width: "60px" },
      headerStyle: { fontWeight: "500", borderTop: "1px solid #CDCDCD" },
      formatExtraData: { actionShow, isOpen },
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <div>
        <div id="overlay" onClick={(e) => off(e)}></div>
        <div className="heading bussiness" style={{ marginBottom: "-5px" }}>
          <span>Manage Returns</span>
        </div>

        <ToolkitProvider
          keyField="id"
          data={UsersData}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div>
                <div style={{ float: "right", marginTop: "-42px" }}>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "-15px",
                      height: "26px",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ width: "220px" }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search..."
                        style={{
                          height: "25px",
                          // border: "1px solid #C6C6C6",
                          border: "none",
                          background: "white",
                          borderRadius: "3px",
                          marginRight: "-50px",
                        }}
                      />
                    </div>
                    <div onClick={clearFilter} className="filter-refresh">
                      <FontAwesomeIcon icon={faRedoAlt} />
                      &nbsp; Refresh
                    </div>
                    <div className="filter-refresh" onClick={handleClick}>
                      {" "}
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp; Filters
                    </div>
                    {inDashboard ? (
                      <div
                        className="filter-refresh"
                        onClick={() => inDashboard(false)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div>
                {show && (
                  <div
                    className="UHinputfields"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "135px",
                      backgroundColor: "#F7F7F7",
                      padding: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Job Card ID
                        </label>
                        <select
                          name="jobCardID"
                          value={formValue.jobCardID}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Job Card ID
                          </option>
                          {
                            uniquejobID.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Job Type
                        </label>
                        <select
                          type="text"
                          name="jobType"
                          value={formValue.jobType}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Job Type
                          </option>
                          {
                            uniquejobType.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          vehicle Reg No
                        </label>
                        <select
                          name="vehicleRegNo"
                          value={formValue.vehicleRegNo}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Vehicle Reg No
                          </option>
                          {
                            uniquevehicleRegistrationNo.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          Status
                        </label>
                        <select
                          name="status"
                          value={formValue.status}
                          onChange={handleChange}
                          style={{ marginTop: "-5px", width: "100%" }}
                        >
                          <option value="" disabled selected>
                            Status
                          </option>
                          {
                            uniquestatus.map((item)=>(
                              <option>{item}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          From
                        </label>
                        <input
                          type="date"
                          name="from"
                          defaultValue={formValue.from}
                          onChange={handleChange}
                          placeholder="From"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          marginRight: "2%",
                        }}
                      >
                        <label style={{ fontSize: "13px", fontWeight: "500" }}>
                          To
                        </label>
                        <input
                          type="date"
                          name="to"
                          defaultValue={formValue.to}
                          onChange={handleChange}
                          placeholder="From"
                          style={{ marginTop: "-5px", width: "100%" }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          justifyContent: "end",
                          width: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <button
                          id="apply"
                          style={{ marginTop: "22px", marginLeft: "10px" }}
                          onClick={fetchReport}
                        >
                          Apply
                        </button>
                        <button
                          onClick={clearFilter}
                          id="clear"
                          style={{ marginTop: "22px", width: "auto" }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <br></br>
              </div>

              <BootstrapTable
                wrapperClasses="table-responsive"
                // rowClasses="text-nowrap"
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  showTotal: true,
                  hideSizePerPage: true,
                  paginationSize: 2,
                  sizePerPage: "10",
                })}
                noDataIndication="No Data Found"
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default ManageReturns;
