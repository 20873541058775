import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Action from "./action/Action";
import "../../../components/modal/modal.scss";
import { KeyboardBackspace } from "@material-ui/icons";
import AddVendorModal from "./action/addVendorModal";
import auth from "../../../utils/auth";

const ViewVendorDetails = ({ updatedData,rowData, closeModal }) => {
  const [actionShow, setActionShow] = useState(false);
  const [showAddVendor, setAddVendor] = useState();
  const [open, setOpen] = useState();

  const submitHandler = () => {
    console.log("export succesfully!");
  };

  function on() {
    document.getElementById("overlay").style.display = "block";
  };

  function off() {
    document.getElementById("overlay").style.display = "none";
    setOpen(false);
  }

  const actionHandler = (row) => {
    setOpen(true);
    setActionShow(row.vendorID);
    on();
  };

  const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button className="action-btn" onClick={() => actionHandler(row)}>
          <FontAwesomeIcon id="outside" icon={faEllipsisH} />
        </button>
        {(actionShow === row.vendorID) && open ? <Action updatedData={updatedData} componentID={rowData.componentID} rowData={row}/> : null}
      </div>
    );
  };

  const locationFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div style={{ textAlign: "center",overflow:"auto" }}>
        {row.deliveryDetails.map((loc) => (
          <div>{loc.deliveryLocation}</div>
        ))}
      </div>
    );
  };

  const priceFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log(row)
    return (
      <div style={{ textAlign: "center" }}>
      {row?.deliveryDetails?.map((loc) => (
        <div>{`${auth.getUserInfo().currencySymbol}`}{loc.unitPrice}/piece</div>
      ))}
    </div>
    );
  };

  const columns = [
    {
      text: "Vendor Name",
      dataField: "vendorName",
      headerAttrs: { width: "180px" },
      classes:"cellsOverflow",
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      }
    },
    {
      text: "Vendor Part Name",
      dataField: "vendorPartName",
      headerAttrs: { width: "180px" },
      classes:"cellsOverflow",
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      }
    },
    {
      text: "Vendor Part No.",
      dataField: "vendorPartCode",
      headerAttrs: { width: "180px" },
      classes:"cellsOverflow",
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Vendor Location",
      dataField: "vendorCity",
      headerAttrs: { width: "180px" },
      classes:"cellsOverflow",
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
    },
    {
      text: "Delivery Location",
      headerAttrs: { width: "160px" },
      classes:"cellsOverflow",
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: locationFormatter,
    },
    {
      text: `Price (${auth.getUserInfo().currencySymbol})`,
      headerAttrs: { width: "100px" },
      classes:"cellsOverflow",
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatter: priceFormatter,
    },
    {
      text: "Action",
      headerAttrs: { width: "60px" },
      headerStyle: {
        fontWeight: "500",
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      formatExtraData: {actionShow,open},
      formatter: actionFormatter,
    },
  ];
  return (
    <div className="container1">
       <div id="overlay" onClick={(e) => off(e)}></div>
      <div
        className="modalContainer"
        style={{ width: "1116px", height: "450px" }}
      >
        <div className="header">
          <h3>Vendor Details</h3>
        </div>
        <div className="header">
          <p style={{ margin: "0 10px 0 0" }}>Component Name : {rowData.componentName}</p>
          <p>Component SKU Code : {rowData.skuCode} </p>
        </div>

        <div className="body" style={{ overflow: "scroll" }}>
          {rowData.vendorDetails.map((item) => {
            return(
              <BootstrapTable
                wrapperClasses="table-responsive"
                rowClasses="text-nowrap"
                keyField="vendorID"
                data={[item]}
                columns={columns}
                rowStyle={{ border: "1px solid rgba(0,0,0,0.2)" }}
              />
            )
          })}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}><KeyboardBackspace/>Back</button>
          <button id="submit-button" onClick={setAddVendor}>
            Add Vendor
          </button>
          {showAddVendor && <AddVendorModal updatedData={updatedData} rowData={rowData} closeModal={setAddVendor}/>}
        </div>
      </div>
    </div>
  );
};

export default ViewVendorDetails;
