import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../../utils/api";
import '../../../../components/modal/modal.scss'
import { successNotification, errorNotification } from "../../../../utils/notifications";
import auth from "../../../../utils/auth";

function DeleteScUser({ updatedData, editData, closeModal, tabName }) {
  const [formValue, setFormValue] = useState({
    deleteReason: "",
    description: "",
  });

  const submitHandler = async () => {
  if(formValue.deleteReason){
    console.log(formValue);
    let editDetails = {
      userID: editData.userID,
      reason: formValue.deleteReason,
      description: formValue.description,
      status: "Deleted"
    }
    const resp = await trackPromise(API.post(`serviceCenter-module/api/v1/user/status`, editDetails));

    const { success, message } = resp;

    if (success) {
      closeModal(false);
      updatedData();
      successNotification(message, { autoClose: 3000 });
    } else {
      errorNotification(message, { autoclose: 2000 });
    }
  }
  else{
    return errorNotification("Please select delete reason", { autoclose: 2000 });
  }
  };

  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Delete</h3>
        </div>

        <div className="body">
          <label style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <label style={{ fontSize: "13px", fontWeight: "500", width: "83px" }}>Delete Reason<span style={{ color: "red" }}>*</span></label>
            <select
              name="deleteReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{ width: '100%', marginTop: '-5px' }}
            >
              <option value="" disabled selected>Select Reason</option>
              <option value="No Longer Required">
                No Longer Required
              </option>
              <option value="In Correct User Type">
                In Correct User Type
              </option>
              <option value="In Correct Email ID">
                In Correct Email ID
              </option>
              <option value="Others">Others</option>
            </select>
          </label>
          {formValue.deleteReason === "Others" ?
            <label className="lgInput">
              Description
              <input
                type="text"
                style={{ width: "508px" }}
                name="description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({ ...formValue, [e.target.name]: e.target.value })
                }
                placeholder="Deactivation Reason"
                autoComplete="off"
              />
            </label> : ""}
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteScUser