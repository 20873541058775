import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import {
  KeyboardBackspace,
  CheckCircle
} from "@material-ui/icons";
import {
  errorNotification,
  successNotification,
} from "../../../utils/notifications";

const Edit = ({ closeModal, editData, updatedData }) => {

  const [formValue, setFormValue] = useState(
    {
      fName: editData.fName,
      lName: editData.lName,
      email: editData.email,
    },
  );

  const handleInputChange = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  // Body Data

  let zonalBodyData = {
    countryName: editData?.countryName,
    zone: editData?.zone,
    zsmFname: formValue?.fName,
    zsmLname: formValue?.lName,
    zsmEmail: formValue?.email
  }

  const submitHandler = async () => {

    const resp = await trackPromise(API.post(`/nationalSales-module/api/v1/users/editUserDetails`, zonalBodyData));
    if (resp.success) {
      updatedData()
      closeModal(false)
      return successNotification(resp.message, { autoClose: 10000 });
    } else {
      return errorNotification(resp.message, { autoClose: 10000 });
    }


  };


  var expanded = false;
  function showCheckboxes() {
    var checkboxes = document.getElementById("checkboxes");
    if (!expanded) {
      checkboxes.style.display = "block";
      expanded = true;
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }
  }

  // const [stateValue, setStateValue] = useState(serviceDetailsData.additionalChecks);

  const onCheckboxChange = (e, item, index) => {
    // let tempInfo = [...stateValue];

    // if (e.target.checked == true) {
    //   console.log("pushed")
    //   if (tempInfo.some((info) => info.additionalID == item.additionalID)) {
    //     serviceDetailsData.additionalChecks[index].verify = true
    //   } else {
    //     tempInfo.push(item);
    //     serviceDetailsData.additionalChecks[index].verify = true
    //   }
    // } else {
    //   console.log("poped")
    //   if (tempInfo.some((info) => info.additionalID == item.additionalID)) {
    //     serviceDetailsData.additionalChecks[index].verify = false
    //   } else {
    //     tempInfo = tempInfo.filter((info) => info.additionalID != item.additionalID);
    //     serviceDetailsData.additionalChecks[index].verify = false
    //   }
    // }
    // setFormValue(tempInfo);
    // console.log(tempInfo)
  };

  return (
    <div className="container1">
      <div
        className="modalContainer"
        style={{ width: "835px", height: "326px", padding: "15px" }}
      >
        <div className="header">
          <h3>Edit User Details </h3>
        </div>
        <section>
          <form id="add-user-from" className="setting-form">
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                    marginLeft: "5px"
                  }}
                >
                  User Type
                </label>
                <input
                  type="text"
                  placeholder="User Type"
                  name="fName"
                  disabled
                  value={editData?.role}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                    marginLeft: "5px"
                  }}
                >
                  Country
                </label>
                <input
                  type="text"
                  placeholder="Country"
                  name="fName"
                  disabled
                  value={editData?.countryName}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                    marginLeft: "5px"
                  }}
                >
                  Zone/Region
                </label>
                <input
                  type="text"
                  placeholder="Zone"
                  disabled
                  value={editData?.zone}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                    marginLeft: "5px"
                  }}
                >
                  Area
                </label>
                <input
                  type="text"
                  placeholder="Area"
                  disabled
                  value={editData?.areaName}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                />
              </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                    marginLeft: "5px"
                  }}
                >
                  Mapped States
                </label>
                <select
                  name="states"
                  id="mappedStates"
                  className="input-config"
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  onClick={showCheckboxes}
                >
                  <option style={{ color: "gray" }} value="" disabled selected>
                    Mapped States
                  </option>
                  {editData?.states?.map((item, index) => {
                        return (
                          <label>
                            <input
                              className="service-list"
                              type="checkbox"
                              id={item.additionalID}
                              checked={item.verify == undefined || item.verify == false ? false : true}
                              onChange={(event) => { onCheckboxChange(event, item, index) }}
                            />{item?.stateName}</label>
                        )
                      })}
                  {/* {
                    editData?.states?.map((item) => {
                      return (
                        <>
                          <option value="" disabled>{item?.stateName}</option> 
                        </>
                      );
                    })
                  } */}
                </select>
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start",
                  }}
                >
                  First Name
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="fName"
                  defaultValue={formValue.fName}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start"
                  }}
                >
                  Last Name
                </label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lName"
                  defaultValue={formValue.lName}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div
                style={{
                  margin: "0px 5px 0px 5px",
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    width: "100%",
                    textAlign: "start"
                  }}
                >
                  Email ID
                </label>
                <input
                  type="text"
                  disabled
                  placeholder="Email ID"
                  name="email"
                  defaultValue={formValue.email}
                  style={{
                    width: "100%",
                    outline: "none",
                    marginTop: "-8px",
                  }}
                  className="input-config"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
          </form>
        </section>
        <div
          className="footer"
          style={{ width: "100%", display: "flex", flexDirection: "row" }}
        >
          <button
            style={{ width: "100px", height: "42px", background: "#fff" }}
            onClick={() => closeModal(false)}
          >
            <KeyboardBackspace style={{ fontSize: "25px" }} />
            &nbsp; Back
          </button>
          <button
            onClick={submitHandler}
            style={{
              marginRight: "0",
              width: "120px",
              height: "42px",
              backgroundColor: "#0A18A3",
            }}
            id="submit-button"
          >
            <CheckCircle style={{ fontSize: "17px" }} />
            &nbsp; Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default Edit;
