import React, { useState,useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import '../../../components/modal/modal.scss'
import { successNotification,errorNotification } from "../../../utils/notifications";
import auth from "../../../utils/auth";

const ShowroomReactivate = ({ updatedData,editData,closeModal,tabName }) => {
  const [formValue, setFormValue] = useState({
    reactivationReason: "",
    description: "",
  });

  const editDetails ={
    userID:editData.businessID,
    userType:editData.businessUserType,
    status:"Reactivate",
    reason:formValue.reactivationReason,
    description:formValue.description
  }

  const submitShowroomHandler = async () => {
    console.log(formValue);
    const resp = await trackPromise(API.post(`dealer-module/api/v1/businessusers/changeStatus`, editDetails));
    
    const { success,message} = resp;
    
    if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        updatedData();
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
  };

  const submitServiceCenterHandler = async () => {
    console.log(formValue);
    const resp = await trackPromise(API.post(`dealer-module/api/v1/businessusers/changeStatus`, editDetails));
    
    const { success,message} = resp;
    
    if (success) {
        closeModal(false);
        successNotification(message, { autoClose: 3000 });
        updatedData();
    } else {
        errorNotification(message, { autoclose: 2000 });
    }
  };
  
  return (
    <div className="container1">
      <div className="modalContainer">
        <div className="header">
          <h3>Deactivate</h3>
        </div>

        <div className="body">
          <label style={{display:"flex", justifyContent:"start", textAlign:"start", flexDirection:"column"}}>
          <label style={{fontSize: "13px", fontWeight: "500"}}>Reactivation Reason<span style={{color:"red"}}>*</span></label>
            <select
              name="reactivationReason"
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              style={{marginTop:"-2px"}}
            >
              <option value="" disabled selected>Reactivation Reason</option>
              <option value="Activity Halted">
                Activity Resumed
              </option>
              <option value="Others">Others</option>
            </select>
          </label>
          { formValue.reactivationReason === "Others" ? 
          <label className="lgInput">
            Description
            <input
              type="text"
              style={{ width: "508px" }}
              name="description"
              value={formValue.description}
              onChange={(e) =>
                setFormValue({ ...formValue, [e.target.name]: e.target.value })
              }
              placeholder="Deactivation Reason"
              autoComplete="off"
            />
          </label> : "" }
        </div>
        <div className="footer">
          <button onClick={() => closeModal(false)}>Cancel</button>
          <button id="submit-button" onClick={tabName == "Service Center" ? submitServiceCenterHandler : submitShowroomHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default ShowroomReactivate